import React, { useState, useEffect } from "react";
import Layout from "../../common/Layout";
import Box from "../../common/Box";
import api from "../../../actions/api";
import dayjs from "dayjs";
import { Loader } from "semantic-ui-react";

const timeSpanOptions = [
  { text: "1 Month", value: 1 },
  { text: "3 Months", value: 3 },
  { text: "6 Months", value: 6 },
  { text: "12 Months", value: 12 },
];

export default function MemberActivity() {
  const [data, setData] = useState([]);
  const [queryParams, setQueryParams] = useState({
    deploymentType: "None",
    trainingType: "None",
    timeSpan: 12,
  });
  const [trainingTypes, setTrainingTypes] = useState([]);
  const [deploymentTypes, setDeploymentTypes] = useState([]);
  const [trainingBreakpoints, setTrainingBreakpoints] = useState({
    red: 0,
    yellow: 0,
    green: 0,
  });
  const [deploymentBreakpoints, setDeploymentBreakpoints] = useState({
    red: 0,
    yellow: 0,
    green: 0,
  });

  const [currentParams, setCurrentParams] = useState({
    deploymentType: "None",
    trainingType: "None",
    timeSpan: 12,
  });
  const [mountLoading, setMountLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (mountLoading) {
      Promise.all([api.settings.getIncidentTypesList(), api.settings.all()])
        .then((res) => {
          if (res[0].length > 0) {
            setDeploymentTypes(res[0]);
          }

          if (res[1]) {
            if (res[1].activityReportDeploymentBreakpoints) {
              setDeploymentBreakpoints(
                res[1].activityReportDeploymentBreakpoints
              );
            }

            if (res[1].activityReportTrainingBreakpoints) {
              setTrainingBreakpoints(res[1].activityReportTrainingBreakpoints);
            }

            if (res[1].eventTypes !== undefined) {
              setTrainingTypes(
                res[1].eventTypes.map((item) => {
                  return { text: item.content, value: item.content };
                })
              );
            }

            let query_params = {};

            if (res[1].activityReportDeploymentDefault !== undefined) {
              query_params.deploymentType =
                res[1].activityReportDeploymentDefault;
            }

            if (res[1].activityReportTrainingDefault !== undefined) {
              query_params.trainingType = res[1].activityReportTrainingDefault;
            }

            setQueryParams({ ...query_params, ...queryParams });
            setCurrentParams({ ...query_params, ...queryParams });
          }
        })
        .finally(() => {
          setMountLoading(false);
        });
    }
  }, [mountLoading, queryParams]);

  useEffect(() => {
    if (loading && !mountLoading) {
      api.management
        .activityList(
          queryParams.trainingType,
          queryParams.deploymentType,
          queryParams.timeSpan
        )
        .then((res) => {
          setCurrentParams({ ...queryParams });
          setData(res);
          setLoading(false);
        })
        .catch((e) => {
          console.log("ERROR");
        });
    }
  }, [loading, mountLoading, queryParams]);

  return (
    <Layout full>
      <div className=" grid grid-cols-1 md:grid-cols-1 gap-4 ">
        <div className="col-span-1 md:col-span-1">
          <Box>
            <div>
              <div className="inline-block w-1/3 p-2">
                <label
                  htmlFor="deployment_type"
                  className="block text-sm font-medium text-gray-400"
                >
                  Deployment Type
                </label>
                <select
                  name="deployment_type"
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-gray-400 bg-gray-900 border-gray-300 focus:outline-none  rounded-md"
                  value={queryParams.deploymentType}
                  onChange={(e) => {
                    e.preventDefault();
                    setQueryParams({
                      ...queryParams,
                      deploymentType: e.target.value,
                    });
                  }}
                  disabled={loading === true}
                >
                  <option key={"NONE"} value={"None"}>
                    None
                  </option>
                  {deploymentTypes.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.text}
                    </option>
                  ))}
                </select>
              </div>
              <div className="inline-block w-1/3 p-2">
                <label
                  htmlFor="training_type"
                  className="block text-sm font-medium text-gray-400"
                >
                  Event Type
                </label>
                <select
                  name="training_type"
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-gray-400 bg-gray-900 border-gray-300 focus:outline-none  rounded-md"
                  value={queryParams.trainingType}
                  onChange={(e) => {
                    e.preventDefault();
                    setQueryParams({
                      ...queryParams,
                      trainingType: e.target.value,
                    });
                  }}
                  disabled={loading === true}
                >
                  <option key={"NONE"} value={"None"}>
                    None
                  </option>
                  {trainingTypes.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.text}
                    </option>
                  ))}
                </select>
              </div>
              <div className="inline-block w-1/3 p-2">
                <label
                  htmlFor="time_span"
                  className="block text-sm font-medium text-gray-400"
                >
                  Time Span
                </label>
                <select
                  name="time_span"
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-gray-400 bg-gray-900 border-gray-300 focus:outline-none  rounded-md"
                  value={queryParams.timeSpan}
                  onChange={(e) => {
                    e.preventDefault();
                    setQueryParams({
                      ...queryParams,
                      timeSpan: e.target.value,
                    });
                  }}
                  disabled={loading === true}
                >
                  {timeSpanOptions.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.text}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {(!loading &&
              (currentParams.deploymentType !== queryParams.deploymentType ||
                currentParams.timeSpan !== queryParams.timeSpan ||
                currentParams.trainingType !== queryParams.trainingType)) ||
            (!loading && data && data.length === 0) ? (
              <div className="p-2">
                <button
                  type="button"
                  className="inline p-2 w-full text-gray-400 bg-green-900 border-green-300 focus:outline-none rounded-md"
                  onClick={() => setLoading(true)}
                >
                  Search
                </button>
              </div>
            ) : (
              <></>
            )}
          </Box>
        </div>
        {loading ? (
          <Loader active={loading || mountLoading}>Loading Report...</Loader>
        ) : (
          <div className="col-span-1 md:col-span-1">
            <Box title={"Activity List"}>
              <table
                className={`min-w-full divide-y divide-gray-200 border-separate table-auto `}
              >
                <thead>
                <tr className="bg-gray-800">
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-400 uppercase ">
                    Name
                  </th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-400 uppercase w-40">
                    Last Deployment
                  </th>
                  {/* <th className="px-4 py-3 text-left text-xs font-medium text-gray-400 uppercase ">
                   Last Login
                   </th>
                   <th className="px-4 py-3 text-left text-xs font-medium text-gray-400 uppercase ">
                   Date Of Going Off Call
                   </th> */}
                  <th className="px-4 py-3 text-center text-xs font-medium text-gray-400 uppercase w-40">
                    Deployments <br/>
                    (Last {currentParams.timeSpan} Months)
                  </th>
                  <th className="px-4 py-3 text-center text-xs font-medium text-gray-400 uppercase w-40">
                    Deployments <br/>
                    Attended
                  </th>

                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-400 uppercase w-40">
                    Last Event
                  </th>
                  <th className="px-4 py-3 text-center text-xs font-medium text-gray-400 uppercase w-40">
                    Events <br/>
                    (Last {currentParams.timeSpan} Months)
                  </th>
                  <th className="px-4 py-3 text-center text-xs font-medium text-gray-400 uppercase w-40">
                    Events <br/> Attended
                  </th>
                </tr>
                </thead>
                <tbody>
                {data.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="p-2 border-b border-gray-900">
                        {item.name}{" "}
                        {item.teamId ? (
                          <span className="italic">({item.teamId})</span>
                        ) : (
                          <></>
                        )}
                      </td>
                      <td className="p-2 border-b border-gray-900">
                        {item.last_search !== "N/A"
                          ? dayjs(item.last_search).format("DD-MM-YY")
                          : item.last_search}
                      </td>
                      {/* <td className="p-2 border-b border-gray-900">
                       {item.last_login}
                       </td>
                       <td className="p-2 border-b border-gray-900">
                       {item.off_call_date}
                       </td> */}

                      <td className="p-2 border-b border-gray-900 text-center">
                        {item.searches_last_period_attended}
                      </td>
                      <td
                        className={`p-2 border-b border-gray-900 text-center ${
                          deploymentBreakpoints.red &&
                          deploymentBreakpoints.yellow &&
                          deploymentBreakpoints.green
                            ? Math.round(
                              item.searches_last_period_percentage
                            ) <= deploymentBreakpoints.red
                              ? "negative1"
                              : Math.round(
                                item.searches_last_period_percentage
                              ) > deploymentBreakpoints.red &&
                              Math.round(
                                item.searches_last_period_percentage
                              ) < deploymentBreakpoints.green
                                ? "warning1"
                                : Math.round(
                                  item.searches_last_period_percentage
                                ) >= deploymentBreakpoints.green &&
                                Math.round(
                                  item.searches_last_period_percentage
                                ) > deploymentBreakpoints.yellow
                                  ? "positive1"
                                  : ""
                            : ""
                        }`}
                      >
                        {Math.round(item.searches_last_period_percentage)}%
                      </td>
                      <td className="p-2 border-b border-gray-900">
                        {item.last_training !== "N/A"
                          ? dayjs(item.last_training).format("DD-MM-YY")
                          : item.last_training}
                      </td>
                      <td className="p-2 border-b border-gray-900 text-center">
                        {item.training_last_period_attended}
                      </td>
                      <td
                        className={`p-2 border-b border-gray-900 text-center ${
                          deploymentBreakpoints.red &&
                          deploymentBreakpoints.yellow &&
                          deploymentBreakpoints.green
                            ? Math.round(
                              item.training_last_period_percentage
                            ) <= trainingBreakpoints.red
                              ? "negative1"
                              : Math.round(
                                item.training_last_period_percentage
                              ) > trainingBreakpoints.red &&
                              Math.round(
                                item.training_last_period_percentage
                              ) < trainingBreakpoints.green
                                ? "warning1"
                                : Math.round(
                                  item.training_last_period_percentage
                                ) >= trainingBreakpoints.green &&
                                Math.round(
                                  item.training_last_period_percentage
                                ) > trainingBreakpoints.yellow
                                  ? "positive1"
                                  : ""
                            : ""
                        }`}
                      >
                        {Math.round(item.training_last_period_percentage)}%
                      </td>
                    </tr>
                  );
                })}
                </tbody>
              </table>
            </Box>
          </div>
        )}
      </div>
    </Layout>
  );
}
