import React from "react";
import { connect } from "react-redux";
import {
  Form,
  Grid,
  Header,
  Segment,
  Input,
  Button,
  Table,
  Divider,
  Label,
} from "semantic-ui-react";
import api from "../../actions/api";
import {
  getSystemSettings,
  updateSetting,
} from "../../actions/settingsActions";
import SettingList from "../common/lists/settingList";
import SettingsListColor from "../common/lists/settingListColor";
import SettingListCourses from "../common/lists/settingListCourses";
import { Select } from "semantic-ui-react";
// import SettingListRequired from "../common/lists/settingListRequired";

function mapStateToProps(state) {
  return {
    system: state.system,
  };
}
class TrainingSettingsPage extends React.Component {
  state = {
    disabled: true,
    data: {
      teamName: "",
      systemName: "",
    },
    orgRoles: [],
    comps: [],
    comp: {
      refNum: 0,
      subject: "",
    },
  };
  updateFormTimeout = 0;
  componentDidMount() {
    this.getData();
  }
  getData = () => {
    this.props.getSystemSettings();
    api.training.comps.all().then((comps) => {
      this.setState({ comps });
    });
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.system !== prevProps.system) {
      this.setState({
        data: this.props.system.settings,
      });
      const data = this.props.system.settings.orgRoles;
      let dataArray = [];
      for (var key in data) {
        dataArray.push({
          key: key,
          text: data[key],
          value: data[key],
        });
      }
      this.setState({
        orgRoles: dataArray,
      });
    }
    if (this.props.system !== prevProps.system) {
      if (
        this.props.system.settings &&
        this.props.system.settings.incidentTypes
      ) {
        this.setOptions("type", this.props.system.settings.incidentTypes);
      }
    }
  };
  toggleDisabled = () => {
    this.setState({
      disabled: !this.state.disabled,
    });
  };
  onChange = (e) =>
    this.setState(
      {
        data: { ...this.state.data, [e.target.name]: e.target.value },
      },
      () => {
        this.props.updateSetting(this.state.data, () => {});
      }
    );
  // handleDropdownChange = (props, e) => {
  //   this.setState(
  //     {
  //       data: { ...this.state.data, [e.name]: e.value },
  //     },
  //     () => {
  //       this.props.updateSetting(this.state.data, () => {});
  //     }
  //   );
  // };
  addRoleType = (r) => {
    // console.log(r);
    let list = this.props.system.settings.CPDRoles;
    // console.log("1", list);
    list.push(r);
    // console.log("2", list);
    setTimeout(() => {
      let data = {
        CPDRoles: list,
      };
      // console.log(data);
      this.props.updateSetting(data, () => {});
    }, 100);
  };
  removeRoleType = (r) => {
    let list = this.props.system.settings.CPDRoles;
    list = list.filter((t) => {
      return t !== r;
    });
    setTimeout(() => {
      let data = {
        CPDRoles: list,
      };
      this.props.updateSetting(data, () => {});
    }, 100);
  };
  updateModel = (e) => {
    this.setState({
      updating: true,
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
    if (this.updateFormTimeout) {
      clearTimeout(this.updateFormTimeout);
    }
    this.updateFormTimeout = setTimeout(() => {
      return this.props
        .updateSetting(this.state.data)
        .then((res) => {
          this.setState({
            updating: false,
          });
          //TODO add a fancy screen notification that the title has successfully updated
        })
        .catch((err) => {
          console.error("Unable to save", err);
        });
    }, 500);
  };
  handleSubmit = (data) => {
    return this.props.updateSetting(data).then(() => {
      this.getData();
    });
  };
  handleDelete = (data) => {
    this.props.updateSetting(data, () => {});
  };
  setOptions = (key, types) => {
    if (types) {
      const data = types;
      let dataArray = [];
      for (var i in data) {
        dataArray.push({
          key: i,
          text: data[i].name,
          value: data[i].name,
        });
      }
      this.setState({ [key]: dataArray });
    }
  };
  compOnChange = (e) =>
    this.setState({
      comp: { ...this.state.comp, [e.target.name]: e.target.value },
    });
  updateComps = () => {
    let data = this.state.comp;
    api.training.comps.update(data).then(() => {
      this.setState({
        comp: {
          refNum: 0,
          subject: "",
        },
      });
      this.getData();
    });
  };
  updateComp = (comp) => {
    this.setState({
      comp,
    });
  };
  renderTraining() {
    return (
      <Grid stackable doubling className={"no-border"}>
        <Grid.Row>
          <Grid.Column width={8}>
            <Segment>
              <SettingListCourses
                submit={this.handleSubmit}
                delete={this.handleDelete}
                data={
                  this.props.system.settings.courses
                    ? this.props.system.settings.courses
                    : []
                }
                placeholder="Courses..."
                title="The Courses List"
                label="Courses"
                field="courses"
              />
            </Segment>
            <Segment>
              {/* <Select
                placeholder={"pick one"}
                name={"CPDRoles"}
                options={this.state.orgRoles}
                multiple
                selection
                onChange={this.handleDropdownChange}
              />
              {this.props.system.settings &&
                this.props.system.settings.CPDRoles &&
                this.props.system.settings.CPDRoles.map((r) => {
                  return <Label>{r}</Label>;
                })} */}
              <Segment basic>
                <Header>Roles Requring CPD</Header>
                <Divider />
                <div className="flex gap2">
                  <div className="w-full border-r-2 border-gray-700">
                    {this.props.system.settings.orgRoles &&
                      this.props.system.settings.orgRoles
                        .filter((f) => {
                          return (
                            this.props.system.settings.CPDRoles.indexOf(f) ===
                            -1
                          );
                        })
                        .map((t, i) => {
                          return (
                            <div
                              className="flex p-2 text-lg justify-between border-b-2 border-gray-900  content-center items-center	 "
                              key={(t, i)}
                            >
                              <span>{t}</span>
                              <button
                                className="py-2 px-2 bg-green-800 border border-green-400 rounded-md hover:bg-green-700"
                                onClick={() => this.addRoleType(t)}
                              >
                                Add
                              </button>
                            </div>
                          );
                        })}
                  </div>

                  <div className="w-full ">
                    {this.props.system.settings.CPDRoles &&
                    this.props.system.settings.CPDRoles.length > 0
                      ? this.props.system.settings.CPDRoles.map((t, i) => {
                          return (
                            <div
                              className="flex p-2 text-lg justify-between border-b-2 border-gray-900  content-center items-center	 "
                              key={(t, i)}
                            >
                              <button
                                className="py-2 px-2 bg-red-800 border border-red-400 rounded-md hover:bg-red-700"
                                onClick={() => this.removeRoleType(t)}
                              >
                                Remove
                              </button>
                              <span>{t}</span>
                            </div>
                          );
                        })
                      : ""}
                  </div>
                </div>
              </Segment>
            </Segment>
          </Grid.Column>
          <Grid.Column width={8}>
            <Segment>
              <SettingList
                submit={this.handleSubmit}
                delete={this.handleDelete}
                data={
                  this.props.system.settings.trainingTypes
                    ? this.props.system.settings.trainingTypes
                    : []
                }
                placeholder="log types..."
                title="Training Log List"
                label="Training Types"
                field="trainingTypes"
              />
            </Segment>
            {this.renderComps()}
          </Grid.Column>
        </Grid.Row>
        {/* <Grid.Row>
          <Grid.Column width={8}>
            <SettingListComps title="The Comp List" />
          </Grid.Column>
        </Grid.Row> */}
      </Grid>
    );
  }
  renderComps() {
    return (
      <>
        <Segment>
          <Header>Competency Requirements</Header>
          <Divider />
          <Form onSubmit={this.updateComps}>
            <Form.Group width={3}>
              <Form.Field>
                <Input
                  name="refNum"
                  value={this.state.comp.refNum}
                  type="number"
                  required
                  onChange={this.compOnChange}
                />
              </Form.Field>
              <Form.Field width={8}>
                <Input
                  name="subject"
                  value={this.state.comp.subject}
                  required
                  onChange={this.compOnChange}
                />
              </Form.Field>
              <Form.Field>
                <Button type="submit" positive>
                  Save
                </Button>
              </Form.Field>
            </Form.Group>
          </Form>
          {/* <Divider /> */}
          <Table striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Ref</Table.HeaderCell>
                <Table.HeaderCell>Subject</Table.HeaderCell>
                <Table.HeaderCell>Sections</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.state.comps.map((c, i) => {
                return (
                  <Table.Row key={i}>
                    <Table.Cell>{c.refNum}</Table.Cell>
                    <Table.Cell>{c.subject}</Table.Cell>
                    <Table.Cell>{c.Sections.length}</Table.Cell>
                    <Table.Cell>
                      <Button
                        icon="pencil"
                        primary
                        size={"tiny"}
                        onClick={() => this.updateComp(c)}
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </Segment>

        <Segment>
          <SettingsListColor
            submit={this.handleSubmit}
            delete={this.handleDelete}
            data={
              this.props.system.settings.compScoreColours
                ? this.props.system.settings.compScoreColours
                : []
            }
            placeholder="1,2,3,4......"
            title="Comp Score Colours"
            field="compScoreColours"
          />
        </Segment>
      </>
    );
  }
  render() {
    // console.log(this.props.system.settings);
    return <Segment basic>{this.renderTraining()}</Segment>;
  }
}

export default connect(mapStateToProps, {
  getSystemSettings,
  updateSetting,
})(TrainingSettingsPage);
