import React, { Component } from "react";
import { Segment, Header, Grid, Divider, Table } from "semantic-ui-react";
import api from "../../../actions/api";
export default class CompCard extends Component {
  state = {
    score: {},
    totalPercent: 0,
    loading: true,
  };
  componentDidMount() {
    if (this.props.member) {
      this.getData();
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.member !== prevProps.member) {
      this.getData();
    }
  }
  getData = () => {
    this.setState(
      {
        loading: true,
      },
      () => {
        api.training.comps
          .getMemberCompScore(this.props.comp._id, this.props.member)
          .then((res) => {
            this.setState({
              score: res.scores,
              totalPercent: res.totalPercent,
            });
          })
          .catch((err) => {
            //TODO fill me in
          })
          .finally(() => {
            this.setState({
              loading: false,
            });
          });
      }
    );
  };
  getTheScore = (s) => {
    if (this.state.score[s._id] !== undefined) {
      return this.state.score[s._id];
    }
    return "[UNKNOWN]";
  };
  render() {
    let color = "negative1";
    if (this.state.totalPercent) {
      switch (true) {
        case this.state.totalPercent >= 75:
          color = "positive1";
          break;
        case this.state.totalPercent >= 26:
          color = "warning";
          break;
        case this.state.totalPercent < 26:
          color = "negative1";
          break;
        default:
          color = "negative1";
          break;
      }
    }
    return (
      <Grid.Column>
        <Segment className={`reqBox  no-pad`} raised>
          <Segment basic className={`no-marg ${color}`}>
            <Header as="h2">{this.props.comp.subject}</Header>
          </Segment>
          <Divider className="no-marg" />
          <Header as={"h2"}>
            Score: <strong>{this.state.totalPercent}%</strong>
          </Header>
          {this.props.showScores && (
            <>
              <Divider />
              <Table className="compTable">
                <Table.Body>
                  {this.props.comp.Sections.map((s) => {
                    return (
                      <Table.Row key={s._id}>
                        <Table.Cell>{s.title}</Table.Cell>
                        <Table.Cell>{this.getTheScore(s)}</Table.Cell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
            </>
          )}
          <Divider hidden />
        </Segment>
      </Grid.Column>
    );
  }
}
