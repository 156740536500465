import React from "react";

const Layout = ({ children, full = false }) => {
  return (
    <main>
      <div
        className={`${
          full ? "w-full" : "max-w-screen-2xl"
        } mx-auto py-3 sm:px-6 lg:px-4`}
      >
        <div className="px-1 py-1 sm:px-0">
          <div className="	">{children}</div>
        </div>
      </div>
    </main>
  );
};
export default Layout;
