import React, { Component } from "react";
import { connect } from "react-redux";
import { Segment, Header, Grid } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { getSystemSettings } from "../../actions/settingsActions";
import api from "../../actions/api";

function mapStateToProps(state) {
  return {
    system: state.system,
    user: state.user,
  };
}
class ManagementDashPage extends Component {
  state = {
    dash: [],
  };
  componentDidMount() {
    // this._getData();
    // this.props.getSystemSettings();
    // this.props.i18n.changeLanguage("en");
  }
  _getData = () => {
    api.training.dash().then((dash) => {
      this.setState({
        dash,
      });
    });
  };

  render() {
    return (
      <Segment loading={this.state.loading} basic>
        <Header>Management Dash</Header>
        <Grid>
          <Grid.Row>
            <Grid.Column width={16}>
              <Header textAlign="center">So what shall we put here?</Header>
            </Grid.Column>
            <Grid.Column></Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}
const ManagementDash = withTranslation()(ManagementDashPage);
export default connect(mapStateToProps, { getSystemSettings })(ManagementDash);
