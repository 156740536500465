import React, { Component } from "react";
import { connect } from "react-redux";
import { Header, Segment, Icon, Button, Grid, Modal, Form, Divider, Select, TextArea } from "semantic-ui-react";
import api from "../../actions/api";
import { getSystemSettings } from "../../actions/settingsActions";
import InlineError from "../common/forms/errorMessage.js";
import Geosuggest from "react-geosuggest";
import commonFunctions from "../../assets/utils/commonFunctions";
import LocationMap from "../common/mapping/locationMap";

function mapStateToProps(state) {
  return {
    system: state.system,
    user: state.user,
  };
}

class AddSupplier extends Component {
  state = {
    data: {
      name: "",
      contact: "",
      contactPhone: "",
      contactEmail: "",
      details: "",
      type: [],
      address: {},
    },
    members: [],
    courses: [],
    locations: [],
    supplierTypes: [],

    loading: true,
    coursesLoading: true,
    errors: {},
    addressModal: false,
    showMap: false,
  };
  componentDidMount = () => {
    this.props
      .getSystemSettings()
      .then(() => {
        const data = this.props.system.settings.supplierTypes;
        let dataArray = [];
        for (var key in data) {
          dataArray.push({
            key: key,
            text: data[key],
            value: data[key],
          });
        }
        this.updateSupplierTypes(dataArray);
      })
      .catch((err) => {
        console.log(err);
      });

    this.getData();
  };
  componentDidUpdate(prevProps, prevState) {}
  getData = () => {};
  // getLocation = id => {
  //   api.location.show(id).then(location => {
  //     this.setState({
  //       data: {
  //         ...this.state.data,
  //         location: {
  //           geoJSON: location.geoJSON
  //         }
  //       },
  //       showMap: true
  //     });
  //   });
  // };
  resetForm = () => {
    this.setState({
      data: {
        start: "",
        end: "",
        title: "",
        type: "",
        valid: 0,
        isRequired: false,
        organiserID: "",
        organiserName: "",
        locationID: null,
        location: null,
        roles: [],
      },
      errors: {},
    });
  };
  updateSupplierTypes = (data) => {
    this.setState({ supplierTypes: data });
  };
  onChange = (e) =>
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  handleChange = (event, { name, value }) => {
    this.setState({
      data: {
        ...this.state.data,
        [name]: value,
      },
    });
  };
  handleDropdownChange = (props, e) => {
    this.setState({
      data: { ...this.state.data, [e.name]: e.value },
    });
  };
  handleLocationPicker = (props, e) => {
    this.getLocation(e.value);
    //
  };
  handleTypeChange = (props, e) => {
    this.setState(
      {
        data: {
          ...this.state.data,
          type: e.value,
        },
      },
      () => {
        this.state.courseTypes.forEach((course) => {
          if (course.text === this.state.data.type) {
            this.setState({
              data: {
                ...this.state.data,
                validityLength: course.valid,
                isRequired: course.required,
              },
            });
          }
        });
      }
    );
  };
  validate = (data) => {
    const errors = {};
    if (!data.name) errors.name = "Can't be empty";
    if (!data.type) errors.type = "Can't be empty";
    // if (!data.start) errors.start = "Can't be empty";
    // if (!data.end) errors.end = "Can't be empty";
    // if (!data.email) errors.email = "Can't be empty";
    return errors;
  };
  updateAddress = (address) => {
    this.setState({
      data: {
        ...this.state.data,
        location: {
          geoJSON: address.geoJSON,
        },
      },
      showMap: true,
    });
  };
  handleAddSupplier = () => {
    const errors = this.validate(this.state.data);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      let data = this.state.data;
      api.management.suppliers.add(data).then((res) => {
        this.props.history.push(`/management/suppliers`);
      });
      // api.training.courses.add(data).then(res => {
      //   this.goto(res._id);
      // });
    }
  };
  goto = (id) => {
    this.props.history.push(`/manangement/suppliers/${id}`);
  };
  addressModal = () => {
    return (
      <Modal open={this.state.addressModal} onClose={() => this.setState({ addressModal: false })} centered={false} size="large" closeOnDimmerClick={false}>
        <Header icon="map pin" content="Add Location" />
        <Modal.Content>{/* <AddressCard
            country={this.props.system.country}
            update={this.updateAddress}
          /> */}</Modal.Content>
        <Modal.Actions>
          <Button negative floated={"left"} onClick={() => this.setState({ addressModal: false })}>
            Cancel
          </Button>
          <Button color="green" onClick={() => this.setState({ addressModal: false })} inverted>
            <Icon name="save" />
            Add
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };
  onSuggestSelect = (suggest) => {
    let gridType = "";
    if (this.props.system.country.gridType) {
      gridType = this.props.system.country.gridType;
    }
    if (suggest) {
      let address = commonFunctions.returnAddress(suggest, gridType);
      if (address) {
        this.setState({
          data: {
            ...this.state.data,
            address: address,
          },
        });
      }
    }
  };
  renderAddForm() {
    const { errors, data } = this.state;
    return (
      <Segment>
        <Header textAlign="center">Details</Header>
        <Divider />
        <Form>
          <Segment>
            <Form.Group>
              <Form.Field width={8}>
                <label htmlFor="name">Name</label>
                <input id="name" name="name" placeholder="name" value={data.title} onChange={this.onChange} className={errors.name ? "warning" : ""} />
                {errors.name && <InlineError text={errors.name} />}
              </Form.Field>
              <Form.Field width={8}>
                <label> Type</label>
                <Select placeholder={"pick one"} name={"type"} options={this.state.supplierTypes} multiple selection onChange={this.handleDropdownChange} />
              </Form.Field>
            </Form.Group>
            <Form.Group widths={2}>
              <Form.Field width={8}>
                <label htmlFor="contact">Primary Contact</label>
                <input id="contact" name="contact" placeholder="contact name" value={data.contact} onChange={this.onChange} />
              </Form.Field>
              <Form.Field width={8}>
                <label htmlFor="contactPhone">Primary Contact Number</label>
                <input id="contactPhone" name="contactPhone" placeholder="contact number" value={data.contactPhone} onChange={this.onChange} />
              </Form.Field>
            </Form.Group>
            <Form.Field width={16}>
              <label htmlFor="contactEmail">Primary Contact Email</label>
              <input id="contactEmail" name="contactEmail" placeholder="contact email" value={data.contactEmail} onChange={this.onChange} />
            </Form.Field>

            <Form.Field>
              <label>Details</label>
              <TextArea name="details" onChange={this.onChange} />
            </Form.Field>
          </Segment>

          <Divider clearing />
          <Form.Group widths={"equal"} className={"form-actions"}>
            <Form.Field>
              <Button color="red" floated={"left"} onClick={this.resetForm}>
                <Icon name="close" /> Cancel
              </Button>
            </Form.Field>
            <Form.Field>
              <Button color="green" onClick={() => this.handleAddSupplier()} floated="right">
                <Icon name="plus" /> Add
              </Button>
            </Form.Field>
          </Form.Group>
        </Form>
      </Segment>
    );
  }
  renderLocation() {
    const { data } = this.state;
    return (
      <Segment>
        <Header textAlign="center">Address</Header>
        <Divider />
        <Grid centered>
          <Grid.Row>
            <Grid.Column textAlign="center" width={16}>
              <Geosuggest
                ref={(el) => (this._geoSuggest = el)}
                placeholder="Tesco, Ashford, Kent"
                inputClassName="geosuggest__input"
                country={this.props.system.country.name}
                onSuggestSelect={this.onSuggestSelect}
                suggestsClassName="geosuggest__suggests"
                suggestsHiddenClassName="geosuggest__suggests--hidden"
                suggestItemClassName="geosuggest__item"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider />
        <Segment basic>
          {!data.address.geoJSON && <p>no location!</p>}
          {data.address.geoJSON && data.address.geoJSON.properties && (
            <Grid stackable celled>
              <Grid.Row>
                <Grid.Column textAlign="left">
                  <Header>
                    Name: <span>{data.address.geoJSON.properties.name}</span>
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column textAlign="left">
                  <Header>
                    Town: <span>{data.address.geoJSON.properties.town}</span>
                  </Header>
                </Grid.Column>
                <Grid.Column textAlign="left">
                  <Header>
                    Postcode:
                    <span>{data.address.geoJSON.properties.postcode}</span>
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row className="no-pad">
                <Grid.Column width={16} className="no-pad">
                  <LocationMap marker={this.state.data.address} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          )}
        </Segment>
      </Segment>
    );
  }
  render() {
    return (
      <Segment>
        {this.addressModal()}
        <Header>Add a Supplier </Header>
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column>{this.renderAddForm()}</Grid.Column>
            <Grid.Column>{this.renderLocation()}</Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}
export default connect(mapStateToProps, { getSystemSettings })(AddSupplier);
