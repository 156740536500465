import React, { Component } from "react";
import { Form, Button, TextArea, Divider, Segment } from "semantic-ui-react";

export default class AddNoteForm extends Component {
  state = {
    data: {
      message: "",
    },
  };
  componentDidMount() {
    if (this.props.message) {
      this.setState({
        data: {
          message: this.props.message,
        },
      });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.message !== prevProps.message) {
      this.setState({
        data: {
          message: this.props.message,
        },
      });
    }
  }
  resetState = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.setState(
      {
        data: {
          message: "",
        },
      },
      () => this.props.cancel()
    );
  };
  onSubmit = () => {
    this.props.submit(this.state.data).then(() => {
      if (this.props.notModal) {
        this.resetState();
      }
      this.props.cancel();
    });
  };
  onChange = (e) =>
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  render() {
    return (
      <Segment>
        <Form onSubmit={this.onSubmit}>
          <Form.Field>
            <TextArea
              name="message"
              id="message"
              placeholder={this.props.placeholder ? this.props.placeholder : "add your note here"}
              style={{ minHeight: 100 }}
              value={this.state.data.message}
              onChange={this.onChange}
            />
            <Divider />
            <Form.Group widths={"equal"} className={"form-actions"}>
              <Form.Field>
                <Button negative floated={"left"} onClick={this.resetState}>
                  Cancel
                </Button>
              </Form.Field>
              <Form.Field>
                <Button positive floated={"right"} disabled={this.state.disabled}>
                  Add
                </Button>
              </Form.Field>
            </Form.Group>
          </Form.Field>
        </Form>
      </Segment>
    );
  }
}
