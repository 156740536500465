import React from "react";
import Blank from "../../../assets/images/blank.png";
import {
  Table,
  Image,
  Button,
  Confirm,
  Modal,
  Form,
  TextArea,
  Header,
  Icon,
  Popup,
} from "semantic-ui-react";

export default class ResponseResultList extends React.Component {
  state = {
    confirmOpen: false,
    confirmResultOpen: false,
    response: "",
    result: "",
    isCompetent: null,
    comment: "",
    competent: "",
    member: {},
  };
  confirmClose = () => {
    this.setState({
      confirmOpen: false,
      confirmResultOpen: false,
    });
  };
  sendResponse = () => {
    this.props
      .setResponse(this.state.response, this.state.member.centralId)
      .then(() => {
        this.confirmClose();
      });
  };
  sendResult = () => {
    this.props
      .setResult(
        // this.state.result,
        this.state.isCompetent,
        this.state.member.centralId,
        this.state.member._id,
        this.state.comment
      )
      .then(() => {
        this.setState({
          comment: "",
        });
        this.confirmClose();
      });
  };

  renderConfirmBox = () => {
    return (
      <Confirm
        header={`Are you sure you would like to change this response for ${this.state.member.name}?`}
        open={this.state.confirmOpen}
        onCancel={this.confirmClose}
        onConfirm={this.sendResponse}
      />
    );
  };
  handleChange = (event, { name, value }) => {
    this.setState({
      comment: value,
    });
  };
  renderResultConfirmBox = () => {
    return (
      <Modal
        open={this.state.confirmResultOpen}
        onClose={this.confirmClose}
        size="small"
      >
        <Header
          icon="browser"
          content={`Are you sure you would like to change the result for ${this.state.member.name}`}
        />
        <Modal.Content>
          <Form>
            <Form.Field>
              <TextArea
                name="comment"
                onChange={this.handleChange}
                value={this.state.comment}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button color="red" onClick={this.confirmClose} inverted>
            <Icon name="close" /> Cancel
          </Button>
          <Button color="green" onClick={this.sendResult} inverted>
            <Icon name="checkmark" /> Confirm
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };
  renderMembers() {
    const permission = this.props.permissions.canUpdateOthers;
    if (this.props.data && this.props.data.length) {
      const members = this.props.data.map((item) => {
        if (item.member) {
          let result = this.props.results.filter((res) => {
            return res.memberCentralID === item.member.centralId;
          });
          let note;
          let comp;
          if (result[0] && result[0].isCompetent === true) {
            comp = true;
            note = result[0].comment;
          } else if (result[0] && result[0].isCompetent === false) {
            comp = false;
            note = result[0].comment;
          } else {
            comp = null;
          }
          return (
            <Table.Row
              key={item._id}
              // positive={item.response === "going" ? true : false}
              // warning={item.response === "not going" ? true : false}
            >
              {item.member && item.member.photo ? (
                <Table.Cell width={1}>
                  <Image src={item.member.photo} size="mini" />
                </Table.Cell>
              ) : (
                <Table.Cell width={1}>
                  <Image src={Blank} size="mini" />
                </Table.Cell>
              )}
              {item.member ? (
                <Table.Cell>
                  {item.member.name}{" "}
                  {note && (
                    <Popup
                      position={"top center"}
                      trigger={<Icon name="sticky note" color="yellow" />}
                      content={note}
                    />
                  )}
                </Table.Cell>
              ) : (
                "-"
              )}
              {permission && (
                <Table.Cell textAlign="center">
                  {item.response === "going" ? (
                    <Button
                      animated="vertical"
                      basic
                      fluid
                      inverted
                      className={"compButtons comp"}
                      onClick={() =>
                        this.setState({
                          response: "not going",
                          member: item.member,
                          confirmOpen: true,
                        })
                      }
                    >
                      <Button.Content hidden>Not Attending</Button.Content>
                      <Button.Content visible>Attending</Button.Content>
                    </Button>
                  ) : (
                    <Button
                      animated="vertical"
                      basic
                      fluid
                      inverted
                      className={"compButtons notComp"}
                      onClick={() =>
                        this.setState({
                          response: "going",
                          member: item.member,
                          confirmOpen: true,
                        })
                      }
                    >
                      <Button.Content hidden>Attending</Button.Content>
                      <Button.Content visible>Not Attending</Button.Content>
                    </Button>
                  )}
                </Table.Cell>
              )}
              {permission && (
                <Table.Cell>
                  {comp === false && (
                    <Button
                      basic
                      fluid
                      inverted
                      className={"compButtons notComp"}
                      onClick={() =>
                        this.setState({
                          // result: "Competent",
                          isCompetent: true,
                          member: item.member,
                          comment: note,
                          confirmResultOpen: true,
                        })
                      }
                    >
                      Not Yet
                    </Button>
                  )}

                  {comp === true && (
                    <Button
                      basic
                      fluid
                      inverted
                      className={"compButtons comp"}
                      onClick={() =>
                        this.setState({
                          // result: "Not Competent",
                          isCompetent: false,
                          member: item.member,
                          confirmResultOpen: true,
                          comment: note,
                        })
                      }
                    >
                      Competent
                    </Button>
                  )}

                  {comp === null && (
                    <Button.Group fluid basic widths="2" inverted>
                      <Button
                        basic
                        className={"compButtons comp"}
                        onClick={() =>
                          this.setState({
                            // result: "Competent",
                            isCompetent: true,
                            member: item.member,
                            confirmResultOpen: true,
                          })
                        }
                      >
                        Yes
                      </Button>
                      <Button
                        negative
                        basic
                        className={"compButtons notComp"}
                        onClick={() =>
                          this.setState({
                            // result: "Not Competent",
                            isCompetent: false,
                            member: item.member,
                            confirmResultOpen: true,
                          })
                        }
                      >
                        Not yet
                      </Button>
                    </Button.Group>
                  )}
                </Table.Cell>
              )}
            </Table.Row>
          );
        }
        return null;
      });
      return members;
    }
  }
  render() {
    const permission = this.props.permissions.canUpdateOthers;

    return (
      <Table stackable celled>
        {this.renderConfirmBox()}
        {this.renderResultConfirmBox()}
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Photo</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            {permission && (
              <Table.HeaderCell textAlign="center">Attendance</Table.HeaderCell>
            )}
            {permission && (
              <Table.HeaderCell textAlign="center">Competent</Table.HeaderCell>
            )}
          </Table.Row>
        </Table.Header>
        <Table.Body>{this.renderMembers()}</Table.Body>
      </Table>
    );
  }
}
