import React from "react";
import { connect } from "react-redux";
import {
  Grid,
  Segment,
  Icon,
  Checkbox,
  Button,
  Header,
  Popup,
} from "semantic-ui-react";
import api from "../../actions/api";
import {
  getSystemSettings,
  updateSetting,
} from "../../actions/settingsActions";
import { toast } from "react-hot-toast";

function mapStateToProps(state) {
  return {
    system: state.system,
  };
}
class SettingsDash extends React.Component {
  state = {
    disabled: true,
    isCentralSyncing: false,
    data: {
      teamName: "",
      systemName: "",
    },
  };
  updateFormTimeout = 0;
  componentDidMount() {
    this.getData();
  }
  getData = () => {
    return this.props.getSystemSettings();
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.system !== prevProps.system) {
      this.setState({
        data: this.props.system.settings,
      });
    }
    if (this.props.system !== prevProps.system) {
      if (
        this.props.system.settings &&
        this.props.system.settings.incidentTypes
      ) {
        this.setOptions("type", this.props.system.settings.incidentTypes);
      }
    }
  };
  toggleDisabled = () => {
    this.setState({
      disabled: !this.state.disabled,
    });
  };
  onChange = (e) =>
    this.setState(
      {
        data: { ...this.state.data, [e.target.name]: e.target.value },
      },
      () => {
        this.props.updateSetting(this.state.data, () => {});
      }
    );
  handleCheckboxChange = (props, e) => {
    toast.success("Updating form.");
    this.setState(
      {
        updating: true,
        data: { ...this.state.data, [e.name]: e.checked },
      },
      () => {
        this.props.updateSetting(this.state.data).then(() => {
          setTimeout(() => {
            this.setState({
              updating: false,
            });
          }, 1000);
        });
      }
    );
  };
  updateModel = (e) => {
    this.setState({
      updating: true,
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
    if (this.updateFormTimeout) {
      clearTimeout(this.updateFormTimeout);
    }
    this.updateFormTimeout = setTimeout(() => {
      return this.props
        .updateSetting(this.state.data)
        .then((res) => {
          this.setState({
            updating: false,
          });
          //TODO add a fancy screen notification that the title has successfully updated
        })
        .catch((err) => {
          console.error("Unable to save", err);
        });
    }, 500);
  };
  handleSubmit = (data) => {
    return this.props.updateSetting(data).then(() => {
      this.getData();
    });
  };
  handleDelete = (data) => {
    this.props.updateSetting(data, () => {});
  };
  setOptions = (key, types) => {
    if (types) {
      const data = types;
      let dataArray = [];
      for (var i in data) {
        dataArray.push({
          key: i,
          text: data[i].name,
          value: data[i].name,
        });
      }
      this.setState({ [key]: dataArray });
    }
  };
  syncTeam = () => {
    this.setState({
        isCentralSyncing: true
    }, () => {
        api.settings.syncTeam().then(() => {
            toast.success("SarStuff Central users have successfully been synced with TeamSite members.");
        }).catch((err) => {
            toast.error("SarStuff Central users could not be synced with TeamSite members due to a unexpected error.");
        }).finally(() => {
            this.setState({
                isCentralSyncing: false
            });
        });
    });
  };
  renderSettings() {
    const { settings } = this.props.system;
    return (
      <Segment basic>
        <Grid stackable>
          <Grid.Row>
            <Grid.Column width={16}>
              <Segment>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width="16" className="columnHeader">
                      <Popup
                        content={
                          "Switch on or off driving license categories under the team settings"
                        }
                        position={"right center"}
                        trigger={
                          <Header className={"withIcon"}>
                            Driving Categories
                            <Icon
                              name="info circle"
                              color="yellow"
                              size="small"
                            />
                          </Header>
                        }
                      />
                      <Checkbox
                        toggle
                        name="drivingCatSwitch"
                        className={"goodToggle"}
                        checked={settings.drivingCatSwitch}
                        onChange={this.handleCheckboxChange}
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column width="16" className="columnHeader">
                      <Popup
                        content={
                          "Switch on or off vehicle kit under the team settings"
                        }
                        position={"right center"}
                        trigger={
                          <Header className={"withIcon"}>
                            Vehicle Kit
                            <Icon
                              name="info circle"
                              color="yellow"
                              size="small"
                            />
                          </Header>
                        }
                      />
                      <Checkbox
                        toggle
                        name="vehicleKitSwitch"
                        className={"goodToggle"}
                        checked={settings.vehicleKitSwitch}
                        onChange={this.handleCheckboxChange}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width="16" className="columnHeader">
                      <Popup
                        content={
                          "Switch on or off sections under the team settings"
                        }
                        position={"right center"}
                        trigger={
                          <Header className={"withIcon"}>
                            Sections
                            <Icon
                              name="info circle"
                              color="yellow"
                              size="small"
                            />
                          </Header>
                        }
                      />
                      <Checkbox
                        toggle
                        name="sectionsSwitch"
                        className={"goodToggle"}
                        checked={settings.sectionsSwitch}
                        onChange={this.handleCheckboxChange}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width="16" className="columnHeader">
                      <Popup
                        content={
                          "Switch on or off awards under the team settings"
                        }
                        position={"right center"}
                        trigger={
                          <Header className={"withIcon"}>
                            Awards
                            <Icon
                              name="info circle"
                              color="yellow"
                              size="small"
                            />
                          </Header>
                        }
                      />
                      <Checkbox
                        toggle
                        name="awardsSwitch"
                        className={"goodToggle"}
                        checked={settings.awardsSwitch}
                        onChange={this.handleCheckboxChange}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width="16" className="columnHeader">
                      <Popup
                        content={"Switch on or off fees under the org settings"}
                        position={"right center"}
                        trigger={
                          <Header className={"withIcon"}>
                            Fees
                            <Icon
                              name="info circle"
                              color="yellow"
                              size="small"
                            />
                          </Header>
                        }
                      />
                      <Checkbox
                        toggle
                        name="feeSwitch"
                        className={"goodToggle"}
                        checked={settings.feeSwitch}
                        onChange={this.handleCheckboxChange}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width="16" className="columnHeader">
                      <Popup
                        content={
                          "Show competencies on front page rather than courses"
                        }
                        position={"right center"}
                        trigger={
                          <Header className={"withIcon"}>
                            Competencies
                            <Icon
                              name="info circle"
                              color="yellow"
                              size="small"
                            />
                          </Header>
                        }
                      />
                      <Checkbox
                        toggle
                        name="compsSwitch"
                        className={"goodToggle"}
                        checked={settings.compsSwitch}
                        onChange={this.handleCheckboxChange}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width="16" className="columnHeader">
                      <Popup
                        content={"Show callouts per Member"}
                        position={"right center"}
                        trigger={
                          <Header className={"withIcon"}>
                            Callouts
                            <Icon
                              name="info circle"
                              color="yellow"
                              size="small"
                            />
                          </Header>
                        }
                      />
                      <Checkbox
                        toggle
                        name="calloutsSwitch"
                        className={"goodToggle"}
                        checked={settings.calloutsSwitch}
                        onChange={this.handleCheckboxChange}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width="16" className="columnHeader">
                      <Popup
                        content={"Allow team members to change their own photo"}
                        position={"right center"}
                        trigger={
                          <Header className={"withIcon"}>
                            Team Photos
                            <Icon
                              name="info circle"
                              color="yellow"
                              size="small"
                            />
                          </Header>
                        }
                      />
                      <Checkbox
                        toggle
                        name="changePhoto"
                        className={"goodToggle"}
                        checked={settings.changePhoto}
                        onChange={this.handleCheckboxChange}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width="16" className="columnHeader">
                      <Popup
                        content={"Sync all Central members down to Team Site"}
                        position={"right center"}
                        trigger={
                          <Header className={"withIcon"}>
                            Sync Central
                            <Icon
                              name="info circle"
                              color="yellow"
                              size="small"
                            />
                          </Header>
                        }
                      />

                      <Button disabled={this.state.isCentralSyncing} basic primary inverted onClick={this.syncTeam}>
                          {this.state.isCentralSyncing ? "Syncing..." : "Sync"}
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }

  render() {
    const { settings } = this.props.system;
    return (
      <Grid stackable style={{ marginBottom: 10 }}>
        <Grid.Row columns={2}>
          <Grid.Column>{this.renderSettings()}</Grid.Column>
          <Grid.Column>
            {/* {this.state.updating && (
              <Segment basic>
                <Message
                  icon
                  hidden={!this.state.updating}
                  positive
                  size="mini"
                  floating
                  compact
                >
                  <Icon name="circle notched" loading />
                  <Message.Content>
                    <Message.Header>Just one second</Message.Header>
                    Updating form.
                  </Message.Content>
                </Message>
              </Segment>
            )} */}
            {/* <Segment basic>
              <Segment>
                <Header as="h3" textAlign="center">
                  App Settings
                </Header>
                <div className="flex justify-between">
                  <Popup
                    content={"Allow team members to add vehicles from the app"}
                    position={"right center"}
                    trigger={
                      <Header className={"withIcon"}>
                        Allow Add Vehicle
                        <Icon name="info circle" color="yellow" size="small" />
                      </Header>
                    }
                  />
                  <Checkbox
                    toggle
                    name="appAddVehicleSwitch"
                    className={"goodToggle"}
                    checked={settings.appAddVehicleSwitch}
                    onChange={this.handleCheckboxChange}
                  />
                </div>
              </Segment>
            </Segment> */}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default connect(mapStateToProps, {
  getSystemSettings,
  updateSetting,
})(SettingsDash);
