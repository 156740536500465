import React, { useState, useEffect } from "react";
import Box from "../ui-wrappers/Box";
import dayjs from "dayjs";
import api from "../../../actions/api";
export default function SupplierAssets({ match, history }) {
  const [assets, setAssets] = useState([]);

  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    api.assets.getAssetsBySupplier(match.params.id).then((res) => {
      setAssets(res);
    });
  };
  console.log(assets);
  return (
    <div>
      <div className="p-4 flex gap-4">
        <Box title={"Assets"}>
          <table className="min-w-full divide-y divide-gray-200 border-separate table-fixed">
            <thead>
              <tr className="bg-gray-800">
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-400 uppercase ">
                  Asset
                </th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-400 uppercase ">
                  Asset Number
                </th>
                <th className="px-4 py-3 text-center text-xs font-medium text-gray-400 uppercase ">
                  Purchase Date
                </th>
                <th className="px-4 py-3 text-center text-xs font-medium text-gray-400 uppercase ">
                  Expires Date
                </th>
                <th className="px-4 py-3 text-center text-xs font-medium text-gray-400 uppercase ">
                  Tags
                </th>
              </tr>
            </thead>
            <tbody className="bg-gray-900 divide-y divide-gray-200 text-lg">
              {assets.map((a) => {
                return (
                  <tr
                    key={a._id}
                    className={`cursor-pointer hover:bg-gray-800`}
                    onClick={() => history.push(`/assets/${a._id}`)}
                  >
                    <td className="p-2">{a.name}</td>
                    <td className="p-2">{a.assetNum}</td>
                    <td className="p-2   ">
                      {dayjs(a.date_purchased).format("DD-MM-YY")}
                    </td>
                    <td className="p-2 ">
                      {a.date_expires
                        ? dayjs(a.date_expires).format("DD-MM-YY")
                        : "--"}
                    </td>
                    <td className="p-2 ">
                      {a.tags.map((t, i) => {
                        return (
                          <span
                            key={i}
                            className="p-2 m-1 bg-gray-800 rounded-lg border border-gray-700"
                          >
                            {t}
                          </span>
                        );
                      })}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Box>
      </div>
    </div>
  );
}
