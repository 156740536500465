import React, { PureComponent, lazy, Suspense } from "react";
import {
  Segment,
  Header,
  Grid,
  Menu,
  Dropdown,
  Divider,
  Icon,
  Modal,
  Radio,
  Button,
  Form,
  Popup,
  Message,
  Select,
  Confirm,
  Card,
  Statistic,
  TextArea,
  Table,
} from "semantic-ui-react";
import moment from "moment";
import dayjs from "dayjs";
import { DateInput } from "semantic-ui-calendar-react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { withRouter } from "react-router";
import { FaThumbsUp, FaThumbsDown, FaRegStickyNote } from "react-icons/fa";
import api from "../../../actions/api";
import MemberCourseList from "../lists/memberCourseList";
import MemberCompList from "../lists/memberCompList";
import ArrayCard from "./arrayCard";
import NotesPage from "../NotesPage";
import MemberCompPage from "../MemberCompPage";
import CalloutList from "../lists/calloutList";
import Box from "../Box";
import {
  AuthComponents,
  Authentication,
} from "@liquidcomputing/sarstuff__reactjs-singlesignon";
import "react-big-calendar/lib/css/react-big-calendar.css";
import MemberCanEdit from "../../../lib/MemberCanEdit";
import UpdateMemberDetailsModal from "../forms/updateMemberDetailsModal";
import CPDList from "../lists/cpdList";
import DisplayAndUpdatePhoto from "./_components/DisplayAndUpdatePhoto";

const UserDetails = lazy(() => import("./userDetails"));
moment.locale("en-GB");
const localizer = momentLocalizer(moment);

const tyres = [
  { key: 1, text: "Road", value: "Road" },
  { key: 2, text: "Mud", value: "Mud" },
  { key: 3, text: "Mud/Snow", value: "Mud/Snow" },
  { key: 4, text: "All Terrain", value: "All Terrain" },
];
class UserCard extends PureComponent {
  state = {
    activeItem: "dash",
    // activeItem: "dash",
    addLog: false,
    showLog: false,
    addNote: false,
    trainingLogs: [],
    incidentLogs: [],
    cpdLogs: [],
    cpdDash: [],
    notes: [],
    logTypes: [],
    user: {
      geoJSON: {
        name: "",
        town: "",
        county: "",
        postcode: "",
      },
    },
    editModal: false,
    passModal: false,
    vettingModal: false,
    vehicleModal: false,
    archiveModal: false,
    vetting: {},
    assets: [],
    log: {
      logType: "training",
      title: "",
      trainingType: "",
      description: "",
      learningOutcome: "",
      date: new dayjs(),
      hide: false,
    },
    data: {
      firstName: "",
      lastName: "",
      phone: "",
      phoneSecondary: "",
      email_secondary: "",
      photo: "",
      dob: "",
      nickName: "",
      nokName: "",
      nokPhone: "",
      nokRel: "",
      teamId: "",
      forceId: "",
    },
    vehicle: {
      make: "",
      model: "",
      regNumber: "",
      doors: 0,
      seats: 0,
      towWeight: 0,
      tyres: "",
    },
    vettingData: [],
    compData: [],
    errors: {},
    warning: {},
    addRole: false,
    addRole_Data: {
      role: "",
    },
    addRole_Error: null,
    addRole_Errors: {},
    addRole_Loading: false,
    confirmOpen: false,
    removeRole: null,
    showImageText: false,
    events: [],
    loadingVetting: true,
    loading: true,
  };

  componentDidMount() {
    if (this.props.user) {
      this.setState({
        user: this.props.user,
        data: {
          ...this.state.data,
          firstName: this.props.user.firstName,
          lastName: this.props.user.lastName,
          phone: this.props.user.phone,
          phoneSecondary: this.props.user.phoneSecondary,
          email_secondary: this.props.user.email_secondary
            ? this.props.user.email_secondary
            : "",
          photo: this.props.user.photo ? this.props.user.photo : "",
          nickName: this.props.user.nickName,
          dob: this.props.user.dob,
          teamId: this.props.user.teamId,
          forceId: this.props.user.forceId,
          isArchived: this.props.user.isArchived,
          nokName:
            this.props.user.nok && this.props.user.nok.name
              ? this.props.user.nok.name
              : "",
          nokPhone:
            this.props.user.nok && this.props.user.nok.phone
              ? this.props.user.nok.phone
              : "",
          nokRel:
            this.props.user.nok && this.props.user.nok.relationship
              ? this.props.user.nok.relationship
              : "",
        },
        vettingData: this.props.user.vetting ? this.props.user.vetting : [],
        compData: this.props.user.courses ? this.props.user.courses : [],
        // loadingVetting: false
        loading: false,
      });
      this.getData();
    }
    if (this.props.settings) {
      const data = this.props.settings.trainingTypes;
      let dataArray = [];
      for (var key in data) {
        dataArray.push({
          key: key,
          text: data[key],
          value: data[key],
        });
      }
      this.setState({
        logTypes: dataArray,
        loading: false,
      });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.user !== prevProps.user) {
      this.setState({
        user: this.props.user,
        data: {
          ...this.state.data,
          firstName: this.props.user.firstName ? this.props.user.firstName : "",
          lastName: this.props.user.lastName ? this.props.user.lastName : "",
          phone: this.props.user.phone ? this.props.user.phone : "",
          phoneSecondary: this.props.user.phoneSecondary
            ? this.props.user.phoneSecondary
            : "",
          email_secondary: this.props.user.email_secondary
            ? this.props.user.email_secondary
            : "",
          photo: this.props.user.photo ? this.props.user.photo : "",
          nickName: this.props.user.nickName,
          dob: this.props.user.dob,
          teamId: this.props.user.teamId ? this.props.user.teamId : "",
          forceId: this.props.user.forceId ? this.props.user.forceId : "",
          isArchived: this.props.user.isArchived
            ? this.props.user.isArchived
            : false,
          nokName:
            this.props.user.nok && this.props.user.nok.name
              ? this.props.user.nok.name
              : "",
          nokPhone:
            this.props.user.nok && this.props.user.nok.phone
              ? this.props.user.nok.phone
              : "",
          nokRel:
            this.props.user.nok && this.props.user.nok.relationship
              ? this.props.user.nok.relationship
              : "",
        },
        vettingData: this.props.user.vetting ? this.props.user.vetting : [],
        compData: this.props.user.courses ? this.props.user.courses : [],
        loadingVetting: false,
        loading: false,
      });
    } else {
      this.setState({
        loadingVetting: false,
        loading: false,
      });
    }
  }
  getData = () => {
    api.member.getTrainingLogs(this.props.user._id).then((logs) => {
      this.setState({
        trainingLogs: logs.filter((l) => l.logType === "training"),
        incidentLogs: logs.filter((l) => l.logType === "incident"),
      });
    });
    api.assets.getbyAssigned("member", this.props.user._id).then((assets) => {
      this.setState({
        assets,
      });
    });
    api.member.getCPDLogs(this.props.user._id).then((res) => {
      this.setState({
        cpdLogs: res,
      });
    });
    api.member.getCPDDash(this.props.user._id).then((res) => {
      console.log("dash", res);
      this.setState({
        cpdDash: res,
      });
    });
  };
  handleArchiveClose = () => [
    this.setState({
      archiveModal: false,
    }),
  ];
  goto = (loc) => {
    if (loc) {
      this.props.history.push(loc);
    }
  };
  onChange = (e) => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  };
  onLogChange = (e) => {
    this.setState({
      log: { ...this.state.log, [e.target.name]: e.target.value },
    });
  };
  onVehicleChange = (e) => {
    this.setState({
      vehicle: { ...this.state.vehicle, [e.target.name]: e.target.value },
    });
  };
  handleVehicleDropdownChange = (e, data) => {
    const vname = data.name;
    this.setState({
      vehicle: { ...this.state.vehicle, [vname]: data.value },
    });
  };
  handleLogDropdownChange = (e, data) => {
    const vname = data.name;
    this.setState({
      log: { ...this.state.log, [vname]: data.value },
    });
  };
  handleChange = (event, { name, value }) => {
    this.setState({
      data: {
        ...this.state.data,
        [name]: value,
      },
    });
  };
  handleLogChange = (event, { name, value }) => {
    this.setState({
      log: {
        ...this.state.log,
        [name]: value,
      },
    });
  };

  archiveOnSubmit = () => {
    this.props.archive(this.state.data);
    this.handleArchiveClose();
  };
  onStausChange = () => {
    // console.log("change of status");
  };
  updateStatus = (data) => {
    if (data) {
      this.props.status(data);
    }
  };
  updatePrivacy = (privacy) => {
    if (privacy) {
      this.props.update({
        privacy,
      });
    }
  };
  updateAddress = (data) => {
    if (data) {
      // let geoJSON = {
      //   geoJSON: data.geoJSON
      // };
      this.setState({
        user: {
          ...this.state.user,
          geoJSON: data.geoJSON,
        },
      });
      // return this.props.update(geoJSON);
    }
  };
  addAddress = () => {
    this.setState(
      {
        addressModal: false,
      },
      () => {
        this.props.update({
          geoJSON: this.state.user.geoJSON,
        });
      }
    );
  };
  handleDropdownChange = (e, data) => {
    const vname = data.name;
    this.setState({
      addRole_Data: { ...this.state.addRole_Data, [vname]: data.value },
    });
  };
  handleMenuClick = (e, { name }) => this.setState({ activeItem: name });
  handleRoles = (data) => {
    if (this.props.user.roles) {
      let current = this.props.user.roles;
      data.roles.forEach((element) => {
        current.push(element);
      });
      return this.props.update({ roles: current });
    } else {
      return this.props.update({ roles: data.roles });
    }
  };
  handleDrivingCats = (data) => {
    // might be able to use this to reduce similare functions
    // let bob = Object.keys(data);

    if (this.props.user.drivingCat) {
      let current = this.props.user.drivingCat;
      data.drivingCat.forEach((element) => {
        current.push(element);
      });
      return this.props.update({ drivingCat: current });
    } else {
      return this.props.update({ drivingCat: data.drivingCat });
    }
  };
  handleSections = (data) => {
    if (this.props.user.sections) {
      let current = this.props.user.sections;
      data.sections.forEach((element) => {
        current.push(element);
      });
      return this.props.update({ sections: current });
    } else {
      return this.props.update({ sections: data.sections });
    }
  };
  handleAwards = (data) => {
    if (this.props.user.awards) {
      let current = this.props.user.awards;
      data.awards.forEach((element) => {
        current.push(element);
      });
      return this.props.update({ awards: current });
    } else {
      return this.props.update({ awards: data.awards });
    }
  };

  updateData = (data) => {
    return this.props.update({
      drivingCat: data.drivingCat,
    });
  };
  addNote = (data) => {
    return this.props.update({
      notes: data,
    });
  };

  updateRoleData = (data) => {
    return this.props.update({
      roles: data.roles,
    });
  };
  updateSectionsData = (data) => {
    // might be able to use this to reduce similare functions
    // let bob = Object.keys(data);
    return this.props.update({
      sections: data.sections,
    });
  };
  updateAwardsData = (data) => {
    // might be able to use this to reduce similare functions
    // let bob = Object.keys(data);
    return this.props.update({
      awards: data.awards,
    });
  };

  eventStyleGetter = (event, start, end, isSelected) => {
    var backgroundColor = event.color;
    var style = {
      backgroundColor: backgroundColor,
      borderRadius: "0px",
      opacity: 0.8,
      color: "white",
      border: "0px",
      display: "block",
    };
    return {
      style: style,
    };
  };
  confirmClose = () => {
    this.setState({
      confirmOpen: false,
    });
  };
  addVetting_old = () => {
    this.props.vetting(this.state.vettingData).then(() => {
      this.setState({ vettingModal: false });
    });
  };
  addVetting = (data) => {
    return this.props.vetting(data).then(() => {
      // this.setState({ vettingModal: false });
    });
  };
  addFees = (data) => {
    return this.props.fees(data).then(() => {
      // this.setState({ vettingModal: false });
    });
  };
  validateVehicle = (data) => {
    const errors = {};
    if (!data.make) errors.make = "Can't be empty";
    if (!data.model) errors.model = "Can't be empty";
    if (!data.regNumber) errors.regNumber = "Can't be empty";

    return errors;
  };
  addVehicle = () => {
    let data = this.state.vehicle;
    data.member = this.props.user._id;
    const errors = this.validateVehicle(data);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      api.vehicles.add(data).then(() => {
        this.props.reloadUser();
        this.setState({
          vehicleModal: false,
        });
      });
    }
  };
  addTrainingLog = () => {
    let data = this.state.log;
    data.member = this.props.user._id;

    api.member.addTrainingLog(this.props.user._id, data).then(() => {
      this.getData();
      this.setState({
        addLog: false,
      });
    });
  };
  addVehicleModal() {
    const { errors } = this.state;
    return (
      <Modal
        open={this.state.vehicleModal}
        onClose={() => this.setState({ vehicleModal: false })}
        centered={false}
        size="large"
        closeOnDimmerClick={false}
      >
        <Header icon="car" content="Add Vehicle" />
        <Modal.Content>
          <Form>
            <Form.Group widths={3}>
              <Form.Field>
                <label>Make</label>
                <input name="make" onChange={this.onVehicleChange} />
                {errors.make && (
                  <p style={{ color: "orange" }}>{errors.make}</p>
                )}
              </Form.Field>
              <Form.Field>
                <label>Model</label>
                <input name="model" onChange={this.onVehicleChange} />
                {errors.model && (
                  <p style={{ color: "orange" }}>{errors.model}</p>
                )}
              </Form.Field>
              <Form.Field>
                <label>Reg Number</label>
                <input name="regNumber" onChange={this.onVehicleChange} />
                {errors.regNumber && (
                  <p style={{ color: "orange" }}>{errors.regNumber}</p>
                )}
              </Form.Field>
            </Form.Group>
            <Form.Group widths={4}>
              <Form.Field>
                <label>Seats</label>
                <input name="seats" onChange={this.onVehicleChange} />
              </Form.Field>
              <Form.Field>
                <label>Tyres</label>
                <Select
                  name="tyres"
                  options={tyres}
                  onChange={this.handleVehicleDropdownChange}
                />
              </Form.Field>
              <Form.Field>
                <label>Doors</label>
                <input name="doors" onChange={this.onVehicleChange} />
              </Form.Field>
              <Form.Field>
                <label>Tow Weight</label>
                <input name="towWeight" onChange={this.onVehicleChange} />
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            negative
            floated={"left"}
            onClick={() => this.setState({ vehicleModal: false })}
          >
            Cancel
          </Button>
          <Button color="green" onClick={this.addVehicle} inverted>
            <Icon name="save" />
            Add
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
  checkDate = (date) => {
    let now = new moment();
    let theDate = moment(date);
    return theDate.diff(now, "months");
  };
  showLog = (log) => {
    if (log) {
      this.setState({
        log,
        showLog: true,
      });
    }
  };
  renderConfirmBox = () => {
    return (
      <Confirm
        header={`Are you sure you would like to remove the role '${this.state.removeRole}'?`}
        open={this.state.confirmOpen}
        onCancel={this.confirmClose}
        onConfirm={this.removeUserRole}
      />
    );
  };

  handleGetUploadSpec = () => {
    return api.member.getPhotoUploadConfig(this.props.user._id)
  };
  handleGettingPhoto = () => {
    return api.member.getPhoto(this.props.user._id);
  };
  handlePhotoUpload = formData => api.member.setPhotoImage(this.props.user._id, formData).then(a => {
    this.props.reloadUser()
    return a;
  });

  renderDetails() {
    let { user, settings } = this.props;
    // let age = 0;
    // if (user.dob) {
    //   age = moment().diff(user.dob, "years");
    // }
    // let disabled = false;
    // if (Authentication.userData.sub === this.props.user.centralId) {
    //   disabled = true;
    // }
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <UserDetails
          user={user}
          settings={settings}
          vetting={this.addVetting}
          fees={this.addFees}
          permissions={this.props.permissions}
          addAddress={this.addAddress}
          country={this.props.country}
          updateAddress={this.updateAddress}
        />
      </Suspense>
    );
  }
  renderDate = (date) => {
    if (date) {
      return moment(date).format("DD/MM/YYYY");
    } else {
      return "--";
    }
  };
  renderComp() {
    if (this.props.settings && this.props.settings.compsSwitch) {
      return (
        <MemberCanEdit user={this.props.user}>
          <MemberCompPage
            showScores={false}
            user={this.props.user}
            settings={this.props.settings}
          />
        </MemberCanEdit>
      );
    } else {
      return (
        <MemberCompList
          centralID={this.state.user.centralId}
          settings={this.props.settings}
        />
      );
    }
  }
  renderDash() {
    let { user, settings } = this.props;
    let disabled = false;
    if (Authentication.userData.sub === this.props.user.centralId) {
      disabled = true;
    }
    return (
      <Grid stackable>
        <Grid.Row>
          <Grid.Column width={4} className={"borderRight"}>
            {/* <Divider hidden /> */}
            <Segment className="subtle">
              <DisplayAndUpdatePhoto
                canUpdate={settings.changePhoto ? this.props.permissions.canUpdate : this.props.permissions.canUpdateOthers}
                onGetUploadSpec={this.handleGetUploadSpec}
                onGetPhoto={this.handleGettingPhoto}
                onUpload={this.handlePhotoUpload}
              />
            </Segment>
            <Segment className="userData">
              <Header as={"h3"} style={{ marginTop: 4 }}>
                Phone:
                {this.props.canUpdate || this.props.canUpdateSelf ? (
                  <span>{user.phone}</span>
                ) : (
                  <span>{user.phone}</span>
                )}
              </Header>
              <Header as={"h3"} style={{ marginTop: 4 }}>
                Email:
                {this.props.canUpdate || this.props.canUpdateSelf ? (
                  <span>{user.email}</span>
                ) : (
                  <span>{user.email}</span>
                )}
              </Header>
              <Header as={"h3"} style={{ marginTop: 4 }}>
                Town:
                <span>
                  {user.geoJSON && user.geoJSON.properties
                    ? user.geoJSON.properties.town
                      ? user.geoJSON.properties.town
                      : "N/A"
                    : "N/A"}
                </span>
              </Header>
              <Header as={"h3"} style={{ marginTop: 4 }}>
                Force Number:
                <span>{user.forceId}</span>
              </Header>
            </Segment>
            {this.renderRoles()}
            {this.props.settings.drivingCatSwitch && (
              <>
                <Divider />
                {this.renderDriving()}
              </>
            )}
            {this.props.settings.sectionsSwitch && (
              <>
                <Divider />
                {this.renderSections()}
              </>
            )}

            {this.props.settings.awardsSwitch && (
              <>
                <Divider />
                {this.renderAwards()}
              </>
            )}
          </Grid.Column>
          <Grid.Column width={12}>
            <Grid stackable>
              <Grid.Row stretched>
                <Grid.Column width={8} className="no-pad">
                  <Grid stackable className="no-marg">
                    <Grid.Row stretched>
                      <Grid.Column computer={8} mobile={16}>
                        <Segment textAlign={"center"}>
                          <Header as={"h3"}>Last callout</Header>
                          <Header as={"h1"}>
                            {user.Dash.lastCallout !== "--"
                              ? dayjs(user.Dash.lastCallout).format("DD-MM-YY")
                              : "--"}
                          </Header>
                        </Segment>
                      </Grid.Column>
                      <Grid.Column computer={8} mobile={16}>
                        <Segment textAlign="center">
                          <Header as={"h3"}>Callouts in 12</Header>
                          <Header as={"h1"}>{user.Dash.in12}</Header>
                        </Segment>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
                <Grid.Column width={8} className="no-pad">
                  <Grid stackable className="no-marg">
                    <Grid.Row stretched>
                      <Grid.Column computer={8} mobile={16}>
                        <Segment textAlign={"center"}>
                          <Header as={"h3"}>Operational</Header>
                          {this.props.permissions.canUpdateOthers ? (
                            <Button
                              size={"huge"}
                              onClick={() =>
                                this.updateStatus({
                                  operational: !user.operational,
                                })
                              }
                              positive={user.operational}
                              negative={!user.operational}
                            >
                              {user.operational ? (
                                <FaThumbsUp size={"2rem"} />
                              ) : (
                                <FaThumbsDown size={"2rem"} />
                              )}
                            </Button>
                          ) : user.operational ? (
                            <div className="flex items-center justify-center">
                              <FaThumbsUp size={"3rem"} color={"green"} />
                            </div>
                          ) : (
                            <div className="flex items-center justify-center">
                              <FaThumbsDown size={"3rem"} color={"red"} />
                            </div>
                          )}
                        </Segment>
                      </Grid.Column>
                      <Grid.Column computer={8} mobile={16}>
                        <Segment textAlign="center">
                          <Header as={"h3"}>On-call / Off-call:</Header>
                          {this.props.permissions.canUpdate ? (
                            <Button
                              size={"huge"}
                              onClick={() =>
                                this.updateStatus({
                                  onCall: !user.onCall,
                                })
                              }
                              positive={user.onCall}
                              negative={!user.onCall}
                              disabled={user.operational ? false : true}
                            >
                              {user.onCall ? (
                                <FaThumbsUp size={"2rem"} />
                              ) : (
                                <FaThumbsDown size={"2rem"} />
                              )}
                            </Button>
                          ) : user.onCall ? (
                            <div className="flex items-center justify-center">
                              <FaThumbsUp size={"3rem"} color={"green"} />
                            </div>
                          ) : (
                            <div className="flex items-center justify-center">
                              <FaThumbsDown size={"3rem"} color={"red"} />
                            </div>
                          )}
                        </Segment>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row className="no-pad">
                <Grid.Column style={{ marginBottom: "2rem" }}>
                  {this.props.settings &&
                    this.state.loading === false &&
                    this.renderComp()}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
  renderAddCPDModal() {
    const { log } = this.state;
    const logType = log.logType === "training" ? "Training" : "Incident";
    return (
      <Modal
        open={this.state.addLog}
        onClose={() => this.setState({ addLog: false })}
        centered={false}
        size="small"
        closeOnDimmerClick={false}
      >
        <Modal.Header>
          <Icon name="graduation cap" />
          {`Add CPD`}
        </Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <label>Title</label>
              <input name="title" onChange={this.onLogChange} />
            </Form.Field>

            <Form.Group widths={2}>
              <Form.Field>
                <label>Types</label>
                {this.state.log.logType === "training" ? (
                  <Select
                    name="type"
                    options={this.state.logTypes}
                    onChange={this.handleLogDropdownChange}
                  />
                ) : (
                  <input name="type" readOnly value={"Incident"} />
                )}
              </Form.Field>
              <Form.Field>
                <label htmlFor="date">Date</label>
                <DateInput
                  autoComplete="off"
                  name="date"
                  startMode={"day"}
                  animation="none"
                  dateFormat={"YYYY-MM-DD"}
                  closable={true}
                  placeholder="Date"
                  value={log.date ? dayjs(log.date).format("YYYY-MM-DD") : ""}
                  iconPosition="left"
                  onChange={this.handleLogChange}
                  localization="en_GB"
                />
              </Form.Field>
            </Form.Group>
            <Form.Field>
              <label>Description</label>
              <TextArea name="description" onChange={this.onLogChange} />
            </Form.Field>
            <Form.Field>
              <label>Learning Outcome</label>
              <TextArea name="learningOutcome" onChange={this.onLogChange} />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            negative
            floated={"left"}
            onClick={() => this.setState({ addLog: false })}
          >
            Cancel
          </Button>
          <Button color="green" onClick={this.addTrainingLog} inverted>
            <Icon name="save" />
            Add
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
  renderAddLogModal() {
    const { log } = this.state;
    const logType = log.logType === "training" ? "Training" : "Incident";
    return (
      <Modal
        open={this.state.addLog}
        onClose={() => this.setState({ addLog: false })}
        centered={false}
        size="small"
        closeOnDimmerClick={false}
      >
        <Modal.Header>
          <Icon name="graduation cap" />
          {`Add ${logType} Log`}
        </Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <label>Title</label>
              <input name="title" onChange={this.onLogChange} />
            </Form.Field>

            <Form.Group widths={2}>
              <Form.Field>
                <label>Types</label>
                {this.state.log.logType === "training" ? (
                  <Select
                    name="type"
                    options={this.state.logTypes}
                    onChange={this.handleLogDropdownChange}
                  />
                ) : (
                  <input name="type" readOnly value={"Incident"} />
                )}
              </Form.Field>
              <Form.Field>
                <label htmlFor="date">Date</label>
                <DateInput
                  autoComplete="off"
                  name="date"
                  startMode={"day"}
                  animation="none"
                  dateFormat={"YYYY-MM-DD"}
                  closable={true}
                  placeholder="Date"
                  value={log.date ? dayjs(log.date).format("YYYY-MM-DD") : ""}
                  iconPosition="left"
                  onChange={this.handleLogChange}
                  localization="en_GB"
                />
              </Form.Field>
            </Form.Group>
            <Form.Field>
              <label>Description</label>
              <TextArea name="description" onChange={this.onLogChange} />
            </Form.Field>
            <Form.Field>
              <label>Learning Outcome</label>
              <TextArea name="learningOutcome" onChange={this.onLogChange} />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            negative
            floated={"left"}
            onClick={() => this.setState({ addLog: false })}
          >
            Cancel
          </Button>
          <Button color="green" onClick={this.addTrainingLog} inverted>
            <Icon name="save" />
            Add
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
  renderGarage() {
    let vehicles = {};
    if (this.props.vehicles && this.props.vehicles.length) {
      vehicles = this.props.vehicles.map((vehicle) => {
        let approved = "";
        if (vehicle.approved) {
          approved = "positive1";
        }
        return (
          <Card
            key={vehicle._id}
            className={approved}
            onClick={() =>
              this.props.history.push(`/team/vehicles/${vehicle._id}`)
            }
            // raised
          >
            <Card.Content className={approved}>
              {/* <Image floated="right" size="mini" src="https://react.semantic-ui.com/images/avatar/large/steve.jpg" /> */}
              <Card.Header>
                {vehicle.make} {vehicle.model}
              </Card.Header>
              <Card.Meta>{vehicle.regNumber}</Card.Meta>
              <Card.Description>
                <Grid stackable celled centered className={"vehicleBox"}>
                  <Grid.Row columns={2} centered>
                    <Grid.Column>
                      <Header>Seats</Header>
                    </Grid.Column>
                    <Grid.Column>
                      <Header>Doors</Header>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={2} centered>
                    <Grid.Column>
                      <Header as={"h3"}>{vehicle.seats}</Header>
                    </Grid.Column>
                    <Grid.Column>
                      <Header as={"h3"}>{vehicle.doors}</Header>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Card.Description>
            </Card.Content>
          </Card>
        );
      });
    } else {
      vehicles = (
        <Segment basic>
          <Message
            color={"black"}
            icon="info"
            header="Vehicles"
            content={"No vehicles recorded for this member."}
          />
        </Segment>
      );
    }
    return (
      <Grid stackable className={"no-pad no-marg"}>
        {this.addVehicleModal()}

        <Grid.Row centered>
          <Grid.Column width="16" textAlign="center">
            <p className={"boxHeader "}>
              Vehicles &nbsp;
              <Button
                onClick={() => this.setState({ vehicleModal: true })}
                icon
                basic
                className="subtleButton"
              >
                <Icon name="plus" color="green" />
              </Button>
            </p>
            <Card.Group itemsPerRow={4} doubling stackable>
              {vehicles}
            </Card.Group>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
  renderArchiveModal() {
    const { data } = this.state;
    return (
      <Modal
        open={this.state.archiveModal}
        onClose={this.handleArchiveClose}
        centered={false}
        size="large"
        closeOnDimmerClick={false}
      >
        <Header icon="user" content={`Archive Member `} />
        <Modal.Content>
          <Form>
            <Header block>
              Marking an account as Archived will take this member off call and
              make them non operational. Unless marked as to be kept this
              account will also be deleted in 60 days.
            </Header>
            <Form.Group widths="equal">
              <Form.Field>
                <label>Archive Member:</label>

                <Form.Checkbox
                  className={"goodToggle"}
                  style={{ padding: 10 }}
                  checked={data.isArchived}
                  onClick={() =>
                    this.setState({
                      data: {
                        ...this.state.data,
                        isArchived: !data.isArchived,
                      },
                    })
                  }
                />
              </Form.Field>
              <Form.Field>
                <label>Keep Account:</label>

                <Form.Checkbox
                  className={"goodToggle"}
                  style={{ padding: 10 }}
                  checked={data.keepAccount}
                  onClick={() =>
                    this.setState({
                      data: {
                        ...this.state.data,
                        keepAccount: !data.keepAccount,
                      },
                    })
                  }
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button negative floated={"left"} onClick={this.handleArchiveClose}>
            Cancel
          </Button>
          <Button color="green" onClick={this.archiveOnSubmit} inverted>
            <Icon name="save" />
            Update
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
  renderEditModal() {
    if (this.state.editModal) {
      return (
        <UpdateMemberDetailsModal
          user={this.state.user}
          onClose={() => {
            this.setState({ editModal: false });
            this.props.reloadUser();
          }}
          permissions={this.props.permissions}
        />
      );
    }
    return null;
  }
  renderDiary() {
    const style = {
      borderRadius: 10,
      // opacity: 0.9,
      padding: "2em",
    };
    let dash = {};
    if (this.props.eventsDash) {
      dash = this.props.eventsDash.map((item) => {
        return (
          <Statistic key={item.name}>
            <Statistic.Value>{item.count}</Statistic.Value>
            <Statistic.Label>{item.name}</Statistic.Label>
          </Statistic>
        );
      });
    }
    const CustomEvent = (event) => {
      let org = "";
      if (event.event.organiserID === this.props.user.centralId) {
        org = <Icon name="star" />;
      }
      return (
        <Popup
          inverted
          position="top center"
          size="small"
          style={style}
          trigger={
            <div>
              <strong>{moment(event.event.start).format("HH:mm")} </strong> -{" "}
              {event.title} {org}
            </div>
          }
        >
          <Grid celled stackable className="popupGrid">
            <Grid.Row columns={1}>
              <Grid.Column textAlign="center">
                <p>{event.title}</p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column textAlign="center">
                <Header className="no-pad no-marg">Start</Header>
                <Divider />
                <p>{moment(event.event.start).format("HH:mm")}</p>
              </Grid.Column>
              <Grid.Column textAlign="center">
                <Header className="no-pad no-marg">End</Header>
                <Divider />
                <p>{moment(event.event.end).format("HH:mm")}</p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
              <Grid.Column textAlign="center">
                <p>{event.event.details}</p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Popup>
      );
    };

    return (
      // <Segment className="no-pad " basic>
      <Grid stackable className="no-marg">
        <Grid.Row>
          <Grid.Column width={16} textAlign="center">
            <p className="boxHeader">Events Attended in Last 12 months</p>
            {dash.length > 0 && (
              <Statistic.Group widths={dash.length}>{dash}</Statistic.Group>
            )}
            {/* <Divider /> */}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1}>
          <Grid.Column style={{ paddingBottom: 20 }}>
            {this.props.events && (
              <Segment>
                <Calendar
                  // views={["month", "week", "day"]}
                  step={60}
                  localizer={localizer}
                  events={this.props.events}
                  titleAccessor="type"
                  startAccessor={(event) => {
                    return moment(event.start).toDate();
                  }}
                  endAccessor={(event) => {
                    return moment(event.end).toDate();
                  }}
                  defaultDate={new Date()}
                  tooltipAccessor="details"
                  onSelectEvent={(e) => this.goto(`/calendar/${e._id}`)}
                  eventPropGetter={this.eventStyleGetter}
                  components={{
                    event: CustomEvent,
                    // toolbar: this.CustomToolbarCapsule({ ADD: this.addEventShow })
                  }}
                  style={{ minHeight: 60 + "vh" }}
                />
              </Segment>
            )}
            <span className="key">
              <Icon name="star" /> Member is event organiser. Only showing
              events user is organising or going to.
            </span>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      // </Segment>
    );
  }
  renderPrivacy() {
    let { user } = this.props;
    if (user.privacy) {
      return (
        <Grid stackable className={" no-marg"}>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Segment className="subtle">
                <p className="boxHeader">Privacy</p>
                <Header as={"h3"}>
                  You can hide your email address, phone numbers and actual
                  address from team members. Please note that team
                  administrators will still have access to these details.
                </Header>
                <Header as={"h3"}>
                  Hide Phone:
                  {this.props.permissions.canUpdate ? (
                    <Radio
                      toggle
                      className={"goodToggle"}
                      checked={user.privacy.hidePhone}
                      onClick={() =>
                        this.updatePrivacy({
                          hidePhone: !user.privacy.hidePhone,
                        })
                      }
                    />
                  ) : user.privacy.hidePhone ? (
                    <span>Yes</span>
                  ) : (
                    <span>No</span>
                  )}
                </Header>
                <Header as={"h3"}>
                  Hide Email:
                  {this.props.permissions.canUpdate ? (
                    <Radio
                      toggle
                      className={"goodToggle"}
                      checked={user.privacy.hideEmail}
                      onClick={() =>
                        this.updatePrivacy({
                          hideEmail: !user.privacy.hideEmail,
                        })
                      }
                    />
                  ) : user.privacy.hideEmail ? (
                    <span>Yes</span>
                  ) : (
                    <span>No</span>
                  )}
                </Header>
                <Header as={"h3"}>
                  Hide Address:
                  {this.props.permissions.canUpdate ? (
                    <Radio
                      toggle
                      className={"goodToggle"}
                      checked={user.privacy.hideAddress}
                      onClick={() =>
                        this.updatePrivacy({
                          hideAddress: !user.privacy.hideAddress,
                        })
                      }
                    />
                  ) : user.privacy.hideAddress ? (
                    <span>Yes</span>
                  ) : (
                    <span>No</span>
                  )}
                </Header>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      );
    }
  }
  renderSettings() {
    return (
      <Grid stackable className="no-marg">
        <Grid.Row>
          <Grid.Column width={6}>{this.renderPrivacy()}</Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
  renderCourses() {
    return (
      <Grid stackable className="no-marg">
        <Grid.Row>
          <Grid.Column width={16} className="no-pad">
            <Segment basic>
              <p className="boxHeader">Courses</p>
              <MemberCourseList centralID={this.state.user.centralId} />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
  renderFullMenu() {
    const { activeItem } = this.state;
    const { user } = this.props;
    let name = user.name;
    if (user.nickName) {
      name = name + " " + `(${this.state.user.nickName}) `;
    }
    if (user.teamId) {
      name = name + " - " + this.state.data.teamId;
    }
    return (
      <Menu stackable className="subMenu">
        <Menu.Item>
          <Header as={"h2"}>{name}</Header>
        </Menu.Item>
        <Menu.Item
          name="dash"
          active={activeItem === "dash"}
          onClick={this.handleMenuClick}
        >
          Dash
        </Menu.Item>
        <MemberCanEdit user={this.props.user}>
          <Menu.Item
            name="details"
            active={activeItem === "details"}
            onClick={this.handleMenuClick}
          >
            Details
          </Menu.Item>

          <Dropdown text="Training" pointing className="link item">
            <Dropdown.Menu>
              <Dropdown.Header>Categories</Dropdown.Header>
              <Dropdown.Item
                name="diary"
                active={activeItem === "diary"}
                onClick={this.handleMenuClick}
              >
                Diary
              </Dropdown.Item>
              <Dropdown.Item
                name="courses"
                active={activeItem === "courses"}
                onClick={this.handleMenuClick}
              >
                Courses
              </Dropdown.Item>
              <Dropdown.Item
                name="comps"
                active={activeItem === "comps"}
                onClick={this.handleMenuClick}
              >
                Competencies
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Header>Logs</Dropdown.Header>
              <Dropdown.Item
                name="trainingLog"
                active={activeItem === "trainingLog"}
                onClick={this.handleMenuClick}
              >
                Training
              </Dropdown.Item>
              <Dropdown.Item
                name="incidentLog"
                active={activeItem === "incidentLog"}
                onClick={this.handleMenuClick}
              >
                Incident
              </Dropdown.Item>
              <Dropdown.Item
                name="cpdLog"
                active={activeItem === "cpdLog"}
                onClick={this.handleMenuClick}
              >
                CPD
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Menu.Item
            name="callouts"
            active={activeItem === "callouts"}
            onClick={this.handleMenuClick}
          >
            Deployments
          </Menu.Item>

          <Menu.Item
            name="garage"
            active={activeItem === "garage"}
            onClick={this.handleMenuClick}
          >
            Garage
          </Menu.Item>

          <Menu.Item
            name="settings"
            active={activeItem === "settings"}
            onClick={this.handleMenuClick}
          >
            Settings
          </Menu.Item>
          <Menu.Item
            name="assets"
            active={activeItem === "assets"}
            onClick={this.handleMenuClick}
          >
            Assets
          </Menu.Item>
        </MemberCanEdit>

        <Menu.Menu position="right" style={{ paddingRight: "1rem" }}>
          <MemberCanEdit user={this.props.user}>
            <Dropdown text="Admin" pointing className="link item">
              <Dropdown.Menu>
                <MemberCanEdit user={this.props.user}>
                  <Dropdown.Item
                    onClick={() => {
                      this.setState({ editModal: true });
                    }}
                  >
                    <Icon name="pencil" color="blue" />
                    Edit
                  </Dropdown.Item>
                </MemberCanEdit>

                <React.Fragment>
                  <AuthComponents.Can scope={"members:update:archive"}>
                    <Dropdown.Item
                      onClick={() => {
                        this.setState({ archiveModal: true });
                      }}
                    >
                      <Icon name="close" color="red" />
                      Archive
                    </Dropdown.Item>
                  </AuthComponents.Can>
                  <AuthComponents.Can scope={"notes:create"}>
                    <Dropdown.Item
                      name="notes"
                      active={activeItem === "notes"}
                      onClick={this.handleMenuClick}
                    >
                      <FaRegStickyNote style={{ marginRight: "1rem" }} />
                      Notes
                    </Dropdown.Item>
                  </AuthComponents.Can>
                </React.Fragment>
              </Dropdown.Menu>
            </Dropdown>
          </MemberCanEdit>
        </Menu.Menu>
      </Menu>
    );
  }
  renderModals() {
    return (
      <React.Fragment>
        {this.renderEditModal()}
        {this.renderConfirmBox()}
        {/* {this.renderAddressModal()} */}
        {this.renderArchiveModal()}
        {this.renderAddLogModal()}
        {this.renderShowLog()}
      </React.Fragment>
    );
  }
  renderRoles() {
    let disabled = false;
    if (Authentication.userData.sub === this.props.user.centralId) {
      disabled = true;
    }
    return (
      <ArrayCard
        disabled={disabled}
        title={"Roles"}
        fieldName="roles"
        permissions={this.props.permissions}
        data={this.props.user.roles}
        list={this.props.settings.orgRoles}
        add={this.handleRoles}
        update={this.updateRoleData}
      />
    );
  }
  renderSections() {
    let disabled = false;
    if (Authentication.userData.sub === this.props.user.centralId) {
      disabled = true;
    }
    return (
      <ArrayCard
        disabled={disabled}
        title={"Sections"}
        fieldName="sections"
        permissions={this.props.permissions}
        data={this.props.user.sections}
        list={this.props.settings.sections}
        add={this.handleSections}
        update={this.updateSectionsData}
      />
    );
  }
  renderAwards() {
    let disabled = false;
    if (Authentication.userData.sub === this.props.user.centralId) {
      disabled = true;
    }
    return (
      <ArrayCard
        disabled={disabled}
        title={"Awards/Skills"}
        fieldName="awards"
        permissions={this.props.permissions}
        data={this.props.user.awards}
        list={this.props.settings.awards}
        add={this.handleAwards}
        update={this.updateAwardsData}
      />
    );
  }
  renderDriving() {
    let disabled = false;
    if (Authentication.userData.sub === this.props.user.centralId) {
      disabled = true;
    }
    return (
      <ArrayCard
        disabled={disabled}
        title={"Driving Categories"}
        fieldName="drivingCat"
        permissions={this.props.permissions}
        data={this.props.user.drivingCat}
        list={this.props.settings.drivingCat}
        add={this.handleDrivingCats}
        update={this.updateData}
      />
    );
  }
  renderShowLog() {
    const { log } = this.state;
    return (
      <Modal
        open={this.state.showLog}
        onClose={() => this.setState({ showLog: false })}
        centered={false}
        size="small"
      >
        <Modal.Header>
          <Icon name="graduation" />
          Training Log
        </Modal.Header>
        <Modal.Content className="no-pad">
          <Segment basic>
            <p className={"boxHeader"}>{log.title}</p>

            <Grid stackable className="no-pad no-grid">
              <Grid.Row>
                <Grid.Column width={8}>
                  <Header as={"h3"}>
                    Date
                    <span>{this.renderDate(log.date)}</span>
                  </Header>
                </Grid.Column>
                <Grid.Column width={8}>
                  <Header as={"h3"}>
                    Type
                    <span>{log.type}</span>
                  </Header>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column width={16}>
                  <p className="boxHeader">Description</p>
                  <Header as={"h3"}>{log.description}</Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16}>
                  <p className="boxHeader">Learning Outcome</p>
                  <Header as={"h3"}>{log.learningOutcome}</Header>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Modal.Content>
        <Modal.Actions className="no-marg">
          <Button
            primary
            onClick={() => this.setState({ showLog: false })}
            style={{ margin: 0 }}
          >
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
  renderIncidentLog() {
    const list = this.state.incidentLogs.map((log) => {
      return (
        <Table.Row key={log._id} onClick={() => this.showLog(log)}>
          <Table.Cell width={4}>{this.renderDate(log.date)}</Table.Cell>
          <Table.Cell width={4}>{log.type}</Table.Cell>
          <Table.Cell>{log.title}</Table.Cell>
        </Table.Row>
      );
    });
    return (
      <Grid stackable className="no-marg">
        <Grid.Row>
          <Grid.Column width={16} className="no-pad">
            <Segment basic>
              <p className="boxHeader">
                Incident Log
                <Button
                  basic
                  className="subtleButton"
                  size="tiny"
                  icon
                  onClick={() =>
                    this.setState({
                      addLog: true,
                      log: {
                        ...this.state.log,
                        logType: "incident",
                        type: "Incident",
                      },
                    })
                  }
                >
                  <Icon name="plus" color="green" />
                </Button>
              </p>
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Date</Table.HeaderCell>

                    <Table.HeaderCell>Type</Table.HeaderCell>
                    <Table.HeaderCell>Title</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>{list}</Table.Body>
              </Table>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
  renderCPDLog() {
    // const list = this.state.cpdLogs.map((log) => {
    //   return (
    //     <Table.Row key={log._id} onClick={() => this.showLog(log)}>
    //       <Table.Cell width={4}>{this.renderDate(log.date)}</Table.Cell>
    //       <Table.Cell width={4}>{log.type}</Table.Cell>
    //       <Table.Cell>{log.details}</Table.Cell>
    //       <Table.Cell>{log.minutes}</Table.Cell>
    //     </Table.Row>
    //   );
    // });
    return (
      <Grid stackable className="no-marg">
        <Grid.Row>
          <Grid.Column width={16} className="no-pad">
            <Segment basic>
              <p className="boxHeader" style={{ color: "white" }}>
                CPD Log
              </p>
              <CPDList data={this.state.cpdDash} />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
  renderTrainingLog() {
    const list = this.state.trainingLogs.map((log) => {
      return (
        <Table.Row key={log._id} onClick={() => this.showLog(log)}>
          <Table.Cell width={4}>{this.renderDate(log.date)}</Table.Cell>
          <Table.Cell width={4}>{log.type}</Table.Cell>
          <Table.Cell>{log.title}</Table.Cell>
        </Table.Row>
      );
    });
    return (
      <Grid stackable className="no-marg">
        <Grid.Row>
          <Grid.Column width={16} className="no-pad">
            <Segment basic>
              <p className="boxHeader">
                Training Log
                <Button
                  basic
                  className="subtleButton"
                  size="tiny"
                  icon
                  onClick={() =>
                    this.setState({
                      addLog: true,
                      log: { ...this.state.log, logType: "training" },
                    })
                  }
                >
                  <Icon name="plus" color="green" />
                </Button>
              </p>
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Date</Table.HeaderCell>

                    <Table.HeaderCell>Type</Table.HeaderCell>
                    <Table.HeaderCell>Title</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>{list}</Table.Body>
              </Table>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
  renderNotes1() {
    const list = this.state.notes.map((note) => {
      return (
        <Table.Row key={note._id}>
          <Table.Cell width={4}>{this.renderDate(note.date)}</Table.Cell>
          <Table.Cell width={4}>{note.details}</Table.Cell>
        </Table.Row>
      );
    });
    return (
      <Grid stackable className="no-marg">
        <Grid.Row>
          <Grid.Column width={16} className="no-pad">
            <Segment basic>
              <p className="boxHeader">
                Notes
                <Button
                  basic
                  className="subtleButton"
                  size="tiny"
                  icon
                  onClick={() => this.setState({ addNote: true })}
                >
                  <Icon name="plus" color="green" />
                </Button>
              </p>
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Date</Table.HeaderCell>

                    <Table.HeaderCell>Details</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>{list}</Table.Body>
              </Table>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
  renderNotes() {
    return (
      <NotesPage
        notes={this.props.user.notes}
        addNote={this.addNote}
        whoami={this.props.whoami}
      />
    );
  }
  renderAssets() {
    return (
      <div className="mt-2">
        <Box title="Assigned Assets">
          <table className="min-w-full divide-y divide-gray-200 border-separate table-fixed">
            <thead>
              <tr className="bg-gray-800">
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-400 uppercase ">
                  Asset
                </th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-400 uppercase ">
                  Asset Number
                </th>
                <th className="px-4 py-3 text-center text-xs font-medium text-gray-400 uppercase ">
                  Purchase Date
                </th>
                <th className="px-4 py-3 text-center text-xs font-medium text-gray-400 uppercase ">
                  Expires Date
                </th>
                <th className="px-4 py-3 text-center text-xs font-medium text-gray-400 uppercase ">
                  Tags
                </th>
              </tr>
            </thead>
            <tbody className="bg-gray-900 divide-y divide-gray-200 text-lg">
              {this.state.assets.map((a) => {
                return (
                  <tr
                    key={a._id}
                    className={`cursor-pointer hover:bg-gray-800`}
                    onClick={() => this.props.history.push(`/assets/${a._id}`)}
                  >
                    <td className="p-2">{a.name}</td>
                    <td className="p-2">{a.assetNum}</td>
                    <td className="p-2   ">
                      {moment(a.date_purchased).format("DD-MM-YY")}
                    </td>
                    <td className="p-2 ">
                      {a.date_expires
                        ? moment(a.date_expires).format("DD-MM-YY")
                        : "--"}
                    </td>
                    <td className="p-2 ">
                      {a.tags.map((t, i) => {
                        return (
                          <span
                            key={i}
                            className="p-2 m-1 bg-gray-800 rounded-lg border border-gray-700"
                          >
                            {t}
                          </span>
                        );
                      })}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Box>
      </div>
    );
  }
  render() {
    const { activeItem } = this.state;

    return (
      <Segment basic className="page-holder">
        {this.props.user.isArchived && (
          <Message color={"yellow"}>
            <Message.Header>
              Archived ({this.renderDate(this.props.user.archivedDate)})
            </Message.Header>{" "}
            This user is archived and will no longer be able to re-login to any
            system/add.
          </Message>
        )}
        {this.renderModals()}
        <Grid stackable className="no-marg userCard">
          <Grid.Row className={"no-pad"}>
            <Grid.Column width={16} className={" no-pad"}>
              {this.renderFullMenu()}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className={"no-pad"}>
            <Grid.Column width={16}>
              <MemberCanEdit user={this.props.user}>
                <React.Fragment>
                  {/* {activeItem === "dash" && this.renderDash()} */}
                  {activeItem === "details" && this.renderDetails()}
                  {activeItem === "diary" && this.renderDiary()}
                  {activeItem === "courses" && this.renderCourses()}
                  {activeItem === "settings" && this.renderSettings()}
                  {activeItem === "garage" && this.renderGarage()}
                  {activeItem === "trainingLog" && this.renderTrainingLog()}
                  {activeItem === "incidentLog" && this.renderIncidentLog()}
                  {activeItem === "cpdLog" && this.renderCPDLog()}
                  {activeItem === "notes" && this.renderNotes()}
                  {activeItem === "assets" && this.renderAssets()}
                  {activeItem === "comps" && (
                    <MemberCompPage
                      showScores={true}
                      user={this.props.user}
                      settings={this.props.settings}
                    />
                  )}
                  {activeItem === "callouts" && (
                    <Segment basic>
                      <CalloutList
                        data={this.props.callouts}
                        oldCallouts={this.props.user.oldCallouts}
                      />
                    </Segment>
                  )}
                </React.Fragment>
              </MemberCanEdit>

              <React.Fragment>
                {activeItem === "dash" && this.renderDash()}
              </React.Fragment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}
export default withRouter(UserCard);
