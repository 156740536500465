import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Menu, Header } from "semantic-ui-react";

function mapStateToProps(state) {
  return {
    user: state.user,
    system: state.system,
  };
}

class TeamNav extends React.Component {
  state = { activeItem: "home" };
  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name });
  };
  componentDidMount() {}
  render() {
    const { activeItem } = this.state;
    return (
      <Menu stackable className={"sub-menu"}>
        <Menu.Item>
          <Header as={"h3"} className={"subtle"}>
            Team Menu
          </Header>
        </Menu.Item>

        <Menu.Item
          name={"dash"}
          as={NavLink}
          to={"/management/team"}
          active={activeItem === "dash"}
          exact
          onClick={this.handleItemClick}
        >
          Dash
        </Menu.Item>
        <Menu.Item
          name={"vetting"}
          as={NavLink}
          to={"/management/team/vetting"}
          active={activeItem === "vetting"}
          exact
          onClick={this.handleItemClick}
        >
          Vetting
        </Menu.Item>
        <Menu.Item
          name={"fees"}
          as={NavLink}
          to={"/management/team/fees"}
          active={activeItem === "fees"}
          exact
          onClick={this.handleItemClick}
        >
          Fees
        </Menu.Item>
        <Menu.Item
          name={"activity"}
          as={NavLink}
          to={"/management/team/activity"}
          active={activeItem === "activity"}
          exact
          onClick={this.handleItemClick}
        >
          Activity Report
        </Menu.Item>
      </Menu>
    );
  }
}

export default connect(mapStateToProps, {})(TeamNav);
