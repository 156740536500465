import React, { Component } from "react";
import { Table, Header } from "semantic-ui-react";
import api from "../../../actions/api";
import CommonFunctions from "../../../lib/CommonFunctions";
export default class CompScoreList extends Component {
  state = {
    scores: {
      members: [],
      sections: [],
    },
  };
  componentDidMount() {
    this.getData();
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.comp.id !== prevProps.comp.id) {
      this.getData();
    }
  }
  getData = () => {
    if (this.props.comp.id) {
      api.management.compScoresList(this.props.comp.id).then((scores) => {
        this.setState({
          scores,
        });
      });
    }
  };

  renderData() {
    const scores = this.state.scores.members.map((s) => {
      let color = "negative1";
      if (s._results.totalPercent) {
        switch (true) {
          case s._results.totalPercent >= 75:
            color = "positive1";
            break;
          case s._results.totalPercent >= 26:
            color = "warning1";
            break;
          case s._results.totalPercent < 26:
            color = "negative1";
            break;
          default:
            color = "negative1";
            break;
        }
      }
      return (
        <Table.Row key={s._id}>
          <Table.Cell>
            <Header>{s.firstName + " " + s.lastName}</Header>
          </Table.Cell>

          {this.state.scores.sections.map((section, i) => {
            const value = s._results.scores[section._id];
            const colour = CommonFunctions.getScoreColour(
              this.props.settings.compScoreColours,
              value
            );
            return (
              <Table.Cell
                key={i}
                textAlign="center"
                style={{ background: colour }}
              >
                {value === undefined ? 0 : value}
              </Table.Cell>
            );
          })}
          <Table.Cell textAlign="center" className={`${color}`}>
            <Header>{s._results.totalPercent}</Header>
          </Table.Cell>
        </Table.Row>
      );
    });
    return scores;
  }
  render() {
    return (
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <Header>Member</Header>
            </Table.HeaderCell>
            {this.state.scores.sections.map((s) => {
              return (
                <Table.HeaderCell key={s._id} textAlign="center">
                  <Header>
                    {this.props.comp.refNum}.{s.refNum} {s.title}
                  </Header>
                </Table.HeaderCell>
              );
            })}
            <Table.HeaderCell textAlign="center">%</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{this.renderData()}</Table.Body>
      </Table>
    );
  }
}
