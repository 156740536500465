import React, { useState, useEffect, Suspense, lazy } from "react";
import api from "../../../actions/api";
import Box from "../ui-wrappers/Box";
import Layout from "../ui-wrappers/Layout";
import dayjs from "dayjs";
import { PencilIcon, PrinterIcon } from "@heroicons/react/outline";
import Tooltip from "../ui-wrappers/Tooltip";

export default function ServicePage({ match, history }) {
  const [asset, setAsset] = useState({});

  const [service, setService] = useState({
    type: "",
    date_scheduled: "",
    date_serviced: "",
    notes: "",
    status: "",
    replacedParts: [],
    documents: [],
    supplier: "",
    asset: "",
    partsCost: "",
    labourCost: "",
    totalCost: "",
  });
  useEffect(() => {
    api.assets.show(match.params.asset).then((res) => {
      setAsset({
        ...asset,
        ...res,
      });
    });
    api.assets.service.show(match.params.asset, match.params.id).then((res) => {
      setService({
        ...service,
        ...res,
      });
    });
  }, []);
  const gotoSupplier = (id) => {
    history.push(`/assets/suppliers/${id}`);
  };
  console.log("service", service);
  return (
    <Layout full>
      <div className="text-2xl text-center mb-4">
        <div className="flex flex-row gap-4 p-2 w-full">
          <div
            className="flex flex-col text-center w-1/5 border border-blue-900 text-yellow-700 cursor-pointer hover:bg-gray-700 rounded-lg"
            onClick={() => history.push(`/assets/${asset._id}`)}
          >
            Name
            <span className="text-white">{asset.name}</span>
          </div>
          <div className="flex flex-col text-center w-1/5 border border-gray-700 text-yellow-700 rounded-lg">
            Asset Num
            <span className="text-white">{asset.assetNum}</span>
          </div>
          <div className="flex flex-col text-center w-1/5 border border-gray-700 text-yellow-700 rounded-lg">
            Service Interval
            <span className="text-white">{asset.serviceInterval}</span>
          </div>
          <div className="flex flex-col text-center w-1/5 border border-gray-700 text-yellow-700 rounded-lg">
            Last Service
            <span className="text-white">
              {asset.LastService
                ? dayjs(asset.LastService).format("DD-MM-YYYY")
                : "--"}
            </span>
          </div>
          <div className="flex flex-col text-center w-1/5 border border-gray-700 text-yellow-700 rounded-lg">
            Next Service
            <span className="text-white">
              {asset.NextService
                ? dayjs(asset.NextService).format("DD-MM-YYYY")
                : "--"}
            </span>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
        <div className="col-span-1 ">
          <Box title="Service Details">
            <div className="w-full ">
              <div className="flex justify-around gap-1">
                <button
                  onClick={() =>
                    history.push(
                      `/assets/${asset._id}/service/edit/${service._id}`
                    )
                  }
                  className="w-full bg-gray-700 p-2 text-xl flex justify-center items-center hover:bg-blue-900"
                >
                  <PencilIcon className="h-6 pr-2 text-blue-400" />
                  Edit
                </button>
                <button className="w-full bg-gray-700 p-2 text-xl flex justify-center items-center hover:bg-blue-900">
                  <PrinterIcon className="h-6 pr-2 text-blue-400" />
                  Print
                </button>
              </div>

              <div className="p-2 flex justify-between text-xl border-b border-gray-700 bg-gray-800 text-sar">
                Service Type:{" "}
                <span className="px-2 text-white">{service.type}</span>
              </div>

              <div className="p-2 flex justify-between text-xl border-b border-gray-700 bg-gray-800 text-sar">
                Scheduled Date:{" "}
                <span className="px-2 text-white">
                  {service.date_scheduled
                    ? dayjs(service.date_scheduled).format("DD-MM-YYYY")
                    : "--"}
                </span>
              </div>
              <div className="p-2 flex justify-between text-xl border-b border-gray-700 bg-gray-800 text-sar">
                Serviced Date:{" "}
                <span className="px-2 text-white">
                  {service.date_serviced
                    ? dayjs(service.date_serviced).format("DD-MM-YYYY")
                    : "--"}
                </span>
              </div>
              <div className="p-2 flex justify-between text-xl border-b border-gray-700 bg-gray-800 text-sar">
                Status:{" "}
                <span className="px-2 text-white">{service.status}</span>
              </div>
              <div className="p-2 flex justify-between text-xl border-b border-gray-700 bg-gray-800 text-sar">
                Parts Cost:{" "}
                <span className="px-2 text-white">{service.partsCost}</span>
              </div>
              <div className="p-2 flex justify-between text-xl border-b border-gray-700 bg-gray-800 text-sar">
                Labour Cost:{" "}
                <span className="px-2 text-white">{service.labourCost}</span>
              </div>
              <div className="p-2 flex justify-between text-xl border-b border-gray-700 bg-gray-800 text-sar">
                Total Cost:{" "}
                <span className="px-2 text-white">{service.totalCost}</span>
              </div>
              <div className="p-2 flex justify-between text-xl border-b border-gray-700 bg-gray-800 text-sar">
                Supplier:{" "}
                <span className="px-2 text-white">
                  {asset.supplier && asset.supplier.name ? (
                    <button
                      onClick={() => gotoSupplier(asset.supplier._id)}
                      className="text-right bg-gray-800 py-2 px-4 border border-gray-700 rounded-xl hover:bg-gray-900"
                    >
                      {asset.supplier.name}
                    </button>
                  ) : (
                    "--"
                  )}
                </span>
              </div>
              <div className="p-2 flex justify-between text-xl border-b border-gray-700 bg-gray-800 text-sar">
                Notes: <span className="px-2 text-white">{asset.notes}</span>
              </div>
            </div>
          </Box>
        </div>
        <div className="col-span-1 ">
          {/*<Box title="Documents">*/}
          {/*  <table className="min-w-full divide-y divide-gray-200 border-separate table-fixed">*/}
          {/*    <thead>*/}
          {/*      <tr className="bg-gray-800">*/}
          {/*        <th className="px-4 py-3 text-left text-xs font-medium text-gray-400 uppercase ">*/}
          {/*          Name*/}
          {/*        </th>*/}

          {/*        <th className="px-4 py-3 text-center text-xs font-medium text-gray-400 uppercase ">*/}
          {/*          Type*/}
          {/*        </th>*/}
          {/*      </tr>*/}
          {/*    </thead>*/}
          {/*    <tbody className="bg-gray-900 divide-y divide-gray-200 text-lg">*/}
          {/*      {service.documents.map((a, i) => {*/}
          {/*        return (*/}
          {/*          <tr key={i}>*/}
          {/*            <td className="p-2 ">{a.className}</td>*/}

          {/*            <td className="p-2 w-4/6">{a.type}</td>*/}
          {/*          </tr>*/}
          {/*        );*/}
          {/*      })}*/}
          {/*    </tbody>*/}
          {/*  </table>*/}
          {/*</Box>*/}
          <div className="p-4"></div>
          <Box title="Replaced Parts">
            <table className="min-w-full divide-y divide-gray-200 border-separate table-fixed">
              <thead>
                <tr className="bg-gray-800">
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-400 uppercase ">
                    Qty
                  </th>

                  <th className="px-4 py-3 text-center text-xs font-medium text-gray-400 uppercase ">
                    Item
                  </th>
                  <th className="px-4 py-3 text-center text-xs font-medium text-gray-400 uppercase ">
                    Cost
                  </th>
                </tr>
              </thead>
              <tbody className="bg-gray-900 divide-y divide-gray-200 text-lg">
                {service.replacedParts.map((a, i) => {
                  return (
                    <tr key={i}>
                      <td className="p-2 ">{a.qty}</td>

                      <td className="p-2 w-4/6">{a.item}</td>
                      <td className="p-2 w-1/6">{a.cost}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Box>
        </div>
      </div>
    </Layout>
  );
}
