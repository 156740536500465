import React from "react";

const Input = ({
  label,
  field,
  type = "text",
  onChange,
  placeholder,
  value,
  maxLength,
  style = "w-full",
  error,
  disabled = false,
}) => {
  // console.log("error", error);
  return (
    <div className={style}>
      <label
        htmlFor={field}
        className={`py-2 block text-md font-medium font-mono ${
          error ? "text-red-400" : "text-yellow-700"
        }`}
      >
        {label}
      </label>
      <input
        disabled={disabled}
        type={type}
        name={field}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        maxLength={maxLength}
        autoComplete="off"
        className={`py-2 px-2 text-lg  border-2 focus:ring-gray-600 focus:border-gray-500 placeholder-gray-600 block w-full shadow-sm  ${
          error ? "border-red-400" : "border-gray-600"
        } ${
          disabled
            ? " bg-gray-800 border-0 text-lg "
            : " bg-gray-900 border-gray-400 text-md "
        } rounded-md`}
      />
    </div>
  );
};
export default Input;
