import React from "react";

const Box = ({ title, button, children, styles, outer, className, basic }) => {
  return (
    <div className={`flex flex-1 ${outer} ${className}`}>
      <div
        className={`${
          basic ? " border-0 " : " border-4 "
        } border-opacity-60 border-double border-blue-100 rounded-md w-full  p-0`}
      >
        <div className="flex w-full justify-between bg-gray-900">
          {title && (
            <header className="p-2 bg-gray-900 text-gray-400 text-xl rounded-t-md font-mono">
              {title}
            </header>
          )}
          {button && button.text && (
            <button
              onClick={button.action}
              className={`m-2 py-2 px-4 ${button.colour} rounded-lg  text-white text-center`}
            >
              {button.text}
            </button>
          )}
        </div>

        <div className={`  ${styles}`}>{children}</div>
      </div>
    </div>
  );
};
export default Box;
