import React, {useEffect, useState} from "react";
import prettyBytes from "pretty-bytes";
import {Button, Form, Header, Icon, Message, Modal} from "semantic-ui-react";
import InlineError from "../../forms/errorMessage";


const UpdatePhotoModal = ({onGetUploadSpec, onUpload, onClose}) => {
  const [uploadError, setUploadError]             = useState(null);
  const [isLoading, setIsUploading]               = useState(false);
  const [photoFieldError, setPhotoFieldError]     = useState(null);
  const [photoToBeUploaded, setPhotoToBeUploaded] = useState("");
  const [uploadConfig, setUploadConfig]           = useState({
    maxBytes: 0,
    fileTypes: []
  });

  useEffect(() => {
    onGetUploadSpec().then(res => {
      setUploadConfig(res);
    });
  }, [onGetUploadSpec]);

  const handleOnFileChange = e => {
    if (e.currentTarget.files[0] === undefined){
      return;
    }
    const file = e.currentTarget.files[0];
    setPhotoFieldError(null);
    setPhotoToBeUploaded(file);
  };
  const handlePhotoUpload  = () => {
    if (photoToBeUploaded.name === undefined){
      setPhotoFieldError("You must select an image to upload");
      return;
    } else {
      if (photoToBeUploaded.size > uploadConfig.maxBytes){
        setPhotoFieldError("Image must be less then " + prettyBytes(uploadConfig.maxBytes, {maximumFractionDigits: 0}));
        return;
      }
      const fileTypes = uploadConfig.fileTypes.filter(a => photoToBeUploaded.type.substr(0, a.length) === a)
      if (fileTypes.length === 0){
        setPhotoFieldError("Only images are allowed to be uploaded");
        return;
      }
    }

    setIsUploading(true);
    let formData = new FormData();
    formData.append("file", photoToBeUploaded);
    onUpload(formData).then(() => {
      onClose();
    }).catch((error) => {
      let errorMessage = "Unable to upload photo at this time, please try again later";
      if (error.response !== undefined){
        errorMessage = error.response.data.message;
      }
      setIsUploading(false);
      setUploadError(errorMessage);
    });
  };

  if (uploadConfig.maxBytes === 0){
    return null;
  }

  return (
    <Modal open={true} onClose={onClose} centered={false} size="small" closeOnDimmerClick={false}>
      <Header icon="image" content="Update Photo"/>
      <Modal.Content>
        {uploadError && <Message negative>{uploadError}</Message>}
        <Form loading={isLoading}>
          <Form.Field error={photoFieldError !== null}>
            <label htmlFor="file-browser-input">
              New Profile Photo ({prettyBytes(uploadConfig.maxBytes, {maximumFractionDigits: 0})} max size)
            </label>
            <input
              type="file"
              id="file-browser-input"
              name="file-browser-input"
              onChange={handleOnFileChange}
              className={photoFieldError !== null ? "fieldWarning" : "good"}
            />
            {photoFieldError && <InlineError text={photoFieldError}/>}
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button negative floated={"left"} onClick={onClose} disabled={isLoading}>
          Cancel
        </Button>
        <Button color="green" onClick={handlePhotoUpload} inverted disabled={isLoading}>
          <Icon name="save"/>
          Update Photo
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
export default UpdatePhotoModal;