import React from "react";
import {
  Table,
  Form,
  Checkbox,
  Button,
  Icon,
  Confirm,
  Divider,
} from "semantic-ui-react";
import Box from "../ui-wrappers/Box";

export default class ManageSupplierContacts extends React.PureComponent {
  state = {
    allowSave: false,
    contacts: [],
    deleteContact: null,
    formError: null,
  };

  componentDidMount() {
    if (this.props.contacts) {
      this.setState({
        contacts: this.props.contacts,
      });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.contacts !== prevProps.contacts) {
      this.setState({
        contacts: this.props.contacts,
      });
    }
  }

  // Form Handlers
  onSubmit() {
    //TODO form validation
    this.setState(
      {
        submitting: true,
        formError: null,
      },
      () => {
        this.props
          .onSubmit(this.state.contacts)
          .then(() => {})
          .catch((err) => {
            let errorMessage =
              "Sorry, an unexpected error occurred while trying to save your changes. Please try again in a few moments";
            if (err.response !== undefined) {
              errorMessage = err.response.data.error_message;
            }
            this.setState({
              formError: errorMessage,
              submitting: false,
            });
          })
          .finally(() => {
            this.setState({
              submitting: false,
              allowSave: false,
            });
          });
      }
    );
  }
  onCancel() {
    this.setState({
      allowSave: false,
    });
    this.props.onCancel();
  }
  handleAddContact() {
    let contacts = this.state.contacts;
    contacts.push({ name: "", phone: "", email: "", primary: false });
    this.setState({
      contacts,
      allowSave: true,
    });
  }
  handleSetPrimary(contactIndex) {
    let contacts = this.state.contacts.map((item, index) => {
      return {
        ...item,
        primary: index === contactIndex,
      };
    });
    this.setState({
      contacts,
      allowSave: true,
    });
  }
  handleDeleteContact() {
    let contactIndex = this.state.deleteContact;
    this.setState({
      contacts: this.state.contacts.filter(
        (item, index) => index !== contactIndex
      ),
      deleteContact: null,
    });
  }
  handleTextChange(field, value, contactIndex) {
    this.setState({
      allowSave: true,
      contacts: this.state.contacts.map((contact, index) => {
        if (index !== contactIndex) {
          return contact;
        }
        return {
          ...contact,
          [field]: value,
        };
      }),
    });
  }
  render() {
    return (
      <Box
        title="Contacts List"
        button={{
          text: "Add Contact",
          colour: "bg-green-600",
          action: () => this.handleAddContact(),
        }}
      >
        <Form loading={this.state.submitting}>
          {this.state.deleteContact !== null && (
            <Confirm
              open={true}
              onCancel={() => this.setState({ deleteContact: null })}
              onConfirm={() => this.handleDeleteContact()}
            />
          )}

          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Phone</Table.HeaderCell>
                <Table.HeaderCell>Email</Table.HeaderCell>
                <Table.HeaderCell>Primary Contact</Table.HeaderCell>
                <Table.HeaderCell>{""}</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.state.contacts.map((contact, index) => {
                return (
                  <Table.Row key={index}>
                    <Table.Cell>
                      <input
                        placeholder="First Name"
                        value={contact.name}
                        onChange={(e) =>
                          this.handleTextChange("name", e.target.value, index)
                        }
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <input
                        placeholder="Phone"
                        value={contact.phone}
                        onChange={(e) =>
                          this.handleTextChange("phone", e.target.value, index)
                        }
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <input
                        placeholder="Email"
                        value={contact.email}
                        onChange={(e) =>
                          this.handleTextChange("email", e.target.value, index)
                        }
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <Checkbox
                        toggle
                        checked={contact.primary}
                        onChange={() => this.handleSetPrimary(index)}
                        // disabled={contact.primary}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      {!contact.primary && (
                        <Button
                          icon
                          negative
                          floated={"right"}
                          onClick={() =>
                            this.setState({ deleteContact: index })
                          }
                        >
                          <Icon name="trash" />
                        </Button>
                      )}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
          <Divider />
          <Form.Group widths={"equal"} className={"form-actions p-4"}>
            <Form.Field>
              <Button negative floated={"left"} onClick={() => this.onCancel()}>
                Cancel
              </Button>
            </Form.Field>
            <Form.Field>
              <Button
                positive
                floated={"right"}
                onClick={() => this.onSubmit()}
                disabled={!this.state.allowSave}
              >
                Save
              </Button>
            </Form.Field>
          </Form.Group>
        </Form>
      </Box>
    );
  }
}
