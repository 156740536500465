import React from "react";
import SupplierDash from "./dash";
import { Route, Switch } from "react-router-dom";
import Supplier from "./supplier";
import SupplierNav from "./navBar";
import ContactsPage from "./contacts";
import AddSupplier from "./addSupplier";
import AssetsPage from "./assets";
import EditSupplier from "./editSupplier";

function SupplierPages({ match, history }) {
  return (
    <div>
      <SupplierNav match={match} history={history} />
      <Switch>
        <Route
          exact
          path="/assets/suppliers/:id/contacts"
          component={ContactsPage}
        />
        <Route
          exact
          path="/assets/suppliers/:id/edit"
          component={EditSupplier}
        />
        <Route
          exact
          path="/assets/suppliers/:id/assets"
          component={AssetsPage}
        />
        <Route exact path="/assets/suppliers/:id" component={Supplier} />
      </Switch>
    </div>
  );
}

export default function SuppliersPages({ match, history }) {
  return (
    <Switch>
      <Route exact path="/assets/suppliers/add" component={AddSupplier} />
      <Route path="/assets/suppliers/:id" component={SupplierPages} />

      <Route exact path="" component={SupplierDash} />
    </Switch>
  );
}
