import React, { Component } from "react";
import { connect } from "react-redux";
import { Header, Segment, Statistic, Icon, Button, Grid, Modal, Form, Divider, Select, Popup, Container, TextArea, Table } from "semantic-ui-react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { DateTimeInput, DateInput } from "semantic-ui-calendar-react";
import { getSystemSettings } from "../../actions/settingsActions";
import InlineError from "../common/forms/errorMessage.js";
import api from "../../actions/api";
import RecruitList from "../common/lists/recruitList";
import RecCustomToolbar from "./reccalendarToolbar";
import TheLoader from "../common/TheLoader";
import "react-big-calendar/lib/css/react-big-calendar.css";

moment.locale("en-GB");
const localizer = momentLocalizer(moment);
function mapStateToProps(state) {
  return {
    system: state.system,
    user: state.user,
  };
}
class RecruitmentDash extends Component {
  state = {
    recruits: {},
    data: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      nickName: "",
      enquiryDate: moment(),
      dob: "",
      source: "",
    },
    intakes: [],
    event: {
      title: "",
      start: "",
      end: "",
      details: "",
      type: "",
      organiserID: "",
    },
    teamList: [],
    locations: [],
    sourceTypes: [],
    errors: {},
    warning: {},
    addModal: false,
    eventModal: false,
    events: [],
    recEvents: [],
    filtered: [],
    eventTypes: [],
    loading: true,
    recruitLoading: true,
    eventLoading: true,
    showRec: false,
    addError: null,
  };
  componentDidMount = () => {
    this.props
      .getSystemSettings()
      .then(() => {
        const data = this.props.system.settings.source;
        let dataArray = [];
        for (var key in data) {
          dataArray.push({
            key: key,
            text: data[key],
            value: data[key],
          });
        }
        this.updateSourceTypes(dataArray);
      })
      .catch((err) => {
        console.log(err);
      });
    this.props.getSystemSettings().then(() => {
      const data = this.props.system.settings.recruitReq;
      let dataArray = [];
      for (var key in data) {
        dataArray.push({
          key: key,
          text: data[key].content,
          value: data[key].content,
        });
      }
      this.updateEventTypes(dataArray);
    });
    // .then(() => {
    api.member.all().then((res) => {
      let teamArray = [];
      const data = res;
      for (var key in data) {
        teamArray.push({
          key: key,
          text: data[key].name,
          value: data[key].centralId,
        });
      }
      this.setState({
        teamList: teamArray,
      });
    });

    this.getData();
  };
  componentDidUpdate(prevProps, prevState) {
    if (this.props.user !== prevProps.user) {
      this.setState({
        event: {
          ...this.state.event,
          organiserID: this.props.user.details.sub,
        },
      });
    }
  }
  getData = () => {
    api.recruitment.latest().then((res) => {
      this.setState({
        recruits: res,
        recruitLoading: false,
      });
    });
    api.location.all().then((res) => {
      let locArray = [];
      const data = res;
      for (var key in data) {
        locArray.push({
          key: key,
          text: data[key].geoJSON.properties.name,
          value: data[key]._id,
        });
      }
      this.setState({
        locations: locArray,
      });
    });
    Promise.all([api.event.all(), api.recruitment.events(), api.recruitment.intakes.all()])
      .then((data) => {
        this.setState({
          events: data[0],
          filtered: data[1],
          recEvents: data[1],
          intakes: data[2],
          eventLoading: false,
        });
      })
      .catch((err) => {
        let errorMessage = "There was an unknown network error while retrieving data from the server";
        if (err.response !== undefined) {
          if (err.response.data.message !== undefined) {
            errorMessage = err.response.data.message;
          }
        }
        console.log(errorMessage);
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
    // api.event.all().then(events => {
    //   this.setState({
    //     events: events
    //   });
    // });
    // api.recruitment.events().then(events => {
    //   this.setState({
    //     recEvents: events
    //   });
    // });
  };
  updateSourceTypes = (data) => {
    this.setState({ sourceTypes: data });
  };
  updateEventTypes = (data) => {
    this.setState({ eventTypes: data });
  };
  handleAddClose = () => {
    this.setState({
      addModal: false,
    });
  };
  onChange = (e) => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  };
  onEventChange = (e) => {
    this.setState({
      event: { ...this.state.event, [e.target.name]: e.target.value },
    });
  };
  handleChange = (event, { name, value }) => {
    this.setState({
      data: {
        ...this.state.data,
        [name]: value,
      },
    });
  };
  handleEventChange = (event, { name, value }) => {
    this.setState({
      event: {
        ...this.state.event,
        [name]: value,
      },
    });
  };
  handleEventDropdownChange = (props, e) => {
    this.setState({
      event: { ...this.state.event, [e.name]: e.value },
    });
  };
  handleAddEvent = () => {
    let data = this.state.event;
    data = {
      ...data,
      start: moment(data.start),
      end: moment(data.end),
    };
    api.event.add(data).then(() => {
      this.getData();
      this.setState({
        eventModal: false,
        respError: "",
        event: {
          title: "",
          start: "",
          end: "",
          details: "",
          type: "",
          organiserID: "",
        },
      });
    });
  };
  goto = (id) => {
    if (id) {
      return this.props.history.push(`/recruitment/${id}`);
    }
  };
  gotoEvent = (id) => {
    if (id) {
      return this.props.history.push(`/recruitment/event/${id}`);
    }
  };
  onSubmit = () => {
    this.setState({
      addError: null,
    });
    api.recruitment
      .add(this.state.data)
      .then(() => {
        this.handleAddClose();
        this.getData();
      })
      .catch((err) => {
        this.setState({
          addError: err.response.data.message,
        });
      });
  };
  eventStyleGetter = (event, start, end, isSelected) => {
    var backgroundColor = event.color;
    var style = {
      backgroundColor: backgroundColor,
      borderRadius: "0px",
      opacity: 0.8,
      color: "white",
      border: "0px",
      display: "block",
    };
    return {
      style: style,
    };
  };
  CustomToolbarCapsule = (props) => {
    return class CustomToolbarInstance extends RecCustomToolbar {
      static defaultProps = { ...props };
    };
  };
  addEventShow = () => {
    this.setState({
      eventModal: true,
    });
  };
  drillDown = (id) => {
    if (id) {
      this._getEvent(id).then((res) => {
        this.setState({
          eventShowModal: true,
          event: res,
        });
      });
    }
  };
  filterEvents = () => {
    if (!this.state.showRec) {
      this.setState({
        filtered: this.state.events,
        showRec: true,
      });
    } else {
      this.setState({
        filtered: this.state.recEvents,
        showRec: false,
      });
    }
  };
  renderAddRecruitModal() {
    const { errors, data, warning } = this.state;
    return (
      <Modal open={this.state.addModal} onClose={this.handleAddClose} centered={false} size="large" closeOnDimmerClick={false}>
        <Header icon="user" content="Add New Recruit" />
        <Modal.Content>
          <Form>
            <Form.Group widths="equal">
              <Form.Field error={!!errors.firstName}>
                <label htmlFor="firstName">First Name</label>
                <input name="firstName" id="firstName" placeholder="Name" value={data.firstName} onChange={this.onChange} className={warning.firstName ? "fieldWarning" : "good"} />
                {errors.firstName && <InlineError text={errors.firstName} />}
              </Form.Field>
              <Form.Field error={!!errors.lastName}>
                <label htmlFor="lastName">Last Name</label>
                <input name="lastName" id="lastName" placeholder="lastName" value={data.lastName} onChange={this.onChange} className={warning.lastName ? "fieldWarning" : "good"} />
                {errors.lastName && <InlineError text={errors.lastName} />}
              </Form.Field>
              <Form.Field error={!!errors.nickName}>
                <label htmlFor="nickName">Nickname</label>
                <input name="nickName" id="nickName" placeholder="Nickname" value={data.nickName} onChange={this.onChange} className={warning.nickName ? "fieldWarning" : "good"} />
                {errors.nickName && <InlineError text={errors.nickName} />}
              </Form.Field>
            </Form.Group>
            <Form.Group widths={3}>
              <Form.Field error={!!errors.phone}>
                <label htmlFor="phone">Mobile Phone</label>
                <input name="phone" id="phone" placeholder="phone" value={data.phone} onChange={this.onChange} className={warning.phone ? "fieldWarning" : "good"} />
                {errors.phone && <InlineError text={errors.phone} />}
              </Form.Field>
              <Form.Field error={!!errors.email}>
                <label htmlFor="email">Email</label>
                <input name="email" id="phone" placeholder="email" value={data.email} onChange={this.onChange} className={warning.email ? "fieldWarning" : "good"} />
                {errors.email && <InlineError text={errors.email} />}
              </Form.Field>

              <Form.Field>
                <label htmlFor="dob">Date of Birth</label>
                <DateInput
                  name="dob"
                  startMode={"year"}
                  animation="none"
                  dateFormat={"YYYY-MM-DD"}
                  closable={true}
                  placeholder="Date"
                  value={data.dob ? moment(data.dob).format("YYYY-MM-DD") : ""}
                  iconPosition="left"
                  onChange={this.handleChange}
                  localization="en_GB"
                />
              </Form.Field>
            </Form.Group>
            <Divider horizontal>Admin</Divider>
            <Form.Group widths={3}>
              <Form.Field>
                <label htmlFor="enquiryDate">Date Enquired</label>
                <DateInput
                  name="enquiryDate"
                  startMode={"day"}
                  animation="none"
                  dateFormat={"YYYY-MM-DD"}
                  closable={true}
                  placeholder="date enquired"
                  value={data.enquiryDate ? moment(data.enquiryDate).format("YYYY-MM-DD") : ""}
                  iconPosition="left"
                  onChange={this.handleChange}
                  localization="en_GB"
                />
              </Form.Field>
              <Form.Field>
                <label htmlFor="source">Source</label>
                <Select
                  placeholder={"pick one"}
                  name={"source"}
                  options={this.state.sourceTypes}
                  value={this.state.data.source}
                  onChange={this.handleChange}
                  // onBlur={this.updateMisperModel}
                  className={warning.source ? "fieldWarning" : "good"}
                  //   disabled={disabled}
                />
              </Form.Field>
            </Form.Group>
          </Form>
          {this.state.addError && <div className="">{this.state.addError}</div>}
        </Modal.Content>
        <Modal.Actions>
          <Button negative floated={"left"} onClick={this.handleAddClose}>
            Cancel
          </Button>
          <Button color="green" onClick={this.onSubmit} inverted>
            <Icon name="save" />
            Add
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
  renderAddEventModal() {
    return (
      <Modal open={this.state.eventModal} onClose={() => this.setState({ eventModal: false })} centered={false} closeOnDimmerClick={false} size="small">
        <Header>Add Recruit Event</Header>
        <Modal.Content>
          <Form loading={this.state.addRole_Loading}>
            <Form.Group widths={2}>
              <Form.Field>
                <DateTimeInput
                  hideMobileKeyboard
                  autoComplete={"off"}
                  name="start"
                  animation="none"
                  dateTimeFormat="YYYY/MM/DD HH:mm"
                  closable={true}
                  disableMinute={true}
                  placeholder="Start Date & Time"
                  value={this.state.event.start}
                  iconPosition="left"
                  onChange={this.handleEventChange}
                />
              </Form.Field>
              <Form.Field>
                <DateTimeInput
                  hideMobileKeyboard
                  autoComplete={"off"}
                  name="end"
                  animation="none"
                  dateTimeFormat="YYYY/MM/DD HH:mm"
                  closable={true}
                  disableMinute={true}
                  placeholder="End Date & Time"
                  minDate={this.state.event.start}
                  initialDate={this.state.event.start}
                  value={this.state.event.end}
                  iconPosition="left"
                  onChange={this.handleEventChange}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths={2}>
              <Form.Field>
                <label htmlFor="title">Title</label>
                <input
                  id="title"
                  name="title"
                  placeholder="title"
                  value={this.state.event.title}
                  onChange={this.onEventChange}
                  // className={warning.firstName ? "fieldWarning" : "good"}
                />
              </Form.Field>
              <Form.Field>
                <label>Location</label>
                <Select placeholder={"pick one"} name={"location"} options={this.state.locations} onChange={this.handleEventDropdownChange} />
              </Form.Field>
            </Form.Group>
            <Form.Group widths={2}>
              <Form.Field>
                <label>Type</label>
                <Select placeholder={"pick one"} name={"type"} options={this.state.eventTypes} onChange={this.handleEventDropdownChange} />
              </Form.Field>
              <Form.Field>
                <label>Organiser</label>
                <Select
                  placeholder={"pick one"}
                  name={"organiserID"}
                  options={this.state.teamList}
                  value={this.state.event.organiserID}
                  onChange={this.handleEventDropdownChange}
                />
              </Form.Field>
            </Form.Group>
            <Form.Field>
              <TextArea name={"details"} placeholder="Tell us more" onChange={this.handleEventChange} />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button color="red" floated={"left"} onClick={() => this.setState({ eventModal: false })}>
            <Icon name="close" /> Cancel
          </Button>
          <Button color="green" onClick={() => this.handleAddEvent()}>
            <Icon name="plus" /> Add
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
  renderIntakes() {
    const intakes = this.state.intakes.map((intake) => {
      let active = 0;
      let archived = 0;
      intake.recruits.forEach((rec) => {
        if (rec.isArchived) {
          archived++;
        } else {
          active++;
        }
      });
      return (
        <Table.Row key={intake._id} className={"selectableRow"} onClick={() => this.props.history.push("/recruitment/intakes")}>
          <Table.Cell width="6">{intake.ref}</Table.Cell>
          <Table.Cell width="6">{intake.type}</Table.Cell>
          <Table.Cell width="4" textAlign={"center"} className="no-pad">
            <Popup
              basic
              trigger={
                <Header>
                  {archived} <i style={{ color: "#0099ff" }}> -- </i>
                  {active}
                </Header>
              }
              content={`${archived} Archived and ${active} Active Recruits`}
            />
          </Table.Cell>
        </Table.Row>
      );
    });
    return (
      // <Grid celled stackable className="no-pad no-marg">
      //   <Grid.Row className="gridHeaderRow">
      //     <Grid.Column width="6">Ref</Grid.Column>
      //     <Grid.Column width="6">Type</Grid.Column>
      //     <Grid.Column width="4" textAlign={"center"}>
      //       #Recruits
      //     </Grid.Column>
      //   </Grid.Row>

      // </Grid>
      <Table celled stackable selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Ref</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>#Recruits</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{intakes}</Table.Body>
      </Table>
    );
  }
  render() {
    const style = {
      borderRadius: 10,
      // opacity: 0.9,
      padding: "2em",
    };
    const CustomEvent = (event) => {
      let org = "";
      if (event.event.organiserID === this.props.user.details.sub) {
        org = <Icon name="star" />;
      }
      return (
        <Popup
          inverted
          position="top center"
          size="small"
          style={style}
          trigger={
            <div>
              <strong>{moment(event.event.start).format("HH:mm")} </strong> - {event.title} {org}
            </div>
          }
        >
          <Grid stackable className="popupGrid">
            <Grid.Row columns={1}>
              <Grid.Column textAlign="center">
                <p>{event.title}</p>
                <p>{event.event.type}</p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column textAlign="center">
                <Header className="no-pad no-marg">Start</Header>
                <Divider />
                <p>{moment(event.event.start).format("HH:mm")}</p>
              </Grid.Column>
              <Grid.Column textAlign="center">
                <Header className="no-pad no-marg">End</Header>
                <Divider />
                <p>{moment(event.event.end).format("HH:mm")}</p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
              <Grid.Column textAlign="center">
                <p>{event.event.details}</p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
              <Grid.Column textAlign="center">
                <p>Location: {event.event.location && event.event.location.geoJSON ? event.event.location.geoJSON.properties?.name : "--"}</p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Popup>
      );
    };
    return (
      <Container fluid className="container">
        {this.renderAddRecruitModal()}
        {this.renderAddEventModal()}
        <Grid stackable className="no-marg">
          <Grid.Row>
            <Grid.Column width={10}>
              <Segment className="subtle">
                <Statistic.Group widths={3}>
                  <Statistic>
                    <Statistic.Value>{this.state.recruits.length}</Statistic.Value>
                    <Statistic.Label>Active</Statistic.Label>
                  </Statistic>
                  <Statistic>
                    <Statistic.Value>--</Statistic.Value>
                    <Statistic.Label>Archived</Statistic.Label>
                  </Statistic>
                  <Statistic>
                    <Statistic.Label>&nbsp;</Statistic.Label>
                    <Button positive onClick={() => this.setState({ addModal: true })}>
                      <Icon name="plus" />
                      Add Recruit
                    </Button>
                  </Statistic>
                </Statistic.Group>
              </Segment>
              <Segment>
                <p className="boxHeader">Recruit Events</p>
                <TheLoader active={this.state.eventLoading} content="Loading events" />

                <Calendar
                  step={60}
                  localizer={localizer}
                  events={this.state.filtered}
                  views={{
                    month: true,
                    week: true,
                  }}
                  titleAccessor="title"
                  startAccessor={(event) => {
                    return moment(event.start).toDate();
                  }}
                  endAccessor={(event) => {
                    return moment(event.end).toDate();
                  }}
                  defaultDate={new Date()}
                  tooltipAccessor="details"
                  onSelectEvent={(e) => this.gotoEvent(e._id)}
                  eventPropGetter={this.eventStyleGetter}
                  components={{
                    event: CustomEvent,
                    toolbar: this.CustomToolbarCapsule({
                      ADD: this.addEventShow,
                      Filter: this.filterEvents,
                      active: this.state.showRec,
                    }),
                  }}
                  style={{ minHeight: 50 + "vh" }}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column width={6}>
              <Segment className="subtle">
                <p className="boxHeader">Active Recruit Intakes</p>
                {this.renderIntakes()}
              </Segment>

              <Segment className="subtle">
                <p className="boxHeader">Recent Recruits List</p>
                <TheLoader active={this.state.recruitLoading} content="Loading recruits" />
                <RecruitList data={this.state.recruits} action={this.goto} />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}
export default connect(mapStateToProps, { getSystemSettings })(RecruitmentDash);
