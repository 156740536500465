import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter} from "react-router-dom";
import {createStore, applyMiddleware} from "redux";
import {Provider} from "react-redux";
import thunk from "redux-thunk";
import {composeWithDevTools} from "redux-devtools-extension";
import * as serviceWorker from "./serviceWorker";
import ErrorCatchPage from "./lib/ErrorCatchPage";
import App from "./App";
import rootReducer from "./reducers";
import "./i18n";
import "./main.css";

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));

ReactDOM.render(<ErrorCatchPage>
    <BrowserRouter>
        <Provider store={store}>
            <App/>
        </Provider>
    </BrowserRouter>
</ErrorCatchPage>, document.getElementById("root"));
serviceWorker.unregister();