import React from "react";

export default function Stat({ label, value, colour = "bg-gray-800", action }) {
  return (
    <div
      className={`${colour} bg-opacity-50 shadow flex-1 overflow-hidden sm:rounded-lg`}
    >
      <div
        className="flex justify-center items-center flex-col p-3 sm:px-6 cursor-pointer"
        onClick={action}
      >
        <h1 className="text-3xl leading-8 font-medium ">{value}</h1>
        <p className="mt-1 max-w-2xl text-md md:text-lg text-gray-400">
          {label}
        </p>
      </div>
    </div>
  );
}
