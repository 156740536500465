import React, { Component } from "react";
import { connect } from "react-redux";
import { getSystemSettings } from "../../../../actions/settingsActions";
import CommonFunctions from "../../../../lib/CommonFunctions";

import dayjs from "dayjs";
import { Segment, Header, Table } from "semantic-ui-react";
import api from "../../../../actions/api";

function mapStateToProps(state) {
  return {
    system: state.system,
    user: state.user,
  };
}
class CompScores extends Component {
  state = {
    scores: [],
  };
  componentDidMount() {
    this.props.getSystemSettings();
    this.getData();
  }

  getData = () => {
    api.training.comps.getSessionScores().then((scores) => {
      this.setState({
        scores,
      });
    });
  };

  render() {
    const { scores } = this.state;
    return (
      <Segment basic>
        <Segment>
          <Header>Scores</Header>
          <Table structured celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Session</Table.HeaderCell>
                <Table.HeaderCell>Member</Table.HeaderCell>
                <Table.HeaderCell>Subjects</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {scores.map((s) => {
                return (
                  <Table.Row key={s._id}>
                    <Table.Cell>
                      {s.competency.subject}
                      <br />
                      {dayjs(s.sessionDate).format("DD-MM-YYYY")}
                    </Table.Cell>
                    <Table.Cell>{s.member.name}</Table.Cell>
                    <Table.Cell className="no-pad">
                      <Table>
                        <Table.Body>
                          {s.sections.map((sec) => {
                            let colour = CommonFunctions.getScoreColour(
                              this.props.system.settings.compScoreColours,
                              sec.score
                            );
                            return (
                              <Table.Row key={sec._id}>
                                <Table.Cell>{sec.title}</Table.Cell>
                                <Table.Cell
                                  collapsing
                                  style={{ background: colour }}
                                >
                                  {sec.score}
                                </Table.Cell>
                              </Table.Row>
                            );
                          })}
                        </Table.Body>
                      </Table>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </Segment>
      </Segment>
    );
  }
}
export default connect(mapStateToProps, { getSystemSettings })(CompScores);
