import React, { Component } from "react";
import { connect } from "react-redux";
import { Segment, Grid, Header, Label, Menu, Icon, Form, TextArea, Divider, Button, Select } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { getSystemSettings } from "../../actions/settingsActions";
import api from "../../actions/api";
import AddressCard from "../common/cards/addressCard";
import LocationMap from "../common/mapping/locationMap";
import InlineError from "../common/forms/errorMessage.js";
import ManageSupplierContacts from "./manageSupplierContacts";

function mapStateToProps(state) {
  return {
    system: state.system,
    user: state.user,
  };
}

class SupplierPage extends Component {
  state = {
    activeItem: "dash",
    supplier: {},
    addNew: false,
    events: [],
    data: {},
    errors: {},
    edit: false,
    manageContacts: false,
  };
  componentDidMount() {
    this.props
      .getSystemSettings()
      .then(() => {
        const data = this.props.system.settings.supplierTypes;
        let dataArray = [];
        for (var key in data) {
          dataArray.push({
            key: key,
            text: data[key],
            value: data[key],
          });
        }
        this.updateSupplierTypes(dataArray);
      })
      .catch((err) => {
        console.log(err);
      });

    this._getData();
  }
  updateSupplierTypes = (data) => {
    this.setState({ supplierTypes: data });
  };
  onChange = (e) =>
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  handleChange = (event, { name, value }) => {
    this.setState({
      data: {
        ...this.state.data,
        [name]: value,
      },
    });
  };
  handleDropdownChange = (props, e) => {
    this.setState({
      data: { ...this.state.data, [e.name]: e.value },
    });
  };
  handleTypeChange = (props, e) => {
    this.setState(
      {
        data: {
          ...this.state.data,
          type: e.value,
        },
      },
      () => {
        this.state.courseTypes.forEach((course) => {
          if (course.text === this.state.data.type) {
            this.setState({
              data: {
                ...this.state.data,
                validityLength: course.valid,
                isRequired: course.required,
              },
            });
          }
        });
      }
    );
  };
  validate = (data) => {
    const errors = {};
    if (!data.name) errors.name = "Can't be empty";
    if (!data.type) errors.type = "Can't be empty";
    return errors;
  };
  _getData = () => {
    api.management.suppliers.show(this.props.match.params.id).then((res) => {
      this.setState({
        supplier: res,
        data: res,
      });
    });
  };
  handleMenuClick = (e, { name }) => this.setState({ activeItem: name });
  handleUpdateSupplier = () => {
    api.management.suppliers.update(this.props.match.params.id, this.state.data).then(() => {
      this._getData();
      this.setState({
        edit: false,
      });
    });
  };
  handleSupplierContactsUpdate(contacts) {
    return api.management.suppliers
      .update(this.props.match.params.id, {
        ...this.state.supplier,
        contacts,
      })
      .then(() => {
        this._getData();
        this.setState({
          manageContacts: false,
        });
      });
  }
  handleAddressUpdate = (data) => {
    if (data) {
      this.setState({
        data: {
          ...this.state.data,
          address: {
            geoJSON: data.geoJSON,
          },
        },
      });
    }
  };
  renderSupplier() {
    const { supplier } = this.state;
    return (
      <Segment basic className="no-pad">
        <Segment basic className="subtle">
          <Grid stackable className="">
            <Grid.Row columns={1}>
              <Grid.Column>
                <Header as={"h3"}>
                  Name:
                  <span>{supplier.name} </span>
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
              <Grid.Column>
                <Header as={"h3"}>
                  Type:
                  <span>
                    {supplier.type &&
                      supplier.type.map((type) => {
                        return <Label key={type}>{type}</Label>;
                      })}{" "}
                  </span>
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
              <Grid.Column>
                <Header as={"h3"}>
                  Details:
                  <span>{supplier.details}</span>
                </Header>
              </Grid.Column>
            </Grid.Row>
            {supplier.address && supplier.address.geoJSON && supplier.address.geoJSON.properties && (
              //   <Grid.Row columns={1} className="no-pad">
              //
              <React.Fragment>
                <Grid.Row columns={1}>
                  <Grid.Column textAlign="left">
                    <Header as={"h3"}>
                      Address: <span>{supplier.address.geoJSON.properties.name}</span>
                    </Header>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                  <Grid.Column textAlign="left">
                    <Header as={"h3"}>
                      Town: <span>{supplier.address.geoJSON.properties.town}</span>
                    </Header>
                  </Grid.Column>
                  <Grid.Column textAlign="left">
                    <Header as={"h3"}>
                      Postcode:
                      <span>{supplier.address.geoJSON.properties.postcode}</span>
                    </Header>
                  </Grid.Column>
                </Grid.Row>
              </React.Fragment>
            )}
          </Grid>
        </Segment>
        <Segment basic className="subtle">
          <Grid stackable>
            <Grid.Row columns={1}>
              <Grid.Column>
                <p as={"h2"} className="boxHeader">
                  Contacts
                </p>
              </Grid.Column>
            </Grid.Row>
            {supplier.contacts &&
              supplier.contacts
                .sort((a, b) => {
                  if (a.primary < b.primary) {
                    return 1;
                  } else if (a.primary > b.primary) {
                    return -1;
                  } else {
                    return 0;
                  }
                })
                .map((contact) => {
                  return (
                    <React.Fragment key={contact._id}>
                      <Grid.Row columns={1}>
                        <Grid.Column>
                          <Header as={"h3"}>
                            Name:
                            <span>
                              {contact.name}{" "}
                              {contact.primary && (
                                <Label size="small" color="teal">
                                  Primary
                                </Label>
                              )}
                            </span>
                          </Header>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row columns={1}>
                        <Grid.Column>
                          <Header as={"h3"}>
                            Phone:
                            <span>{contact.phone}</span>
                          </Header>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row columns={1}>
                        <Grid.Column>
                          <Header as={"h3"}>
                            Email:
                            <span>{contact.email}</span>
                          </Header>
                        </Grid.Column>
                      </Grid.Row>
                      <Divider />
                    </React.Fragment>
                  );
                })}
          </Grid>
        </Segment>
      </Segment>
    );
  }
  renderMap() {
    return <LocationMap marker={this.state.supplier.address} mapStyle={{ minHeight: 70 + "vh" }} />;
  }
  renderDetails() {
    return (
      <Grid stackable className="no-marg">
        <Grid.Row columns="2">
          <Grid.Column>{this.renderSupplier()}</Grid.Column>
          <Grid.Column>{this.renderMap()}</Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
  renderMenu() {
    const { activeItem, supplier } = this.state;
    return (
      <Menu stackable>
        <Menu.Item>
          <Header as={"h2"}>{supplier.name} Menu</Header>
        </Menu.Item>

        <Menu.Item name="dash" active={activeItem === "dash"} onClick={this.handleMenuClick}>
          <Icon name="home" />
        </Menu.Item>
        <Menu.Item name="contacts" active={activeItem === "contacts"} onClick={this.handleMenuClick}>
          <Icon name="group" />
          Manage Contacts
        </Menu.Item>
        {/* <Menu.Item>
          <Icon name="plus" color={"green"} />
          Location
        </Menu.Item> */}
        <Menu.Item>
          <Icon name="pound" />
          Orders (coming soon)
        </Menu.Item>
        <Menu.Menu position={"right"}>
          <Menu.Item name="edit" active={activeItem === "edit"} onClick={this.handleMenuClick}>
            <Icon name="pencil" color={"blue"} />
            Edit
          </Menu.Item>
        </Menu.Menu>
      </Menu>
    );
  }
  renderEdit() {
    const { data, errors } = this.state;
    return (
      <Segment>
        <Form>
          <Form.Group>
            <Form.Field width={8}>
              <label htmlFor="name">Name</label>
              <input id="name" name="name" placeholder="name" value={data.name} onChange={this.onChange} className={errors.name ? "warning" : ""} />
              {errors.name && <InlineError text={errors.name} />}
            </Form.Field>
            <Form.Field width={8}>
              <label> Type</label>
              <Select placeholder={"pick one"} name={"type"} options={this.state.supplierTypes} multiple selection value={data.type} onChange={this.handleDropdownChange} />
            </Form.Field>
          </Form.Group>
          <Form.Field>
            <label>Details</label>
            <TextArea name="details" onChange={this.onChange} value={data.details} />
          </Form.Field>
        </Form>
        <Header>Primary Address</Header>
        <AddressCard address={this.state.data.address.geoJSON} country={this.props.system.country} update={this.handleAddressUpdate} />

        <Divider clearing />
        <Form>
          <Form.Group widths={"equal"} className={"form-actions"}>
            <Form.Field>
              <Button color="red" floated={"left"} onClick={() => this.setState({ edit: false })}>
                <Icon name="close" /> Cancel
              </Button>
            </Form.Field>
            <Form.Field>
              <Button color="blue" onClick={() => this.handleUpdateSupplier()} floated="right">
                <Icon name="save" /> Update
              </Button>
            </Form.Field>
          </Form.Group>
        </Form>
      </Segment>
    );
  }
  renderManageContacts() {
    return (
      <Segment basic>
        <p className="boxHeader">Manage Contacts</p>
        <ManageSupplierContacts contacts={this.state.supplier.contacts} onCancel={() => this._getData()} onSubmit={(data) => this.handleSupplierContactsUpdate(data)} />
      </Segment>
    );
  }
  render() {
    const { activeItem } = this.state;
    return (
      //   <Segment loading={this.state.loading} basic className="no-marg ">
      <Grid stackable className="no-marg">
        <Grid.Row className="no-pad">
          <Grid.Column width={16} className="no-pad">
            {this.renderMenu()}
          </Grid.Column>
        </Grid.Row>

        <Grid.Row columns={1}>
          <Grid.Column>
            {activeItem === "dash" && this.renderDetails()}
            {activeItem === "contacts" && this.renderManageContacts()}
            {activeItem === "edit" && this.renderEdit()}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      //   </Segment>
    );
  }
}

const Supplier = withTranslation()(SupplierPage);
export default connect(mapStateToProps, { getSystemSettings })(Supplier);
