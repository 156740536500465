import React, { Component } from "react";

import { Grid } from "semantic-ui-react";
import api from "../../actions/api";
import CompCard from "./cards/compCard";

class MemberCompPage extends Component {
  state = {
    user: {},
    comps: [],
  };
  componentDidMount() {
    this.getData();
  }
  getData = () => {
    api.training.comps.all().then((comps) => {
      this.setState({
        comps,
      });
    });
  };
  renderComps() {
    return this.state.comps.map((item, i) => {
      return (
        <CompCard
          key={item._id}
          showScores={this.props.showScores}
          comp={item}
          member={this.props.user._id}
        />
      );
    });
  }
  render() {
    return (
      <Grid stackable>
        <Grid.Row className={"no-pad"}>
          <Grid.Column width={16}>
            <p className={"boxHeader padded"}>Competencies</p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={3} style={{ padding: 10 }} stretched>
          {this.renderComps()}
        </Grid.Row>
      </Grid>
    );
  }
}

export default MemberCompPage;
