import React from "react";

import {
  Table,
  Segment,
  Button,
  Input,
  Form,
  Header,
  Divider,
  Icon,
} from "semantic-ui-react";
import { GithubPicker } from "react-color";

export default class SettingListColor extends React.Component {
  state = {
    textvalue: "",
    color: "#004dcf",
    formData: [],
  };
  componentDidMount() {}
  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.setState({
        formData: this.props.data,
      });
    }
  }
  // handleChange = e => {
  //   this.setState({
  //     textvalue: e.target.value
  //   });
  // };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleChangeComplete = (color) => {
    this.setState({ color: color.hex });
  };
  handleSubmit = () => {
    let fd = this.state.formData;
    fd.push({
      content: this.state.textvalue,
      color: this.state.color,
    });
    this.setState({
      formData: fd,
      textvalue: "",
      color: "#004dcf",
    });
    let field = this.props.field;
    const data = {};
    data[field] = this.state.formData;
    this.props.submit(data);
  };
  handleDelete = (obj) => {
    let newList = this.state.formData.filter((item, i) => {
      return obj !== item;
    });
    this.setState({
      formData: newList,
    });
    let field = this.props.field;
    const data = {};
    data[field] = newList;
    this.props.delete(data);
  };
  render() {
    const form = (
      <Segment basic>
        <Form onSubmit={this.handleSubmit} className="listSpace">
          <Input
            style={{ flex: 1 }}
            name="textvalue"
            onChange={this.handleChange}
            value={this.state.textvalue}
            placeholder={this.props.placeholder}
          />

          <GithubPicker
            // color={ this.state.background }
            triangle="hide"
            color={this.state.color}
            colors={[
              "#B80000",
              "#DB3E00",
              "#b0900a",
              "#008B02",
              "#006B76",
              "#1273DE",
            ]}
            onChangeComplete={this.handleChangeComplete}
          />
          <Button icon basic color="green">
            <Icon name="plus" color="green" />
          </Button>
        </Form>
      </Segment>
    );
    let list;
    if (this.props.data) {
      list = this.props.data.map((item, i) => {
        return (
          <Table.Row key={i}>
            <Table.Cell>{item.content}</Table.Cell>
            <Table.Cell textAlign="center" style={{ background: item.color }}>
              {item.color}
            </Table.Cell>
            <Table.Cell>
              <Button
                circular
                icon="close"
                floated="right"
                size="tiny"
                color="red"
                onClick={() => this.handleDelete(item)}
              />
            </Table.Cell>
          </Table.Row>
        );
      });
    }

    return (
      <Segment basic>
        <Header>{this.props.title}</Header>
        <Divider />
        {form}
        <Table stackable className={"settingTable"}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Label</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">Colour</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{list}</Table.Body>
        </Table>
      </Segment>
    );
  }
}
