import React from "react";
import { connect } from "react-redux";
import { Grid, Segment } from "semantic-ui-react";
import {
  getSystemSettings,
  updateSetting,
} from "../../../actions/settingsActions";
import SettingsList from "../../common/lists/settingList";
import ActivityReportBreakpoints from "./activityReportBreakpoints";
import ActivityReportDefaults from "./activityReportDefaults";

function mapStateToProps(state) {
  return {
    system: state.system,
  };
}

class TeamSettingsPage extends React.Component {
  state = {
    disabled: true,
    data: {
      teamName: "",
      systemName: "",
    },
  };

  updateFormTimeout = 0;

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    return this.props.getSystemSettings();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.system !== prevProps.system) {
      this.setState({
        data: this.props.system.settings,
      });
    }
    if (this.props.system !== prevProps.system) {
      if (
        this.props.system.settings &&
        this.props.system.settings.incidentTypes
      ) {
        this.setOptions("type", this.props.system.settings.incidentTypes);
      }
    }
  };

  toggleDisabled = () => {
    this.setState({
      disabled: !this.state.disabled,
    });
  };

  onChange = (e) =>
    this.setState(
      {
        data: { ...this.state.data, [e.target.name]: e.target.value },
      },
      () => {
        this.props.updateSetting(this.state.data, () => {});
      }
    );

  updateModel = (e) => {
    this.setState({
      updating: true,
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
    if (this.updateFormTimeout) {
      clearTimeout(this.updateFormTimeout);
    }
    this.updateFormTimeout = setTimeout(() => {
      return this.props
        .updateSetting(this.state.data)
        .then((res) => {
          this.setState({
            updating: false,
          });
          //TODO add a fancy screen notification that the title has successfully updated
        })
        .catch((err) => {
          console.error("Unable to save", err);
        });
    }, 500);
  };

  handleSubmit = (data) => {
    return this.props.updateSetting(data).then(() => {
      this.getData();
    });
  };

  handleDelete = (data) => {
    this.props.updateSetting(data, () => {});
  };

  setOptions = (key, types) => {
    if (types) {
      const data = types;
      let dataArray = [];
      for (var i in data) {
        dataArray.push({
          key: i,
          text: data[i].name,
          value: data[i].name,
        });
      }
      this.setState({ [key]: dataArray });
    }
  };

  renderTeam() {
    return (
      <Grid stackable doubling columns={3}>
        {this.props.system.settings.awardsSwitch && (
          <Grid.Column>
            <Segment>
              <SettingsList
                submit={this.handleSubmit}
                delete={this.handleDelete}
                data={
                  this.props.system.settings.awards
                    ? this.props.system.settings.awards
                    : []
                }
                placeholder="100 callouts..."
                title="Awards/Skills"
                field="awards"
              />
            </Segment>
          </Grid.Column>
        )}
        {this.props.system.settings.drivingCatSwitch && (
          <Grid.Column>
            <Segment>
              <SettingsList
                submit={this.handleSubmit}
                delete={this.handleDelete}
                data={
                  this.props.system.settings.drivingCat
                    ? this.props.system.settings.drivingCat
                    : []
                }
                placeholder="B+E"
                title="Driving Categories"
                field="drivingCat"
              />
            </Segment>
          </Grid.Column>
        )}
        {this.props.system.settings.vehicleKitSwitch && (
          <Grid.Column>
            <Segment>
              <SettingsList
                submit={this.handleSubmit}
                delete={this.handleDelete}
                data={
                  this.props.system.settings.vehicleKit
                    ? this.props.system.settings.vehicleKit
                    : []
                }
                placeholder="snorkle..."
                title="Vehicle Kit"
                field="vehicleKit"
              />
            </Segment>
          </Grid.Column>
        )}
        {this.props.system.settings.sectionsSwitch && (
          <Grid.Column>
            <Segment>
              <SettingsList
                submit={this.handleSubmit}
                delete={this.handleDelete}
                data={
                  this.props.system.settings.sections
                    ? this.props.system.settings.sections
                    : []
                }
                placeholder="Dog team, East, West..."
                title="Sections"
                field="sections"
              />
            </Segment>
          </Grid.Column>
        )}
        <ActivityReportDefaults
          submit={this.handleSubmit}
          eventTypes={this.state.data.eventTypes}
          data={{
            activityReportDeploymentDefault:
              this.state.data.activityReportDeploymentDefault,
            activityReportTrainingDefault:
              this.state.data.activityReportTrainingDefault,
          }}
        />
        <ActivityReportBreakpoints
          submit={this.handleSubmit}
          data={{
            activityReportDeploymentBreakpoints:
              this.state.data.activityReportDeploymentBreakpoints,
            activityReportTrainingBreakpoints:
              this.state.data.activityReportTrainingBreakpoints,
          }}
        />
      </Grid>
    );
  }

  render() {
    return (
      <Segment basic className="mb-6">
        {this.renderTeam()}
      </Segment>
    );
  }
}

export default connect(mapStateToProps, {
  getSystemSettings,
  updateSetting,
})(TeamSettingsPage);
