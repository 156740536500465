import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Menu, Header } from "semantic-ui-react";

function mapStateToProps(state) {
  return {
    user: state.user,
    system: state.system,
  };
}

class ManagementNav extends React.Component {
  state = { activeItem: "home" };
  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name });
  };
  componentDidMount() {}
  render() {
    const { activeItem } = this.state;
    return (
      <Menu stackable className={"sub-menu"}>
        <Menu.Item>
          <Header as={"h3"} className={"subtle"}>
            Management Menu
          </Header>
        </Menu.Item>

        {/*<Menu.Item*/}
        {/*  name={"dash"}*/}
        {/*  as={NavLink}*/}
        {/*  to={"/management/"}*/}
        {/*  active={activeItem === "dash"}*/}
        {/*  exact*/}
        {/*  onClick={this.handleItemClick}*/}
        {/*>*/}
        {/*  Dash*/}
        {/*</Menu.Item>*/}
        <Menu.Item
          name={"teamsite"}
          as={NavLink}
          to={"/management/team"}
          active={activeItem === "teamsite"}
          exact
          onClick={this.handleItemClick}
        >
          Team
        </Menu.Item>
        <Menu.Item
          name={"training"}
          as={NavLink}
          to={"/management/training"}
          active={activeItem === "training"}
          onClick={this.handleItemClick}
        >
          Training
        </Menu.Item>
        <Menu.Item
          name={"vehicles"}
          as={NavLink}
          to={"/management/vehicles"}
          active={activeItem === "vehicles"}
          exact
          onClick={this.handleItemClick}
        >
          Vehicles
        </Menu.Item>
        {/* <Menu.Item
          name={"suppliers"}
          as={NavLink}
          to={"/management/suppliers"}
          active={activeItem === "suppliers"}
          exact
          onClick={this.handleItemClick}
        >
          Suppliers
        </Menu.Item> */}
        <Menu.Item
          name={"Incidents"}
          as={NavLink}
          to={"/management/incidents"}
          active={activeItem === "incidents"}
          exact
          onClick={this.handleItemClick}
        >
          Incidents
        </Menu.Item>
      </Menu>
    );
  }
}

export default connect(mapStateToProps, {})(ManagementNav);
