import { Authentication } from "@liquidcomputing/sarstuff__reactjs-singlesignon";

export default function MemberCanEdit(props) {
  const currentUser = Authentication.getUserData();
  if (props.user._id !== undefined) {
    if (props.user.centralId === currentUser.sub) {
      return props.children;
    }
    if (Authentication.can("members:update:anyone")) {
      return props.children;
    }
  }
  return null;
}
