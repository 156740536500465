import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getSystemSettings,
  updateSetting,
} from "../../actions/settingsActions";
import { Segment, Icon, Checkbox, Header, Popup } from "semantic-ui-react";
import { toast } from "react-hot-toast";
function mapStateToProps(state) {
  return {
    system: state.system,
  };
}
export class AppSettings extends Component {
  state = {
    disabled: true,
    data: [],
    data1: {},
  };
  updateFormTimeout = 0;
  componentDidMount() {
    this.getData();
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.system !== prevProps.system) {
      this.setState({
        data: this.props.system.settings.appOrgRolesList
          ? this.props.system.settings.appOrgRolesList
          : [],
      });
    }
  };
  getData = () => {
    return this.props.getSystemSettings();
  };
  handleToggleboxChange = (props, e) => {
    toast.success("Updating form.");
    this.setState(
      {
        updating: true,
        data1: { ...this.state.data1, [e.name]: e.checked },
      },
      () => {
        this.props.updateSetting(this.state.data1).then(() => {
          setTimeout(() => {
            this.setState({
              updating: false,
            });
          }, 1000);
        });
      }
    );
  };
  handleCheckboxChange = (e) => {
    toast.success("Updating form.");
    let list = this.state.data;
    if (list.includes(e.target.name)) {
      const filtered = list.filter((role) => role !== e.target.name);
      list = filtered;
    } else {
      list.push(e.target.name);
    }

    this.props.updateSetting({ appOrgRolesList: list }).then(() => {
      setTimeout(() => {
        this.setState({
          updating: false,
        });
      }, 1000);
    });
  };

  render() {
    const { settings } = this.props.system;
    return (
      <div className={"grid grid-cols-2 gap-4 p-4"}>
        <Segment>
          <Header as="h3" textAlign="center">
            App Settings
          </Header>
          <div className="flex justify-between">
            <Popup
              content={"Allow team members to add vehicles from the app"}
              position={"right center"}
              trigger={
                <Header className={"withIcon"}>
                  Allow Add Vehicle
                  <Icon name="info circle" color="yellow" size="small" />
                </Header>
              }
            />
            <Checkbox
              toggle
              name="appAddVehicleSwitch"
              className={"goodToggle"}
              checked={settings.appAddVehicleSwitch}
              onChange={this.handleToggleboxChange}
            />
          </div>

          <div className="mt-6 ">
            <Header as="h3" textAlign="center">
              Roles to show on team list filter
            </Header>
            {settings.orgRoles &&
              settings.orgRoles.map((r, i) => {
                let checked = this.state.data && this.state.data.includes(r);

                // console.log("checked", checked);
                return (
                  <div
                    key={i}
                    className="flex items-middle items-center gap-2  p-2"
                  >
                    <input
                      id={r}
                      aria-describedby="comments-description"
                      name={r}
                      type="checkbox"
                      checked={checked}
                      onChange={this.handleCheckboxChange}
                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                    />
                    <label htmlFor={r} className="font-medium text-gray-200">
                      {r}
                    </label>
                  </div>
                );
              })}

            {/* <div className="ml-3 "> */}

            {/* </div> */}
            {/* </div> */}
          </div>
        </Segment>
      </div>
    );
  }
}
export default connect(mapStateToProps, {
  getSystemSettings,
  updateSetting,
})(AppSettings);
