import React from "react";
import {
  Button,
  Confirm,
  Header,
  Loader,
  Message,
  Modal,
  Segment,
} from "semantic-ui-react";
import API from "../../actions/api";

const Screen = {
  Loading: 1,
  Success: 2,
  Failed: 3,
};

export default class RecruitConvertToMember extends React.Component {
  state = {
    showConfirm: true,
    showModal: false,
    screen: Screen.Loading,
    error: null,
    canClose: false,
  };
  execRecruitConversion() {
    this.setState(
      {
        canClose: false,
        screen: Screen.Loading,
        error: null,
        showConfirm: false,
        showModal: true,
      },
      () => {
        API.recruitment
          .convertToMember(this.props.recruit._id)
          .then((res) => {
            this.setState({
              screen: Screen.Success,
            });
          })
          .catch((err) => {
            let error =
              "There was an unexpected error while processing your request.";
            if (err.response !== undefined) {
              if (err.response.data.message !== undefined) {
                error = err.response.data.message;
              }
            }
            this.setState({
              error,
              screen: Screen.Failed,
            });
          })
          .finally(() => {
            this.setState({
              canClose: true,
            });
          });
      }
    );
  }

  renderScreen() {
    switch (this.state.screen) {
      case Screen.Loading:
        return (
          <Loader
            active
            inline
            content={
              "Please wait while we convert this recruit and setup there Central Account"
            }
          />
        );
      case Screen.Success:
        return (
          <Message positive>
            Successfully converted Recruit to full TeamSite member and
            created/linked there SarStuff Central
          </Message>
        );
      case Screen.Failed:
        return (
          <Message negative>
            Failed to convert the recruit due to the following error:{" "}
            {this.state.error}
          </Message>
        );
      default:
        return <React.Fragment />;
    }
  }

  renderConvertProgress() {
    if (!this.state.showModal) return null;
    return (
      <Modal
        open
        size={"large"}
        closeOnDimmerClick={false}
        closeOnEscape={false}
        closeOnDocumentClick={false}
      >
        <Header>Converting Recruit...</Header>
        <Modal.Content>
          <Segment basic textAlign={"center"}>
            {this.renderScreen()}
          </Segment>
        </Modal.Content>
        {this.state.canClose && (
          <Modal.Actions>
            <Button color="green" inverted onClick={() => this.props.onClose()}>
              Close
            </Button>
          </Modal.Actions>
        )}
      </Modal>
    );
  }
  render() {
    return (
      <React.Fragment>
        {this.renderConvertProgress()}
        <Confirm
          open={this.state.showConfirm}
          onCancel={() => {
            this.props.onClose();
          }}
          onConfirm={() => {
            this.execRecruitConversion();
          }}
          header={"Are you sure?"}
          content={
            "Are you sure you like to convert this recruit into a full member? They will automatically be created a SarStuff Central account as part of the conversion process."
          }
        />
      </React.Fragment>
    );
  }
}
