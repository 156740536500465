import React, { Component } from "react";
import { Container } from "semantic-ui-react";
import { Route, Switch } from "react-router-dom";
import LocationsDash from "./locations/dash";
import locationPage from "./locations/locationPage";
import TrainingNav from "./trainingNav";
import CoursePages from "./courses";
import TrainingDash from "./dash";
import CompsPages from "./comps";
export default class TrainingPages extends Component {
  render() {
    return (
      <Container fluid>
        <TrainingNav match={this.props.match} />
        <Switch>
          <Route path="/training/comps" component={CompsPages} />
          <Route exact path="/training" component={TrainingDash} />
          <Route exact path="/training/locations" component={LocationsDash} />
          <Route path="/training/courses" component={CoursePages} />
          <Route path="/training/locations/:id" component={locationPage} />
          {/* <Route exact path="/contacts/:id/edit" component={UpdateContact} /> */}
        </Switch>
      </Container>
    );
  }
}
