import React from "react";
import { Route, Switch } from "react-router-dom";
import { Container, Segment } from "semantic-ui-react";
import SettingsNav from "./settingsNav";

import RecruitmentSettingsPage from "./recruitment";
import TrainingSettingsPage from "./training";
import TeamSettingsPage from "./team";
import OrgSettingsPage from "./org";

import Dash from "./dash";
import appSettings from "./appSettings";
import ChangeLog from "./changeLog";

export class SettingsPage extends React.Component {
  render() {
    return (
      <Container fluid style={{ padding: 0 }}>
        <SettingsNav match={this.props.match} />
        <Segment basic className="no-pad no-marg">
          <Switch>
            <Route exact path={`${this.props.match.url}`} component={Dash} />
            <Route
              exact
              path={`${this.props.match.url}/org`}
              component={OrgSettingsPage}
            />
            <Route
              exact
              path={`${this.props.match.url}/recruitment`}
              component={RecruitmentSettingsPage}
            />
            <Route
              exact
              path={`${this.props.match.url}/training`}
              component={TrainingSettingsPage}
            />
            <Route
              exact
              path={`${this.props.match.url}/team`}
              component={TeamSettingsPage}
            />
            <Route
              exact
              path={`${this.props.match.url}/app`}
              component={appSettings}
            />
            <Route
              exact
              path={`${this.props.match.url}/changelog`}
              component={ChangeLog}
            />
          </Switch>
        </Segment>
      </Container>
    );
  }
}
export default SettingsPage;
