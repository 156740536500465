import React, { useState, useEffect } from "react";
import Box from "../../common/Box";
import DatePicker from "react-date-picker";
import api from "../../../actions/api";
import dayjs from "dayjs";
import { Transition, Dialog } from "@headlessui/react";
import { FaEdit, FaSave } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import { FcCancel } from "react-icons/fc";
export default function AssetContents({ asset, canUpdate }) {
  const [openDelete, setOpenDelete] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState({});
  const [contents, setContents] = useState([]);
  const [content, setContent] = useState({
    qty: "",
    item: "",
    expiryDate: "",
  });

  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    api.assets.getContents(asset._id).then((res) => {
      setContents(res);
    });
  };
  const addContent = () => {
    api.assets.addContents(asset._id, content).then((res) => {
      setContent({ qty: "", item: "", expiryDate: "" });
      getData();
    });
  };
  const openDialog = (data) => {
    setDeleteRecord(data);
    setOpenDelete(true);
  };
  const deleteContent = () => {
    api.assets.delContents(asset._id, deleteRecord._id).then(() => {
      getData();
      setOpenDelete(false);
    });
  };
  const updateContent = () => {
    api.assets.updateContent(asset._id, content._id, content).then(() => {
      getData();
      setIsUpdate(false);
      setContent({ qty: "", item: "", expiryDate: "" });
    });
  };
  const DeleteDialog = () => {
    return (
      <Transition
        show={openDelete}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Dialog
          open={openDelete}
          onClose={() => setOpenDelete(false)}
          className="fixed z-10 inset-0 overflow-y-auto "
        >
          <div className="text-center min-h-screen">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-80" />
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="border-4 border-gray-700 inline-block w-full max-w-2xl p-4 my-2 text-center align-middle transition-all transform bg-gray-800 shadow-xl rounded-2xl">
              <div className="text-2xl">
                <Dialog.Title className="text-xl">
                  Delete{" "}
                  <span className="italic">
                    {asset.name} {asset.assetNum}
                  </span>{" "}
                  content item
                  <span className="italic"> {deleteRecord.item}</span>
                </Dialog.Title>
              </div>

              <div className="flex justify-between mt-4 border-t border-gray-700 pt-4">
                <button
                  className="px-4 py-2 bg-red-600 rounded-lg"
                  onClick={() => setOpenDelete(false)}
                >
                  Cancel
                </button>
                <button
                  className="px-4 py-2 bg-green-600 rounded-lg disabled:bg-red-500"
                  onClick={deleteContent}
                >
                  Yes Delete
                </button>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  };
  return (
    <Box basic title="Asset Contents">
      {DeleteDialog()}
      <div className=" w-full">
        {canUpdate && (
          <div className="flex justify-arround gap-2 p-2">
            <div className="flex flex-col w-1/6 text-center">
              <label
                className={`pb-1 block text-lg font-medium text-yellow-700 `}
              >
                Qty
              </label>
              <input
                onChange={(e) =>
                  setContent({
                    ...content,
                    qty: e.target.value,
                  })
                }
                value={content.qty}
                name="qty"
                placeholder="qty"
                className="  py-2 px-2 text-lg  border-2 focus:ring-gray-600 focus:border-gray-500 placeholder-gray-600 block  shadow-sm  border-gray-600 bg-gray-900  rounded-lg "
              />
            </div>
            <div className="flex flex-col w-4/6 text-center">
              <label
                className={`pb-1 block text-lg font-medium text-yellow-700 `}
              >
                Item
              </label>
              <input
                onChange={(e) =>
                  setContent({
                    ...content,
                    item: e.target.value,
                  })
                }
                value={content.item}
                name="item"
                placeholder="item"
                className="py-2 px-2 text-lg  border-2 focus:ring-gray-600 focus:border-gray-500 placeholder-gray-600 block w-full shadow-sm  border-gray-600 bg-gray-900  rounded-lg "
              />
            </div>
            <div className="flex flex-col  text-center">
              <label
                className={`pb-1 block text-lg font-medium text-yellow-700 `}
              >
                Expiry Date
              </label>
              <DatePicker
                name="expiryDate"
                onChange={(date) =>
                  setContent({
                    ...content,
                    expiryDate: date,
                  })
                }
                minDate={new Date()}
                className="z-50 text-white  h-12 rounded-lg border border-gray-700"
                value={content.expiryDate ? new Date(content.expiryDate) : ""}
              />
            </div>
            <div className="flex pt-8 items-middle w-1/6">
              {isUpdate ? (
                <div className="flex gap-2">
                  <button
                    className="bg-gray-200 py-2 px-4  border rounded-lg text-red"
                    onClick={() => {
                      setContent({ qty: "", item: "", expiryDate: "" });
                      setIsUpdate(false);
                    }}
                  >
                    <FcCancel />
                  </button>
                  <button
                    onClick={updateContent}
                    className="py-2 px-4 border border-gray-700 rounded-lg bg-green-600 w-full"
                  >
                    Save
                  </button>
                </div>
              ) : (
                <button
                  onClick={addContent}
                  className="py-2 px-4 border border-gray-700 rounded-lg bg-green-600"
                >
                  Add
                </button>
              )}
            </div>
          </div>
        )}
        <div className="mt-4">
          <table className="min-w-full divide-y divide-gray-200 border-separate table-fixed">
            <thead>
              <tr className="bg-gray-800">
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-400 uppercase ">
                  Qty
                </th>

                <th className="px-4 py-3 text-center text-xs font-medium text-gray-400 uppercase ">
                  Item
                </th>
                <th className="px-4 py-3 text-center text-xs font-medium text-gray-400 uppercase ">
                  Expiry Date
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody className="bg-gray-900 divide-y divide-gray-200 text-lg">
              {contents.map((a) => {
                const expired = dayjs(a.expiryDate).isBefore(dayjs());
                return (
                  <tr
                    key={a._id}
                    className={`${
                      expired ? "bg-red-700 bg-opacity-30" : "bg-gray-900"
                    }`}
                  >
                    <td className="p-2 ">{a.qty}</td>

                    <td className="p-2 w-4/6">{a.item}</td>
                    <td className={`p-2 w-1/6 `}>
                      {a.expiryDate
                        ? dayjs(a.expiryDate).format("DD-MM-YYYY")
                        : "--"}
                    </td>
                    <td className="justify-end p-2 flex gap-2">
                      <button
                        className="bg-blue-800 p-2  border rounded-lg"
                        onClick={() => {
                          setContent(a);
                          setIsUpdate(true);
                        }}
                      >
                        <FaEdit />
                      </button>

                      <button
                        className="bg-red-800 p-2 border rounded-lg text-white"
                        onClick={() => openDialog(a)}
                      >
                        <MdDeleteForever />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </Box>
  );
}
