import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { Container } from "semantic-ui-react";
import TeamList from "./teamList";
import MemberPage from "./memberPage";
import vehiclePage from "./vehicles/vehiclePage";
import VehicleList from "./vehicleList";
import TeamNav from "./teamNav";
import test from "./test";
// import ShowMember from "./showMember";

export default class TeamPages extends Component {
  render() {
    return (
      <Container fluid className="page-holder">
        <TeamNav match={this.props.match} />
        <Switch>
          <Route exact path="/team/vehicles" component={VehicleList} />
          <Route path="/team/vehicles/:vid" component={vehiclePage} />
          <Route exact path="/team/:id/test" component={test} />
          {/* New Design Member Details */}
          {/* <Route path="/team/:id" component={ShowMember} /> */}
          <Route path="/team/:id" component={MemberPage} />

          <Route exact path="/team" component={TeamList} />
          {/* <Route exact path="/contacts/:id/edit" component={UpdateContact} /> */}
        </Switch>
      </Container>
    );
  }
}
