import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { Container } from "semantic-ui-react";
import addCompSession from "./addCompSession";

import compScores from "./compScores";
import CompSession from "./compSession";
import CompsDash from "./dash";

export default class CompsPages extends Component {
  state = {};
  render() {
    return (
      <Container fluid className="no-pad">
        {/* <CompNav match={this.props.match} /> */}
        <Switch>
          <Route
            exact
            path="/management/training/comps/scores"
            component={compScores}
          />
          <Route
            exact
            path="/management/training/comps/add"
            component={addCompSession}
          />

          <Route
            exact
            path="/management/training/comps"
            component={CompsDash}
          />
          <Route
            exact
            path="/management/training/comps/:id"
            component={CompSession}
          />
        </Switch>
      </Container>
    );
  }
}
