import React from "react";
import moment from "moment";
import { Table, Image, Button, Confirm } from "semantic-ui-react";
import Blank from "../../../assets/images/blank.png";
import { withRouter } from "react-router";
class ResponseList extends React.Component {
  state = {
    confirmOpen: false,
    response: "",
    member: {},
    permission: false,
  };
  componentDidMount() {
    if (this.props.permissions) {
      if (this.props.permissions.canUpdate === true) {
        this.setState({
          permission: true,
        });
      }
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.permissions !== prevProps.permissions) {
      if (
        this.props.permissions !== undefined &&
        this.props.permissions.canUpdate
      ) {
        this.setState({
          permission: true,
        });
      } else if (this.props.permissions === undefined) {
        this.setState({
          permission: false,
        });
      }
    }
  }
  goto = (address) => {
    if (address) {
      this.props.history.push(address);
    }
  };
  confirmClose = () => {
    this.setState({
      confirmOpen: false,
    });
  };
  sendResponse = () => {
    this.props
      .setResponse(this.state.response, this.state.member.centralId)
      .then(() => {
        this.confirmClose();
      });
  };
  renderConfirmBox = () => {
    return (
      <Confirm
        header={`Are you sure you would like to change this response for ${this.state.member.name}?`}
        open={this.state.confirmOpen}
        onCancel={this.confirmClose}
        onConfirm={this.sendResponse}
      />
    );
  };
  renderMembers() {
    if (this.props.data && this.props.data.length) {
      const members = this.props.data.map((item) => {
        if (item.recruit) {
          if (this.props.combined) {
            return (
              <Table.Row
                key={item._id}
                positive={item.response === "going" ? true : false}
                warning={item.response === "not going" ? true : false}
              >
                {this.props.hidePhoto === undefined && (<Table.Cell width={1}>
                  {item.recruit.photo ? (<Image src={item.recruit.photo} size="mini" />) : (<Image src={Blank} size="mini" />)}
                </Table.Cell>)}
                {item.recruit ? (
                  <Table.Cell
                    onClick={() =>
                      this.goto(`/recruitment/${item.recruit._id}`)
                    }
                    className="selectable"
                  >
                    {item.recruit.name}{" "}
                    <small>
                      <i style={{ color: "yellow" }}>(recruit)</i>
                    </small>
                  </Table.Cell>
                ) : (
                  "-"
                )}
                {this.state.permission && (
                  <Table.Cell>{item.message || "[N/A]"}</Table.Cell>
                )}
                <Table.Cell>
                  {moment(item.updatedAt).format("DD-MM-YYYY HH:mm")}
                </Table.Cell>
                {this.state.permission && (
                  <Table.Cell width={6} textAlign="center">
                    {item.response === "going" ? (
                      <Button
                        negative
                        onClick={() =>
                          this.setState({
                            response: "not going",
                            recruit: item.recruit,
                            confirmOpen: true,
                          })
                        }
                      >
                        Change to not going
                      </Button>
                    ) : (
                      <Button
                        positive
                        onClick={() =>
                          this.setState({
                            response: "going",
                            recruit: item.recruit,
                            confirmOpen: true,
                          })
                        }
                      >
                        Change to going
                      </Button>
                    )}
                  </Table.Cell>
                )}
              </Table.Row>
            );
          } else {
            return null;
          }
        }
        if (item.member) {
          return (
            <Table.Row
              key={item._id}
              positive={item.response === "going" ? true : false}
              warning={item.response === "not going" ? true : false}
            >
              {this.props.hidePhoto === undefined && (<Table.Cell width={1}>
                {item.member.photo ? (<Image src={item.member.photo} size="mini" />) : (<Image src={Blank} size="mini" />)}
              </Table.Cell>)}
              {item.member ? (
                <Table.Cell
                  onClick={() => this.goto(`/team/${item.member._id}`)}
                  className="selectable"
                >
                  {item.member.name}
                </Table.Cell>
              ) : (
                "-"
              )}
              {this.state.permission && <Table.Cell>{item.message || "[N/A]"}</Table.Cell>}
              <Table.Cell>
                {moment(item.updatedAt).format("DD-MM-YYYY HH:mm")}
              </Table.Cell>
              {this.state.permission && (
                <Table.Cell width={6} textAlign="center">
                  {item.response === "going" ? (
                    <Button
                      negative
                      onClick={() =>
                        this.setState({
                          response: "not going",
                          member: item.member,
                          confirmOpen: true,
                        })
                      }
                    >
                      Change to not going
                    </Button>
                  ) : (
                    <Button
                      positive
                      onClick={() =>
                        this.setState({
                          response: "going",
                          member: item.member,
                          confirmOpen: true,
                        })
                      }
                    >
                      Change to going
                    </Button>
                  )}
                </Table.Cell>
              )}
            </Table.Row>
          );
        } else {
          return (
            <Table.Row
              key={item._id}
              positive={item.response === "going" ? true : false}
              warning={item.response === "not going" ? true : false}
            >
              {this.props.hidePhoto === undefined && (<Table.Cell width={1}>
                  {item.photo ? (<Image src={item.photo} size="mini" />) : (<Image src={Blank} size="mini" />)}
              </Table.Cell>)}
              <Table.Cell>{item.name}</Table.Cell>
              {this.state.permission && this.props.response && (
                <Table.Cell>{item.message}</Table.Cell>
              )}
              {this.props.response && (
                <Table.Cell>
                  {moment(item.updatedAt).format("DD-MM-YYYY HH:mm")}
                </Table.Cell>
              )}
              {this.state.permission && (
                <Table.Cell textAlign="center">
                  {!item.response && (
                    <React.Fragment>
                      <Button
                        negative
                        onClick={() =>
                          this.setState({
                            response: "not going",
                            member: item,
                            confirmOpen: true,
                          })
                        }
                      >
                        Not Going
                      </Button>
                      <Button
                        positive
                        onClick={() =>
                          this.setState({
                            response: "going",
                            member: item,
                            confirmOpen: true,
                          })
                        }
                      >
                        Going
                      </Button>
                    </React.Fragment>
                  )}
                </Table.Cell>
              )}
            </Table.Row>
          );
        }
      });
      return members;
    }
  }
  render() {
    return (
      <Table stackable>
        {this.renderConfirmBox()}
        <Table.Header>
          <Table.Row>
            {this.props.hidePhoto === undefined && <Table.HeaderCell>Photo</Table.HeaderCell>}
            <Table.HeaderCell>Name</Table.HeaderCell>
            {this.props.response && this.state.permission && (
              <Table.HeaderCell>Message</Table.HeaderCell>
            )}
            {this.props.response && (
              <Table.HeaderCell>Responded</Table.HeaderCell>
            )}
            {this.state.permission && (
              <Table.HeaderCell textAlign="center">Action</Table.HeaderCell>
            )}
          </Table.Row>
        </Table.Header>
        <Table.Body>{this.renderMembers()}</Table.Body>
      </Table>
    );
  }
}
export default withRouter(ResponseList);
