import React, { Component } from "react";
import { Header } from "semantic-ui-react";

export default class TopNav extends Component {
  state = {
    drawerPos: 1,
  };
  componentDidUpdate(prevProps, prevState) {
    if (this.state.drawerPos !== prevState.drawerPos) {
      this.props.updateWidth(this.state.drawerPos);
    }
  }

  handleDrawer = () => {
    if (this.state.drawerPos < 2) {
      this.setState((state) => ({
        drawerPos: state.drawerPos + 1,
      }));
    } else {
      this.setState({
        drawerPos: 0,
      });
    }
  };
  render() {
    return (
      <div className="navbar">
        <div>
          {/* <Button icon onClick={this.handleDrawer} basic>
            <Icon name="bars" />
          </Button> */}
        </div>
        <div>
          <Header as={"h1"} style={{ paddingLeft: "1rem" }} textAlign="center">
            SarStuff Teamsite
          </Header>
        </div>
        <div></div>
      </div>
    );
  }
}
