import React from "react";
import moment from "moment";
import { Table, Segment, Header, Statistic } from "semantic-ui-react";
import CalloutBar from "../calloutBar";
export default class CalloutList extends React.Component {
  state = {};
  renderDate = (date) => {
    if (date) {
      return moment(date).format("DD/MM/YY HH:mm");
    } else {
      return "--";
    }
  };
  renderCallouts() {
    if (this.props.data) {
      const stats = this.props.data.byType;
      const typeList = Object.keys(stats);
      if (typeList.length) {
        const tasks = typeList.map((f) => {
          const callouts = stats[f];
          const rows = callouts.map((d, i) => {
            return (
              <Table.Row key={f + "_" + i}>
                <Table.Cell width={2}>{this.renderDate(d.opDate)}</Table.Cell>
                <Table.Cell width={3}>{d.name}</Table.Cell>
                <Table.Cell width={2}>{d.type}</Table.Cell>

                <Table.Cell>{d.outcome}</Table.Cell>
                {/* <Table.Cell width={2}>soon</Table.Cell> */}
              </Table.Row>
            );
          });
          return (
            <React.Fragment key={f}>
              <Table.Row>
                <Table.Cell colSpan={4} className="headerCell">
                  <Header as={"h3"}>{f}</Header>
                </Table.Cell>
              </Table.Row>
              {rows}
            </React.Fragment>
          );
        });
        return tasks;
      }
    }
  }
  renderStats() {
    if (this.props.data) {
      const stats = this.props.data.byType;
      const typeList = Object.keys(stats);
      if (typeList.length) {
        const tasks = typeList.map((f) => {
          const callouts = stats[f];
          const rows = callouts.length;
          return (
            <Statistic key={f}>
              <Statistic.Value>{rows}</Statistic.Value>
              <Statistic.Label>{f}</Statistic.Label>
            </Statistic>
          );
        });
        return (
          <Segment>
            <Statistic.Group widths={tasks.length}>{tasks}</Statistic.Group>
          </Segment>
        );
      }
    }
  }
  render() {
    return (
      <Segment className="no-pad">
        <CalloutBar
          data={this.props.data}
          oldCallouts={this.props.oldCallouts}
        />
        {/* {this.renderStats()} */}
        <Table stackable selectable className="calloutList">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Date</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Type</Table.HeaderCell>
              <Table.HeaderCell>Outcome</Table.HeaderCell>
              {/* <Table.HeaderCell>Hrs</Table.HeaderCell> */}
            </Table.Row>
          </Table.Header>
          <Table.Body>{this.renderCallouts()}</Table.Body>
        </Table>
      </Segment>
    );
  }
}
