import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Segment,
  Grid,
  Statistic,
  Header,
  Icon,
  Button,
  Checkbox,
  Modal,
  Form,
  Divider,
  Select,
  TextArea,
  Message,
} from "semantic-ui-react";
import {
  AuthComponents,
  Authentication,
} from "@liquidcomputing/sarstuff__reactjs-singlesignon";
import moment from "moment";
import api from "../../actions/api";
import ResponseList from "../common/lists/recRespList";
import { getSystemSettings } from "../../actions/settingsActions";
import { DateTimeInput } from "semantic-ui-calendar-react";

function mapStateToProps(state) {
  return {
    system: state.system,
    user: state.user,
  };
}

class RecruitmentEventPage extends Component {
  state = {
    event: {},
    eventEditModal: false,
    responses: {},
    recruits: [],
    locations: [],
    eventTypes: [],
    teamList: [],
    loading: true,
    data: {
      title: "",
      start: "",
      end: "",
      details: "",
      type: "",
      organiserID: "",
      canSignUp: true,
    },
    errors: {},
  };
  componentDidMount() {
    this._getData();
    this.returnResponses();
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.event !== prevState.event) {
      this.returnResponses();
    }
  }
  _getData = () => {
    api.event
      .show(this.props.match.params.id)
      .then((res) => {
        this.setState({
          event: res,
          loading: false,
        });
      })
      .catch((err) => {
        //todo display error message to user
      });
    api.recruitment.all().then((rec) => {
      this.setState(
        {
          recruits: rec,
        },
        () => {
          this.returnResponses();
        }
      );
    });
    api.location.all().then((res) => {
      let locArray = [];
      const data = res;
      for (var key in data) {
        locArray.push({
          key: key,
          text: data[key].geoJSON.properties.name,
          value: data[key]._id,
        });
      }
      this.setState({
        locations: locArray,
      });
    });
    this.props.getSystemSettings().then(() => {
      const data = this.props.system.settings.recruitReq;
      let dataArray = [];
      for (var key in data) {
        dataArray.push({
          key: key,
          text: data[key].content,
          value: data[key].content,
        });
      }
      this.setState({
        eventTypes: dataArray,
      });
    });
    api.member.all().then((res) => {
      let teamArray = [];
      const data = res;
      for (var key in data) {
        teamArray.push({
          key: key,
          text: data[key].name,
          value: data[key].centralId,
        });
      }
      this.setState({
        teamList: teamArray,
      });
    });
  };
  goto = (loc) => {
    if (loc) {
      this.setState({ eventShowModal: false });
      this.props.history.push(loc);
    }
  };
  setResponse = (resp, id) => {
    let event = this.state.event._id;
    if (event) {
      let data = {
        recruit: id,
        eventID: event,
        response: resp,
      };
      return api.event.responses
        .addRec(event, data)
        .then((res) => {
          this._getData().then((res) => {
            this.setState({
              event: res,
              respError: "",
            });
          });
        })
        .catch((err) => {
          this.setState({
            respError: err,
          });
        });
    }
  };
  returnResponses = () => {
    let responses = {
      going: 0,
      notGoing: 0,
      notResponded: 0,
    };
    let recruitResponses =
      this.state.event.responses &&
      this.state.event.responses.filter((resp) => {
        return resp.recruit;
      });
    if (this.state.event.responses) {
      responses.going = recruitResponses.filter((resp) => {
        return resp.response === "going";
      });
      responses.notGoing = recruitResponses.filter((resp) => {
        return resp.response === "not going";
      });
      let goingIDs = responses.going
        .filter((i) => i.recruit)
        .map((i) => i.recruit._id);
      let notGoingIDs = responses.notGoing
        .filter((i) => i.recruit)
        .map((i) => i.recruit._id);
      responses.notResponded = this.state.recruits.filter(
        (rec) =>
          goingIDs.indexOf(rec._id) === -1 &&
          notGoingIDs.indexOf(rec._id) === -1
      );
    }
    this.setState({
      responses,
    });
  };
  editEvent = () => {
    this.setState({
      eventShowModal: false,
      eventEditModal: true,
      data: {
        ...this.state.event,
        location: this.state.event.location && this.state.event.location._id,
      },
    });
  };
  handleEditEvent = () => {
    let data = this.state.data;
    data = {
      ...data,
      start: moment(data.start),
      end: moment(data.end),
    };
    api.event.update(data._id, data).then(() => {
      this._getData();
      this.setState({
        eventEditModal: false,
      });
    });
  };

  handleChange = (event, { name, value }) => {
    this.setState({
      data: {
        ...this.state.data,
        [name]: value,
      },
    });
  };
  onChange = (e) =>
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  handleDropdownChange = (props, e) => {
    this.setState({
      data: { ...this.state.data, [e.name]: e.value },
    });
  };
  // renderEditEventModal1 = () => {
  //   let event = this.state.event;
  //   return (
  //     <Modal
  //       open={this.state.eventEditModal}
  //       centered={false}
  //       closeOnDimmerClick={false}
  //       size="large"
  //     >
  //       <Header>{event.type}</Header>
  //       <Modal.Content className="no-pad">
  //         <Form>
  //           <Grid centered stackable>
  //             <Grid.Row columns={2}>
  //               <Grid.Column textAlign="center">
  //                 <Header>Start</Header>
  //                 <Divider />
  //                 <Form.Field>
  //                   <DateTimeInput
  //                     name="start"
  //                     animation="none"
  //                     dateTimeFormat="YYYY/MM/DD HH:mm"
  //                     closable={true}
  //                     disableMinute={true}
  //                     placeholder="Start Date & Time"
  //                     value={moment(this.state.data.start).format(
  //                       "YYYY/MM/DD HH:mm"
  //                     )}
  //                     iconPosition="left"
  //                     onChange={this.handleChange}
  //                   />
  //                 </Form.Field>
  //               </Grid.Column>
  //               <Grid.Column textAlign="center">
  //                 <Header>End</Header>
  //                 <Divider />
  //                 <Form.Field>
  //                   <DateTimeInput
  //                     name="end"
  //                     animation="none"
  //                     dateTimeFormat="YYYY/MM/DD HH:mm"
  //                     closable={true}
  //                     disableMinute={true}
  //                     placeholder="End Date & Time"
  //                     minDate={this.state.data.start}
  //                     value={moment(this.state.data.end).format(
  //                       "YYYY/MM/DD HH:mm"
  //                     )}
  //                     iconPosition="left"
  //                     onChange={this.handleChange}
  //                   />
  //                 </Form.Field>
  //               </Grid.Column>
  //               <Grid.Column textAlign="center">
  //                 <Header>Type</Header>
  //                 <Divider />
  //                 <Form.Field>
  //                   <Select
  //                     placeholder={"pick one"}
  //                     name={"type"}
  //                     options={this.state.eventTypes}
  //                     value={this.state.data.type}
  //                     onChange={this.handleDropdownChange}
  //                   />
  //                 </Form.Field>
  //               </Grid.Column>
  //               <Grid.Column textAlign="center">
  //                 <Header>Organiser</Header>
  //                 <Divider />
  //                 <Form.Field>
  //                   <Select
  //                     placeholder={"pick one"}
  //                     name={"organiserID"}
  //                     options={this.state.teamList}
  //                     value={this.state.data.organiserID}
  //                     onChange={this.handleDropdownChange}
  //                   />
  //                 </Form.Field>
  //               </Grid.Column>
  //             </Grid.Row>

  //             <Grid.Row columns={3}>
  //               <Grid.Column>
  //                 <Header>Title</Header>
  //                 <Divider />
  //                 <input
  //                   id="title"
  //                   name="title"
  //                   placeholder="title"
  //                   value={this.state.data.title}
  //                   onChange={this.onChange}
  //                   // className={warning.firstName ? "fieldWarning" : "good"}
  //                 />
  //               </Grid.Column>
  //               <Grid.Column textAlign="center">
  //                 <Header>Details</Header>
  //                 <Divider />
  //                 <TextArea
  //                   name={"details"}
  //                   value={this.state.data.details}
  //                   placeholder="Tell us more"
  //                   onChange={this.handleChange}
  //                 />
  //               </Grid.Column>

  //               <Grid.Column textAlign="center">
  //                 <Header>Location</Header>
  //                 <Divider />
  //                 <Select
  //                   placeholder={"pick one"}
  //                   name={"location"}
  //                   value={this.state.data.location}
  //                   options={this.state.locations}
  //                   onChange={this.handleDropdownChange}
  //                 />
  //               </Grid.Column>
  //             </Grid.Row>
  //           </Grid>
  //         </Form>
  //       </Modal.Content>
  //       <Modal.Actions>
  //         <Button
  //           color="red"
  //           onClick={() =>
  //             this.setState({
  //               eventEditModal: false,
  //               eventShowModal: true,
  //               respError: "",
  //             })
  //           }
  //         >
  //           <Icon name="close" /> Cancel
  //         </Button>
  //         {this.state.respError && (
  //           <Message error floating compact>
  //             {this.state.respError &&
  //               this.state.respError.response.data.message}
  //           </Message>
  //         )}
  //         <Button color="green" onClick={this.handleEditEvent}>
  //           <Icon name="check" /> Update
  //         </Button>
  //       </Modal.Actions>
  //     </Modal>
  //   );
  // };
  renderEditEventModal() {
    return (
      <Modal
        open={this.state.eventEditModal}
        onClose={() => this.setState({ eventEditModal: false })}
        centered={false}
        closeOnDimmerClick={false}
        size="small"
      >
        <Header>Edit Event</Header>
        <Modal.Content>
          <Form>
            <Form.Group widths={2}>
              <Form.Field>
                <DateTimeInput
                  // inline
                  hideMobileKeyboard
                  name="start"
                  animation="none"
                  dateTimeFormat="YYYY/MM/DD HH:mm"
                  closable={true}
                  disableMinute={false}
                  placeholder="Start Date & Time"
                  value={moment(this.state.data.start).format(
                    "YYYY/MM/DD HH:mm"
                  )}
                  iconPosition="left"
                  onChange={this.handleChange}
                  autoComplete="off"
                />
              </Form.Field>
              <Form.Field>
                <DateTimeInput
                  hideMobileKeyboard
                  name="end"
                  animation="none"
                  dateTimeFormat="YYYY/MM/DD HH:mm"
                  closable={true}
                  disableMinute={false}
                  placeholder="End Date & Time"
                  minDate={moment(this.state.data.start).format(
                    "YYYY/MM/DD HH:mm"
                  )}
                  // initialDate={this.state.data.start}
                  value={moment(this.state.data.end).format("YYYY/MM/DD HH:mm")}
                  iconPosition="left"
                  onChange={this.handleChange}
                  autoComplete="off"
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths={2}>
              <Form.Field>
                <label htmlFor="title">Title</label>
                <input
                  id="title"
                  name="title"
                  placeholder="title"
                  value={this.state.data.title}
                  onChange={this.onChange}
                  // className={warning.firstName ? "fieldWarning" : "good"}
                />
              </Form.Field>
              <Form.Field>
                <label>Location</label>
                <Select
                  search
                  placeholder={"pick one"}
                  name={"location"}
                  options={this.state.locations}
                  onChange={this.handleDropdownChange}
                  value={
                    this.state.data.location && this.state.data.location._id
                      ? this.state.data.location._id
                      : this.state.data.location
                  }
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths={2}>
              <Form.Field>
                <label>Type</label>
                <Select
                  placeholder={"pick one"}
                  name={"type"}
                  options={this.state.eventTypes}
                  onChange={this.handleDropdownChange}
                  value={this.state.data.type}
                />
              </Form.Field>
              <Form.Field>
                <label>Organiser</label>
                <Select
                  placeholder={"pick one"}
                  name={"organiserID"}
                  options={this.state.teamList}
                  value={this.state.data.organiserID}
                  onChange={this.handleDropdownChange}
                />
              </Form.Field>
            </Form.Group>
            <Form.Field>
              <Form.TextArea
                label="Details"
                name={"details"}
                placeholder="Tell us more"
                onChange={this.handleChange}
                value={this.state.data.details}
              />
            </Form.Field>
            {(Authentication.can("events:update") ||
              Authentication.can("events:create")) && (
              <Form.Field>
                <Form.TextArea
                  label="Notes"
                  name={"note"}
                  placeholder="Additional notes relating to this event."
                  onChange={this.handleChange}
                  value={this.state.data.note}
                />
              </Form.Field>
            )}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="red"
            floated={"left"}
            onClick={() => this.setState({ eventEditModal: false })}
          >
            <Icon name="close" /> Cancel
          </Button>
          <Button color="green" onClick={() => this.handleEditEvent()}>
            <Icon name="save" /> Update
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
  renderDetails() {
    const { event } = this.state;
    return (
      <Segment className="subtle ">
        <Grid stackable>
          <Grid.Row>
            <Grid.Column width={16}>
              <p className="boxHeader">
                {event.title}{" "}
                <Button
                  basic
                  icon
                  onClick={() => this.editEvent()}
                  className=""
                >
                  <Icon color="blue" name="edit" style={{ marginLeft: 10 }} />
                </Button>
              </p>
            </Grid.Column>
          </Grid.Row>
          {/* <Grid.Row>
            <Grid.Column width={16} className={"no-pad"}>
              {this.renderMenu()}
            </Grid.Column>
          </Grid.Row> */}
          <Grid.Row columns={2}>
            <Grid.Column>
              <Header>
                Title:
                <span>{event.title}</span>
              </Header>
            </Grid.Column>
            <Grid.Column>
              <Header>
                Type:
                <span>{event.type}</span>
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Header>
                Start:
                <span>{moment(event.start).format("DD-MM-YYYY HH:mm")}</span>
              </Header>
            </Grid.Column>
            <Grid.Column>
              <Header>
                End:
                <span>{moment(event.end).format("DD-MM-YYYY HH:mm")}</span>
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Header>
                Details:
                <span>{event.details}</span>
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Header>
                Location:
                <span>
                  {event.location && event.location.geoJSON.properties.name ? (
                    <Button
                      basic
                      inverted
                      onClick={() =>
                        this.goto(`/training/locations/${event.location._id}`)
                      }
                    >
                      <Icon name="globe" />
                      {event.location.geoJSON.properties.name}
                    </Button>
                  ) : (
                    "--"
                  )}
                </span>
              </Header>
            </Grid.Column>
            <Grid.Column>
              <Grid.Column>
                <Header>
                  Organiser:
                  <span>{event.organiser ? event.organiser.name : "--"}</span>
                </Header>
              </Grid.Column>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
  render() {
    let recruitResponses =
      this.state.event.responses &&
      this.state.event.responses.filter((resp) => resp.recruit);
    return (
      <Segment loading={this.state.loading} basic>
        {this.renderEditEventModal()}
        <Grid stackable>
          <Grid.Row>
            <Grid.Column width={8}>
              {this.renderDetails()}
              <Segment className="subtle">
                <Header textAlign="center" as={"h2"}>
                  Recruits Not Responded
                </Header>
                <ResponseList
                  data={this.state.responses.notResponded}
                  setResponse={this.setResponse}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column width={8}>
              <Grid stackable celled>
                <Grid.Row>
                  <Grid.Column width={16} className={"no-pad"}>
                    <Segment>
                      <Header textAlign="center">Recruits</Header>
                      <Statistic.Group widths={2}>
                        <Statistic>
                          <Statistic.Value>
                            {this.state.responses.going
                              ? this.state.responses.going.length
                              : 0}
                          </Statistic.Value>
                          <Statistic.Label>Going</Statistic.Label>
                        </Statistic>
                        <Statistic>
                          <Statistic.Value>
                            {this.state.responses.notGoing
                              ? this.state.responses.notGoing.length
                              : 0}
                          </Statistic.Value>
                          <Statistic.Label>Not Going</Statistic.Label>
                        </Statistic>
                        {/* <Statistic>
                          <Statistic.Value>{this.state.responses.notResponded ? this.state.responses.notResponded.length : 0}</Statistic.Value>
                          <Statistic.Label>Not Responded</Statistic.Label>
                        </Statistic> */}
                      </Statistic.Group>
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <Segment className="subtle ">
                <Header textAlign="center" as={"h2"}>
                  Responses
                </Header>
                <ResponseList
                  response={true}
                  setResponse={this.setResponse}
                  data={recruitResponses}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}
export default connect(mapStateToProps, { getSystemSettings })(
  RecruitmentEventPage
);
