import { APIRequest } from "@liquidcomputing/sarstuff__reactjs-singlesignon";
import AppConfig from "../_appConfig";

const server = AppConfig.api_server;

const quickObjectToQueryString = (query) => {
  return (
    "?" +
    Object.keys(query)
      .map((key) => key + "=" + query[key])
      .join("&")
  );
};

export default {
  _getBuildNumber: () =>
    APIRequest.get(server + `/_buildNumber`).then((res) => res.data),
  assets: {
    all: () => APIRequest.get(server + "/assets").then((res) => res.data),
    getbyAssigned: (type = "member", id) =>
      APIRequest.get(server + `/assets/assigned/${type}/${id}`).then(
        (res) => res.data
      ),
    getAssetsBySupplier: (supplier_id) =>
      APIRequest.get(server + `/assets/supplier/${supplier_id}`).then(
        (res) => res.data
      ),
    dash: () => APIRequest.get(server + "/assets/dash").then((res) => res.data),
    add: (data) =>
      APIRequest.post(server + "/assets", data).then((res) => res.data),
    addContents: (id, data) =>
      APIRequest.post(server + `/assets/${id}/contents`, data).then(
        (res) => res.data
      ),
    delContents: (assetID, id) =>
      APIRequest.delete(server + `/assets/${assetID}/contents/${id}`).then(
        (res) => res.data
      ),
    updateContent: (assetID, id, data) =>
      APIRequest.patch(server + `/assets/${assetID}/contents/${id}`, data).then(
        (res) => res.data
      ),
    getContents: (id) =>
      APIRequest.get(server + `/assets/${id}/contents`).then((res) => res.data),
    getContentReport: () =>
      APIRequest.get(server + `/assets/contentreport`).then((res) => res.data),
    show: (id) =>
      APIRequest.get(server + `/assets/${id}`).then((res) => res.data),
    update: (id, data) =>
      APIRequest.patch(server + `/assets/${id}`, data).then((res) => res.data),
    assign: (id, data) =>
      APIRequest.patch(server + `/assets/${id}/assign`, data).then(
        (res) => res.data
      ),
    check: {
      all: (id) =>
        APIRequest.get(server + `/assets/checks/${id}`).then((res) => res.data),
      add: (id, data) =>
        APIRequest.post(server + `/assets/checks/${id}`, data).then(
          (res) => res.data
        ),
      update: (assetID, id, data) =>
        APIRequest.patch(
          server + `/assets/checks/${assetID}/update/${id}`,
          data
        ).then((res) => res.data),
    },
    service: {
      all: (id) =>
        APIRequest.get(server + `/assets/service/${id}`).then(
          (res) => res.data
        ),
      show: (assetID, id) =>
        APIRequest.get(server + `/assets/service/${assetID}/show/${id}`).then(
          (res) => res.data
        ),
      add: (id, data) =>
        APIRequest.post(server + `/assets/service/${id}`, data).then(
          (res) => res.data
        ),
      update: (assetID, id, data) =>
        APIRequest.patch(
          server + `/assets/service/${assetID}/update/${id}`,
          data
        ).then((res) => res.data),
    },
    settings: {
      all: () =>
        APIRequest.get(server + "/assets/settings").then((res) => res.data),
      update: (data) =>
        APIRequest.patch(server + `/assets/settings`, data).then(
          (res) => res.data
        ),
    },
  },
  member: {
    all: () => APIRequest.get(server + "/members").then((res) => res.data),
    add: (data) =>
      APIRequest.post(server + "/members", data).then((res) => res.data),
    show: (id) =>
      APIRequest.get(server + `/members/${id}`).then((res) => res.data),
    callouts: (id) =>
      APIRequest.get(server + `/members/${id}/callouts`).then(
        (res) => res.data
      ),
    updateStatus: (id, data) =>
      APIRequest.patch(server + `/members/${id}/status`, data).then(
        (res) => res.data
      ),
    archive: (id, data) =>
      APIRequest.patch(server + `/members/${id}/archive`, data).then(
        (res) => res.data
      ),
    update: (id, data) =>
      APIRequest.patch(server + `/members/${id}`, data).then((res) => res.data),
    updateVetting: (id, data) =>
      APIRequest.patch(server + `/members/${id}/vetting`, data).then(
        (res) => res.data
      ),
    updateFees: (id, data) =>
      APIRequest.patch(server + `/members/${id}/fees`, data).then(
        (res) => res.data
      ),
    addTrainingLog: (id, data) =>
      APIRequest.post(server + `/members/${id}/traininglogs`, data).then(
        (res) => res.data
      ),
    getTrainingLogs: (id) =>
      APIRequest.get(server + `/members/${id}/traininglogs`).then(
        (res) => res.data
      ),
    getTrainingLog: (id, lid) =>
      APIRequest.get(server + `/members/${id}/traininglogs${lid}`).then(
        (res) => res.data
      ),
    addCPDLog: (id, data) =>
      APIRequest.post(server + `/members/${id}/cpd`, data).then(
        (res) => res.data
      ),
    getCPDLogs: (id) =>
      APIRequest.get(server + `/members/${id}/cpd`).then((res) => res.data),
    getCPDDash: (id) =>
      APIRequest.get(server + `/members/${id}/cpd/dash`).then(
        (res) => res.data
      ),
    getCPDLog: (id, lid) =>
      APIRequest.get(server + `/members/${id}/cpd${lid}`).then(
        (res) => res.data
      ),
    getPhoto: id => APIRequest.get(server + `/members/${id}/photo`).then((res) => res.data),
    getPhotoUploadConfig: id => APIRequest.get(server + `/members/${id}/photo/_uploadConfig`).then((res) => res.data),
    setPhotoImage: (id, profilePhoto) => APIRequest.patch(server + `/members/${id}/photo`, profilePhoto, {headers: {"Content-Type": "multipart/form-data"}}),
    events: (id) =>
      APIRequest.get(server + `/events/${id}/member`).then((res) => res.data),
    eventsDash: (id) =>
      APIRequest.get(server + `/events/${id}/member/dash`).then(
        (res) => res.data
      ),
    courses: (id) =>
      APIRequest.get(server + `/training/course/member/${id}`).then(
        (res) => res.data
      ),
    comps: (id) =>
      APIRequest.get(server + `/training/course/comps/${id}`).then(
        (res) => res.data
      ),
    vehiclesOld: {
      all: (id) =>
        APIRequest.get(server + `/members/${id}/vehicle`).then(
          (res) => res.data
        ),
      add: (id, data) =>
        APIRequest.post(server + `/members/${id}/vehicle`, data).then(
          (res) => res.data
        ),
      show: (id, vid) =>
        APIRequest.get(server + `/members/${id}/vehicle/${vid}`).then(
          (res) => res.data
        ),
      update: (id, vid, data) =>
        APIRequest.patch(server + `/members/${id}/vehicle/${vid}`, data).then(
          (res) => res.data
        ),
      toggleApprove: (id, vid, data) =>
        APIRequest.patch(
          server + `/members/${id}/vehicle/${vid}/approve`,
          data
        ).then((res) => res.data),
    },
  },
  vehicles: {
    all: (teamOnly = true, approvedOnly = true) =>
      APIRequest.get(
        server +
          `/vehicle` +
          (teamOnly ? "?teamOnly=1" : "?teamOnly=0") +
          (approvedOnly ? "&approvedOnly=1" : "&approvedOnly=0")
      ).then((res) => res.data),
    add: (data) =>
      APIRequest.post(server + `/vehicle`, data).then((res) => res.data),
    show: (vid) =>
      APIRequest.get(server + `/vehicle/${vid}`).then((res) => res.data),
    dvla: (vid) =>
      APIRequest.get(server + `/vehicle/dvla/${vid}`).then((res) => res.data),
    getTeamVehicles: () =>
      APIRequest.get(server + `/vehicle/team`).then((res) => res.data),
    getMemberVehicles: () =>
      APIRequest.get(server + `/vehicle/member`).then((res) => res.data),
    update: (vid, data) =>
      APIRequest.patch(server + `/vehicle/${vid}`, data).then(
        (res) => res.data
      ),

    getPhoto: vid => APIRequest.get(server + `/vehicle/${vid}/photo`).then((res) => res.data),
    getPhotoUploadConfig: vid => APIRequest.get(server + `/vehicle/${vid}/photo/_uploadConfig`).then((res) => res.data),
    setPhotoImage: (vid, profilePhoto) => APIRequest.patch(server + `/vehicle/${vid}/photo`, profilePhoto, {headers: { "Content-Type": "multipart/form-data" }}),

    archiveVehicle: (vid, data) =>
      APIRequest.patch(server + `/vehicle/${vid}/archive`, data).then(
        (res) => res.data
      ),
    toggleApprove: (vid, data) =>
      APIRequest.patch(server + `/vehicle/${vid}/approve`, data).then(
        (res) => res.data
      ),
    setAppraisal: (vid, data) =>
      APIRequest.patch(server + `/vehicle/${vid}/appraisal`, data).then(
        (res) => res.data
      ),
    aMembersVehicles: (id) =>
      APIRequest.get(server + `/vehicle/member/${id}`).then((res) => res.data),
  },
  training: {
    dash: () => APIRequest.get(server + `/training`).then((res) => res.data),
    courses: {
      all: (query = {}) =>
        APIRequest.get(
          server + `/training/course` + quickObjectToQueryString(query)
        ).then((res) => res.data),
      add: (data) =>
        APIRequest.post(server + `/training/course`, data).then(
          (res) => res.data
        ),
      show: (id) =>
        APIRequest.get(server + `/training/course/${id}`).then(
          (res) => res.data
        ),
      update: (id, data) =>
        APIRequest.patch(server + `/training/course/${id}`, data).then(
          (res) => res.data
        ),
      remove: (courseId) =>
        APIRequest.delete(server + `/training/course/${courseId}`).then(
          (res) => res.data
        ),
      result: {
        add: (id, data) =>
          APIRequest.post(server + `/training/course/${id}/result`, data).then(
            (res) => res.data
          ),
      },
    },
    comps: {
      all: () =>
        APIRequest.get(server + `/training/comps`).then((res) => res.data),

      show: (id) =>
        APIRequest.get(server + `/training/comps/${id}`).then(
          (res) => res.data
        ),
      update: (data) =>
        APIRequest.patch(server + `/training/comps/`, data).then(
          (res) => res.data
        ),
      addSession: (data) =>
        APIRequest.post(server + `/training/comps/sessions`, data).then(
          (res) => res.data
        ),
      getAllSessions: () =>
        APIRequest.get(server + `/training/comps/sessions`).then(
          (res) => res.data
        ),
      getASession: (id) =>
        APIRequest.get(server + `/training/comps/sessions/${id}`).then(
          (res) => res.data
        ),

      addSection: (data) =>
        APIRequest.patch(server + `/training/comps/section`, data).then(
          (res) => res.data
        ),
      deleteSection: (id) =>
        APIRequest.delete(server + `/training/comps/section/${id}`).then(
          (res) => res.data
        ),
      updateSectionCriteria: (id, cid, data) =>
        APIRequest.patch(
          server + `/training/comps/section/${id}/criteria/${cid}`,
          data
        ).then((res) => res.data),
      deleteSectionCriteria: (id, cid) =>
        APIRequest.delete(
          server + `/training/comps/section/${id}/criteria/${cid}`
        ).then((res) => res.data),
      getSections: (id) =>
        APIRequest.get(server + `/training/comps/section/${id}`).then(
          (res) => res.data
        ),
      getSessionScores: () =>
        APIRequest.get(server + `/training/comps/sessions/scores`).then(
          (res) => res.data
        ),
      getMemberCompScores: (id) =>
        APIRequest.get(server + `/training/comps/member/scores/${id}`).then(
          (res) => res.data
        ),
      getMemberCompScore: (compId, userId) =>
        APIRequest.get(
          server + `/training/comps/${compId}/members/${userId}/score`
        ).then((res) => res.data),

      getSectionScore: (sid, uid) =>
        APIRequest.get(
          server + `/training/comps/section/${sid}/score/${uid}`
        ).then((res) => res.data),

      addSessionScore: (sid, data) =>
        APIRequest.post(
          server + `/training/comps/sessions/${sid}/score`,
          data
        ).then((res) => res.data),
    },
  },
  event: {
    all: () => APIRequest.get(server + "/events").then((res) => res.data),
    future: (type = "Training", limit = 10) =>
      APIRequest.get(
        server + `/events/future?type=${type}&limit=${limit}`
      ).then((res) => res.data),
    show: (id) =>
      APIRequest.get(server + `/events/${id}`).then((res) => res.data),
    add: (data) =>
      APIRequest.post(server + "/events", data).then((res) => res.data),
    update: (id, data) =>
      APIRequest.patch(server + `/events/${id}`, data).then((res) => res.data),
    copy: (id, data) =>
      APIRequest.post(server + `/events/${id}/copy`, data).then(
        (res) => res.data
      ),
    print: (id) =>
      APIRequest.post(
        server + `/events/${id}/print`,
        {},
        { responseType: "blob" }
      ).then((res) => res.data),
    remove: (eventId) =>
      APIRequest.delete(server + `/events/${eventId}`).then((res) => res.data),
    responses: {
      show: (id) =>
        APIRequest.get(server + `/events/${id}/responses`).then(
          (res) => res.data
        ),
      add: (id, data) =>
        APIRequest.post(server + `/events/${id}/responses`, data).then(
          (res) => res.data
        ),
      addRec: (id, data) =>
        APIRequest.post(server + `/events/${id}/responses/recruit`, data).then(
          (res) => res.data
        ),
    },
  },
  recruitment: {
    all: () => APIRequest.get(server + "/recruitment").then((res) => res.data),
    archived: () =>
      APIRequest.get(server + "/recruitment/archived").then((res) => res.data),
    events: () =>
      APIRequest.get(server + "/events/recruitment").then((res) => res.data),
    latest: () =>
      APIRequest.get(server + "/recruitment/latest").then((res) => res.data),
    show: (id) =>
      APIRequest.get(server + `/recruitment/${id}`).then((res) => res.data),
    add: (data) =>
      APIRequest.post(server + "/recruitment", data).then((res) => res.data),
    update: (id, data) =>
      APIRequest.patch(server + `/recruitment/${id}`, data).then(
        (res) => res.data
      ),
    addNote: (id, data) =>
      APIRequest.patch(server + `/recruitment/${id}/note`, data).then(
        (res) => res.data
      ),
    recEvents: (id) =>
      APIRequest.get(server + `/events/${id}/recruit`).then((res) => res.data),

    getPhoto: id => APIRequest.get(server + `/recruitment/${id}/photo`).then((res) => res.data),
    getPhotoUploadConfig: id => APIRequest.get(server + `/recruitment/${id}/photo/_uploadConfig`).then((res) => res.data),
    setPhotoImage: (id, profilePhoto) => APIRequest.patch(server + `/recruitment/${id}/photo`, profilePhoto, {headers: { "Content-Type": "multipart/form-data" },}),

    convertToMember: (id) =>
      APIRequest.post(server + `/recruitment/${id}/convert_to_member`),
    intakes: {
      all: () =>
        APIRequest.get(server + "/recruitment/intake").then((res) => res.data),
      archived: () =>
        APIRequest.get(server + "/recruitment/intake/archive").then(
          (res) => res.data
        ),
      add: (data) =>
        APIRequest.post(server + "/recruitment/intake", data).then(
          (res) => res.data
        ),
      update: (id, data) =>
        APIRequest.patch(server + `/recruitment/intake/${id}`, data).then(
          (res) => res.data
        ),
      recruits: (id) =>
        APIRequest.get(server + `/recruitment/intake/${id}/recruits`).then(
          (res) => res.data
        ),
    },
  },
  service: {
    all: () => APIRequest.get(server + "/service").then((res) => res.data),
    allVehicle: (id) =>
      APIRequest.get(server + `/service/${id}/vehicle`).then((res) => res.data),
    add: (data) =>
      APIRequest.post(server + "/service", data).then((res) => res.data),
    show: (id) =>
      APIRequest.get(server + `/service/${id}`).then((res) => res.data),
    update: (id, data) =>
      APIRequest.patch(server + `/service/${id}`, data).then((res) => res.data),
  },
  location: {
    all: () => APIRequest.get(server + "/locations").then((res) => res.data),
    add: (data) =>
      APIRequest.post(server + "/locations", data).then((res) => res.data),
    show: (id) =>
      APIRequest.get(server + `/locations/${id}`).then((res) => res.data),
    update: (id, data) =>
      APIRequest.patch(server + `/locations/${id}`, data).then(
        (res) => res.data
      ),
  },
  settings: {
    all: () =>
      APIRequest.get(server + `/settings/system`).then((res) => res.data),
    update: (data) =>
      APIRequest.patch(server + `/settings/system`, data).then(
        (res) => res.data
      ),
    getIncidentTypesList: () =>
      APIRequest.get(server + `/settings/incident_types_list`).then(
        (res) => res.data
      ),
    syncTeam: () =>
      APIRequest.get(server + `/members/sync`).then((res) => res.data),
  },
  management: {
    archivedMembers: () => APIRequest.get(server + "/management/members/archived").then((res) => res.data),
    vettingList: (months) => APIRequest.get(server + `/management/members/vetting?months=${months}`).then((res) => res.data),
    activityList: (
      training_type = "None",
      deployment_type = "None",
      time_span = 12
    ) => APIRequest.get(server + `/management/members/activity?training_type=${training_type}&deployment_type=${deployment_type}&time_span=${time_span}`).then((res) => res.data),
    feesList: (fee) => APIRequest.get(server + `/management/members/fees/${fee}`).then((res) => res.data),
    listNotInTeamSite: () => APIRequest.get(server + `/management/getNotInTeamSite`).then((res) => res.data),
    importFromCentral: centralId => APIRequest.post(server + `/management/import_from_central/${centralId}`, {}).then(res => res.data),
    dash: () => APIRequest.get(server + `/management/dash`).then((res) => res.data),
    trainingDash: () => APIRequest.get(server + `/management/training/dash`).then((res) => res.data),
    compScoresList: (compId) => APIRequest.get(server + `/management/training/compscores/${compId}`).then((res) => res.data),
    attendanceReport: () => APIRequest.get(server + `/management/training/attendance`).then((res) => res.data),
    courseReport: (course_type) => APIRequest.post(server + `/management/training/coursereport`, {course_type,}).then((res) => res.data),
    suppliers: {
      add: (data) =>
        APIRequest.post(server + "/suppliers", data).then((res) => res.data),
      all: () => APIRequest.get(server + `/suppliers`).then((res) => res.data),
      show: (id) =>
        APIRequest.get(server + `/suppliers/${id}`).then((res) => res.data),
      update: (id, data) =>
        APIRequest.patch(server + `/suppliers/${id}`, data).then(
          (res) => res.data
        ),
    },
    logs: {
      all: (query = {}) =>
        APIRequest.get(server + `/logs` + quickObjectToQueryString(query)).then(
          (res) => res.data
        ),
    },
    incidents: {
      all: () =>
        APIRequest.get(server + `/sarsys/local_incidents`).then(
          (res) => res.data
        ),
      show: (id) =>
        APIRequest.get(server + `/sarsys/local_incidents/${id}`).then(
          (res) => res.data
        ),
      incident: {
        getMembers: (id) =>
          APIRequest.get(
            server + `/sarsys/local_incidents/${id}/members/`
          ).then((res) => res.data),
        assignMember: (incident_id, member_id, period_id) =>
          APIRequest.post(
            server +
              `/sarsys/local_incidents/${incident_id}/members/${member_id}/period/${period_id}`,
            {}
          ).then((res) => res.data),
        unassignMember: (incident_id, member_id, period_id) =>
          APIRequest.delete(
            server +
              `/sarsys/local_incidents/${incident_id}/members/${member_id}/period/${period_id}`
          ).then((res) => res.data),
      },
      sarsys: {
        all: () =>
          APIRequest.get(server + `/sarsys/incidents`).then((res) => res.data),
        view: (id) =>
          APIRequest.get(server + `/sarsys/incidents/${id}/view`).then(
            (res) => res.data
          ),
        import: (incident_id) =>
          APIRequest.post(
            server + `/sarsys/incidents/${incident_id}/import`
          ).then((res) => res.data),
      },
    },
  },
};
