import React, { Component } from "react";
import { connect } from "react-redux";
import { Segment, Header, Table, Grid } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { getSystemSettings } from "../../../actions/settingsActions";
import api from "../../../actions/api";

function mapStateToProps(state) {
  return {
    system: state.system,
    user: state.user,
  };
}
class TrainingDashPage extends Component {
  state = {
    dash: [],
  };
  componentDidMount() {
    this._getData();
    this.props.getSystemSettings();
    // this.props.i18n.changeLanguage("en");
  }
  componentDidUpdate(prevProps, prevState) {}

  _getData = () => {
    api.training.dash().then((dash) => {
      this.setState({
        dash,
      });
    });
  };
  renderComps() {
    const data = this.state.dash.map((d, i) => {
      return (
        <Table.Row key={i}>
          <Table.Cell>
            <Header>{d.competency}</Header>
          </Table.Cell>
          <Table.Cell textAlign="center">
            <Header>{d.comp}</Header>
          </Table.Cell>
          <Table.Cell textAlign="center">
            <Header>{d.nonComp}</Header>
          </Table.Cell>
        </Table.Row>
      );
    });
    return (
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Competency</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Competent</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
              Non Competent
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{data}</Table.Body>
      </Table>
    );
  }
  render() {
    return (
      <Segment loading={this.state.loading} basic>
        <Header textAlign="center" as={"h1"}>
          Management Training Dash
        </Header>
        <Grid>
          <Grid.Row>
            <Grid.Column width={8}>
              <Header>Need Stuff here</Header>
              {/* {this.renderComps()} */}
            </Grid.Column>
            <Grid.Column></Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}
const TrainingDash = withTranslation()(TrainingDashPage);
export default connect(mapStateToProps, { getSystemSettings })(TrainingDash);
