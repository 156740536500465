import React, { Component } from "react";
import {
  Segment,
  Button,
  Grid,
  Form,
  Divider,
  Select,
} from "semantic-ui-react";
import PropTypes from "prop-types";
import UserMap from "../mapping/userMap";
import Geosuggest from "react-geosuggest";
import axios from "axios";

class AddressCard extends Component {
  state = {
    data: {
      update: false,
      town: "",
      geoJSON: {
        properties: {
          name: "",
          address: "",
          town: "",
          postcode: "",
        },
        geometry: {},
      },
    },
    disabled: true,
    postcodes: [],
  };
  componentDidMount() {
    this.getData();
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.data !== prevState.data) {
      if (this.props.update) {
        this.props.update(this.state.data);
      }
    }
  }

  getData = () => {
    if (this.props.geoJSON) {
      this.setState({
        data: {
          ...this.state.data,
          geoJSON: this.props.geoJSON,
        },
      });
    }
  };
  onChange = (e) => this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  onGeoJSONChange = (e) => this.setState({
      data: {
        ...this.state.data,
        geoJSON: {
          ...this.state.data.geoJSON,
          properties: {
            ...this.state.data.geoJSON.properties,
            [e.target.name]: e.target.value,
          },
        },
      },
    });
  onGeoJSONDropDownChange1 = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        geoJSON: {
          ...this.state.data.geoJSON,
          properties: {
            ...this.state.data.geoJSON.properties,
            [e.name]: e.value,
          },
        },
      },
    });
  };
  onGeoJSONDropDownChange = (props, e) => {
    this.setState({
      data: {
        ...this.state.data,
        geoJSON: {
          ...this.state.data.geoJSON,
          properties: {
            ...this.state.data.geoJSON.properties,
            [e.name]: e.value,
          },
        },
      },
    });
  };
  onCancel = () => {
    this.getData();
    this._geoSuggest.clear();
    this.setState({
      val: {},
      update: false,
    });
  };
  onSuggestSelect = (suggest) => {
    let coords = [];
    let components = {};
    if (suggest) {
      coords = [suggest.location.lng, suggest.location.lat];
      components = suggest.gmaps.address_components;
    }
    // let components = suggest.gmaps.address_components;
    let admin_area = "";
    let postal_town = "";
    let postal_code = "";
    // let name = "";
    let street = "";
    let county = "";
    let town = "";
    for (let component = 0; component < components.length; component++) {
      // if (components[component].types[0] === "premise") {
      //     name = components[component].long_name;
      // }
      if (components[component].types[0] === "route") {
        street = components[component].long_name;
      }
      if (components[component].types[0] === "locality") {
        admin_area = components[component].long_name;
      }
      if (components[component].types[0] === "postal_town") {
        postal_town = components[component].long_name;
      }
      if (components[component].types[0] === "administrative_area_level_2") {
        county = components[component].long_name;
      }
      if (components[component].types[0] === "postal_code") {
        postal_code = components[component].long_name;
      }
      if (admin_area) {
        town = admin_area;
      } else {
        town = postal_town;
      }
    }
    if (suggest) {
      this.setState({
        data: {
          ...this.state.data,
          // update: true,
          geoJSON: {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: coords,
            },
            properties: {
              name: suggest.label,
              street: street,
              town: town,
              county: county,
              postcode: postal_code,
              address: suggest.gmaps.formatted_address,
            },
          },
        },
        errors: {},
        val: suggest,
        zoom: 10,
      });
    }
  };
  postcode = (e) => {
    this.setState({
      postcode: e.target.value,
    });
  };
  onClick = () => {
    let data = this.state.data.geoJSON.geometry.coordinates;
    axios
      .get(
        `https://api.postcodes.io/postcodes?lon=${data[0]}&lat=${data[1]}&radius=300`
      )
      .then((res) => {
        let locArray = [];
        if (res.data.result && res.data.result.length) {
          const data = res.data.result;
          for (var key in data) {
            locArray.push({
              key: key,
              text:
                data[key].postcode +
                ", (" +
                parseInt(data[key].distance) +
                "m away)",
              value: data[key].postcode,
            });
          }
          this.setState({
            postcodes: locArray,
          });
        } else {
          this.setState({
            postcodes: locArray,
          });
        }
      });
    // axios
    //   .get(
    //     `https://api.getAddress.io/find/${data}?api-key=Qq9AtI0lz0yXnz_5BrS6hw21498`
    //   )
    //   .then(res => {
    //     this.setState({
    //       postcodes: res.data
    //     });
    //   });
  };
  onMapClick = (data) => {
    this.setState({
      data: {
        ...this.state.data,
        update: true,
        geoJSON: {
          ...this.state.data.geoJSON,
          geometry: {
            ...this.state.data.geoJSON.geometry,
            coordinates: data,
          },
        },
      },
      disabled: false,
    });
  };
  updateAddress = () => {
    let data = this.state.data;
    this.props.update(data).then(() => {
      this.setState({
        data: {
          ...this.state.data,
          update: false,
        },
      });
    });
  };

  renderForm() {
    let disabled = this.state.disabled;
    return (
      <Form>
        <Form.Field>
          <label>Add/Change address</label>
          <Geosuggest
            ref={(el) => (this._geoSuggest = el)}
            placeholder="123 the street, Molash, Kent"
            inputClassName="geosuggest__input"
            country={this.props.country.name}
            onSuggestSelect={this.onSuggestSelect}
            suggestsClassName="geosuggest__suggests"
            suggestsHiddenClassName="geosuggest__suggests--hidden"
            suggestItemClassName="geosuggest__item"
          />
        </Form.Field>
        <Divider />
        {this.state.data.geoJSON.properties &&
        this.state.data.geoJSON.properties.address ? (
          <React.Fragment>
            <Form.Field>
              <label>Name</label>
              <input
                id="name"
                name={"name"}
                onChange={this.onGeoJSONChange}
                value={
                  this.state.data.geoJSON.properties &&
                  this.state.data.geoJSON.properties.name
                }
              />
            </Form.Field>
            <Form.Field>
              <label>Address</label>
              <input
                id="address"
                name="address"
                onChange={this.onGeoJSONChange}
                value={
                  this.state.data.geoJSON.properties &&
                  this.state.data.geoJSON.properties.address
                }
                disabled={disabled}
              />
            </Form.Field>
            <Form.Field>
              <label>Town</label>
              <input
                id="town"
                name="town"
                onChange={this.onGeoJSONChange}
                value={
                  this.state.data.geoJSON.properties &&
                  this.state.data.geoJSON.properties.town
                }
                disabled={disabled}
              />
            </Form.Field>
            <Form.Field>
              <label>Post Code</label>
              <input
                id="postcode"
                name="postcode"
                onChange={this.onGeoJSONChange}
                value={
                  this.state.data.geoJSON.properties &&
                  this.state.data.geoJSON.properties.postcode
                }
                disabled={disabled}
              />
            </Form.Field>

            <Form.Group>
              <Form.Field>
                {this.state.data.update && (
                  <Button negative onClick={this.onCancel}>
                    Cancel
                  </Button>
                )}
              </Form.Field>
              <Form.Field>
                {this.state.data.update && (
                  <Button positive onClick={this.onClick}>
                    Check for nearest postcode
                  </Button>
                )}
              </Form.Field>
            </Form.Group>
            {this.state.postcodes.length ? (
              <Form.Field>
                <label>Nearest postcodes in metres</label>
                <Select
                  placeholder={"pick one"}
                  name={"postcode"}
                  options={this.state.postcodes}
                  onChange={this.onGeoJSONDropDownChange}
                />
              </Form.Field>
            ) : (
              ""
            )}
          </React.Fragment>
        ) : (
          <p>No Address</p>
        )}
      </Form>
    );
  }
  render() {
    return (
      <Grid stackable>
        <Grid.Row>
          <Grid.Column width={6}>{this.renderForm()}</Grid.Column>
          <Grid.Column width={10}>
            <Segment className="mapHolder no-pad">
              {this.state.data.geoJSON.properties &&
              this.state.data.geoJSON.properties.address ? (
                <UserMap
                  mapStyle={{ height: 40 + "vh" }}
                  marker={this.state.data.geoJSON}
                  data={this.onMapClick}
                />
              ) : (
                <p>No address</p>
              )}
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
AddressCard.propTypes = {
  country: PropTypes.object.isRequired,
};
export default AddressCard;
