import React, { useState } from "react";
import Box from "./Box";
import { Dialog, Transition } from "@headlessui/react";

const SettingsList = ({
  title,
  label,
  field,
  onSubmit,
  onDelete,
  data,
  style,
}) => {
  const [value, setValue] = useState("");
  const [del, setDelete] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const handleAdd = () => {
    let fd = data;
    if (!Array.isArray(fd)) {
      fd = [];
    }
    fd.push(value);
    let theField = field;
    const list = {};
    list[theField] = fd;
    onSubmit(list);
    setValue("");
  };
  const handleDelete = () => {
    let newList = data.filter((item, i) => {
      return del !== item;
    });
    let theField = field;
    const list = {};
    list[theField] = newList;
    onDelete(list);
    setIsOpen(false);
  };
  const CloseDialog = () => {
    return (
      <Transition
        show={isOpen}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Dialog
          open={isOpen}
          onClose={() => setIsOpen(false)}
          className="fixed z-10 inset-0 overflow-y-auto "
        >
          <div className="text-center min-h-screen">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-80" />
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="border-4 border-gray-700 inline-block w-full max-w-2xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-gray-800 shadow-xl rounded-2xl">
              <div className="pt-4">
                <Dialog.Title>
                  Delete <span className="italic">{title}</span> Value{" "}
                  <span className="italic font-bold">{del}</span>
                </Dialog.Title>
              </div>

              <div className="flex justify-between mt-4 border-t border-gray-700 pt-4">
                <button
                  className="px-4 py-2 bg-blue-600 rounded-lg"
                  onClick={() => setIsOpen(false)}
                >
                  Cancel
                </button>
                <button
                  className="px-4 py-2 bg-red-600 rounded-lg disabled:bg-red-500"
                  onClick={handleDelete}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  };
  return (
    <Box title={title}>
      <div className="flex flex-col w-full p-4">
        <div className="flex w-full gap-2 align-middle items-center">
          <input
            value={value}
            className="py-2 px-2 text-lg border-gray-600 bg-gray-900  focus:ring-gray-500 focus:border-gray-500 placeholder-gray-600 block w-full shadow-sm rounded-md"
            type={"text"}
            field={field}
            onChange={(e) => setValue(e.target.value)}
            placeholder="..."
          />
          <button
            disabled={value === "" ? true : false}
            onClick={handleAdd}
            className="bg-green-700 py-2 px-4 rounded-md text-sm h-10"
          >
            Add
          </button>
        </div>
        {CloseDialog()}
        <div className="w-full mt-4">
          <table className=" w-full">
            <tbody>
              {data &&
                data.map((d) => {
                  return (
                    <tr className="px-2 py-4 border-b border-gray-700" key={d}>
                      <td className="text-lg py-4">{d}</td>
                      <td className="text-right">
                        <button
                          className="bg-red-600 py-2 px-4 rounded-md"
                          onClick={() => {
                            setDelete(d);
                            setIsOpen(true);
                          }}
                        >
                          X
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </Box>
  );
};
export default SettingsList;
