import React, { useState, useEffect } from "react";
import {
  Grid,
  Segment,
  Header,
  Divider,
  Form,
  Table,
  Button,
  Message,
} from "semantic-ui-react";

// THIS COMPONENT REQUIRES "data" and "submit" TO BE PASSED TO IT TO FUNCTION

export default function ActivityReportBreakpoints(props) {
  const [
    activityReportDeploymentBreakpoints,
    setActivityReportDeploymentBreakpoints,
  ] = useState({});
  const [
    activityReportTrainingBreakpoints,
    setActivityReportTrainingBreakpoints,
  ] = useState({});

  const [updateFlag, setUpdateFlag] = useState(false);
  const [errorFlag, setErrorFlag] = useState(false);

  useEffect(() => {
    if (
      Object.keys(activityReportDeploymentBreakpoints).length === 0 &&
      Object.keys(activityReportTrainingBreakpoints).length === 0 &&
      props.data &&
      (props.data.activityReportDeploymentBreakpoints ||
        props.data.activityReportTrainingBreakpoints)
    ) {
      setActivityReportDeploymentBreakpoints(
        props.data.activityReportDeploymentBreakpoints
      );
      setActivityReportTrainingBreakpoints(
        props.data.activityReportTrainingBreakpoints
      );
    }
  }, [
    props.data,
    activityReportDeploymentBreakpoints,
    activityReportTrainingBreakpoints,
  ]);

  const handleInputChange = (event, { name, value }) => {
    if (value <= 100 && value >= 0) {
      setUpdateFlag(true);

      name = name.split("_");

      if (name[0] === "deployment") {
        setActivityReportDeploymentBreakpoints({
          ...activityReportDeploymentBreakpoints,
          [name[1]]: parseFloat(value),
        });
      } else {
        setActivityReportTrainingBreakpoints({
          ...activityReportTrainingBreakpoints,
          [name[1]]: parseFloat(value),
        });
      }
    }
  };

  return (
    <Grid.Column className="mb-10 pb-4">
      <Segment>
        <Segment basic>
          <Header>Activity Report Percentage Breakpoints</Header>
          <Divider />
          {errorFlag && (
            <Message
              error
              content="Please make sure that below group ascends left to right or is left as all 0's"
              header="Error Validating"
            />
          )}
          <Table textAlign="center" compact stackable>
            <Table.Body>
              <Table.Row>
                <Table.Cell colSpan="4">Deployment</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell className="negative1">Red</Table.Cell>
                <Table.Cell className="warning1">Yellow</Table.Cell>
                <Table.Cell className="positive1">Green</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <Form>
                    <Form.Input
                      name="deployment_red"
                      type="number"
                      fluid
                      value={
                        activityReportDeploymentBreakpoints &&
                        activityReportDeploymentBreakpoints.red
                          ? activityReportDeploymentBreakpoints.red
                          : 0
                      }
                      onChange={handleInputChange}
                    />
                  </Form>
                </Table.Cell>
                <Table.Cell>
                  <Form>
                    <Form.Input
                      name="deployment_yellow"
                      type="number"
                      fluid
                      value={
                        activityReportDeploymentBreakpoints &&
                        activityReportDeploymentBreakpoints.yellow
                          ? activityReportDeploymentBreakpoints.yellow
                          : 0
                      }
                      onChange={handleInputChange}
                      //   disabled={
                      //     !activityReportDeploymentBreakpoints.red ||
                      //     (activityReportDeploymentBreakpoints.red &&
                      //       activityReportDeploymentBreakpoints.red === 0)
                      //   }
                    />
                  </Form>
                </Table.Cell>
                <Table.Cell>
                  <Form>
                    <Form.Input
                      name="deployment_green"
                      type="number"
                      fluid
                      value={
                        activityReportDeploymentBreakpoints &&
                        activityReportDeploymentBreakpoints.green
                          ? activityReportDeploymentBreakpoints.green
                          : 0
                      }
                      onChange={handleInputChange}
                      //   disabled={
                      //     !activityReportDeploymentBreakpoints.red ||
                      //     (activityReportDeploymentBreakpoints.red &&
                      //       activityReportDeploymentBreakpoints.red === 0) ||
                      //     !activityReportDeploymentBreakpoints.yellow ||
                      //     (activityReportDeploymentBreakpoints.yellow &&
                      //       activityReportDeploymentBreakpoints.yellow === 0)
                      //   }
                    />
                  </Form>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell colSpan="4">Training</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={3} className="negative1">
                  Red
                </Table.Cell>
                <Table.Cell width={3} className="warning1">
                  Yellow
                </Table.Cell>
                <Table.Cell width={3} className="positive1">
                  Green
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={3}>
                  <Form>
                    <Form.Input
                      name="training_red"
                      fluid
                      value={
                        activityReportTrainingBreakpoints &&
                        activityReportTrainingBreakpoints.red
                          ? activityReportTrainingBreakpoints.red
                          : 0
                      }
                      onChange={handleInputChange}
                    />
                  </Form>
                </Table.Cell>
                <Table.Cell width={3}>
                  <Form>
                    <Form.Input
                      name="training_yellow"
                      fluid
                      value={
                        activityReportTrainingBreakpoints &&
                        activityReportTrainingBreakpoints.yellow
                          ? activityReportTrainingBreakpoints.yellow
                          : 0
                      }
                      onChange={handleInputChange}
                      //   disabled={
                      //     !activityReportTrainingBreakpoints.red ||
                      //     (activityReportTrainingBreakpoints.red &&
                      //       activityReportTrainingBreakpoints.red === 0)
                      //   }
                    />
                  </Form>
                </Table.Cell>
                <Table.Cell width={3}>
                  <Form>
                    <Form.Input
                      name="training_green"
                      fluid
                      value={
                        activityReportTrainingBreakpoints &&
                        activityReportTrainingBreakpoints.green
                          ? activityReportTrainingBreakpoints.green
                          : 0
                      }
                      onChange={handleInputChange}
                      //   disabled={
                      //     !activityReportTrainingBreakpoints.red ||
                      //     (activityReportTrainingBreakpoints.red &&
                      //       activityReportTrainingBreakpoints.red) === 0 ||
                      //     !activityReportTrainingBreakpoints.yellow ||
                      //     (activityReportTrainingBreakpoints.yellow &&
                      //       activityReportTrainingBreakpoints.yellow === 0)
                      //   }
                    />
                  </Form>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          {updateFlag && (
            <Button
              fluid
              color="green"
              content="Save"
              icon="disk"
              onClick={() => {
                if (
                  ((activityReportTrainingBreakpoints.red === 0 &&
                    activityReportTrainingBreakpoints.yellow === 0 &&
                    activityReportTrainingBreakpoints.green === 0) ||
                    (activityReportTrainingBreakpoints.red &&
                      activityReportTrainingBreakpoints.yellow &&
                      activityReportTrainingBreakpoints.green &&
                      activityReportTrainingBreakpoints.red <
                        activityReportTrainingBreakpoints.yellow &&
                      activityReportTrainingBreakpoints.yellow <
                        activityReportTrainingBreakpoints.green)) &&
                  ((activityReportTrainingBreakpoints.red === 0 &&
                    activityReportTrainingBreakpoints.yellow === 0 &&
                    activityReportTrainingBreakpoints.green === 0) ||
                    (activityReportDeploymentBreakpoints.red &&
                      activityReportDeploymentBreakpoints.yellow &&
                      activityReportDeploymentBreakpoints.green &&
                      activityReportDeploymentBreakpoints.red <
                        activityReportDeploymentBreakpoints.yellow &&
                      activityReportDeploymentBreakpoints.yellow <
                        activityReportDeploymentBreakpoints.green))
                ) {
                  props.submit({
                    activityReportTrainingBreakpoints,
                    activityReportDeploymentBreakpoints,
                  });

                  setUpdateFlag(false);
                  setErrorFlag(false);
                } else {
                  setErrorFlag(true);
                }
              }}
            />
          )}
        </Segment>
      </Segment>
    </Grid.Column>
  );
}
