import React, { Component } from "react";
import { connect } from "react-redux";
import { getSystemSettings } from "../actions/settingsActions";
import {
  Segment,
  Select,
  Modal,
  Header,
  Icon,
  Button,
  Form,
  TextArea,
  Grid,
  Divider,
  Statistic,
  Message,
  Popup,
  Menu,
  Checkbox,
} from "semantic-ui-react";
import moment from "moment";
import { Authentication } from "@liquidcomputing/sarstuff__reactjs-singlesignon";
// import { DateTimeInput } from "semantic-ui-calendar-react";
import DatePicker from "react-datepicker";
import api from "../actions/api";
import { Calendar, momentLocalizer } from "react-big-calendar";
import CustomToolbar from "./common/calendarToolbar";
import InlineError from "./common/forms/errorMessage.js";
import "react-big-calendar/lib/css/react-big-calendar.css";

moment.locale("en-GB", {
  week: {
    dow: 1,
    doy: 1,
  },
});
const localizer = momentLocalizer(moment);

function mapStateToProps(state) {
  return {
    system: state.system,
    user: state.user,
  };
}

class CalendarPage extends Component {
  state = {
    events: [],
    eventTypes: [],
    locations: [],
    teamList: [],
    event: {},
    message: "",
    eventModal: false,
    eventShowModal: false,
    eventEditModal: false,
    respError: "",
    data: {
      title: "",
      start: "",
      end: "",
      details: "",
      type: "",
      organiserID: "",
      canSignUp: true,
    },
    errors: {},
  };
  componentDidMount() {
    this._getData();
    this.props
      .getSystemSettings()
      .then(() => {
        const data = this.props.system.settings.eventTypes;
        let dataArray = [];
        for (var key in data) {
          dataArray.push({
            key: key,
            text: data[key].content,
            value: data[key].content,
          });
        }
        this.updateEventTypes(dataArray);
      })
      .then(() => {
        api.member.all().then((res) => {
          let teamArray = [];
          const data = res;
          for (var key in data) {
            teamArray.push({
              key: key,
              text: data[key].name,
              value: data[key].centralId,
            });
          }
          this.setState({
            teamList: teamArray,
          });
        });
      });
    // this.props.i18n.changeLanguage("en");
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.user !== prevProps.user) {
      this.setState({
        data: {
          ...this.state.data,
          organiserID: this.props.user.details.sub,
        },
      });
    }
  }
  _getData = () => {
    api.event.all().then((res) => {
      this.setState({
        events: res,
      });
    });
    api.location.all().then((res) => {
      let locArray = [];
      const data = res;
      for (var key in data) {
        locArray.push({
          key: key,
          text: data[key].geoJSON.properties.name,
          value: data[key]._id,
        });
      }
      this.setState({
        locations: locArray,
      });
    });
  };
  _getEvent = (id) => {
    return api.event.show(id);
  };
  _getCourse = (id) => {
    return api.training.courses.show(id);
  };
  updateEventTypes = (data) => {
    this.setState({ eventTypes: data });
  };
  handleChange = (event, { name, value }) => {
    this.setState({
      data: {
        ...this.state.data,
        [name]: value,
      },
    });
  };
  onChange = (e) =>
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  handleMessageChange = (event, { name, value }) => {
    this.setState({
      message: value,
    });
  };
  handleDropdownChange = (props, e) => {
    this.setState({
      data: { ...this.state.data, [e.name]: e.value },
    });
  };
  validate = (data) => {
    const errors = {};
    if (!data.type) errors.type = "Can't be empty";
    if (!data.start) errors.start = "Can't be empty";
    if (!data.end) errors.end = "Can't be empty";
    // if (!data.email) errors.email = "Can't be empty";
    return errors;
  };
  handleAddEvent = () => {
    const errors = this.validate(this.state.data);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      let data = this.state.data;
      data = {
        ...data,
        start: moment(data.start),
        end: moment(data.end),
      };
      api.event.add(data).then(() => {
        this._getData();
        this.setState({
          eventModal: false,
          event: {},
          respError: "",
          data: {
            title: "",
            start: "",
            end: "",
            details: "",
            type: "",
            organiserID: "",
          },
        });
      });
    }
  };
  handleEditEvent = () => {
    let data = this.state.data;
    data = {
      ...data,
      start: moment(data.start),
      end: moment(data.end),
    };
    api.event.update(data._id, data).then(() => {
      this._getData();
      this.setState({
        eventEditModal: false,
        event: {},
        respError: "",
        data: {
          start: "",
          end: "",
          details: "",
          type: "",
          organiserID: "",
        },
      });
    });
  };
  drillDown = (e) => {
    if (e) {
      if (e.class === "course") {
        this._getCourse(e._id).then((res) => {
          this.setState({
            eventShowModal: true,
            event: res,
          });
        });
      } else {
        this._getEvent(e._id).then((res) => {
          this.setState({
            eventShowModal: true,
            event: res,
          });
        });
      }
    }
  };
  setResponse = (resp) => {
    let event = this.state.event;
    let data = {};
    if (event) {
      if (event.class === "course") {
        data = {
          memberID: this.props.user.details.sub,
          courseID: event._id,
          response: resp,
          message: this.state.message,
        };
        api.event.responses
          .add(event._id, data)
          .then((res) => {
            this._getCourse(event._id).then((res) => {
              this.setState({
                event: res,
                respError: "",
              });
            });
          })
          .catch((err) => {
            this.setState({
              respError: err,
            });
          });
      } else {
        data = {
          memberID: this.props.user.details.sub,
          eventID: event._id,
          response: resp,
          message: this.state.message,
        };
        api.event.responses
          .add(event._id, data)
          .then((res) => {
            this._getEvent(event._id).then((res) => {
              this.setState({
                event: res,
                respError: "",
              });
            });
          })
          .catch((err) => {
            this.setState({
              respError: err,
            });
          });
      }
    }
  };
  eventStyleGetter = (event, start, end, isSelected) => {
    var backgroundColor = event.color;
    var style = {
      backgroundColor: backgroundColor,
      borderRadius: "0px",
      opacity: 0.8,
      color: "white",
      border: "0px",
      display: "block",
    };
    return {
      style: style,
    };
  };
  goto = (loc) => {
    if (loc) {
      this.setState({ eventShowModal: false });
      if (loc.class === "course") {
        this.props.history.push(`/training/courses/${loc._id}`);
      } else {
        this.props.history.push(`/calendar/${loc._id}`);
      }
    }
  };
  editEvent = () => {
    this.setState({
      eventShowModal: false,
      eventEditModal: true,
      data: {
        ...this.state.event,
        location: this.state.event.location && this.state.event.location._id,
      },
    });
  };
  renderAddEventModal() {
    const { errors } = this.state;
    return (
      <Modal
        open={this.state.eventModal}
        onClose={() => this.setState({ eventModal: false })}
        centered={false}
        closeOnDimmerClick={false}
        size="small"
      >
        <Header>Add Event</Header>
        <Modal.Content>
          <Form loading={this.state.addRole_Loading}>
            <Form.Group widths={2}>
              <Form.Field>
                <label htmlFor="title">Start Date</label>
                <DatePicker
                  onChange={(date) => {
                    this.setState({
                      data: {
                        ...this.state.data,
                        start: date,
                        end: date,
                      },
                    });
                  }}
                  selected={
                    this.state.data.start ? new Date(this.state.data.start) : ""
                  }
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  placeholderText="Start Date & Time"
                  timeInputLabel="Time:"
                  dateFormat="dd-MM-yy HH:mm"
                  nextMonthButtonLabel=">"
                  previousMonthButtonLabel="<"
                  className={errors.start ? "warning" : ""}
                  data-disable-touch-keyboard
                />

                {errors.start && <InlineError text={errors.start} />}
              </Form.Field>
              <Form.Field>
                <label htmlFor="title">End Date</label>
                <DatePicker
                  onChange={(date) => {
                    this.setState({
                      data: {
                        ...this.state.data,
                        end: date,
                      },
                    });
                  }}
                  selected={
                    this.state.data.end ? new Date(this.state.data.end) : ""
                  }
                  minDate={this.state.data.start}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  placeholderText="End Date & Time"
                  timeInputLabel="Time:"
                  dateFormat="dd-MM-yy HH:mm"
                  nextMonthButtonLabel=">"
                  previousMonthButtonLabel="<"
                  className={errors.end ? "warning" : ""}
                  data-disable-touch-keyboard
                />

                {errors.end && <InlineError text={errors.end} />}
              </Form.Field>
            </Form.Group>
            <Form.Group widths={2}>
              <Form.Field>
                <label htmlFor="title">Title</label>
                <input
                  id="title"
                  name="title"
                  placeholder="title"
                  value={this.state.data.title}
                  onChange={this.onChange}
                  // className={warning.firstName ? "fieldWarning" : "good"}
                />
              </Form.Field>
              <Form.Field>
                <label>Location</label>
                <Select
                  search
                  placeholder={"pick one"}
                  name={"location"}
                  options={this.state.locations}
                  onChange={this.handleDropdownChange}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths={2}>
              <Form.Field>
                <label>Type</label>
                <Select
                  placeholder={"pick one"}
                  name={"type"}
                  options={this.state.eventTypes}
                  onChange={this.handleDropdownChange}
                />
                {errors.type && <InlineError text={errors.type} />}
              </Form.Field>
              <Form.Field>
                <label>Organiser</label>
                <Select
                  search
                  placeholder={"pick one"}
                  name={"organiserID"}
                  options={this.state.teamList}
                  value={this.state.data.organiserID}
                  onChange={this.handleDropdownChange}
                />
              </Form.Field>
            </Form.Group>
            <Form.Field>
              <Form.TextArea
                label="Details"
                name={"details"}
                placeholder="Tell us more"
                onChange={this.handleChange}
              />
            </Form.Field>
            {(Authentication.can("events:update") ||
              Authentication.can("events:create")) && (
              <Form.Field>
                <Form.TextArea
                  label="Private Notes"
                  name={"note"}
                  placeholder="Additional private notes relating to this event."
                  onChange={this.handleChange}
                  value={this.state.data.note}
                />
              </Form.Field>
            )}
            <Form.Field>
              <label>Allow sign up</label>
              <Checkbox
                style={{
                  textAlign: "center",
                  padding: 0.5 + "rem " + 2 + "rem",
                }}
                label="Allow Sign Up"
                checked={this.state.data.canSignUp}
                onChange={(e) =>
                  this.setState({
                    data: {
                      ...this.state.data,
                      canSignUp: !this.state.data.canSignUp,
                    },
                  })
                }
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="red"
            floated={"left"}
            onClick={() => this.setState({ eventModal: false })}
          >
            <Icon name="close" /> Cancel
          </Button>
          <Button color="green" onClick={() => this.handleAddEvent()}>
            <Icon name="plus" /> Add
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
  renderEventModal = () => {
    let event = this.state.event;
    let going = 0,
      notGoing = 0;
    let disabled = false;
    if (event.responses) {
      event.responses.forEach((response) => {
        if (response.response === "going") {
          going++;
        }
      });
      event.responses.forEach((response) => {
        if (response.response === "not going") {
          notGoing++;
        }
      });
    }
    if (event.start) {
      if (moment(event.start) < moment()) {
        disabled = true;
      }
    }

    return (
      <Modal
        open={this.state.eventShowModal}
        onClose={() =>
          this.setState({ eventShowModal: false, event: {}, respError: "" })
        }
        centered={false}
        closeOnDimmerClick={true}
        size="large"
      >
        <Header>{event.type}</Header>

        <Modal.Content className="no-pad">
          <Grid celled centered className="dataGrid">
            <Grid.Row>
              <Grid.Column
                textAlign="center"
                mobile={8}
                computer={4}
                className="no-pad"
              >
                <Header>Start</Header>

                <p>{moment(event.start).format("DD-MM-YYYY HH:mm")}</p>
              </Grid.Column>
              <Grid.Column
                textAlign="center"
                mobile={8}
                computer={4}
                className="no-pad"
              >
                <Header>End</Header>

                <p>{moment(event.end).format("DD-MM-YYYY HH:mm")}</p>
              </Grid.Column>
              <Grid.Column
                textAlign="center"
                mobile={8}
                computer={4}
                className="no-pad"
              >
                <Header>Type</Header>
                <Divider />
                <p>{event.type}</p>
              </Grid.Column>
              <Grid.Column
                textAlign="center"
                mobile={8}
                computer={4}
                className="no-pad"
              >
                <Header>Organiser</Header>
                <Divider />
                <p>
                  {event.organiser ? event.organiser.name : event.organiserName}
                </p>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column
                textAlign="center"
                mobile={8}
                computer={8}
                className="no-pad"
              >
                <Header>Title</Header>
                <Divider />
                <p>{event.title}</p>
              </Grid.Column>
              <Grid.Column
                textAlign="center"
                mobile={8}
                computer={8}
                className="no-pad"
              >
                <Header>Location</Header>
                <Divider />
                <p>
                  {event.location &&
                  event.location.geoJSON &&
                  event.location.geoJSON.properties &&
                  event.location.geoJSON.properties.name ? (
                    <Button
                      basic
                      inverted
                      onClick={() =>
                        this.goto(`/training/locations/${event.location._id}`)
                      }
                    >
                      <Icon name="globe" />
                      {event.location.geoJSON.properties.name}
                    </Button>
                  ) : (
                    "--"
                  )}
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column
                textAlign="center"
                mobile={16}
                computer={16}
                className="no-pad"
              >
                <Header>Details</Header>
                <Divider />
                <Header as={"h4"} style={{ color: "white !important" }}>
                  {event.details}
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column
                textAlign="center"
                mobile={16}
                computer={8}
                className="no-pad"
              >
                <Header>You're Response</Header>
                <Divider />
                <Grid stackable>
                  <Grid.Row columns={2}>
                    <Grid.Column>
                      <p>
                        <strong>Message:</strong>{" "}
                        {event.yourResponse && event.yourResponse.message}
                      </p>
                    </Grid.Column>
                    <Grid.Column mobile={16} computer={8}>
                      {event.yourResponse ? (
                        event.yourResponse.response === "going" ? (
                          <div>
                            <Header color="green">Going</Header>
                          </div>
                        ) : (
                          <div>
                            <Header color="orange">Not Going</Header>
                          </div>
                        )
                      ) : (
                        <div>
                          <Header color="orange">you havent responded</Header>
                        </div>
                      )}
                    </Grid.Column>
                  </Grid.Row>
                  <Divider />
                  <Grid.Row>
                    <Grid.Column mobile={16} computer={8}>
                      <TextArea
                        name="message"
                        onChange={this.handleMessageChange}
                        placeholder="message if required"
                        disabled={disabled}
                      />
                    </Grid.Column>
                    <Grid.Column mobile={16} computer={8}>
                      {event.yourResponse ? (
                        event.yourResponse.response === "going" ? (
                          <Button
                            onClick={() => this.setResponse("not going")}
                            negative
                            disabled={disabled}
                          >
                            Change to Not Going
                          </Button>
                        ) : (
                          <Button
                            onClick={() => this.setResponse("going")}
                            positive
                            disabled={disabled}
                          >
                            Change to Going
                          </Button>
                        )
                      ) : (
                        <div>
                          <Button
                            onClick={() => this.setResponse("going")}
                            positive
                            disabled={disabled}
                          >
                            Going
                          </Button>
                          <Button
                            onClick={() => this.setResponse("not going")}
                            negative
                            disabled={disabled}
                          >
                            Not Going
                          </Button>
                        </div>
                      )}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
              <Grid.Column
                textAlign="center"
                mobile={16}
                computer={8}
                className={"no-pad"}
              >
                {Authentication.can("events:update") && (
                  <Menu stackable>
                    <Menu.Item>
                      <p className="subtle">Event Menu</p>
                    </Menu.Item>
                    <Menu.Item
                      onClick={() => this.goto(`/calendar/${event._id}`)}
                    >
                      Manage
                    </Menu.Item>
                    <Menu.Item onClick={this.editEvent}>
                      <Icon name="pencil" />
                      Edit
                    </Menu.Item>
                  </Menu>
                )}
                <Segment basic>
                  <Header>Responses</Header>
                  <Divider />
                  <Statistic.Group widths={2}>
                    <Statistic>
                      <Statistic.Value>{going}</Statistic.Value>
                      <Statistic.Label>Going</Statistic.Label>
                    </Statistic>
                    <Statistic>
                      <Statistic.Value>{notGoing}</Statistic.Value>
                      <Statistic.Label>Not Going</Statistic.Label>
                    </Statistic>
                  </Statistic.Group>
                </Segment>
                {/* <Button primary onClick={() => this.goto(`/calendar/${event._id}`)}>
                  Manage
                </Button> */}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          {this.state.respError && (
            <Message error floating compact>
              {this.state.respError &&
                this.state.respError.response.data.message}
            </Message>
          )}
          <Button
            color="blue"
            onClick={() =>
              this.setState({ eventShowModal: false, respError: "" })
            }
          >
            <Icon name="close" /> Close
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };
  renderEditEventModal = () => {
    let event = this.state.event;

    // if (event.start) {
    //   if (moment(event.start) < moment()) {
    //     disabled = true;
    //   }
    // }
    return (
      <Modal
        open={this.state.eventEditModal}
        onClose={() =>
          this.setState({ eventEditModal: false, event: {}, respError: "" })
        }
        centered={false}
        closeOnDimmerClick={false}
        size="large"
      >
        <Header>{event.type}</Header>
        <Modal.Content className="no-pad">
          <Form>
            <Grid celled centered stackable className="dataGrid">
              <Grid.Row columns={4}>
                <Grid.Column textAlign="center">
                  <Header>Start</Header>
                  <Divider />
                  <Form.Field>
                    <DatePicker
                      onChange={(date) => {
                        this.setState({
                          data: {
                            ...this.state.data,
                            start: date,
                            end: date,
                          },
                        });
                      }}
                      selected={
                        this.state.data.start
                          ? new Date(this.state.data.start)
                          : ""
                      }
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      placeholderText="Start Date & Time"
                      timeInputLabel="Time:"
                      dateFormat="dd-MM-yy HH:mm"
                      nextMonthButtonLabel=">"
                      previousMonthButtonLabel="<"
                      data-disable-touch-keyboard
                    />

                    {/* <DateTimeInput
                      name="start"
                      animation="none"
                      dateTimeFormat="YYYY/MM/DD HH:mm"
                      closable={true}
                      disableMinute={true}
                      placeholder="Start Date & Time"
                      value={moment(this.state.data.start).format(
                        "YYYY/MM/DD HH:mm"
                      )}
                      iconPosition="left"
                      onChange={this.handleChange}
                    /> */}
                  </Form.Field>
                </Grid.Column>
                <Grid.Column textAlign="center">
                  <Header>End</Header>
                  <Divider />
                  <Form.Field>
                    <DatePicker
                      onChange={(date) => {
                        this.setState({
                          data: {
                            ...this.state.data,
                            end: date,
                          },
                        });
                      }}
                      selected={
                        this.state.data.end ? new Date(this.state.data.end) : ""
                      }
                      minDate={this.state.data.start}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      placeholderText="End Date & Time"
                      timeInputLabel="Time:"
                      dateFormat="dd-MM-yy HH:mm"
                      nextMonthButtonLabel=">"
                      previousMonthButtonLabel="<"
                      // className={errors.end ? "warning" : ""}
                      data-disable-touch-keyboard
                    />
                    {/* <DateTimeInput
                      name="end"
                      animation="none"
                      dateTimeFormat="YYYY/MM/DD HH:mm"
                      closable={true}
                      disableMinute={true}
                      placeholder="End Date & Time"
                      minDate={this.state.data.start}
                      value={moment(this.state.data.end).format(
                        "YYYY/MM/DD HH:mm"
                      )}
                      iconPosition="left"
                      onChange={this.handleChange}
                    /> */}
                  </Form.Field>
                </Grid.Column>
                <Grid.Column textAlign="center">
                  <Header>Type</Header>
                  <Divider />
                  <Form.Field>
                    <Select
                      placeholder={"pick one"}
                      name={"type"}
                      options={this.state.eventTypes}
                      value={this.state.data.type}
                      onChange={this.handleDropdownChange}
                    />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column textAlign="center">
                  <Header>Organiser</Header>
                  <Divider />
                  <Form.Field>
                    <Select
                      placeholder={"pick one"}
                      name={"organiserID"}
                      options={this.state.teamList}
                      value={this.state.data.organiserID}
                      onChange={this.handleDropdownChange}
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row columns={3}>
                <Grid.Column>
                  <Header>Title</Header>
                  <Divider />
                  <input
                    id="title"
                    name="title"
                    placeholder="title"
                    value={this.state.data.title}
                    onChange={this.onChange}
                    // className={warning.firstName ? "fieldWarning" : "good"}
                  />
                </Grid.Column>
                <Grid.Column textAlign="center">
                  <Header>Details</Header>
                  <Divider />
                  <TextArea
                    name={"details"}
                    value={this.state.data.details}
                    placeholder="Tell us more"
                    onChange={this.handleChange}
                  />
                </Grid.Column>

                <Grid.Column textAlign="center">
                  <Header>Location</Header>
                  <Divider />
                  <Select
                    placeholder={"pick one"}
                    name={"location"}
                    value={this.state.data.location}
                    options={this.state.locations}
                    onChange={this.handleDropdownChange}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="red"
            onClick={() =>
              this.setState({
                eventEditModal: false,
                eventShowModal: true,
                respError: "",
              })
            }
          >
            <Icon name="close" /> Cancel
          </Button>
          {this.state.respError && (
            <Message error floating compact>
              {this.state.respError &&
                this.state.respError.response.data.message}
            </Message>
          )}
          <Button color="green" onClick={this.handleEditEvent}>
            <Icon name="check" /> Update
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };
  addEventShow = () => {
    this.setState({
      eventModal: true,
    });
  };
  CustomToolbarCapsule = (props) => {
    return class CustomToolbarInstance extends CustomToolbar {
      static defaultProps = { ...props };
    };
  };

  renderDeletedEventMessage() {
    if (
      this.props.history.location.state !== undefined &&
      this.props.history.location.state !== null
    ) {
      if (this.props.history.location.state.eventRemoved !== undefined) {
        return (
          <Message positive>
            Successfully deleted the selected event from the database.
          </Message>
        );
      }
    }
    return null;
  }
  render() {
    const style = {
      borderRadius: 10,
      // opacity: 0.9,
      padding: "1rem",
    };

    const CustomEvent = (event) => {
      let org = "";
      if (event.event.organiserID === this.props.user.details.sub) {
        org = <Icon name="star" />;
      }

      return (
        <Popup
          inverted
          position="top center"
          size="small"
          style={style}
          trigger={
            <div>
              <strong>{moment(event.event.start).format("HH:mm")} </strong> -{" "}
              {event.title} {org}
            </div>
          }
        >
          <Segment>
            <Grid celled stackable className="no-marg popupGrid">
              <Grid.Row columns={1}>
                <Grid.Column textAlign="center">
                  <p className="boxHeader">{event.title}</p>
                  <p>{event.event.type}</p>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column textAlign="center">
                  <Header className="no-pad no-marg">Start</Header>

                  <p>{moment(event.event.start).format("HH:mm")}</p>
                </Grid.Column>
                <Grid.Column textAlign="center">
                  <Header className="no-pad no-marg">End</Header>

                  <p>{moment(event.event.end).format("HH:mm")}</p>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={1}>
                <Grid.Column textAlign="center">
                  <p>{event.event.details}</p>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={1}>
                <Grid.Column textAlign="center">
                  <p>
                    Location:{" "}
                    {event.event.location &&
                    event.event.location.geoJSON &&
                    event.event.location.geoJSON.properties
                      ? event.event.location.geoJSON.properties.name
                      : "--"}
                  </p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Popup>
      );
    };
    let canAdd = false;
    if (Authentication.can("events:create")) {
      canAdd = true;
    }

    return (
      <Segment loading={this.state.loading} basic>
        {this.renderAddEventModal()}
        {this.renderEventModal()}
        {this.renderEditEventModal()}
        <Grid>
          <Grid.Row>
            <Grid.Column computer={16}>
              {this.renderDeletedEventMessage()}
              <Segment className="calendarHolder" style={{ minHeight: "80vh" }}>
                <Calendar
                  views={["month", "week", "day"]}
                  step={60}
                  localizer={localizer}
                  events={this.state.events}
                  titleAccessor="title"
                  startAccessor={(event) => {
                    return moment(event.start).toDate();
                  }}
                  endAccessor={(event) => {
                    return moment(event.end).toDate();
                  }}
                  defaultDate={new Date()}
                  tooltipAccessor="details"
                  onSelectEvent={(e) => this.goto(e)}
                  eventPropGetter={this.eventStyleGetter}
                  components={{
                    event: CustomEvent,
                    toolbar: this.CustomToolbarCapsule({
                      ADD: this.addEventShow,
                      canAdd: canAdd,
                    }),
                  }}
                />
                <span className="key">
                  <Icon name="star" /> You are the event organiser.
                </span>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}
// const calendarPage = withTranslation()(CalendarPage);
export default connect(mapStateToProps, { getSystemSettings })(CalendarPage);
