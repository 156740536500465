const initialState = {
  details: {},
  loading: false,
  error: null
};
export function user(state = initialState, action = {}) {
  switch (action.type) {
    case "USER_GOT":
      return {
        ...state,
        loading: false,
        details: action.payload
      };
    case "USER_FALIURE":
      return {
        ...state,
        loading: false,
        error: action.payload,
        details: {}
      };

    default:
      return state;
  }
}
