import React from "react";
import { toast } from "react-semantic-toasts";
/*
CLIPBOARD BUTTON GUIDE

PARAMS (all required):
content = The text on the button
toClipboard = The array you are wanting to use in the clipboard copy
fieldToCopy = The field from the array you are wanting to copy

(OPTIONAL)
margin = DEFAULT 0. Amount of margin to add (using tailwindui markup)
width =  DEFAULT "full". Width of this component (using tailwindui markup)


*/
const checkInterval = 5;
export default function ReallyCoolButton(props) {
  return props.content !== undefined &&
    props.fieldToCopy !== undefined &&
    props.toClipboard !== undefined ? (
    <button
      type="button"
      className={`w-${props.width ? props.width : "full"} my-${
        props.margin ? props.margin : 0
      } px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600 hover:bg-gray-700`}
      onClick={() => {
        if (props.fieldToCopy && props.toClipboard) {
          navigator.clipboard.writeText(
            props.toClipboard.map((item) => item[props.fieldToCopy]).join(";")
          );
          console.log("exporting...");
          toast({
            type: "info",
            icon: "cloud download",
            title: "Copied",
            description: "Addresses copied to clipboard",
            time: 5000,
          });
        }
      }}
    >
      {props.icon && props.icon}
      {props.content}
    </button>
  ) : (
    <p>
      You have missconfigured this component, check for missing required params.
    </p>
  );
}
