import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import competencies from "./competencies";
import focusReport from "./focusReport";
import trainingDash from "./trainingDash";
import TrainingNav from "./trainingNav";
import CompPages from "./comps";
import AttendanceReport from "./attendanceReport";
import coursesReport from "./coursesReport";

export default class TrainingPages extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <TrainingNav match={this.props.match} />
        <Switch>
          <Route
            exact
            path={`/management/training/competency`}
            component={competencies}
          />
          <Route path="/management/training/comps" component={CompPages} />

          <Route
            exact
            path={`/management/training/focus`}
            component={focusReport}
          />
          <Route
            exact
            path={`/management/training/coursereport`}
            component={coursesReport}
          />
          <Route
            path={`/management/training/attendance`}
            component={AttendanceReport}
          />
          <Route exact path="" component={trainingDash} />
        </Switch>
      </React.Fragment>
    );
  }
}
