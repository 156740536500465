export default {
  circleLayout: { visibility: "visible" },
  circlePaint: {
    "circle-stroke-width": 3,
    "circle-radius": 8,
    "circle-blur": 0.15,
    "circle-stroke-color": "#eaa29b",
    "circle-color": ["match", ["get", "ipp"], 1, "red", 0, "purple", "purple"],
  },
  markerPaint: {
    "circle-stroke-width": 3,
    "circle-radius": 6,
    "circle-blur": 0.15,
    "circle-stroke-color": "blue",
    "circle-color": "yellow",
  },
  symbolLayout: {
    "text-field": "{member} ",
    "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
    "text-offset": [0, 0.6],
    "text-anchor": "top",
  },
  vehicleSymbolLayout: {
    "text-field": "{member} \n {vehicle}",
    "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
    "text-offset": [0, 0.6],
    "text-anchor": "top",
  },
  memberSymbolLayout: {
    "text-field": "{name} ",
    "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
    "text-offset": [0, 0.6],
    "text-anchor": "top",
  },
  symbolPaint: {
    "text-color": "black",
  },
  linePaint: {
    "line-width": 4,
    "line-opacity": 0.8,
    "line-color": "blue",
  },
  lineLayout: {
    "line-cap": "square",
    "line-join": "round",
  },
};
