import api from "./api";

export const updateCountry = data => dispatch => {
  dispatch({
    type: "UPDATE_COUNTRY",
    payload: data
  });
};
export const updateCounty = data => dispatch => {
  dispatch({
    type: "UPDATE_COUNTY",
    payload: data
  });
};
export const updateGridType = data => dispatch => {
  dispatch({
    type: "UPDATE_GRIDTYPE",
    payload: data
  });
};

export const getSystemSettings = () => dispatch => {
  return api.settings.all().then(system => {
    dispatch({
      type: "SYSTEM_SETTINGS_GOT",
      payload: system
    });
  });
};

export const settingsBegin = () => ({
  type: "SYSTEM_SETTINGS_BEGIN"
});
export const updateSetting = data => dispatch => {
  return api.settings.update(data).then(system => {
    dispatch({
      type: "SYSTEM_SETTINGS_UPDATING",
      payload: system
    });
    dispatch(getSystemSettings());
  });
};
