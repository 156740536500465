import { Authentication } from "@liquidcomputing/sarstuff__reactjs-singlesignon";
import dayjs from "dayjs";

class CommonFunctions {
  memberPermissions(member, user) {
    let permissions = {
      canUpdate: false,
      canUpdateOthers: false,
    };
    if (member.centralId === user.details.sub) {
      permissions = {
        canUpdate: true,
        canUpdateOthers: false,
      };
    } else if (Authentication.can("members:update:anyone")) {
      permissions = {
        canUpdate: true,
        canUpdateOthers: true,
      };
    } else {
      permissions = {
        canUpdate: false,
        canUpdateOthers: false,
      };
    }
    return permissions;
  }
  eventPermisions(user) {
    let permissions = {
      canUpdate: false,
      canUpdateOthers: false,
    };
    if (Authentication.can("events:update")) {
      permissions = {
        canUpdate: true,
        canUpdateOthers: true,
      };
    }
    return permissions;
  }
  recruitPermisions(user) {
    let permissions = {
      canUpdate: false,
    };
    if (Authentication.can("recruitment:update")) {
      permissions = {
        canUpdate: true,
      };
    }
    return permissions;
  }
  vehiclePermissions(user) {
    if (Authentication.can("vehicles:update:anyone")) {
      return {
        canUpdate: true,
        canUpdateOthers: true,
      };
    }
    if (Authentication.can("vehicles:update")) {
      return {
        canUpdate: true,
        canUpdateOthers: false,
      };
    }
    return {
      canUpdate: false,
      canUpdateOthers: false,
    };
  }
  getScoreColour = (colours, score) => {
    let settings = colours;
    let colour = "#222";
    if (score && settings) {
      colour = settings.filter((s) => {
        return s.content === score.toString();
      });
    }
    if (colour.length) {
      return colour[0].color;
    }
    return colour;
  };

  generateCSV(report_title, header_fields, body_data, pre_header_fields = []) {
    if (body_data && body_data.length !== 0) {
      let csv_rows = [];
      let csv_header = [];

      if (pre_header_fields) {
        pre_header_fields.forEach((item) => {
          csv_rows.push(item);
        });

        csv_rows.push("");
      }

      header_fields.forEach((item) => {
        csv_header.push(item.csv_field);
      });

      csv_rows.push(csv_header.join(","));

      body_data.forEach((item) => {
        csv_rows.push(
          header_fields
            .map((header) => {
              let clean_value = (
                item[header.database_field] === undefined
                  ? ""
                  : "" + item[header.database_field]
              ).replace(/"|'/g, "");
              return `"${clean_value}"`;
            })
            .join(",")
        );
      });

      let data_blob = new Blob([csv_rows.join("\n")], { type: "text/csv" });
      let a = document.createElement("a");

      a.setAttribute("hidden", "");
      a.setAttribute("href", window.URL.createObjectURL(data_blob));
      a.setAttribute(
        "download",
        `${report_title} - ${dayjs().format("YYYY-MM-DD")}.csv`
      );
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }
}
export default new CommonFunctions();
