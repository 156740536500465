import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import ManagmentNav from "./mangamentNav";
import ManagementDash from "./dash";
import suppliers from "./suppliers";
import addSupplier from "./addSupplier";
import supplier from "./supplier";
import VehicleDash from "./vehicleDash";
import Logs from "./logs";
import Team from "./team";
import IncidentPages from "./incidents";
import TrainingPages from "./training";

export default class ManagementPages extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <ManagmentNav match={this.props.match} />
        <Switch>
          {/*<Route exact path="/management" component={ManagementDash} />*/}
          <Route exact path="/management" component={Team} />
          <Route path="/management/team" component={Team} />
          <Route path="/management/incidents" component={IncidentPages} />
          <Route path="/management/training" component={TrainingPages} />
          <Route exact path="/management/vehicles" component={VehicleDash} />
          <Route exact path="/management/suppliers" component={suppliers} />
          <Route exact path="/management/logs" component={Logs} />
          <Route
            exact
            path="/management/suppliers/add"
            component={addSupplier}
          />
          <Route exact path="/management/suppliers/:id" component={supplier} />
          <Route
            exact
            path="/management/suppliers/add"
            component={addSupplier}
          />
        </Switch>
      </React.Fragment>
    );
  }
}
