import React, { useState, useEffect } from "react";
import { Loader } from "semantic-ui-react";
import api from "../../actions/api";
import dayjs from "dayjs";

export default function ContentsReport({ history }) {
  const [contents, setContents] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    api.assets.getContentReport().then((res) => {
      setContents(res);
      setLoading(false);
    });
  };
  // console.log(loading);
  return (
    <div className="w-full">
      <div className="p-2 text-2xl text-center">
        Asset Contents Expiry Report
      </div>
      {!loading ? (
        <table className="min-w-full divide-y divide-gray-200 border-separate table-auto">
          <thead>
            <tr className="bg-gray-700">
              <th className="px-4 py-3 text-left  font-medium text-gray-400 uppercase ">
                Qty
              </th>
              <th className="px-4 py-3 text-center  font-medium text-gray-400 uppercase ">
                Item
              </th>
              <th className="px-4 py-3 text-center  font-medium text-gray-400 uppercase ">
                Expiry Date
              </th>
              <th>Asset</th>
            </tr>
          </thead>
          <tbody className="bg-gray-900 divide-y divide-gray-200 text-lg">
            {contents.map((a) => {
              const expired = dayjs(a.expiryDate).isBefore(dayjs());
              return (
                <tr
                  key={a._id}
                  className={`${
                    expired ? "bg-red-900 bg-opacity-30" : "bg-gray-900"
                  } `}
                >
                  <td
                    className={`${
                      expired ? "bg-red-900 bg-opacity-30" : "bg-gray-900"
                    } w-20 text-center`}
                  >
                    {a.qty}
                  </td>

                  <td
                    className={`${
                      expired ? "bg-red-900 bg-opacity-30" : "bg-gray-900"
                    } p-2 `}
                  >
                    {a.item}
                  </td>
                  <td
                    className={`${
                      expired ? "bg-red-900 bg-opacity-30" : "bg-gray-900"
                    } p-2 `}
                  >
                    {a.expiryDate
                      ? dayjs(a.expiryDate).format("DD-MM-YYYY")
                      : "--"}
                  </td>
                  <td
                    onClick={() => history.push(`/assets/${a.asset._id}`)}
                    className={`${
                      expired ? "bg-red-700 bg-opacity-30" : "bg-gray-900"
                    } p-2 flex gap-2 cursor-pointer hover:bg-opacity-50`}
                  >
                    {a.asset.name} - {a.asset.assetNum}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <Loader active>Loading asset contents...</Loader>
      )}
    </div>
  );
}
