import React, { Component } from "react";
import { connect } from "react-redux";
import { getSystemSettings } from "../../../actions/settingsActions";
import {
  Header,
  Segment,
  Checkbox,
  Icon,
  Button,
  Grid,
  Modal,
  Form,
  Divider,
  Select,
  TextArea,
} from "semantic-ui-react";

import DatePicker from "react-datepicker";
import api from "../../../actions/api";
import InlineError from "../../common/forms/errorMessage.js";

import AddressCard from "../../common/cards/addressCard";
import { withRouter } from "react-router";
function mapStateToProps(state) {
  return {
    system: state.system,
    user: state.user,
  };
}

class AddCourse extends Component {
  state = {
    data: {
      start: "",
      end: "",
      title: "",
      type: "",
      validityLength: 0,
      isRequired: false,
      canSignUp: false,
      organiserID: "",
      organiserName: "",
      locationID: null,
      location: {},
      roles: [],
    },
    members: [],
    courses: [],
    locations: [],
    roles: [],
    courseTypes: [],
    loading: true,
    coursesLoading: true,
    errors: {},
    addressModal: false,
    showMap: false,
  };
  componentDidMount = () => {
    this.props
      .getSystemSettings()
      .then(() => {
        const data = this.props.system.settings.courses;
        let dataArray = [];
        for (var key in data) {
          dataArray.push({
            key: key,
            text: data[key].name,
            value: data[key].name,
            valid: data[key].valid,
            required: data[key].isRequired,
          });
        }
        this.updateCourseTypes(dataArray);
      })
      .then(() => {
        const data = this.props.system.settings.orgRoles;
        let dataArray = [];
        for (var key in data) {
          dataArray.push({
            key: key,
            text: data[key],
            value: data[key],
          });
        }
        this.updateRoles(dataArray);
      })
      .catch((err) => {
        console.log(err);
      });

    this.getData();
  };
  componentDidUpdate(prevProps, prevState) {}
  getData = () => {
    api.location.all().then((res) => {
      let locArray = [];
      const data = res;
      for (var key in data) {
        locArray.push({
          key: key,
          text: data[key].geoJSON.properties.name,
          value: data[key]._id,
        });
      }
      this.setState({
        locations: locArray,
      });
    });
    api.training.courses
      .all()
      .then((courses) => {
        this.setState({
          courses,
          coursesLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
    api.member
      .all()
      .then((members) => {
        let memArray = [];
        const data = members;
        for (var key in data) {
          memArray.push({
            key: key,
            text: data[key].name,
            value: data[key].name,
          });
        }
        this.setState({
          members: memArray,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  getLocation = (id) => {
    api.location.show(id).then((location) => {
      this.setState({
        data: {
          ...this.state.data,
          locationID: id,
          location: {
            geoJSON: location.geoJSON,
          },
        },
        showMap: true,
      });
    });
  };
  resetForm = () => {
    this.setState({
      data: {
        start: "",
        end: "",
        title: "",
        type: "",
        valid: 0,
        isRequired: false,
        organiserID: "",
        organiserName: "",
        locationID: null,
        location: {},
        roles: [],
        validityLength: 0,
      },
      errors: {},
    });
  };
  updateCourseTypes = (data) => {
    this.setState({ courseTypes: data });
  };
  updateRoles = (data) => {
    this.setState({ roles: data });
  };
  onChange = (e) =>
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  onCancel = () => {
    this.resetForm();
    this.props.cancel();
  };
  handleChange = (event, { name, value }) => {
    this.setState({
      data: {
        ...this.state.data,
        [name]: value,
      },
    });
  };
  handleDropdownChange = (props, e) => {
    this.setState({
      data: { ...this.state.data, [e.name]: e.value },
    });
  };
  handleLocationPicker = (props, e) => {
    this.getLocation(e.value);
    //
  };
  handleTypeChange = (props, e) => {
    this.setState(
      {
        data: {
          ...this.state.data,
          type: e.value,
        },
      },
      () => {
        this.state.courseTypes.forEach((course) => {
          if (course.text === this.state.data.type) {
            this.setState({
              data: {
                ...this.state.data,
                validityLength: course.valid,
                isRequired: course.required,
              },
            });
          }
        });
      }
    );
  };
  validate = (data) => {
    const errors = {};
    if (!data.title) errors.title = "Can't be empty";
    // if (!data.type) errors.type = "Can't be empty";
    if (!data.start) errors.start = "Can't be empty";
    if (!data.end) errors.end = "Can't be empty";
    // if (!data.email) errors.email = "Can't be empty";
    return errors;
  };
  updateAddress = (address) => {
    this.setState({
      data: {
        ...this.state.data,
        location: {
          geoJSON: address.geoJSON,
        },
      },
      showMap: true,
    });
  };
  handleAddCourse = () => {
    const errors = this.validate(this.state.data);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      let data = this.state.data;
      api.training.courses.add(data).then((res) => {
        this.goto(res._id);
      });
    }
  };
  goto = (id) => {
    this.props.history.push(`/training/courses/${id}`);
  };
  addressModal = () => {
    return (
      <Modal
        open={this.state.addressModal}
        onClose={() => this.setState({ addressModal: false })}
        centered={false}
        size="large"
        closeOnDimmerClick={false}
      >
        <Header icon="map pin" content="Add Location" />
        <Modal.Content>
          <AddressCard
            country={this.props.system.country}
            update={this.updateAddress}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button
            negative
            floated={"left"}
            onClick={() => this.setState({ addressModal: false })}
          >
            Cancel
          </Button>
          <Button
            color="green"
            onClick={() => this.setState({ addressModal: false })}
            inverted
          >
            <Icon name="save" />
            Add
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };
  renderAddForm() {
    const { errors, data } = this.state;
    return (
      <Segment className="subtle">
        <p className="boxHeader">Details</p>

        <Form>
          <Segment basic>
            <Form.Group widths="2">
              <Form.Field>
                <label htmlFor="title">Title</label>
                <input
                  id="title"
                  name="title"
                  placeholder="title"
                  value={data.title}
                  onChange={this.onChange}
                  className={errors.title ? "warning" : ""}
                />
                {errors.title && <InlineError text={errors.title} />}
              </Form.Field>
              <Form.Field>
                <label>Type</label>
                <Select
                  defaultValue={"--"}
                  clearable
                  placeholder={"pick one"}
                  name={"type"}
                  options={this.state.courseTypes}
                  onChange={this.handleTypeChange}
                  className={errors.title ? "warning" : ""}
                />
                {errors.type && <InlineError text={errors.type} />}
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field width={8}>
                <label htmlFor="title">Valid For in Years</label>
                <input
                  id="validityLength"
                  name="validityLength"
                  placeholder="valid"
                  type="number"
                  value={data.validityLength}
                  onChange={this.onChange}
                />
              </Form.Field>

              <Form.Field width={8}>
                <label>Roles earnt on completion</label>
                <Select
                  placeholder={"pick one"}
                  name={"roles"}
                  options={this.state.roles}
                  multiple
                  selection
                  onChange={this.handleDropdownChange}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="2">
              <Form.Field>
                <label>Start Date Time</label>
                <DatePicker
                  onChange={(date) => {
                    this.setState({
                      data: {
                        ...data,
                        start: date,
                      },
                    });
                  }}
                  selected={data.start ? new Date(data.start) : ""}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  // showTimeInput
                  // shouldCloseOnSelect={false}
                  timeInputLabel="Time:"
                  dateFormat="dd-MM-yy HH:mm"
                  nextMonthButtonLabel=">"
                  previousMonthButtonLabel="<"
                  className={errors.title ? "warning" : ""}
                  data-disable-touch-keyboard
                />
                {errors.start && <InlineError text={errors.start} />}
              </Form.Field>
              <Form.Field>
                <label>End Date Time</label>
                <DatePicker
                  onChange={(date) => {
                    this.setState({
                      data: {
                        ...data,
                        end: date,
                      },
                    });
                  }}
                  selected={data.end ? new Date(data.end) : ""}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  minDate={data.start ? new Date(data.start) : ""}
                  // showTimeInput
                  // shouldCloseOnSelect={false}
                  timeInputLabel="Time:"
                  dateFormat="dd-MM-yy HH:mm"
                  nextMonthButtonLabel=">"
                  previousMonthButtonLabel="<"
                  className={errors.title ? "warning" : ""}
                  data-disable-touch-keyboard
                />
                {errors.end && <InlineError text={errors.end} />}
              </Form.Field>
            </Form.Group>

            <Form.Field>
              <label>Details</label>
              <TextArea name="details" onChange={this.onChange} />
            </Form.Field>
            <Form.Group widths={2}>
              <Form.Field>
                <label>Allow sign up</label>
                <Checkbox
                  style={{
                    textAlign: "center",
                    padding: 0.5 + "rem " + 2 + "rem",
                  }}
                  label="Allow Sign Up"
                  checked={data.canSignUp}
                  onChange={(e) =>
                    this.setState({
                      data: {
                        ...this.state.data,
                        canSignUp: !data.canSignUp,
                      },
                    })
                  }
                />
              </Form.Field>
              <Form.Field>
                <label>A required course/competency</label>
                <Checkbox
                  style={{
                    textAlign: "center",
                    padding: 0.5 + "rem " + 2 + "rem",
                  }}
                  label=" A Required Course"
                  checked={data.isRequired}
                  onChange={(e) =>
                    this.setState({
                      data: {
                        ...this.state.data,
                        isRequired: !data.isRequired,
                      },
                    })
                  }
                />
              </Form.Field>
            </Form.Group>
          </Segment>

          <Segment basic>
            <p className="boxHeader">Organiser</p>

            <Grid relaxed="very" centered>
              <Grid.Row>
                <Grid.Column width={7}>
                  <Form.Field>
                    <label>External</label>
                    <input
                      id="organiserName"
                      name="organiserName"
                      placeholder="Organisers Name"
                      value={data.organiserName}
                      onChange={this.onChange}
                    />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column textAlign="center" width={2}>
                  <Header>OR</Header>
                </Grid.Column>
                <Grid.Column width={7}>
                  <Form.Field>
                    <label>Team Member</label>
                    <Select
                      placeholder={"pick one"}
                      name={"organiserName"}
                      options={this.state.members}
                      onChange={this.handleDropdownChange}
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>

          <Divider clearing />
          <Form.Group widths={"equal"} className={"form-actions"}>
            <Form.Field>
              <Button color="red" floated={"left"} onClick={this.onCancel}>
                <Icon name="close" /> Cancel
              </Button>
            </Form.Field>
            <Form.Field>
              <Button
                color="green"
                onClick={() => this.handleAddCourse()}
                floated="right"
              >
                <Icon name="plus" /> Add
              </Button>
            </Form.Field>
          </Form.Group>
        </Form>
      </Segment>
    );
  }
  renderLocation() {
    const { data } = this.state;
    return (
      <Segment className="subtle">
        <p className={"boxHeader"}>Location</p>
        <Segment basic>
          <Grid relaxed="very" centered>
            <Grid.Row>
              <Grid.Column textAlign="center" width={7}>
                <Select
                  placeholder={"pick one"}
                  name={"locationID"}
                  options={this.state.locations}
                  onChange={this.handleLocationPicker}
                />
              </Grid.Column>
              <Grid.Column textAlign="center" width={2}>
                <Header>OR</Header>
              </Grid.Column>
              <Grid.Column textAlign="center" width={7}>
                <Button
                  positive
                  onClick={() =>
                    this.setState({
                      addressModal: true,
                    })
                  }
                >
                  Add Course Location
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Divider />
          <Segment basic>
            {!data.location.geoJSON && <p>no location!</p>}
            {data.location.geoJSON && data.location.geoJSON.properties && (
              <Grid stackable>
                <Grid.Row>
                  <Grid.Column textAlign="left">
                    <Header>
                      Name: <span>{data.location.geoJSON.properties.name}</span>
                    </Header>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                  <Grid.Column textAlign="left">
                    <Header>
                      Town: <span>{data.location.geoJSON.properties.town}</span>
                    </Header>
                  </Grid.Column>
                  <Grid.Column textAlign="left">
                    <Header>
                      Postcode:
                      <span>{data.location.geoJSON.properties.postcode}</span>
                    </Header>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            )}
          </Segment>
        </Segment>
      </Segment>
    );
  }
  render() {
    return (
      <Grid className="no-marg">
        {this.addressModal()}
        <Grid.Row columns={2}>
          <Grid.Column>{this.renderAddForm()}</Grid.Column>
          <Grid.Column>{this.renderLocation()}</Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
export default withRouter(
  connect(mapStateToProps, { getSystemSettings })(AddCourse)
);
