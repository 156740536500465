import React, { Component } from "react";
import { connect } from "react-redux";
import { getSystemSettings } from "../../../../actions/settingsActions";
// import { DateInput } from "semantic-ui-calendar-react";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import {
  Segment,
  Button,
  Header,
  Grid,
  Form,
  Divider,
} from "semantic-ui-react";
import api from "../../../../actions/api";

function mapStateToProps(state) {
  return {
    system: state.system,
    user: state.user,
  };
}
class AddComp extends Component {
  state = {
    data: {
      sessionDate: "",
      sections: [],
    },
    comps: [],
    comp: {},
    section: {},
  };
  componentDidMount() {
    this.getData();
  }

  getData = () => {
    api.training.comps.all().then((comps) => {
      this.setState({
        comps,
      });
    });
  };
  handleDateChange = (event, { name, value }) => {
    this.setState({
      data: {
        ...this.state.data,
        [name]: value,
      },
    });
  };
  setComp = (c) => {
    this.setState({
      comp: c,
      data: {
        ...this.state.data,
        competency: c._id,
        type: c.subject,
        sections: [],
      },
    });
  };
  setSection = (s) => {
    let sections = this.state.data.sections;
    if (
      sections.filter((i) => {
        return i.section === s._id;
      }).length === 0
    ) {
      sections.push({
        section: s._id,
        title: s.title,
        criteria: s.criteria,
      });
    } else {
      sections = sections.filter((i) => {
        return i.section !== s._id;
      });
    }
    this.setState({
      section: s,
      data: {
        ...this.state.data,
        sections: sections,
      },
    });
  };
  addSession = () => {
    let data = this.state.data;
    api.training.comps.addSession(data).then((res) => {
      this.props.history.push(`/training/comps/${res._id}`);
    });
  };
  render() {
    const { comp, data } = this.state;
    return (
      <Segment className="no-pad" basic>
        <Form>
          <Grid celled className="no-marg">
            <Grid.Row>
              <Grid.Column width={16}>
                <Header as={"h1"} textAlign="center">
                  Competency Session Creator
                </Header>
                <Divider />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={3}>
                <Form.Field>
                  <label>Date of Session</label>
                  <DatePicker
                    onChange={(date) => {
                      this.setState({
                        data: {
                          ...this.state.data,
                          sessionDate: date,
                        },
                      });
                    }}
                    selected={
                      this.state.data.sessionDate
                        ? new Date(this.state.data.sessionDate)
                        : ""
                    }
                    // showTimeSelect
                    // timeFormat="HH:mm"
                    // timeIntervals={15}
                    placeholderText="Session Date"
                    // timeInputLabel="Time:"
                    dateFormat="dd-MM-yy"
                    nextMonthButtonLabel=">"
                    previousMonthButtonLabel="<"
                    data-disable-touch-keyboard
                  />
                  {/* <DateInput
                    autoComplete="off"
                    name="sessionDate"
                    startMode={"day"}
                    animation="none"
                    dateFormat={"YYYY-MM-DD"}
                    closable={true}
                    placeholder="Date"
                    value={data.sessionDate ? data.sessionDate : ""}
                    iconPosition="left"
                    onChange={this.handleDateChange}
                    localization="en_GB"
                  /> */}
                </Form.Field>
                <Form.Field>
                  <label>Session Type</label>
                  <Button.Group vertical inverted>
                    {this.state.comps.map((c) => {
                      return (
                        <Button
                          className="leftBtn"
                          fluid
                          size="large"
                          toggle
                          inverted
                          key={c._id}
                          onClick={() => this.setComp(c)}
                          active={comp._id === c._id}
                        >
                          {c.refNum} {c.subject}
                        </Button>
                      );
                    })}
                  </Button.Group>
                </Form.Field>
              </Grid.Column>
              <Grid.Column width={4}>
                <Button.Group vertical inverted fluid>
                  {comp.Sections &&
                    comp.Sections.map((s) => {
                      return (
                        <Button
                          className="leftBtn"
                          fluid
                          toggle
                          inverted
                          key={s._id}
                          onClick={() => this.setSection(s)}
                          active={
                            data.sections.filter((i) => i.section === s._id)
                              .length
                              ? true
                              : false
                          }
                        >
                          {comp.refNum}.{s.refNum} {s.title}
                        </Button>
                      );
                    })}
                </Button.Group>
              </Grid.Column>
              <Grid.Column width={9}>
                <Segment textAlign="center">
                  <Header as={"h2"}>
                    {data.sessionDate &&
                      dayjs(data.sessionDate).format("DD-MM-YYYY")}
                  </Header>
                  <Header as={"h1"}>{comp.subject}</Header>

                  {data.sections.map((s) => {
                    return (
                      <Header as={"h2"} key={s.section}>
                        {s.title}
                      </Header>
                    );
                  })}
                  <Divider />
                  {data.competency && data.sections.length && data.sessionDate && (
                    <Button positive onClick={this.addSession}>
                      Create Session
                    </Button>
                  )}
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Segment>
    );
  }
}
export default connect(mapStateToProps, { getSystemSettings })(AddComp);
