import React from "react";
import { Route, Switch } from "react-router-dom";
import AssetDash from "./dash";
import AssetNav from "./navBar";
import AssetSettings from "./settings";
import AddAsset from "./addAsset";
import "./tailwind.css";
import "react-datepicker/dist/react-datepicker.css";
import AssetList from "./asset_list";
import AssetPage from "./asset";
import EditAsset from "./asset/editAsset";
import SupplierPages from "./suppliers";
import AddService from "./asset/addService";
import UpdateService from "./asset/updateService";
import ServicePage from "./asset/service";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import ContentsReport from "./contents";

export default function AssetPages({ match }) {
  return (
    <div>
      <AssetNav match={match} />
      <Switch>
        <Route exact path="/assets" component={AssetDash} />
        <Route exact path="/assets/add" component={AddAsset} />
        <Route exact path="/assets/contents" component={ContentsReport} />
        <Route exact path="/assets/settings" component={AssetSettings} />
        <Route exact path="/assets/list" component={AssetList} />
        <Route path="/assets/suppliers" component={SupplierPages} />
        <Route exact path="/assets/:id" component={AssetPage} />
        <Route exact path="/assets/edit/:id" component={EditAsset} />
        <Route exact path="/assets/addservice/:id" component={AddService} />
        <Route exact path="/assets/:asset/service/:id" component={ServicePage} />
        <Route exact path="/assets/:asset/service/edit/:id" component={UpdateService} />
      </Switch>
    </div>
  );
}
