import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Menu, Header } from "semantic-ui-react";

function mapStateToProps(state) {
  return {
    user: state.user,
    system: state.system
  };
}

class RecruitmentNav extends React.Component {
  state = { activeItem: "home" };
  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name });
  };
  componentDidMount() {}
  render() {
    const { activeItem } = this.state;
    return (
      <Menu stackable className={"sub-menu"}>
        <Menu.Item>
          <Header as={"h3"} className={"subtle"}>
            Recruitment Menu
          </Header>
        </Menu.Item>

        <Menu.Item name={"dash"} as={NavLink} to={this.props.match.url + ""} active={activeItem === "dash"} exact onClick={this.handleItemClick}>
          Dash
        </Menu.Item>
        <Menu.Item name={"intakes"} as={NavLink} to={this.props.match.url + "/intakes"} active={activeItem === "intakes"} exact onClick={this.handleItemClick}>
          Intakes
        </Menu.Item>
        <Menu.Item name={"list"} as={NavLink} to={this.props.match.url + "/list"} active={activeItem === "list"} exact onClick={this.handleItemClick}>
          The List
        </Menu.Item>
        {/*<Menu.Item name={"weather"} as={NavLink} to={this.props.match.url + "/weather"} active={activeItem === "weather"} exact onClick={this.handleItemClick}>
          Weather
        </Menu.Item>
        <Menu.Item name={"contact"} as={NavLink} to={this.props.match.url + "/contact"} active={activeItem === "contact"} exact onClick={this.handleItemClick}>
          Contacts
        </Menu.Item> */}
        {/* <Menu.Item
          name={"contact"}
          as={NavLink}
          to={this.props.match.url + "/report"}
          active={activeItem === "contact"}
          exact
          onClick={this.handleItemClick}
        >
          Report
        </Menu.Item> */}
      </Menu>
    );
  }
}

export default connect(
  mapStateToProps,
  {}
)(RecruitmentNav);
