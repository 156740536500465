import React, {useEffect, useState} from "react";
import {Header, Image, Segment} from "semantic-ui-react";
import Blank from "../../../../assets/images/blank.png";
import AppConfig from "../../../../_appConfig";
import UpdatePhotoModal from "./UpdatePhotoModal";

const DisplayAndUpdatePhoto = ({onGetPhoto, onUpload, onGetUploadSpec, blankImage = Blank, canUpdate}) => {
  const [initialLoading, setInitialLoading]           = useState(true);
  const [showUpdateHover, setShowUpdateHover]         = useState(false);
  const [showUpdateImageForm, setShowUpdateImageForm] = useState(false);
  const [photoUrl, setPhotoUrl]                       = useState("no_photo");

  useEffect(() => {
    loadPhoto();
  }, [onGetPhoto]);

  const loadPhoto = () => {
    onGetPhoto().then(res => {
      setPhotoUrl(res.profileImage);
      setInitialLoading(false);
    }).catch(e => {

    });
  }

  console.log("canUpdate", canUpdate)

  return (
    <>
      {showUpdateImageForm && <UpdatePhotoModal onGetUploadSpec={onGetUploadSpec} onUpload={d => onUpload(d).then(a => {
        loadPhoto();
        return a;
      })} onClose={() => setShowUpdateImageForm(false)}/>}
      <Segment
        onMouseOver={() => setShowUpdateHover(true)}
        onMouseLeave={() => setShowUpdateHover(false)}
        basic
        className="no-pad no-marg"
        loading={initialLoading}
      >
        {showUpdateHover && canUpdate && (
          <div className="imageOverlay" onClick={() => setShowUpdateImageForm(true)}>
            <Header>Change photo</Header>
          </div>
        )}
        {photoUrl !== "no_photo" ? (
          <Image
            src={AppConfig.api_server + "/.." + photoUrl}
            centered
            className="photo"
            size="large"
            onClick={() => {
              if (canUpdate){
                setShowUpdateImageForm(true)
              }
            }}
          />
        ) : (
          <Image
            src={blankImage}
            centered
            className="photo"
            onClick={() => {
              if (canUpdate){
                setShowUpdateImageForm(true)
              }
            }}
          />
        )}
      </Segment>
    </>
  );
}
export default DisplayAndUpdatePhoto;