import React, { Component } from "react";
import { Dimmer, Loader } from "semantic-ui-react";
import PropTypes from "prop-types";
export default class TheLoader extends Component {
  render() {
    return (
      <Dimmer active={this.props.active}>
        <Loader content={this.props.content ? this.props.content : "loading..."} />
      </Dimmer>
    );
  }
}
TheLoader.propTypes = {
  active: PropTypes.bool.isRequired,
  content: PropTypes.string
};
