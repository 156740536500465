import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { getSystemSettings } from "../../../../actions/settingsActions";
import Blank from "../../../../assets/images/blank.png";
import dayjs from "dayjs";
import { toast } from "react-semantic-toasts";

import {
  Segment,
  Button,
  Image,
  Header,
  Grid,
  Form,
  Divider,
  Table,
  Sticky,
  Ref,
  Select,
  Input,
} from "semantic-ui-react";
import api from "../../../../actions/api";
import CommonFunctions from "../../../../lib/CommonFunctions";

function mapStateToProps(state) {
  return {
    system: state.system,
    user: state.user,
  };
}

class CompSession extends Component {
  state = {
    session: {},
    teamList: [],
    filtered: [],
    score: [],
    member: {},
    data: {
      _sections: {},
    },
  };
  contextRef = createRef();
  componentDidMount() {
    this.getData();
  }

  getData = () => {
    this.props
      .getSystemSettings()
      .then(() => {
        const data = this.props.system.settings.compScoreColours;
        let dataArray = [];
        for (var key in data) {
          dataArray.push({
            key: key,
            text: data[key].content,
            value: data[key].content,
            color: data[key].color,
          });
        }
        this.setState({
          score: dataArray,
        });
      })

      .catch((err) => {
        console.log(err);
      });
    api.member.all().then((res) => {
      this.setState({
        teamList: res,
        filtered: res,
        loading: false,
      });
    });
    api.training.comps
      .getASession(this.props.match.params.id)
      .then((session) => {
        this.setState({
          session,
        });
      });
  };
  handleScoreChange = (s) => {
    return (event, { value }) => {
      this.setState({
        data: {
          ...this.state.data,
          _sections: {
            ...this.state.data._sections,
            [s.section]: {
              ...(this.state.data._sections[s.section] || {}),
              section: s.section,
              title: s.title,
              score: value,
            },
          },
        },
      });
    };
  };
  setMember = (member) => {
    this.setState({
      member,
    });
  };
  filterList = (e) => {
    let updatedList = this.state.teamList;
    updatedList = updatedList.filter((item) => {
      return (
        item.name.toLowerCase().search(e.target.value.toLowerCase()) !== -1
      );
    });
    this.setState({ filtered: updatedList });
  };
  addScore = () => {
    let data = this.state.data;
    data.member = this.state.member._id;
    data.competency = this.state.session.competency;
    api.training.comps
      .addSessionScore(this.state.session._id, data)
      .then(() => {
        toast({
          type: "success",
          icon: "check",
          title: "Comp score added ",
          time: 2000,
        });
        this.getData();
        this.setState({
          member: {},
        });
      })
      .catch((err) => {})
      .finally(() => {});
  };
  renderMembers() {
    return this.state.filtered.map((item) => {
      return (
        <Table.Row
          key={item._id}
          active={item._id === this.state.member._id}
          positive={item.operational && item.onCall === true}
          warning={item.operational && item.onCall === false}
          negative={item.operational === false}
          onClick={() => this.setMember(item)}
        >
          <Table.Cell width="1">
            <Image src={item.photo ? item.photo : Blank} size="mini" />
          </Table.Cell>
          <Table.Cell width="2">{item.teamId}</Table.Cell>
          <Table.Cell>
            {item.name} {item.nickName && <i>({item.nickName})</i>}
          </Table.Cell>
        </Table.Row>
      );
    });
  }
  render() {
    const { session, member } = this.state;
    return (
      <Segment basic>
        <Ref innerRef={this.contextRef}>
          <Grid className="no-pad" stackable>
            <Grid.Row columns={3}>
              <Grid.Column width={5}>
                <Segment>
                  <Grid stackable className="no-marg ">
                    <Grid.Row columns={1}>
                      <Grid.Column className=" no-pad">
                        <Header as={"h1"} textAlign="center">
                          {session.type}
                        </Header>
                        <Divider />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1}>
                      <Grid.Column>
                        <Header as={"h3"}>
                          Date:
                          <span>
                            {dayjs(session.sessionDate).format("DD-MM-YYYY")}
                          </span>
                        </Header>
                      </Grid.Column>
                    </Grid.Row>
                    {session.sections &&
                      session.sections.map((s) => {
                        return (
                          <React.Fragment key={s._id}>
                            <Grid.Row>
                              <Grid.Column width={16}>
                                <Header as="h3">{s.title}</Header>
                              </Grid.Column>
                            </Grid.Row>
                            {s.criteria.map((c) => {
                              return (
                                <Grid.Row key={c._id}>
                                  <Grid.Column
                                    width={2}
                                    style={{
                                      background:
                                        CommonFunctions.getScoreColour(
                                          this.props.system.settings
                                            .compScoreColours,
                                          c.score
                                        ),
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Header style={{ color: "white" }}>
                                      {c.score}
                                    </Header>
                                  </Grid.Column>
                                  <Grid.Column width={14}>
                                    {c.details}
                                  </Grid.Column>
                                </Grid.Row>
                              );
                            })}
                            <Divider />
                          </React.Fragment>
                        );
                      })}
                  </Grid>
                </Segment>
              </Grid.Column>
              <Grid.Column width={6}>
                <Sticky context={this.contextRef}>
                  {member._id && (
                    <Segment>
                      <Header as={"h1"} textAlign="center">
                        {member.name}
                      </Header>
                      <Divider />
                      <Form>
                        {session.sections &&
                          session.sections.map((s) => {
                            return (
                              <Form.Group widths={2} key={s._id}>
                                <Form.Field width={10}>{s.title}</Form.Field>
                                <Form.Field width={6}>
                                  <Select
                                    style={{ minWidth: "2rem" }}
                                    options={this.state.score}
                                    name="score"
                                    onChange={this.handleScoreChange(s)}
                                  />
                                </Form.Field>
                              </Form.Group>
                            );
                          })}
                        <Divider />
                        <Form.Field width={16}>
                          <Button fluid positive onClick={this.addScore}>
                            Add
                          </Button>
                        </Form.Field>
                      </Form>
                    </Segment>
                  )}
                </Sticky>
              </Grid.Column>
              <Grid.Column width={5}>
                <Segment className="no-pad">
                  <Segment basic>
                    <Input
                      className="inputStyle"
                      autoComplete="off"
                      name="name"
                      fluid
                      placeholder="Please enter a name"
                      icon="search"
                      onChange={this.filterList}
                    />
                  </Segment>

                  <Table stackable selectable>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Photo</Table.HeaderCell>
                        <Table.HeaderCell>Unit ID</Table.HeaderCell>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>{this.renderMembers()}</Table.Body>
                  </Table>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Ref>
      </Segment>
    );
  }
}

export default connect(mapStateToProps, { getSystemSettings })(CompSession);
