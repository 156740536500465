import React, { Component } from "react";
import { Table, Header, Segment, Statistic } from "semantic-ui-react";
import API from "../../../actions/api";

export default class AttendanceReport extends Component {
  state = {
    loading: true,

    data: {
      dash: [],
      eventTypes: [],
      members: [],
    },
  };

  componentDidMount() {
    this._getData();
  }

  _getData = () => {
    API.management.attendanceReport().then((data) => {
      this.setState({
        data,
        loading: false,
      });
    });
  };

  renderData() {
    const scores = this.state.data.members.map((s) => {
      return (
        <Table.Row key={s._id}>
          <Table.Cell
            onClick={() => this.props.history.push(`/team/${s._id}`)}
            selectable
            style={{ paddingLeft: "1rem" }}
          >
            <Header>{s.firstName + " " + s.lastName}</Header>
          </Table.Cell>

          {this.state.data.eventTypes.map((e, i) => {
            const value = s._results[e.content];
            return (
              <Table.Cell
                key={i}
                textAlign="center"
                style={{ fontSize: "1.2rem" }}
              >
                {value === undefined ? 0 : value}
              </Table.Cell>
            );
          })}
        </Table.Row>
      );
    });

    return scores;
  }
  renderTotals() {
    return (
      <Segment basic>
        <Header textAlign="center">Event Type Totals</Header>
        <Statistic.Group widths={4}>
          {this.state.data.dash.map((d, i) => {
            return (
              <Statistic key={i}>
                <Statistic.Value>{d.count}</Statistic.Value>
                <Statistic.Label>{d.name}</Statistic.Label>
              </Statistic>
            );
          })}
        </Statistic.Group>
      </Segment>
    );
  }
  render() {
    const { loading } = this.state;

    return (
      <Segment basic>
        <Segment className="no-pad">
          {this.state.data.dash.length ? this.renderTotals() : <></>}
          <Table celled>
            <Table.Header style={{ position: "sticky", top: "0" }}>
              <Table.Row>
                <Table.HeaderCell>
                  <Header>Member</Header>
                </Table.HeaderCell>
                {this.state.data.eventTypes.map((e, i) => {
                  return (
                    <Table.HeaderCell
                      key={i}
                      textAlign="center"
                      style={{ background: e.color }}
                    >
                      <Header style={{ color: "white" }}>{e.content}</Header>
                    </Table.HeaderCell>
                  );
                })}
                {/* <Table.HeaderCell textAlign="center">%</Table.HeaderCell> */}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {!loading ? (
                this.renderData()
              ) : (
                <Table.Row>
                  <Table.Cell colSpan={this.state.data.eventTypes.length + 1}>
                    Loading....
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </Segment>
      </Segment>
    );
  }
}
