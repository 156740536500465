import React, { Component } from "react";
import {
  Segment,
  Header,
  Input,
  Menu,
  Icon,
  Button,
  Grid,
} from "semantic-ui-react";
import { connect } from "react-redux";
import { getSystemSettings } from "../../actions/settingsActions";
import api from "../../actions/api";

import SupplierList from "../common/lists/supplierList";

function mapStateToProps(state) {
  return {
    user: state.user,
    system: state.system,
  };
}

class SuppliersPage extends Component {
  state = {
    loading: true,
    filter: "",
    filtered: [],
    teamList: [],
  };
  componentDidMount() {
    this._getData();
    this.props.getSystemSettings();
  }

  _getData = () => {
    api.management.suppliers.all().then((res) => {
      this.setState({
        teamList: res,
        filtered: res,
        loading: false,
      });
    });
  };
  goto = (id) => {
    this.props.history.push(`/management/suppliers/${id}`);
  };
  filterBy = (data) => {
    if (data) {
      if (data === this.state.filter) {
        data = "";
      }
      this.setState(
        {
          filter: data,
        },
        () => {
          if (this.state.filter !== "") {
            let list = this.state.teamList;
            list = list.filter((item) => {
              let hasRoles = item.type.filter((t) => {
                return t === this.state.filter;
              });
              return hasRoles.length >= 1;
            });
            this.setState({
              filtered: list,
            });
          } else {
            this.setState({
              filtered: this.state.teamList,
            });
          }
        }
      );
    }
  };
  filterList = (e) => {
    let updatedList = this.state.teamList;
    updatedList = updatedList.filter((item) => {
      return (
        item.name.toLowerCase().search(e.target.value.toLowerCase()) !== -1
      );
    });
    this.setState({ filtered: updatedList });
  };
  renderMenu() {
    return (
      <Menu stackable>
        <Menu.Item>
          <Header className="subtle">Supplier Menu</Header>
        </Menu.Item>
        <Menu.Item
          onClick={() => this.props.history.push(`/management/suppliers/add`)}
        >
          <Icon name="plus" color={"green"} />
          Add
        </Menu.Item>
        {/* <Menu.Item onClick={() => this.setState({ showMembers: true })}>
          Show Members
        </Menu.Item>
        <Menu.Item>Mark All Competent</Menu.Item>
        <Menu.Item></Menu.Item> */}
      </Menu>
    );
  }
  render() {
    let disabled = false;
    if (this.state.filter !== "") {
      disabled = true;
    }

    return (
      <Grid stackable className="no-marg">
        <Grid.Row className="no-pad">
          <Grid.Column width={16} className="no-pad">
            {this.renderMenu()}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={6}>
            <Input
              disabled={disabled}
              placeholder="Search by name..."
              onChange={this.filterList}
              size="large"
              style={{ width: 100 + "%" }}
            />
            <Segment className="roleButtons">
              {this.props.system.settings.supplierTypes !== undefined
                ? this.props.system.settings.supplierTypes
                    .sort()
                    .map((data) => {
                      return (
                        <Button
                          key={data}
                          size="tiny"
                          toggle
                          active={this.state.filter === data}
                          onClick={() => this.filterBy(data)}
                        >
                          {data}
                        </Button>
                      );
                    })
                : "no supplier types"}
              {this.state.filter !== "" && (
                <Button
                  negative
                  size="tiny"
                  onClick={() => this.setState({ filter: "" }, this._getData())}
                >
                  clear
                </Button>
              )}
            </Segment>
          </Grid.Column>
          <Grid.Column width={10}>
            <SupplierList data={this.state.filtered} action={this.goto} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default connect(mapStateToProps, {
  getSystemSettings,
})(SuppliersPage);
