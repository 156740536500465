import React, { Component } from "react";
import { connect } from "react-redux";
import { getSystemSettings } from "../../actions/settingsActions";
import api from "../../actions/api";
import { Segment, Header, Grid } from "semantic-ui-react";
import CompCard from "../common/cards/compCard";

function mapStateToProps(state) {
  return {
    system: state.system,
    user: state.user,
  };
}

class TestPage extends Component {
  state = {
    user: {},
    comps: [],
  };
  componentDidMount() {
    this.props.getSystemSettings();
    this.getData();
  }
  getData = () => {
    api.training.comps.all().then((comps) => {
      this.setState({
        comps,
      });
    });
    api.member
      .show(this.props.match.params.id)
      .then((res) => {
        this.setState({
          user: res,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  renderComps() {
    return this.state.comps.map((item, i) => {
      return (
        <CompCard key={item._id} comp={item} member={this.state.user._id} />
      );
    });
  }
  render() {
    return (
      <Segment>
        <Header>Member Comps</Header>
        <Grid stackable>
          <Grid.Row columns={4} style={{ padding: 10 }} stretched>
            {this.renderComps()}
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}
export default connect(mapStateToProps, { getSystemSettings })(TestPage);
