import React, { useState, useEffect, Fragment } from "react";
import Layout from "../ui-wrappers/Layout";
import Box from "../ui-wrappers/Box";
import Input from "../ui-wrappers/Input";
import Select from "../ui-wrappers/Select";
import DatePicker from "react-date-picker";
import api from "../../../actions/api";
import dayjs from "dayjs";
import { Transition, Dialog, Listbox } from "@headlessui/react";

import { SelectorIcon, CheckIcon } from "@heroicons/react/outline";
import Button from "../ui-wrappers/Button";
export default function UpdateService({ match, history }) {
  const [asset, setAsset] = useState({});
  const [add, setAdd] = useState(true);
  const [part, setPart] = useState({
    qty: "",
    item: "",
    cost: "",
  });
  const [service, setService] = useState({
    type: "",
    date_scheduled: "",
    date_serviced: "",
    notes: "",
    status: "",
    replacedParts: [],
    supplier: "",
    asset: "",
    partsCost: "",
    labourCost: "",
    totalCost: "",
  });
  const [suppliers, setSuppliers] = useState([]);
  const [selected, setSelected] = useState({
    name: "",
    _id: "",
  });
  useEffect(() => {
    api.assets.show(match.params.asset).then((res) => {
      setAsset({
        ...asset,
        ...res,
      });
    });
    api.assets.service.show(match.params.asset, match.params.id).then((res) => {
      setService({
        ...service,
        ...res,
      });
      if (res.supplier) {
        setSelected(res.supplier);
      }
    });
    api.management.suppliers.all().then((res) => {
      setSuppliers(res);
    });
  }, []);
  const addPart = () => {
    console.log(part);
    setService({
      ...service,
      replacedParts: service.replacedParts.concat(part),
    });
    setPart({ qty: "", item: "", cost: "" });
  };
  const updateService = () => {
    let data = service;
    data.asset = asset._id;
    data.supplier = selected._id;
    console.log(data);
    api.assets.service.update(asset._id, service._id, data).then((res) => {
      history.push(`/assets/${asset._id}/service/${service._id}`);
    });
  };
  const deletePart = (part) => {
    let parts = service.replacedParts.filter((p) => {
      return p != part;
    });
    setService({
      ...service,
      replacedParts: parts,
    });
  };
  const onServiceChange = (e) => {
    setService({ ...service, [e.target.name]: e.target.value });
  };
  console.log(service);
  const combinedCost =
    parseFloat(service.partsCost) + parseFloat(service.labourCost);
  return (
    <Layout full>
      <div className="text-2xl text-center mb-4">
        {/* <Box> */}
        <div className="flex flex-row gap-4 p-2 w-full">
          <div
            className="flex flex-col text-center w-1/5 border border-blue-900 text-yellow-700 cursor-pointer hover:bg-gray-700 rounded-lg"
            onClick={() => history.push(`/assets/${asset._id}`)}
          >
            Name
            <span className="text-white">{asset.name}</span>
          </div>
          <div className="flex flex-col text-center w-1/5 border border-gray-700 text-yellow-700 rounded-lg">
            Asset Num
            <span className="text-white">{asset.assetNum}</span>
          </div>
          <div className="flex flex-col text-center w-1/5 border border-gray-700 text-yellow-700 rounded-lg">
            Service Interval
            <span className="text-white">{asset.serviceInterval}</span>
          </div>
          <div className="flex flex-col text-center w-1/5 border border-gray-700 text-yellow-700 rounded-lg">
            Last Service
            <span className="text-white">
              {asset.LastService ? asset.LastService : "--"}
            </span>
          </div>
          <div className="flex flex-col text-center w-1/5 border border-gray-700 text-yellow-700 rounded-lg">
            Next Service
            <span className="text-white">
              {asset.NextService ? asset.NextService : "--"}
            </span>
          </div>
        </div>
        {/* </Box> */}
        {/* Adding Service for {asset.name}{" "}
        <span className={"italic"}>{asset.assetNum}</span> */}
      </div>
      <div className="grid grid-cols-2 gap-4">
        <div className="col-span-1">
          <Box title="Add Service ">
            <div className="p-2">
              <div className="flex gap-4 justify-around">
                <Input
                  label={"Service Type"}
                  field="type"
                  type={"text"}
                  value={service.type}
                  onChange={onServiceChange}
                  placeholder="Annual service"
                />
                <div className="py-2 ">
                  <label
                    className={`pb-1 block text-lg font-medium text-yellow-700 `}
                  >
                    Date Scheduled
                  </label>
                  <DatePicker
                    name="date_scheduled"
                    onChange={(date) =>
                      setService({
                        ...service,
                        date_scheduled: date,
                      })
                    }
                    className="z-50 h-12 rounded-lg"
                    value={service.date_scheduled}
                  />
                </div>
                <div className="py-2 ">
                  <label
                    className={`pb-1 block text-lg font-medium text-yellow-700 `}
                  >
                    Date Serviced
                  </label>
                  <DatePicker
                    name="date_serviced"
                    onChange={(date) =>
                      setService({
                        ...service,
                        date_serviced: date,
                      })
                    }
                    maxDate={new Date()}
                    className="z-50 h-12 rounded-lg"
                    value={service.date_serviced}
                  />
                </div>
              </div>
              <div className="flex flex-col justify-center ">
                <label
                  className={`pt-4 block text-md font-medium font-mono text-yellow-700 `}
                >
                  Supplier
                </label>

                <Listbox value={selected} onChange={(e) => setSelected(e)}>
                  <div className="relative mt-1 ">
                    <Listbox.Button className="border-2 border-gray-700 relative w-full h-12  text-left bg-gray-900 rounded-lg shadow-md cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-lg">
                      <span className="block truncate p-2 text-lg">
                        {selected.name}
                      </span>
                      <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <SelectorIcon
                          className="w-5 h-5 text-white"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>
                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute w-full py-2 mt-1 overflow-auto text-base bg-gray-800 rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {suppliers.map((person, personIdx) => (
                          <Listbox.Option
                            key={personIdx}
                            className={({ active }) =>
                              `${
                                active
                                  ? "text-amber-900 bg-amber-100"
                                  : "text-white"
                              }
                          cursor-default border-b border-gray-700 hover:bg-gray-700 select-none relative py-2 pl-10 pr-4 text-lg`
                            }
                            value={person}
                          >
                            {({ selected, active }) => (
                              <>
                                <span
                                  className={`${
                                    selected ? "font-medium" : "font-normal"
                                  } block truncate `}
                                >
                                  {person.name}
                                </span>
                                {selected ? (
                                  <span
                                    className={`${
                                      active
                                        ? "text-amber-600"
                                        : "text-amber-600"
                                    }
                                absolute inset-y-0 left-0 flex items-center pl-3`}
                                  >
                                    <CheckIcon
                                      className="w-5 h-5 text-yellow-600"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </Listbox>
              </div>

              <div className="flex gap-4 justify-around">
                <div className=" w-full">
                  <Input
                    label={"Parts Cost"}
                    field="partsCost"
                    type={"number"}
                    value={service.partsCost ? service.partsCost : ""}
                    onChange={onServiceChange}
                    placeholder="100"
                  />
                </div>
                <div className=" w-full">
                  <Input
                    label={"Labour Cost"}
                    field="labourCost"
                    type={"number"}
                    value={service.labourCost ? service.labourCost : ""}
                    onChange={onServiceChange}
                    placeholder="100"
                  />
                </div>
                <div className=" w-full">
                  <Input
                    label={"Total Cost"}
                    field="totalCost"
                    type={"number"}
                    value={service.totalCost ? service.totalCost : ""}
                    onChange={onServiceChange}
                    placeholder="100"
                  />

                  <span className="italic p-2 text-gray-400">
                    (parts plus labour ={" "}
                    {isNaN(combinedCost) ? 0 : combinedCost})
                  </span>
                </div>
              </div>
              {/* <div className="flex gap-4 justify-around">
                <Select
                  label="Status"
                  field="status"
                  onChange={onServiceChange}
                  options={[
                    { key: 1, text: "scheduled", value: "scheduled" },
                    { key: 2, text: "pass", value: "pass" },
                    { key: 3, text: "fail", value: "fail" },
                  ]}
                />
              </div> */}
              <div className="flex gap-4 justify-around">
                <div className=" w-full">
                  <label
                    className={`py-2 block text-md font-medium font-mono text-yellow-700 `}
                  >
                    Notes
                  </label>
                  <textarea
                    rows={3}
                    name="notes"
                    value={service.notes}
                    onChange={onServiceChange}
                    className={`p-2 bg-gray-900 mt-1 focus:ring-gray-500 focus:border-gray-500 placeholder-gray-600 block w-full shadow-sm text-xl border-gray-600 rounded-md`}
                  />
                </div>
              </div>
              <div className="flex gap-4 justify-between p-4">
                <Button
                  negative
                  text="Cancel"
                  action={() =>
                    history.push(`/assets/${asset._id}/service/${service._id}`)
                  }
                />
                <Button positive text="Update Service" action={updateService} />
              </div>
            </div>
          </Box>
        </div>
        <div className="col-span-1 ">
          {/*<Box title="Add Service Documents">*/}
          {/*  <div className="text-center text-2xl p-4">*/}
          {/*    TODO: Tim Add uploader*/}
          {/*  </div>*/}
          {/*</Box>*/}
          <div className="p-2"></div>
          <Box title="Replaced Parts List">
            <div className="p-2 mt-2">
              <div className="flex justify-arround gap-2 p-2">
                <div className="flex flex-col w-1/6 text-center">
                  <label
                    className={`pb-1 block text-lg font-medium text-yellow-700 `}
                  >
                    Qty
                  </label>
                  <input
                    autoComplete="off"
                    onChange={(e) =>
                      setPart({
                        ...part,
                        qty: e.target.value,
                      })
                    }
                    value={part.qty}
                    name="qty"
                    placeholder="qty"
                    className="  py-2 px-2 text-lg  border-2 focus:ring-gray-600 focus:border-gray-500 placeholder-gray-600 block  shadow-sm  border-gray-600 bg-gray-900  rounded-lg "
                  />
                </div>
                <div className="flex flex-col w-4/6 text-center">
                  <label
                    className={`pb-1 block text-lg font-medium text-yellow-700 `}
                  >
                    Item
                  </label>
                  <input
                    autoComplete="off"
                    onChange={(e) =>
                      setPart({
                        ...part,
                        item: e.target.value,
                      })
                    }
                    value={part.item}
                    name="item"
                    placeholder="item"
                    className="py-2 px-2 text-lg  border-2 focus:ring-gray-600 focus:border-gray-500 placeholder-gray-600 block w-full shadow-sm  border-gray-600 bg-gray-900  rounded-lg "
                  />
                </div>
                <div className="flex flex-col  text-center">
                  <label
                    className={`pb-1 block text-lg font-medium text-yellow-700 `}
                  >
                    Total Cost
                  </label>
                  <input
                    autoComplete="off"
                    onChange={(e) =>
                      setPart({
                        ...part,
                        cost: e.target.value,
                      })
                    }
                    value={part.cost}
                    name="item"
                    placeholder="cost"
                    className="py-2 px-2 text-lg  border-2 focus:ring-gray-600 focus:border-gray-500 placeholder-gray-600 block w-full shadow-sm  border-gray-600 bg-gray-900  rounded-lg "
                  />
                </div>
                <div className="flex pt-8 items-middle">
                  <button
                    onClick={addPart}
                    className="py-2 px-4 border border-gray-700 rounded-lg bg-green-600"
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
            <div className="mt-4">
              <table className="min-w-full divide-y divide-gray-200 border-separate table-fixed">
                <thead>
                  <tr className="bg-gray-800">
                    <th className="px-4 py-3 text-left text-xs font-medium text-gray-400 uppercase ">
                      Qty
                    </th>

                    <th className="px-4 py-3 text-center text-xs font-medium text-gray-400 uppercase ">
                      Item
                    </th>
                    <th className="px-4 py-3 text-center text-xs font-medium text-gray-400 uppercase ">
                      Cost
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className="bg-gray-900 divide-y divide-gray-200 text-lg">
                  {service.replacedParts.map((a, i) => {
                    return (
                      <tr key={i}>
                        <td className="p-2 ">{a.qty}</td>

                        <td className="p-2 w-4/6">{a.item}</td>
                        <td className="p-2 w-1/6">{a.cost}</td>
                        <td className="text-right p-2 ">
                          <button
                            className="bg-red-800 py-2 px-4 border rounded-lg"
                            onClick={() => deletePart(a)}
                          >
                            X
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Box>
        </div>
      </div>
    </Layout>
  );
}
