import React, { Component } from "react";
import ReactMapboxGl, {
  ZoomControl,
  ScaleControl,
  GeoJSONLayer,
} from "react-mapbox-gl";
import { Button, Popup as ToolTip, Icon } from "semantic-ui-react";
import * as turf from "@turf/turf";
import Default_Styles from "../styles/mainStyles";
const { token, styles } = require("../config.json");
const Map = ReactMapboxGl({
  accessToken: token,
  preserveDrawingBuffer: true,
});
const selectedStyles = ["outdoor", "sat"];
const switchStyles = Object.keys(styles).filter((k) =>
  selectedStyles.includes(k)
);
// const RASTER_SOURCE_OPTIONS = {
//   type: "raster",
//   // tiles: ["https://ecn.t0.tiles.virtualearth.net/tiles/a{quadkey}.jpeg?g=587&mkt=en-gb&n=z"],
//   tiles: [
//     "https://ecn.t0.tiles.virtualearth.net/tiles/r{quadkey}?g=7080&lbl=l1&productSet=mmOS&key=AoMKVVkBRY3_8gv8UJmlIHXp8DCIGGzv9Yw_50Q4WIqC5ujgtVszQK-xLpB34hF2"
//   ],
//   // tiles: ["https://dev.virtualearth.net/REST/v1/Imagery/Metadata/Aerial?key=AoMKVVkBRY3_8gv8UJmlIHXp8DCIGGzv9Yw_50Q4WIqC5ujgtVszQK-xLpB34hF2"],
//   tileSize: 256,
//   maxzoom: 16,
//   minzoom: 12
// };
export default class UserMap extends Component {
  state = {
    styleKey: "outdoor",

    marker: {},
    loading: true,
  };
  componentDidMount() {
    if (this.props.marker) {
      this.setState({
        marker: this.props.marker,
        loading: false,
        center:
          this.props.marker.geometry && this.props.marker.geometry.coordinates,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.marker !== prevProps.marker) {
      this.setState({
        marker: this.props.marker,
        loading: false,
      });
    }
  }

  onStyleLoad = (map) => {
    const { onStyleLoad } = this.props;
    this.setState({
      loading: false,
    });
    return onStyleLoad && onStyleLoad(map);
  };
  nextStyle = () => {
    const { styleKey } = this.state;
    const currentIndex = switchStyles.indexOf(styleKey);
    const nextIndex =
      currentIndex === switchStyles.length - 1 ? 0 : currentIndex + 1;
    this.setState({
      styleKey: switchStyles[nextIndex],
    });
  };
  resetMap = () => {
    setTimeout(() => {
      this.mapBounds();
    }, 500);
    return this.map.state.map.flyTo({
      center: this.props.center,
      zoom: 14,
      bearing: 0,
      pitch: 0,
    });
  };
  onClickMap = (map, evt) => {
    if (evt.lngLat) {
      let coords = [
        parseFloat(evt.lngLat.lng.toFixed(5)),
        parseFloat(evt.lngLat.lat.toFixed(5)),
      ];
      if (coords.length && this.props.data) {
        this.props.data(coords);
      }
    }
  };
  mapHack = () => {
    this.setState(
      {
        reload: false,
      },
      () =>
        this.setState(
          {
            os: !this.state.os,
          },
          () => {
            this.setState({
              reload: true,
            });
          }
        )
    );
  };
  mapBounds = () => {
    let data = [];
    if (this.map) {
      if (this.props.locations && this.props.locations.length) {
        this.props.locations.map((loc, i) => {
          if (loc.geoJSON) {
            if (loc.geoJSON.geometry) {
              data.push(loc.geoJSON.geometry.coordinates);
            }
          }
          return null;
        });
        let list = turf.multiPoint(data);
        let bbox = turf.bbox(list);
        this.map.state.map.fitBounds(bbox, { padding: 180, animate: false });
      }
    }
  };

  renderMarker() {
    if (this.map && !this.state.loading) {
      if (
        this.map.state.map &&
        this.state.marker.geometry.coordinates &&
        this.state.marker.geometry.coordinates.length
      ) {
        // this.map.state.map.panTo({ lng: this.state.marker.geometry.coordinates[0], lat: this.state.marker.geometry.coordinates[1] });
        return (
          <GeoJSONLayer
            id="marker"
            data={this.state.marker}
            circleLayout={Default_Styles.circleLayout}
            circlePaint={Default_Styles.markerPaint}
            symbolLayout={Default_Styles.memberSymbolLayout}
            symbolPaint={Default_Styles.symbolPaint}
          />
        );
      }
    }
  }
  renderData = (data) => {
    let features = data
      .filter((loc) => {
        if (loc.geoJSON.geometry.coordinates.length) {
          return true;
        }
        return false;
      })
      .map((loc) => {
        return loc.geoJSON;
      });
    return {
      type: "FeatureCollection",
      features: features,
    };
  };
  //   renderLocations() {
  //     let locs = this.renderData(this.props.locations);
  //     return (
  //       <GeoJSONLayer
  //         id="locations"
  //         data={locs}
  //         circleLayout={circleLayout}
  //         circlePaint={circlePaint}
  //         circleOnClick={this.onClickCircle}
  //         symbolLayout={symbolLayout}
  //         symbolPaint={symbolPaint}
  //       />
  //     );
  //   }
  renderMapBar() {
    return (
      <div className="mapBar">
        <ToolTip
          trigger={
            <Button icon onClick={(e) => this.resetMap(e)} circular>
              <Icon color="orange" name="compass" />
            </Button>
          }
          content="reset bearing and pitch"
          basic
        />
        <ToolTip
          trigger={
            <Button icon onClick={(e) => this.nextStyle(e)} circular>
              <Icon name="world" />
            </Button>
          }
          content="Change Map Type"
          basic
        />
        {/* <ToolTip
          trigger={
            <Button
              icon
              onClick={() => this.mapHack()}
              circular
              toggle
              active={this.state.os}
            >
              OS
            </Button>
          }
          content="Toggle OS Map"
          basic
        /> */}
      </div>
    );
  }
  render() {
    let mapStyle = {
      height: 100 + "%",
      width: 100 + "%",
    };
    if (this.props.mapStyle) {
      mapStyle = this.props.mapStyle;
    } else {
      mapStyle = {
        minHeight: 50 + "vh",
        width: 100 + "%",
      };
    }
    const { styleKey } = this.state;
    return (
      // <Segment className="mapHolder no-pad" basic>
      <Map
        ref={(e) => {
          this.map = e;
        }}
        center={this.props.marker.geometry.coordinates}
        style={styles[styleKey]}
        onStyleLoad={this.onStyleLoad}
        className="basicMap"
        onClick={this.onClickMap}
        containerStyle={mapStyle}
      >
        <ZoomControl />
        <ScaleControl position={"bottom-left"} />
        {this.renderMapBar()}
        {this.state.marker && this.map && this.renderMarker()}
        {/* {this.props.locations && this.state.reload && this.renderLocations()} */}
      </Map>
      // </Segment>
    );
  }
}
