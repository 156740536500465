import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { Container } from "semantic-ui-react";
import CourseDash from "./dash";
import CoursePage from "./coursePage";
import addCourse from "./addCourse";
import updateCourse from "./updateCourse";

export default class CoursePages extends Component {
  state = {};
  render() {
    return (
      <Container fluid className="no-pad">
        <Switch>
          <Route exact path="/training/courses" component={CourseDash} />
          <Route exact path="/training/courses/add" component={addCourse} />
          <Route
            exact
            path="/training/courses/:id/update"
            component={updateCourse}
          />
          <Route exact path="/training/courses/:id" component={CoursePage} />
        </Switch>
      </Container>
    );
  }
}
