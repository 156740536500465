import React, { useState, useEffect } from "react";
import Layout from "./ui-wrappers/Layout";
import Box from "./ui-wrappers/Box";
import api from "../../actions/api";
import Tooltip from "./ui-wrappers/Tooltip";
import dayjs from "dayjs";

const AssetList = ({ history }) => {
  const [assets, setAssets] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [showArchived, setShowArchived] = useState(false);
  const [loading, setLoading] = useState(true);
  const [serviceWarnings, setServiceWarnings] = useState([]);
  const [tags, setTags] = useState([]);
  const [filter, setFilter] = useState("");
  const [filterTag, setFilterTag] = useState("");

  useEffect(() => {
    api.assets.settings.all().then((res) => {
        if(res.tags !== undefined){
            setTags(
                res.tags.map((t, i) => {
                    return {
                        key: i,
                        text: t,
                        value: t,
                    };
                })
            );
        }
    });
    getData();
  }, []);
  useEffect(() => {
    if (filterTag !== "") {
      let list = assets.filter((a) => {
        return a.archived === showArchived;
      });
      list = list.filter((item) => {
        let hasTag = item.tags.filter((tag) => {
          return tag === filterTag;
        });
        return hasTag.length >= 1;
      });
      setFiltered(list);
    } else {
      setFiltered(
        assets.filter((a) => {
          return a.archived === showArchived;
        })
      );
    }
  }, [filterTag, showArchived]);
  const filterBy = (data) => {
    if (data) {
      if (data === filterTag) {
        data = "";
      }
      setFilterTag(data);
    }
  };
  const getData = () => {
    return api.assets
      .all()
      .then((res) => {
        setAssets(res);
        if (res.length) {
          setFiltered(
            res.filter((a) => {
              return a.archived === showArchived;
            })
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const filterList = (e) => {
    if (e.target.value.length > 0) {
      let updatedList = filtered;
      updatedList = updatedList.filter((item) => {
        return item.name.toLowerCase().search(e.target.value.toLowerCase()) !== -1 || item.serialNum.toLowerCase().search(e.target.value.toLowerCase()) !== -1
      });
      setFiltered(updatedList);
    } else {
      setFiltered(
        assets.filter((a) => {
          return a.archived === showArchived;
        })
      );
    }
  };

  return (
    <Layout full>
      <div className=" grid grid-cols-1 md:grid-cols-1 gap-4 ">
        <div className="col-span-1 md:col-span-1	">
          <Box>
            <div className="flex items-center">
              <div className=" p-4">
                <input
                  autoComplete="off"
                  name="filter"
                  placeholder="asset/serial number"
                  onChange={filterList}
                  className={`p-2 bg-gray-900 mt-1 focus:ring-gray-500 focus:border-gray-500 placeholder-gray-600 block w-full shadow-sm text-xl border-gray-600 rounded-md`}
                />
              </div>
              <div className=" p-4">
                {tags.map((t) => {
                  return (
                    <span
                      onClick={() => filterBy(t.value)}
                      key={t.key}
                      className={`${
                        t.value === filterTag
                          ? " bg-green-800 hover:bg-green-800"
                          : " bg-gray-700 "
                      } p-2 m-1  rounded-lg text-white cursor-pointer hover:bg-gray-600 `}
                    >
                      {t.text}
                    </span>
                  );
                })}
              </div>
              <div className=" p-4">
                <span
                  onClick={() => setShowArchived(true)}
                  className={`${
                    showArchived && "bg-blue-900"
                  } p-2 m-1  rounded-lg text-white cursor-pointer hover:bg-blue-800 `}
                >
                  Archived
                </span>
                <span
                  onClick={() => setShowArchived(false)}
                  className={`${
                    !showArchived && "bg-blue-900"
                  } p-2 m-1  rounded-lg text-white cursor-pointer hover:bg-blue-800 `}
                >
                  Active
                </span>
              </div>
            </div>
            <table className="min-w-full divide-y divide-gray-200 border-separate table-fixed ">
              <thead>
                <tr className="bg-gray-800">
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-400 uppercase ">
                    Asset
                  </th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-400 uppercase ">
                    Asset Number
                  </th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-400 uppercase ">
                    Serial Number
                  </th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-400 uppercase ">
                    Status
                  </th>
                  <th className="px-4 py-3 text-center text-xs font-medium text-gray-400 uppercase ">
                    Last Service
                  </th>
                  <th className="px-4 py-3 text-center text-xs font-medium text-gray-400 uppercase ">
                    Last Check
                  </th>
                  <th className="px-4 py-3 text-center text-xs font-medium text-gray-400 uppercase ">
                    Expires Date
                  </th>
                  <th className="px-4 py-3 text-center text-xs font-medium text-gray-400 uppercase ">
                    Tags
                  </th>
                  <th>Assigned</th>
                </tr>
              </thead>
              <tbody className="bg-gray-900 divide-y divide-gray-200 text-lg">
                {!loading ? (
                  filtered.map((a) => {
                    const assigned = (
                      <div
                        onClick={() =>
                          history.push(
                            `/${a.assignedTo.type}/${a.assignedTo._id}`
                          )
                        }
                        className="px-2 py-1 text-center w-auto border border-gray-700 rounded-xl"
                      >
                        {a.assignedTo.name}
                      </div>
                    );
                    let status = "";
                    switch (true) {
                      case a.status === "Non-Operational" ||
                        a.status === "Out for Repair":
                        status = "bg-red-900 ";
                        break;
                      case a.status === "Faulty":
                        status = "bg-yellow-900 ";
                        break;
                      default:
                        status = "bg-green-900 ";
                        break;
                    }
                    let expires = "";
                    switch (true) {
                      case dayjs(a.date_expires).isBefore(dayjs()):
                        expires = " bg-red-900 bg-opacity-50 ";
                        break;
                      case dayjs(a.date_expires).isAfter(dayjs()) &&
                        dayjs(a.date_expires).isBefore(dayjs().add(3, "month")):
                        expires = " bg-yellow-900 bg-opacity-50 ";
                        break;
                      case dayjs(a.date_expires).isAfter(dayjs()) &&
                        dayjs(a.date_expires).isAfter(dayjs().add(3, "month")):
                        expires = " bg-green-900 bg-opacity-50 ";
                        break;

                      default:
                        expires = " ";
                        break;
                    }
                    return (
                      <tr
                        key={a._id}
                        className={`cursor-pointer hover:bg-gray-800 `}
                      >
                        <td
                          className="p-2"
                          onClick={() => history.push(`/assets/${a._id}`)}
                        >
                          {a.name}
                        </td>
                        <td
                          className="p-2"
                          onClick={() => history.push(`/assets/${a._id}`)}
                        >
                          {a.assetNum}
                        </td>
                        <td
                          className="p-2"
                          onClick={() => history.push(`/assets/${a._id}`)}
                        >
                          {a.serialNum}
                        </td>
                        <td
                          className={`p-2 ${status} bg-opacity-50 `}
                          onClick={() => history.push(`/assets/${a._id}`)}
                        >
                          {a.status}
                        </td>
                        <td
                          className="p-2 "
                          onClick={() => history.push(`/assets/${a._id}`)}
                        >
                          {a.lastService
                            ? dayjs(a.lastService).format("DD-MM-YY")
                            : "--"}
                        </td>
                        <td
                          className={`p-2 ${
                            a.lastCheck.status
                              ? a.lastCheck.status === "OK"
                                ? "bg-green-900 bg-opacity-50"
                                : "bg-red-900 bg-opacity-50 has-tooltip"
                              : ""
                          }`}
                          onClick={() => history.push(`/assets/${a._id}`)}
                        >
                          {a.lastCheck.note && (
                            <Tooltip
                              style={`${
                                a.lastCheck.status !== "OK"
                                  ? "bg-red-900"
                                  : "bg-green-900"
                              }`}
                              text={a.lastCheck.note}
                            />
                          )}
                          {a.lastCheck.date
                            ? dayjs(a.lastCheck.date).format("DD-MM-YY")
                            : "--"}
                        </td>
                        <td
                          className={`p-2 ${expires}`}
                          onClick={() => history.push(`/assets/${a._id}`)}
                        >
                          {a.date_expires
                            ? dayjs(a.date_expires).format("DD-MM-YY")
                            : "--"}
                        </td>
                        <td
                          className="p-2"
                          onClick={() => history.push(`/assets/${a._id}`)}
                        >
                          {a.tags.map((t, i) => {
                            return (
                              <span
                                key={i}
                                className="p-2 m-1 bg-gray-800 rounded-lg border border-gray-700"
                              >
                                {t}
                              </span>
                            );
                          })}
                        </td>
                        <td className="p-2 w-1/8">
                          {a.assignedTo.name ? assigned : "--"}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="8" className="p-4 h-12 text-2xl">
                      Loading.......
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </Box>
        </div>
      </div>
    </Layout>
  );
};
export default AssetList;
