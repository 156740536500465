import React from "react";
import { Pagination, Segment, Select, Message } from "semantic-ui-react";

const pageSizes = [10, 25, 50, 100];

export default class PaginateWrapper extends React.Component {
  state = {
    pageSize: pageSizes[1],
    pageNum: 1,
    totalPages: 0,
    totalItems: 0,
    items: [],
    loading: true,
    globalError: null,
  };
  componentDidMount() {
    this.queryData(1);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.dataQuery !== this.props.dataQuery) {
      this.queryData(this.state.pageNum);
    }
  }
  queryData(newPageNum = 1) {
    this.setState(
      {
        loading: true,
        globalError: null,
      },
      () => {
        this.props
          .dataQuery(newPageNum, this.state.pageSize)
          .then((data) => {
            this.setState({
              pageNum: data.page,
              totalPages: data.pages,
              totalItems: data.total,
              items: data.docs,
            });
          })
          .catch((err) => {
            let globalError =
              "There was an unexpected error while retrieving data from the server";

            if (err.response !== undefined) {
              globalError = err.response.data.message;
            }

            this.setState({
              globalError,
            });
          })
          .finally(() => {
            this.setState({
              loading: false,
            });
          });
      }
    );
  }
  render() {
    return (
      <Segment basic loading={this.state.loading}>
        {this.state.globalError && (
          <Message negative>
            <Message.Header>ERROR</Message.Header>
            {this.state.globalError}
          </Message>
        )}
        <div style={{ textAlign: "right", paddingBottom: "1rem" }}>
          <label>Page Size: </label>
          <Select
            options={pageSizes.map((i) => {
              return {
                key: i,
                value: i,
                text: i,
              };
            })}
            value={this.state.pageSize}
            onChange={(e, data) => {
              this.setState(
                {
                  pageSize: data.value,
                },
                () => {
                  this.queryData(1);
                }
              );
            }}
          />
        </div>
        {this.props.render(this.state.items)}
        {this.state.items.length >= 1 && (
          <div style={{ textAlign: "right", paddingTop: "1rem" }}>
            <Pagination
              defaultActivePage={this.state.pageNum}
              totalPages={this.state.totalPages}
              onPageChange={(event, data) => {
                this.queryData(data.activePage);
              }}
            />
          </div>
        )}
      </Segment>
    );
  }
}
