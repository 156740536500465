import React from "react";
import Blank from "../../../assets/images/blank.png";
import { Table, Image } from "semantic-ui-react";
import PropTypes from "prop-types";
import moment from "moment";
export default class VehicleList extends React.Component {
  state = {};

  calcDiff = (date) => {
    let date1 = moment(date);
    let date2 = date1.add(1, "year");
    return date2.diff(moment(), "days");
  };
  renderVehicles() {
    if (this.props.data && this.props.data.length) {
      const vehicles = this.props.data.map((item) => {
        return (
          <Table.Row key={item._id} onClick={() => this.props.action(item._id)}>
            {!this.props.small && (
              <Table.Cell width="1">
                {item.photo ? (
                  <Image src={item.photo} size="mini" />
                ) : (
                  <Image src={Blank} size="mini" />
                )}
              </Table.Cell>
            )}
            <Table.Cell>
              {item.make} {item.model}
            </Table.Cell>
            <Table.Cell>{item.regNumber}</Table.Cell>
            <Table.Cell>
              {item.member
                ? item.member.name + " (" + item.member.teamId + ")"
                : item.callsign
                ? item.callsign
                : "Team"}
            </Table.Cell>
            {!this.props.small && (
              <Table.Cell>
                {item.unitVehicle
                  ? item.assigned
                    ? item.assigned.geoJSON &&
                      item.assigned.geoJSON.properties &&
                      item.assigned.geoJSON.properties.name
                    : "--"
                  : item.member
                  ? item.member.geoJSON &&
                    item.member.geoJSON.properties &&
                    item.member.geoJSON.properties.town
                  : "--"}
                {}
              </Table.Cell>
            )}
            <Table.Cell textAlign="center">
              {item.unitVehicle ? (
                item.available ? (
                  <span className="good1">Yes</span>
                ) : (
                  <span className="bad1">No</span>
                )
              ) : item.member && item.member.onCall ? (
                <span className="good1">Yes</span>
              ) : (
                <span className="bad1">No</span>
              )}
            </Table.Cell>
            {this.props.tickets && (
              <React.Fragment>
                <Table.Cell textAlign="center">
                  {this.calcDiff(item.mot)}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {this.calcDiff(item.insurance)}
                </Table.Cell>
              </React.Fragment>
            )}
          </Table.Row>
        );
      });
      return vehicles;
    }
  }
  render() {
    return (
      <Table stackable selectable={this.props.selectable}>
        <Table.Header>
          <Table.Row>
            {!this.props.small && <Table.HeaderCell>Photo</Table.HeaderCell>}
            <Table.HeaderCell>Make & Model</Table.HeaderCell>
            <Table.HeaderCell>Registration</Table.HeaderCell>
            {this.props.teamVehicle ? (
              <Table.HeaderCell>Callsign</Table.HeaderCell>
            ) : (
              <Table.HeaderCell>Owner</Table.HeaderCell>
            )}
            {!this.props.small && <Table.HeaderCell>Location</Table.HeaderCell>}
            <Table.HeaderCell textAlign="center">Available</Table.HeaderCell>
            {this.props.tickets && (
              <React.Fragment>
                <Table.HeaderCell textAlign="center">MoT Due</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">
                  Insurance Due
                </Table.HeaderCell>
              </React.Fragment>
            )}
          </Table.Row>
        </Table.Header>
        <Table.Body>{this.renderVehicles()}</Table.Body>
      </Table>
    );
  }
}
VehicleList.propTypes = {
  data: PropTypes.array.isRequired,
  action: PropTypes.func,
};
