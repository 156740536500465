import React, { Component } from "react";
import { connect } from "react-redux";
import { Segment, Header, Grid, Image, Menu, Label, Divider, Icon, Modal, Table, Button, Form, Popup, Message, Select } from "semantic-ui-react";
import moment from "moment";
import { AuthComponents } from "@liquidcomputing/sarstuff__reactjs-singlesignon";
import InlineError from "../common/forms/errorMessage.js";
import { getSystemSettings } from "../../actions/settingsActions";
import { DateInput } from "semantic-ui-calendar-react";
import api from "../../actions/api";
import Blank from "../../assets/images/blank.png";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import AddressCard from "../common/cards/addressCard";
import AddNoteForm from "../common/forms/addNote.js";
import UserMap from "../common/mapping/userMap";
import RecruitConvertToMember from "./recruitConvertToMember";
import { Link } from "react-router-dom";
import DisplayAndUpdatePhoto from "../common/cards/_components/DisplayAndUpdatePhoto";

moment.locale("en-GB");
const localizer = momentLocalizer(moment);

function mapStateToProps(state) {
  return {
    system: state.system,
    user: state.user,
  };
}
class RecruitPage extends Component {
  state = {
    activeItem: "dash",
    data: {},
    recruit: {
      nok: {},
    },
    intakes: [],
    addNew: false,
    loading: true,
    events: [],
    vettingData: [],
    sourceTypes: [],
    checkTypes: [],
    editModal: false,
    reqModal: false,
    convertRec: false,
    errors: {},
    warning: {},
    showImageText: false,
    addressModal: false,
  };

  componentDidMount() {
    this._getData();
    // this.props.getSystemSettings();
    this.props
      .getSystemSettings()
      .then(() => {
        const data = this.props.system.settings.source;
        let dataArray = [];
        for (var key in data) {
          dataArray.push({
            key: key,
            text: data[key],
            value: data[key],
          });
        }
        this.updateSourceTypes(dataArray);

        const checks = this.props.system.settings.recruitChecks;
        // console.log(checks);
        // let checksArray = [];
        // for (var key in checks) {
        //   checksArray.push({
        //     key: key,
        //     text: checks[key],
        //     value: checks[key],
        //   });
        // }
        this.setState({ checkTypes: checks });
      })
      .catch((err) => {
        console.log(err);
      });
    api.recruitment.intakes.all().then((intakes) => {
      const data = intakes;
      let dataArray = [];
      for (var key in data) {
        dataArray.push({
          key: key,
          text: data[key].ref,
          value: data[key]._id,
        });
      }
      this.updateIntakes(dataArray);
    });
    // this.props.i18n.changeLanguage("en");
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.system.settings !== prevProps.system.settings) {
      this.getrecruitRequirements();
    }
  }

  updateSourceTypes = (data) => {
    this.setState({ sourceTypes: data });
  };
  updateIntakes = (data) => {
    this.setState({ intakes: data });
  };
  _getData = () => {
    api.recruitment.show(this.props.match.params.id).then((res) => {
      this.setState(
        {
          loading: false,
          recruit: {
            ...res,

            nokName: res.nok ? res.nok.name : "",
            nokPhone: res.nok ? res.nok.phone : "",
            nokRel: res.nok ? res.nok.relationship : "",
          },
        },
        () => {
          api.recruitment.recEvents(this.state.recruit._id).then((events) => {
            this.setState({
              events: events,
            });
          });
        }
      );
    });
    // .catch(err => {
    //   if (err.response.status === 404) {
    //     this.setState({
    //       addNew: true
    //     });
    //   }
    // });
  };
  getrecruitRequirements = () => {
    let requirements = [];
    if (this.props.system.settings.recruitReq) {
      requirements = this.props.system.settings.recruitReq.map((req) => {
        return {
          name: req.content,
          date: "",
          isRequired: req.isRequired,
        };
      });
    }
    if (this.state.recruit.requirements !== undefined) {
      for (let index = 0; index < this.state.recruit.requirements.length; index++) {
        const theRequire = this.state.recruit.requirements[index];
        let reqExists = requirements.filter((i) => {
          return i.name === theRequire.name;
        });
        if (reqExists[0] === undefined) {
          requirements.push({
            name: theRequire.name,
          });
        }
      }
      requirements = requirements.map((i) => {
        let reqExists = this.state.recruit.requirements.filter((r) => {
          return r.name === i.name;
        });
        if (reqExists[0] === undefined) {
          return i;
        }
        return {
          ...i,
          ...reqExists[0],
        };
      });
    }
    return requirements;
  };
  getVetting1 = () => {
    let vetting = [];
    if (this.props.system.settings.vetting) {
      vetting = this.props.system.settings.vetting.map((req) => {
        return {
          name: req.content,
          appliedFor: "",
          expires: "",
          isRequired: req.isRequired,
        };
      });
    }
    if (this.state.recruit.vetting !== undefined) {
      for (let index = 0; index < this.state.recruit.vetting.length; index++) {
        const theRequire = this.state.recruit.vetting[index];
        let reqExists = vetting.filter((i) => {
          return i.name === theRequire.name;
        });
        if (reqExists[0] === undefined) {
          vetting.push({
            name: theRequire.name,
          });
        }
      }
      vetting = vetting.map((i) => {
        let reqExists = this.state.recruit.vetting.filter((r) => {
          return r.name === i.name;
        });
        if (reqExists[0] === undefined) {
          return i;
        }
        return {
          ...i,
          ...reqExists[0],
        };
      });
    }
    return vetting;
  };
  checkDate = (date) => {
    let now = new moment();
    let theDate = moment(date);
    let expiresIn = theDate.diff(now, "months");
    return expiresIn;
  };
  addVetting = () => {
    let data = this.state.recruit;
    api.recruitment.update(data._id, data).then(() => {
      this._getData();
      this.setState({
        vettingModal: false,
      });
    });
  };
  onNoteChange = (e) => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  };
  addNote = (data) => {
    return api.recruitment.addNote(this.state.recruit._id, data).then(() => {
      this._getData();
      this.setState({
        data: {},
      });
    });
  };
  onChange = (e) => {
    this.setState({
      recruit: { ...this.state.recruit, [e.target.name]: e.target.value },
    });
  };
  handleMenuClick = (e, { name }) => this.setState({ activeItem: name });
  handleChange = (event, { name, value }) => {
    this.setState({
      recruit: {
        ...this.state.recruit,
        [name]: value,
      },
    });
  };
  handleVettingChange =
    (type) =>
    (event, { name, value }) => {
      let oldVetting = this.state.recruit.vetting;
      if (
        oldVetting.filter((i) => {
          return i.name === type.content;
        }).length === 0
      ) {
        oldVetting.push({
          name: type.content,
          isRequired: type.isRequired,
        });
      }
      let newVetting = this.state.recruit.vetting.map((i) => {
        if (i.name !== type.content) {
          return i;
        }
        return {
          ...i,
          [name]: value,
        };
      });
      this.setState({
        recruit: {
          ...this.state.recruit,
          vetting: newVetting,
        },
      });
    };
  handleReqChange =
    (type) =>
    (event, { name, value }) => {
      let oldReq = this.state.recruit.requirements;
      if (
        oldReq.filter((i) => {
          return i.name === type.content;
        }).length === 0
      ) {
        oldReq.push({
          name: type.content,
          isRequired: type.isRequired,
        });
      }
      let newReq = this.state.recruit.requirements.map((i) => {
        if (i.name !== type.content) {
          return i;
        }
        return {
          ...i,
          [name]: value,
        };
      });
      this.setState({
        recruit: {
          ...this.state.recruit,
          requirements: newReq,
        },
      });
    };
  getVettingData = (type) => {
    let vetLevel = [];
    if (type && this.state.recruit.vetting && this.state.recruit.vetting.length) {
      vetLevel = this.state.recruit.vetting.filter((i) => {
        return i.name === type.content;
      });
    }
    if (vetLevel[0] !== undefined) {
      return vetLevel[0];
    }
    return {
      name: type.content,
      appliedFor: null,
      expires: null,
    };
  };
  getReqData = (type) => {
    let reqLevel = [];
    if (type && this.state.recruit.requirements && this.state.recruit.requirements.length) {
      reqLevel = this.state.recruit.requirements.filter((i) => {
        return i.name === type.content;
      });
    }
    if (reqLevel[0] !== undefined) {
      return reqLevel[0];
    }
    return {
      name: type.content,
      date: null,
    };
  };
  validate = (data) => {
    const errors = {};
    if (!data.firstName) errors.firstName = "Can't be empty";
    if (!data.lastName) errors.lastName = "Can't be empty";
    // if (!data.email) errors.email = "Can't be empty";
    return errors;
  };
  handleEditClose = () => {
    this.setState({
      editModal: false,
    });
  };
  onSubmit = () => {
    const errors = this.validate(this.state.recruit);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      let data = this.state.recruit;

      let nok = {
        name: data.nokName,
        phone: data.nokPhone,
        relationship: data.nokRel,
      };
      delete data.photo;
      data.nok = nok;
      api.recruitment.update(data._id, data).then(() => {
        this._getData();
        this.handleEditClose();
      });
    }
  };
  addRequirement = () => {
    let data = this.state.recruit;
    api.recruitment.update(data._id, data).then(() => {
      this._getData();
      this.setState({
        reqModal: false,
      });
    });
  };
  updateRecruit = (data) => {
    this.setState({
      data: {
        ...this.state.data,
        geoJSON: data.geoJSON,
      },
    });
  };
  updateAddress = () => {
    let geoJSON = {
      geoJSON: this.state.data.geoJSON,
    };
    // let data = {
    //   address: rawData.geoJSON,
    // };
    if (geoJSON) {
      return api.recruitment.update(this.props.match.params.id, geoJSON).then(() => {
        this.setState({ addressModal: false });
        this._getData();
      });
    }
    return;
  };
  eventStyleGetter = (event, start, end, isSelected) => {
    var backgroundColor = event.color;
    var style = {
      backgroundColor: backgroundColor,
      borderRadius: "0px",
      opacity: 0.8,
      color: "white",
      border: "0px",
      display: "block",
    };
    return {
      style: style,
    };
  };
  addCheck = (check) => {
    let user = this.props.user.details;
    let initials = user.firstName.slice(0, 1) + user.surname.slice(0, 1);
    let newData = [];
    this.state.recruit.checks.forEach((a) => {
      newData.push(a);
    });
    if (newData.filter((b) => b.name === check).length !== 0) {
      newData = newData.map((c) => {
        if (c.name === check) return { ...c, date: new Date(), initials: initials };
        return c;
      });
    } else {
      newData.push({ name: check, date: new Date(), initials: initials });
    }
    api.recruitment.update(this.state.recruit._id, { checks: newData }).then(() => {
      this._getData();
    });
  };
  removeCheck = (check) => {
    let newData = [];
    this.state.recruit.checks.forEach((a) => {
      newData.push(a);
    });
    if (newData.filter((b) => b.name === check).length !== 0) {
      newData = newData.map((c) => {
        if (c.name === check) return { ...c, date: "", initials: "" };
        return c;
      });
    }
    api.recruitment.update(this.state.recruit._id, { checks: newData }).then(() => {
      this._getData();
    });
  };
  handleGetUploadSpec = () => {
    return api.recruitment.getPhotoUploadConfig(this.state.recruit._id)
  };
  handleGettingPhoto = () => {
    return api.recruitment.getPhoto(this.state.recruit._id);
  };
  handlePhotoUpload = formData => api.recruitment.setPhotoImage(this.state.recruit._id, formData).then(a => {
    this._getData();
    return a;
  });

  renderMembership() {
    let requirements = this.getrecruitRequirements();
    if (requirements && requirements.length) {
      return requirements.map((item, i) => {
        return (
          <Grid.Column key={i} textAlign="center">
            <Segment className="reqBox" raised>
              <Header as="h2">
                {item.name} {item.isRequired && "*"}
              </Header>
              <Divider />
              <Header className="no-marg">
                Date:<strong>{this.renderDate(item.date)}</strong>
              </Header>
            </Segment>
          </Grid.Column>
        );
      });
    }
    return (
      <div className="p-4">
        <Message color={"black"} icon="info" header="Member Requirement" content={"No requirements recorded for this recruit."} />
      </div>
    );
  }
  renderReqModal() {
    let reqs = this.props.system.settings.recruitReq;
    let events;
    if (reqs) {
      events = reqs.map((type, i) => {
        let userData = this.getReqData(type);
        return (
          <Grid.Column key={i}>
            <Segment basic>
              <p className="boxHeader">{type.content}</p>

              <DateInput
                autoComplete="off"
                name="date"
                startMode={"day"}
                animation="none"
                dateFormat={"YYYY-MM-DD"}
                closable={true}
                placeholder="attended"
                value={userData.date ? moment(userData.date).format("YYYY-MM-DD") : ""}
                iconPosition="left"
                onChange={this.handleReqChange(type)}
                localization="en_GB"
              />
            </Segment>
          </Grid.Column>
        );
      });
    }
    return (
      <Modal open={this.state.reqModal} onClose={() => this.setState({ reqModal: false })} centered={false} size="large" closeOnDimmerClick={false}>
        <Header icon="user" content="Update Membership Requirments" />
        <Modal.Content>
          <Grid>
            <Grid.Row columns={4}>{events}</Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button negative floated={"left"} onClick={() => this.setState({ reqModal: false })}>
            Cancel
          </Button>
          <Button color="green" onClick={this.addRequirement} inverted>
            <Icon name="save" />
            Update
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
  renderNotes() {
    let { recruit } = this.state;
    let notes = [];
    if (recruit.notes) {
      notes = recruit.notes
        .sort((a, b) => {
          if (a.date < b.date) {
            return 1;
          } else if (a.date > b.date) {
            return -1;
          } else {
            return 0;
          }
        })
        .map((note) => {
          return (
            <Table.Row key={note._id}>
              <Table.Cell>{this.renderDateTime(note.date)}</Table.Cell>
              <Table.Cell>{note.message}</Table.Cell>
              <Table.Cell>{note.loggedBy}</Table.Cell>
            </Table.Row>
          );
        });
    }
    return (
      <Grid stackable className="no-marg no-pad">
        <Grid.Row>
          <Grid.Column width="16" className="no-pad">
            <AddNoteForm submit={this.addNote} notModal={true} cancel={() => console.log("cancel")} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width="16" className="no-pad">
            {/* <Header>Notes</Header> */}
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Date</Table.HeaderCell>
                  <Table.HeaderCell>Note</Table.HeaderCell>
                  <Table.HeaderCell>Logged By</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>{notes}</Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
  renderDetails() {
    let { recruit } = this.state;
    let age = 0;
    if (recruit.dob) {
      age = moment().diff(recruit.dob, "years");
    }
    return (
      <Grid stackable className="no-marg">
        <Grid.Row columns={1}>
          <Grid.Column>
            <Segment className="subtle">
              {recruit.member !== undefined && (
                <Message positive>
                  This Recruit is now a full TeamSite member, you can access there profile{" "}
                  <Button as={Link} to={"/team/" + recruit.member} positive compact basic>
                    here
                  </Button>
                </Message>
              )}
              <Grid stackable>
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <Header as={"h3"}>
                      Name:
                      <span>
                        {recruit.firstName} {recruit.lastName} {recruit.nickName && <i>({recruit.nickName})</i>}
                      </span>
                    </Header>
                  </Grid.Column>
                  <Grid.Column>
                    <Grid.Column>
                      <Header as={"h3"}>
                        Email:
                        <span>{recruit.email}</span>
                      </Header>
                    </Grid.Column>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row columns={2}>
                  <Grid.Column>
                    <Header as={"h3"}>
                      Phone:
                      <span>{recruit.phone}</span>
                    </Header>
                  </Grid.Column>
                  <Grid.Column>
                    <Header as={"h3"}>
                      DOB:
                      <span>
                        {moment(recruit.dob).format("DD-MM-YYYY")} ({age})
                      </span>
                    </Header>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns="2">
                  <Grid.Column>
                    <Header as={"h3"}>
                      Source:
                      <span>{recruit.source}</span>
                    </Header>
                  </Grid.Column>
                  <Grid.Column>
                    <Grid.Column>
                      <Header as={"h3"}>
                        Intake:
                        <span>{recruit.intake ? recruit.intake.ref : "No Intake"}</span>
                      </Header>
                    </Grid.Column>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <Header as={"h3"}>
                      NOK Name:
                      <span>{recruit.nok && recruit.nok.name}</span>
                    </Header>
                  </Grid.Column>
                  <Grid.Column>
                    <Header as={"h3"}>
                      NOK Relationship:
                      <span>{recruit.nok && recruit.nok.relationship}</span>
                    </Header>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns="2">
                  <Grid.Column>
                    <Header as={"h3"}>
                      NOK Phone:
                      <span>{recruit.nok && recruit.nok.phone}</span>
                    </Header>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1}>
          <Grid.Column>
            <Segment>
              <Grid stackable>
                <Grid.Row>
                  <Grid.Column width="16" className="no-pad">
                    {this.renderVettingModal()}

                    <Divider horizontal style={{ fontSize: 1.3 + "rem" }}>
                      Vetting &nbsp;
                      <Button onClick={() => this.setState({ vettingModal: true })} icon basic className="subtleButton">
                        <Icon name="plus" color="green" />
                      </Button>
                    </Divider>
                    <Grid stackable>
                      <Grid.Row columns={3} style={{ padding: 10 }}>
                        {this.renderVetting()}
                      </Grid.Row>
                    </Grid>
                    {/* <Segment.Group horizontal>{this.renderVetting()}</Segment.Group> */}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1}>
          <Grid.Column>
            <Segment>
              <Grid stackable>
                <Grid.Row>
                  <Grid.Column width="16" className="no-pad">
                    {this.renderReqModal()}
                    <Divider horizontal style={{ fontSize: 1.3 + "rem" }}>
                      Membership &nbsp;
                      <Button onClick={() => this.setState({ reqModal: true })} icon basic className="subtleButton">
                        <Icon name="plus" color="green" />
                      </Button>
                    </Divider>
                    {/* <Segment.Group horizontal>{this.renderMembership()}</Segment.Group> */}
                    <Grid stackable>
                      <Grid.Row columns={3} style={{ padding: 10 }}>
                        {this.renderMembership()}
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
  renderAddressModal = () => {
    return (
      <Modal open={this.state.addressModal} onClose={() => this.setState({ addressModal: false })} centered={false} size="large" closeOnDimmerClick={false}>
        <Header icon="map pin" content="Add Location" />
        <Modal.Content>
          <AddressCard country={this.props.system.country} update={this.updateRecruit} geoJSON={this.state.recruit.geoJSON} />
        </Modal.Content>
        <Modal.Actions>
          <Button negative floated={"left"} onClick={() => this.setState({ addressModal: false })}>
            Cancel
          </Button>
          <Button color="green" onClick={this.updateAddress} inverted>
            <Icon name="save" />
            Add/Update
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };
  renderAddress() {
    const { geoJSON } = this.state.recruit;
    return (
      <Grid stackable>
        <Grid.Row stretched>
          <Grid.Column width={8}>
            {geoJSON !== undefined && geoJSON.properties ? (
              <Segment className="subtle1">
                <p className="boxHeader">Address</p>
                <Header as={"h3"}>
                  Name
                  <span>{geoJSON.properties.name}</span>
                </Header>
                <Header as={"h3"}>
                  Address
                  <span>{geoJSON.properties.address}</span>
                </Header>
                <Header as={"h3"}>
                  Town
                  <span>{geoJSON.properties.town}</span>
                </Header>
                <Header as={"h3"}>
                  Postcode
                  <span>{geoJSON.properties.postcode}</span>
                </Header>

                <Segment basic textAlign="center">
                  <Divider />
                  <Button
                    primary
                    basic
                    onClick={() => {
                      this.setState({ addressModal: true });
                    }}
                  >
                    Update
                  </Button>
                </Segment>
              </Segment>
            ) : (
              <Segment basic className="subtle1">
                <p className="boxHeader" textAlign="center">
                  Address
                </p>
                <Header as={"h3"}>No address.</Header>
                <Button
                  positive
                  onClick={() =>
                    this.setState({
                      addressModal: true,
                    })
                  }
                >
                  Add
                </Button>
              </Segment>
            )}
          </Grid.Column>
          <Grid.Column width={8}>
            <Segment className="no-pad">{geoJSON !== undefined && geoJSON.geometry && geoJSON.geometry.coordinates.length > 0 && <UserMap marker={geoJSON} />}</Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
  renderDate = (date) => {
    if (date) {
      return moment(date).format("DD/MM/YYYY");
    } else {
      return "--";
    }
  };
  renderDateTime = (date) => {
    if (date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    } else {
      return "--";
    }
  };
  renderVetting() {
    let vetting = this.getVetting1();
    let warning = this.props.system.settings.vettingWarning ? this.props.system.settings.vettingWarning : 3;
    // let vetting = this.state.recruit.vetting;
    let status = "";
    let disabled = false;
    if (vetting && vetting.length) {
      return vetting.map((item, i) => {
        status = "";
        let month = this.checkDate(item.expires);
        if (item.expires === "" && item.appliedFor === "") {
          disabled = true;
        }
        if (item.expires && item.expires !== "") {
          switch (true) {
            case month < 0:
              status = "negative1";
              break;
            case month < warning:
              status = "warning";
              break;
            default:
              status = "positive1";
              break;
          }
        }
        return (
          <Grid.Column key={i} textAlign="center">
            <Segment className={`reqBox ${status}`} raised disabled={disabled}>
              <Header as="h2" className="no-marg">
                {item.name} {item.isRequired && "*"}
              </Header>
              <Divider />
              <Header className="no-marg">
                Applied For:<strong>{this.renderDate(item.appliedFor)}</strong>
              </Header>
              <Header>
                Expires: <strong>{this.renderDate(item.expires)}</strong>
              </Header>
            </Segment>
          </Grid.Column>
        );
      });
    }

    return (
      <div className="p-4">
        <Message color={"black"} icon="info" header="Vetting" content={"No vetting recorded for this recruit."} />
      </div>
    );
  }
  renderVettingModal() {
    let types = this.props.system.settings.vetting;
    let levels;
    if (types) {
      levels = types.map((type, i) => {
        let userData = this.getVettingData(type);
        return (
          <Segment key={i} className={"vettingFormBox"}>
            <Header textAlign="center">{type.content}</Header>
            <Form.Field>
              <DateInput
                autoComplete="off"
                name="appliedFor"
                startMode={"day"}
                animation="none"
                dateFormat={"YYYY-MM-DD"}
                closable={true}
                placeholder="Applied for.."
                value={userData.appliedFor ? moment(userData.appliedFor).format("YYYY-MM-DD") : ""}
                iconPosition="left"
                onChange={this.handleVettingChange(type)}
                localization="en_GB"
              />
            </Form.Field>
            <Form.Field>
              <DateInput
                autoComplete="off"
                name="expires"
                startMode={"day"}
                animation="none"
                dateFormat={"YYYY-MM-DD"}
                closable={true}
                placeholder="Expires.."
                value={userData.expires ? moment(userData.expires).format("YYYY-MM-DD") : ""}
                iconPosition="left"
                onChange={this.handleVettingChange(type)}
                localization="en_GB"
              />
            </Form.Field>
          </Segment>
        );
      });
    }
    return (
      <Modal open={this.state.vettingModal} onClose={() => this.setState({ vettingModal: false })} centered={false} size="large" closeOnDimmerClick={false}>
        <Header icon="user" content="Update Vetting" />
        <Modal.Content>
          <Form>
            <Segment.Group horizontal>{levels}</Segment.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button negative floated={"left"} onClick={() => this.setState({ vettingModal: false })}>
            Cancel
          </Button>
          <Button color="green" onClick={this.addVetting} inverted>
            <Icon name="save" />
            Update
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
  renderEditModal() {
    const { errors, recruit, warning } = this.state;
    return (
      <Modal open={this.state.editModal} onClose={this.handleEditClose} centered={false} size="large" closeOnDimmerClick={false}>
        <Header icon="user" content="Update User Details" />
        <Modal.Content>
          <Form>
            <Form.Group widths="equal">
              <Form.Field error={!!errors.firstName}>
                <label htmlFor="firstName">First Name</label>
                <input
                  name="firstName"
                  id="firstName"
                  placeholder="Name"
                  value={recruit.firstName}
                  onChange={this.onChange}
                  className={warning.firstName ? "fieldWarning" : "good"}
                />
                {errors.firstName && <InlineError text={errors.firstName} />}
              </Form.Field>
              <Form.Field error={!!errors.lastName}>
                <label htmlFor="lastName">Last Name</label>
                <input
                  name="lastName"
                  id="lastName"
                  placeholder="lastName"
                  value={recruit.lastName}
                  onChange={this.onChange}
                  className={warning.lastName ? "fieldWarning" : "good"}
                />
                {errors.lastName && <InlineError text={errors.lastName} />}
              </Form.Field>
              <Form.Field error={!!errors.nickName}>
                <label htmlFor="nickName">Nickname</label>
                <input
                  name="nickName"
                  id="nickName"
                  placeholder="Nickname"
                  value={recruit.nickName}
                  onChange={this.onChange}
                  className={warning.nickName ? "fieldWarning" : "good"}
                />
                {errors.nickName && <InlineError text={errors.nickName} />}
              </Form.Field>
            </Form.Group>
            <Form.Group widths={3}>
              <Form.Field error={!!errors.phone}>
                <label htmlFor="phone">Mobile Phone</label>
                <input name="phone" id="phone" placeholder="phone" value={recruit.phone} onChange={this.onChange} className={warning.phone ? "fieldWarning" : "good"} />
                {errors.phone && <InlineError text={errors.phone} />}
              </Form.Field>
              <Form.Field error={!!errors.email}>
                <label htmlFor="email">Email</label>
                <input name="email" id="email" placeholder="email" value={recruit.email} onChange={this.onChange} className={warning.email ? "fieldWarning" : "good"} />
                {errors.email && <InlineError text={errors.email} />}
              </Form.Field>

              <Form.Field>
                <label htmlFor="dob">Date of Birth</label>
                <DateInput
                  autoComplete="off"
                  name="dob"
                  startMode={"year"}
                  animation="none"
                  dateFormat={"YYYY-MM-DD"}
                  closable={true}
                  placeholder="Date"
                  value={recruit.dob ? moment(recruit.dob).format("YYYY-MM-DD") : ""}
                  iconPosition="left"
                  onChange={this.handleChange}
                  localization="en_GB"
                />
              </Form.Field>
            </Form.Group>
            <Divider horizontal>Next of Kin </Divider>
            <Form.Group widths={3}>
              <Form.Field>
                <label htmlFor="nokName">NOK Name</label>
                <input name="nokName" id="nokName" placeholder="name" value={recruit.nokName} onChange={this.onChange} />
              </Form.Field>
              <Form.Field>
                <label htmlFor="nokRel">NOK Relationship</label>
                <input name="nokRel" id="nokRel" placeholder="phone" value={recruit.nokRel} onChange={this.onChange} />
              </Form.Field>
              <Form.Field>
                <label htmlFor="nokPhone">NOK Phone</label>
                <input name="nokPhone" id="nokPhone" placeholder="phone" value={recruit.nokPhone} onChange={this.onChange} />
              </Form.Field>
            </Form.Group>
            <Divider horizontal>Admin</Divider>
            <Form.Group widths={3}>
              <Form.Field>
                <label htmlFor="source">Source</label>
                <Select
                  placeholder={"pick one"}
                  name={"source"}
                  options={this.state.sourceTypes}
                  value={this.state.recruit.source}
                  onChange={this.handleChange}
                  // onBlur={this.updateMisperModel}
                  className={warning.source ? "fieldWarning" : "good"}
                  //   disabled={disabled}
                />
              </Form.Field>
              <Form.Field>
                <label htmlFor="intake">Intake</label>
                <Select
                  placeholder={"pick one"}
                  name={"intake"}
                  options={this.state.intakes}
                  value={this.state.recruit.intake ? this.state.recruit.intake._id : this.state.recruit.intake}
                  onChange={this.handleChange}
                  // onBlur={this.updateMisperModel}
                  className={warning.intake ? "fieldWarning" : "good"}
                  //   disabled={disabled}
                />
              </Form.Field>
              <Form.Field>
                <label>Archive Recruit:</label>

                <Form.Checkbox
                  className={"goodToggle"}
                  style={{ padding: 10 }}
                  checked={recruit.isArchived}
                  onClick={() =>
                    this.setState({
                      recruit: {
                        ...this.state.recruit,
                        isArchived: !recruit.isArchived,
                      },
                    })
                  }
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button negative floated={"left"} onClick={this.handleEditClose}>
            Cancel
          </Button>
          <Button color="green" onClick={this.onSubmit} inverted>
            <Icon name="save" />
            Update
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
  renderDiary() {
    const style = {
      borderRadius: 10,
      // opacity: 0.9,
      padding: "2em",
    };
    const CustomEvent = (event) => {
      let org = "";

      return (
        <Popup
          inverted
          position="top center"
          size="small"
          style={style}
          trigger={
            <div>
              <strong>{moment(event.event.start).format("HH:mm")} </strong> - {event.title} {org}
            </div>
          }
        >
          <Grid celled stackable className="popupGrid">
            <Grid.Row columns={1}>
              <Grid.Column textAlign="center">
                <p>{event.title}</p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column textAlign="center">
                <Header className="no-pad no-marg">Start</Header>
                <Divider />
                <p>{moment(event.event.start).format("HH:mm")}</p>
              </Grid.Column>
              <Grid.Column textAlign="center">
                <Header className="no-pad no-marg">End</Header>
                <Divider />
                <p>{moment(event.event.end).format("HH:mm")}</p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
              <Grid.Column textAlign="center">
                <p>{event.event.details}</p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Popup>
      );
    };
    return (
      <Grid stackable className="no-marg">
        <Grid.Row columns={1}>
          <Grid.Column style={{ paddingBottom: 20 }}>
            <Segment>
              <Calendar
                // views={["month", "week", "day"]}
                step={60}
                localizer={localizer}
                events={this.state.events}
                titleAccessor="type"
                startAccessor={(event) => {
                  return moment(event.start).toDate();
                }}
                endAccessor={(event) => {
                  return moment(event.end).toDate();
                }}
                defaultDate={new Date()}
                tooltipAccessor="details"
                // onSelectEvent={e => this.drillDown(e._id)}
                eventPropGetter={this.eventStyleGetter}
                components={{
                  event: CustomEvent,
                  // toolbar: this.CustomToolbarCapsule({ ADD: this.addEventShow })
                }}
                style={{ minHeight: "50vh" }}
              />
              <span className="key">Only showing events recruit is organising or going to.</span>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
  renderFullMenu() {
    const { activeItem } = this.state;

    return (
      <Menu stackable className="subMenu">
        <Menu.Item>
          <Header as={"h3"}>
            Recruit: {this.state.recruit.firstName} {this.state.recruit.lastName}
            <span className="subtle">{this.state.recruit.isArchived && <span style={{ color: "orange" }}>!!!!! Archived !!!!!</span>}</span>
          </Header>
        </Menu.Item>
        <Menu.Item name="dash" active={activeItem === "dash"} onClick={this.handleMenuClick}>
          Dash
        </Menu.Item>
        <Menu.Item name="address" active={activeItem === "address"} onClick={this.handleMenuClick}>
          Address
        </Menu.Item>

        <Menu.Item name="diary" active={activeItem === "diary"} onClick={this.handleMenuClick}>
          Diary
        </Menu.Item>
        <Menu.Item name="notes" active={activeItem === "notes"} onClick={this.handleMenuClick}>
          Notes
        </Menu.Item>
        <AuthComponents.Can scope={"members:create"}>
          {this.state.recruit.member === undefined && (
            <Menu.Item
              name="member"
              active={activeItem === "member"}
              onClick={() => {
                this.setState({
                  convertRec: true,
                });
              }}
            >
              Convert to Member
            </Menu.Item>
          )}
        </AuthComponents.Can>
        <Menu.Menu position={"right"}>
          {/* <Menu.Item>
            <Icon name="comment alternate" />
            Send Message
          </Menu.Item> */}
          <Menu.Item
            onClick={() => {
              this.setState({ editModal: true });
            }}
          >
            <Icon name="pencil" color="blue" />
            Edit
          </Menu.Item>
        </Menu.Menu>
      </Menu>
    );
  }
  renderModals() {
    return (
      <React.Fragment>
        {this.renderEditModal()}
        {this.renderAddressModal()}
      </React.Fragment>
    );
  }
  renderChecks() {
    return (
      <Segment>
        <Header as={"h3"} textAlign="center">
          Checks
        </Header>
        {this.state.checkTypes !== undefined && this.state.checkTypes.length > 0 ? (
          this.state.checkTypes.map((c, i) => {
            let bob = [];
            let disabled = true;
            bob =
              this.state.recruit.checks &&
              this.state.recruit.checks.filter((check) => {
                return check.name === c;
              });

            if (bob && bob.length > 0 && bob[0].date) {
              disabled = false;
            }

            return (
              <div key={i} className={`my-4 p-2 w-full rounded-md ${bob && bob.length > 0 && bob[0].date ? "bg-green-900" : "bg-red-900"} bg-opacity-20 text-xl `}>
                <div className="flex justify-between items-center">
                  <div>{c}</div>
                  <div>
                    <button onClick={() => this.addCheck(c)} className={`bg-green-800 p-2 text-center ${!disabled && "bg-opacity-20 cursor-not-allowed text-gray-500"}`}>
                      <Icon name="check" className="no-marg" />
                    </button>
                    <button onClick={() => this.removeCheck(c)} className={`bg-red-800 p-2 text-center ${disabled && "bg-opacity-20 cursor-not-allowed text-gray-500"}`}>
                      <Icon name="close" className="no-marg" />
                    </button>
                  </div>
                </div>
                <div>
                  {bob && bob.length > 0 && bob[0].date ? (
                    <div>
                      {moment(bob[0].date).format("DD-MM-YYYY")} <span className="text-sm italic text-gray-400">({bob[0].initials})</span>
                    </div>
                  ) : (
                    "--"
                  )}
                </div>
              </div>
            );
          })
        ) : (
          <div className="p-4">
            <Message color={"black"} icon="info" header="No checks" content={"No recruit checks set."} />
          </div>
        )}
      </Segment>
    );
  }
  render() {
    const { activeItem } = this.state;
    return (
      <Segment loading={this.state.loading} basic className="page-holder">
        {this.state.convertRec && (
          <RecruitConvertToMember
            recruit={this.state.recruit}
            onClose={() => {
              this.setState({
                convertRec: false,
              });
              this._getData();
            }}
          />
        )}
        {this.renderModals()}
        <Grid stackable className="no-marg">
          <Grid.Row className={" no-pad"}>
            <Grid.Column width={16} className={" no-pad"}>
              {this.renderFullMenu()}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={4}>

              <Segment className="subtle">
                {this.state.recruit._id && <DisplayAndUpdatePhoto
                  canUpdate={true}
                  onGetUploadSpec={this.handleGetUploadSpec}
                  onGetPhoto={this.handleGettingPhoto}
                  onUpload={this.handlePhotoUpload}
                />}
              </Segment>

              {this.renderChecks()}

              {/* <Divider horizontal>
                Roles{" "}
                {this.props.isAdmin && (
                  <Button
                    onClick={() =>
                      this.setState({ addRole: !this.state.addRole })
                    }
                    icon
                    inverted
                    circular
                    positive
                  >
                    <Icon name="plus" />
                  </Button>
                )}
              </Divider> */}

              <Segment basic className="userRoles">
                {this.state.recruit.roles !== undefined
                  ? this.state.recruit.roles.map((data) => {
                      return (
                        <Label key={data} style={{ margin: 5 }}>
                          {data}{" "}
                          {this.props.isAdmin && (
                            <Icon
                              name="delete"
                              onClick={() =>
                                this.setState({
                                  confirmOpen: true,
                                  removeRole: data,
                                })
                              }
                            />
                          )}
                        </Label>
                      );
                    })
                  : ""}
              </Segment>
            </Grid.Column>
            <Grid.Column width={12}>
              {activeItem === "dash" && this.renderDetails()}
              {activeItem === "address" && this.renderAddress()}
              {activeItem === "diary" && this.renderDiary()}
              {activeItem === "notes" && this.renderNotes()}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}
// const RecruitPage = withTranslation()(RecruitPage);
export default connect(mapStateToProps, { getSystemSettings })(RecruitPage);
