export default {
  app_storage_prefix: "teamsite--prod",
  api_server: "/api",
  central_server: "https://central.sarstuff.co.uk/api",
  bing_key: "AoMKVVkBRY3_8gv8UJmlIHXp8DCIGGzv9Yw_50Q4WIqC5ujgtVszQK-xLpB34hF2",
  auth: {
    clientId: "5db1be21d95fd021217b046a",
    loginServer: "https://central.sarstuff.co.uk"
  },
  logRocket: false
  // logRocket: {
  //     apiKey: "b3enbx/teamsite-prod"
  // }
};