import React, { useState, useEffect, Fragment } from "react";
import Box from "../ui-wrappers/Box";
import Input from "../ui-wrappers/Input";
import DatePicker from "react-date-picker";
import api from "../../../actions/api";
import dayjs from "dayjs";
import { Transition, Dialog, Listbox } from "@headlessui/react";

import { SelectorIcon, CheckIcon } from "@heroicons/react/outline";

export default function AssetServices({ asset, history, canUpdate }) {
  const [add, setAdd] = useState(false);
  const [part, setPart] = useState({
    qty: "",
    item: "",
    cost: "",
  });

  const [services, setServices] = useState([]);

  useEffect(() => {
    api.assets.service.all(asset._id).then((res) => {
      setServices(res);
    });
  }, []);
  // console.log(services);
  return (
    // <Layout full>
    <Fragment>
      <div className="p-4">
        <Box>
          <div className="flex justify-center gap-4 items-center border-b border-gray-700 p-6">
            <div className="text-xl text-gray-400">
              Next Service:{" "}
              <span className="text-2xl text-white ">
                {asset.NextService
                  ? dayjs(asset.NextService).format("DD-MM-YYYY")
                  : "--"}
              </span>{" "}
            </div>
            <div>
              {/* <Button
                  style={"text-lg"}
                  text="Update Assignment"
                  positive
                  action={AssignAsset}
                /> */}
            </div>
          </div>
        </Box>
      </div>
      <Box
        basic
        title="Services"
        button={
          !asset.archived &&
          canUpdate && {
            text: !add ? "Add Service" : "Show Services",
            colour: "bg-blue-600",
            action: () => history.push(`/assets/addservice/${asset._id}`),
            // action: () => setAdd(!add),
          }
        }
      >
        <table className="min-w-full divide-y divide-gray-200 border-separate table-fixed">
          <thead>
            <tr className="bg-gray-800">
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-400 uppercase ">
                Scheduled Date
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-400 uppercase ">
                Type
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-400 uppercase ">
                Status
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-400 uppercase ">
                Serviced Date
              </th>
            </tr>
          </thead>
          <tbody className="bg-gray-900 divide-y divide-gray-200 text-lg">
            {services.map((s) => {
              return (
                <tr
                  className="hover:bg-gray-800 cursor-pointer"
                  key={s._id}
                  onClick={() =>
                    history.push(`/assets/${asset._id}/service/${s._id}`)
                  }
                >
                  <td className="p-2 ">
                    {s.date_scheduled
                      ? dayjs(s.date_scheduled).format("DD-MM-YYYY")
                      : "--"}
                  </td>
                  <td className="p-2">{s.type}</td>
                  <td className="p-2 ">{s.status}</td>
                  <td className="p-2 ">
                    {s.date_serviced
                      ? dayjs(s.date_serviced).format("DD-MM-YYYY")
                      : "--"}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Box>
    </Fragment>
    // </Layout>
  );
}
