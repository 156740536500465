import React, { Component } from "react";
import { connect } from "react-redux";

import { getSystemSettings } from "../../../actions/settingsActions";
import { withTranslation } from "react-i18next";
import api from "../../../actions/api";
import VehicleCard from "../../common/cards/vehicleCard";
import CommonFunctions from "../../../lib/CommonFunctions";
function mapStateToProps(state) {
  return {
    system: state.system,
    user: state.user,
  };
}
class VehiclePage extends Component {
  state = {
    vehicle: {},
    user: {},
    addNew: false,
    events: [],
    locations: [],
  };
  componentDidMount() {
    this._getData();
    this.props.getSystemSettings();
    // this.props.i18n.changeLanguage("en");
  }
  componentDidUpdate(prevProps, prevState) {}

  _getData = () => {
    api.vehicles.show(this.props.match.params.vid).then((vehicle) => {
      this.setState(
        {
          vehicle,
        },
        () => {
          if (this.state.vehicle.member) {
            this.setState({
              user: this.state.vehicle.member,
            });
          }
        }
      );
    });
    api.service.allVehicle(this.props.match.params.vid).then((services) => {
      this.setState({
        services,
      });
    });
    api.location.all().then((res) => {
      this.setState({
        locations: res,
      });
    });
    api.assets
      .getbyAssigned("vehicle", this.props.match.params.vid)
      .then((assets) => {
        this.setState({
          assets,
        });
      });
  };
  updateVehicle = (data) => {
    return api.vehicles.update(this.props.match.params.vid, data).then(() => {
      this._getData();
    });
  };
  addService = (data) => {
    return api.service.add(data).then(() => {
      this._getData();
    });
  };
  updateService = (data) => {
    return api.service.update(data._id, data).then(() => {
      this._getData();
    });
  };
  toggleApprove = (data) => {
    return api.vehicles
      .toggleApprove(this.props.match.params.vid, data)
      .then(() => {
        this._getData();
      });
  };
  setAppraisal = (data) => {
    return api.vehicles
      .setAppraisal(this.props.match.params.vid, data)
      .then(() => {
        this._getData();
      });
  };

  render() {
    let permission = CommonFunctions.vehiclePermissions(this.state.user);
    return (
      // <Segment loading={this.state.loading} basic className="no-marg">
      <VehicleCard
        vehicle={this.state.vehicle}
        assets={this.state.assets}
        whoami={this.props.user}
        user={this.state.user}
        permissions={permission}
        settings={this.props.system.settings}
        update={this.updateVehicle}
        approve={this.toggleApprove}
        appraisal={this.setAppraisal}
        reload={() => this._getData()}
        locations={this.state.locations}
        services={this.state.services}
        addService={this.addService}
        updateService={this.updateService}
      />
      // </Segment>
    );
  }
}
const Vehicle = withTranslation()(VehiclePage);
export default connect(mapStateToProps, { getSystemSettings })(Vehicle);
