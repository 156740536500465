import React from "react";
import { connect } from "react-redux";
import { Grid, Segment } from "semantic-ui-react";
import {
  getSystemSettings,
  updateSetting,
} from "../../actions/settingsActions";
import SettingsList from "../common/lists/settingList";

import SettingListRequired from "../common/lists/settingListRequired";

function mapStateToProps(state) {
  return {
    system: state.system,
  };
}
class RecruitmentSettingsPage extends React.Component {
  state = {
    disabled: true,
    data: {
      teamName: "",
      systemName: "",
    },
  };
  updateFormTimeout = 0;
  componentDidMount() {
    this.getData();
  }
  getData = () => {
    return this.props.getSystemSettings();
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.system !== prevProps.system) {
      this.setState({
        data: this.props.system.settings,
      });
    }
    if (this.props.system !== prevProps.system) {
      if (
        this.props.system.settings &&
        this.props.system.settings.incidentTypes
      ) {
        this.setOptions("type", this.props.system.settings.incidentTypes);
      }
    }
  };
  toggleDisabled = () => {
    this.setState({
      disabled: !this.state.disabled,
    });
  };
  onChange = (e) =>
    this.setState(
      {
        data: { ...this.state.data, [e.target.name]: e.target.value },
      },
      () => {
        this.props.updateSetting(this.state.data, () => {});
      }
    );
  updateModel = (e) => {
    this.setState({
      updating: true,
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
    if (this.updateFormTimeout) {
      clearTimeout(this.updateFormTimeout);
    }
    this.updateFormTimeout = setTimeout(() => {
      return this.props
        .updateSetting(this.state.data)
        .then((res) => {
          this.setState({
            updating: false,
          });
          //TODO add a fancy screen notification that the title has successfully updated
        })
        .catch((err) => {
          console.error("Unable to save", err);
        });
    }, 500);
  };
  handleSubmit = (data) => {
    return this.props.updateSetting(data).then(() => {
      this.getData();
    });
  };
  handleDelete = (data) => {
    this.props.updateSetting(data, () => {});
  };
  setOptions = (key, types) => {
    if (types) {
      const data = types;
      let dataArray = [];
      for (var i in data) {
        dataArray.push({
          key: i,
          text: data[i].name,
          value: data[i].name,
        });
      }
      this.setState({ [key]: dataArray });
    }
  };

  renderRecruitment() {
    return (
      <Grid stackable doubling className={"no-border"}>
        <Grid.Row>
          <Grid.Column width={6}>
            <Segment>
              <SettingListRequired
                submit={this.handleSubmit}
                delete={this.handleDelete}
                data={
                  this.props.system.settings.recruitReq
                    ? this.props.system.settings.recruitReq
                    : []
                }
                placeholder="Induction evening.."
                title="Recruit Required Events"
                label="Event"
                field="recruitReq"
              />
            </Segment>
          </Grid.Column>
          <Grid.Column width={4}>
            <Segment>
              <SettingsList
                submit={this.handleSubmit}
                delete={this.handleDelete}
                data={
                  this.props.system.settings.source
                    ? this.props.system.settings.source
                    : []
                }
                placeholder="website..."
                title="Recruitment Source"
                field="source"
              />
            </Segment>
          </Grid.Column>
          <Grid.Column width={6}>
            <Segment>
              <SettingListRequired
                submit={this.handleSubmit}
                delete={this.handleDelete}
                data={
                  this.props.system.settings.vetting
                    ? this.props.system.settings.vetting
                    : []
                }
                placeholder="level 1"
                title="Vetting"
                label="Level"
                field="vetting"
              />
            </Segment>
            <Segment>
              <SettingsList
                submit={this.handleSubmit}
                delete={this.handleDelete}
                data={
                  this.props.system.settings.recruitChecks
                    ? this.props.system.settings.recruitChecks
                    : []
                }
                placeholder="Reference1,Medical...."
                title="Recruitment Checks"
                field="recruitChecks"
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  render() {
    return <Segment basic>{this.renderRecruitment()}</Segment>;
  }
}

export default connect(mapStateToProps, {
  getSystemSettings,
  updateSetting,
})(RecruitmentSettingsPage);
