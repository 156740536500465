import React from "react";
import { Segment } from "semantic-ui-react";
import {ChangeLogList} from "../../_changeLog";

export default function ChangeLog() {
  return (
    <Segment basic>
      <div className="text-xl p-2 text-center">Change Log</div>
      <table className="table-fixed min-w-full divide-y divide-gray-200 bg-black border border-gray-700">
        <tbody>{ChangeLogList.map((a, i) => {
            return (<tr key={i} className=" border border-gray-700">
                <td className=" px-2 py-2 whitespace-nowrap border border-gray-700">{a.date}</td>
                <td className=" px-2 py-2 whitespace-nowrap border border-gray-700">{a.type}</td>
                <td className="w-full px-2 py-2  border border-gray-700">{a.details}</td>
            </tr>);
        })}</tbody>
      </table>
    </Segment>
  );
}