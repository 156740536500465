import React, { useState, useEffect, useRef } from "react";
import Box from "../ui-wrappers/Box";
import Layout from "../ui-wrappers/Layout";
import Input from "../ui-wrappers/Input";
import Button from "../ui-wrappers/Button";
import Geosuggest from "react-geosuggest";
import { Authentication } from "@liquidcomputing/sarstuff__reactjs-singlesignon";
import api from "../../../actions/api";

export default function EditSupplier({ history, match }) {
  const _geoSuggest = useRef(null);
  const [supplier, setSupplier] = useState({
    name: "",
    phone: "",
    details: "",
    contactName: "",
    contactPhone: "",
    contactEmail: "",
    type: [],
  });
  const [country, setCountry] = useState("");
  const [settings, setSettings] = useState({
    supplierTypes: [],
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    let user_details = Authentication.getUserData();
    setCountry(user_details.organisation.country);
    api.settings.all().then((res) => {
      setSettings(res);
    });
    getData();
  }, []);
  const getData = () => {
    api.management.suppliers.show(match.params.id).then((res) => {
      setSupplier({ ...supplier, ...res });
    });
  };
  console.log(supplier);
  const validate = (data) => {
    const errors = {};
    if (!data.name) errors.name = "Can't be empty";
    if (data.type.length === 0) errors.type = "Can't be empty";
    return errors;
  };
  const onSupplierChange = (e) => {
    setSupplier({ ...supplier, [e.target.name]: e.target.value });
    const errors = validate(supplier);
    setErrors(errors);
  };
  const AddSupplier = () => {
    const errors = validate(supplier);
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      api.management.suppliers.update(supplier._id, supplier).then((res) => {
        history.push(`/assets/suppliers/${supplier._id}`);
      });
    }
  };
  const onSuggestSelect = (suggest) => {
    if (suggest) {
      let coords = [suggest.location.lng, suggest.location.lat];
      let components = suggest.gmaps.address_components;
      let admin_area = "";
      let postal_town = "";
      let postal_code = "";
      // eslint-disable-next-line
      let name = "";
      // eslint-disable-next-line
      let street = "";
      let county = "";
      let town = "";
      if (suggest.gmaps.name) {
        name = suggest.gmaps.name;
      }

      for (let component = 0; component < components.length; component++) {
        if (components[component].types[0] === "premise" && name === "") {
          name = components[component].long_name;
        }
        if (components[component].types[0] === "locality") {
          admin_area = components[component].long_name;
        }
        if (components[component].types[0] === "postal_town") {
          postal_town = components[component].long_name;
        }
        if (components[component].types[0] === "administrative_area_level_2") {
          county = components[component].long_name;
        }
        if (components[component].types[0] === "postal_code") {
          postal_code = components[component].long_name;
        }
        if (admin_area) {
          town = admin_area;
        } else {
          town = postal_town;
        }
      }

      setSupplier({
        ...supplier,
        address: {
          geoJSON: {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: coords,
            },
            properties: {
              name: name,
              town: town,
              county: county,
              postcode: postal_code,
              address: suggest.gmaps.formatted_address,
              // grid: this.getGrid(suggest.location.lat, suggest.location.lng),
            },
          },
        },
      });
    }
  };
  const manageTags = (data) => {
    const errors = validate(supplier);
    setErrors(errors);
    let bob = supplier.type.includes(data);
    if (bob) {
      let frank = supplier.type.filter((t) => {
        return t !== data;
      });
      setSupplier({
        ...supplier,
        type: frank,
      });
    } else {
      setSupplier({
        ...supplier,
        type: supplier.type.concat([data]),
      });
    }
  };
  return (
    <Layout>
      <div className="flex gap-4">
        <Box title="Details">
          <div className="p-2">
            <div className="flex flex-col gap-4">
              <Input
                error={errors.name}
                label={"Name"}
                field="name"
                type={"text"}
                value={supplier.name}
                onChange={onSupplierChange}
                placeholder="Sarstuff..."
              />
              <Input
                label={"Phone"}
                field="phone"
                type={"text"}
                value={supplier.phone}
                onChange={onSupplierChange}
                placeholder="Main phone..."
              />
              {/* <div className="flex flex-row gap-2">
                <Input
                  label={"Contact Name"}
                  field="contactName"
                  type={"text"}
                  value={supplier.contactName}
                  onChange={onSupplierChange}
                  placeholder="Joe Bloggs..."
                />
                <Input
                  label={"Contact Phone"}
                  field="contactPhone"
                  type={"text"}
                  value={supplier.contactPhone}
                  onChange={onSupplierChange}
                  placeholder="07973....."
                />
              </div>
              <Input
                label={"Contact Email"}
                field="contactEmail"
                type={"text"}
                value={supplier.contactEmail}
                onChange={onSupplierChange}
                placeholder="jb@sarstuff..."
              /> */}
              <div className="flex flex-col gap-4">
                <label
                  className={`block text-md font-medium font-mono text-yellow-700 `}
                >
                  Details
                </label>
                <textarea
                  rows={3}
                  name="details"
                  value={supplier.details}
                  onChange={onSupplierChange}
                  className={`p-2 bg-gray-900 focus:ring-gray-500 focus:border-gray-500 placeholder-gray-600 block w-full shadow-sm text-xl border-gray-600 rounded-md`}
                />
              </div>
            </div>
          </div>
        </Box>
        <Box title="Address">
          <div className="p-2">
            <div className="flex flex-col">
              <Geosuggest
                autoComplete="off"
                ref={_geoSuggest}
                placeholder="Molash, Kent"
                inputClassName="bg-gray-800 w-full text-white"
                country={country}
                onSuggestSelect={onSuggestSelect}
                suggestsClassName="bg-gray-900 "
                suggestsHiddenClassName="geosuggest__suggests--hidden"
                suggestItemClassName="p-2 border-b border-gray-600 cursor-pointer hover:bg-gray-800"
              />
              {supplier.address && supplier.address.geoJSON && supplier.address.geoJSON.properties && (
                <div className="flex flex-col gap-4 p-4">
                  <div
                    className={`bg-gray-800 p-2 block text-xl font-medium font-mono text-yellow-700 `}
                  >
                    Name:{" "}
                    <span className="text-white">
                      {supplier.address.geoJSON.properties.name}
                    </span>
                  </div>
                  <div
                    className={`bg-gray-800 p-2 block text-xl font-medium font-mono text-yellow-700 `}
                  >
                    Address:{" "}
                    <span className="text-white">
                      {supplier.address.geoJSON.properties.address}
                    </span>
                  </div>
                  <div
                    className={`bg-gray-800 p-2 block text-xl font-medium font-mono text-yellow-700 `}
                  >
                    Town:{" "}
                    <span className="text-white">
                      {supplier.address.geoJSON.properties.town}
                    </span>
                  </div>
                  <div
                    className={`bg-gray-800 p-2 block text-xl font-medium font-mono text-yellow-700 `}
                  >
                    Postcode:{" "}
                    <span className="text-white">
                      {supplier.address.geoJSON.properties.postcode}
                    </span>
                  </div>
                </div>
              )}
            </div>
            <div className="flex flex-col justify-around p-4 mt-4 border-t border-gray-400 ">
              <label
                className={`text-center block text-md font-medium font-mono text-yellow-700 `}
              >
                Type
              </label>
              {errors.type && (
                <span className="text-red-500">Please pick supplier type</span>
              )}
              <div className="flex justify-around p-4">
                {settings &&
                  settings.supplierTypes &&
                  settings.supplierTypes.map((t, i) => {
                    let active = supplier.type.includes(t);
                    return (
                      <span
                        onClick={() => manageTags(t)}
                        key={i}
                        className={`p-2 m-1 bg-gray-700 rounded-lg text-white cursor-pointer hover:bg-gray-600 ${
                          active && " bg-green-500 hover:bg-green-600"
                        }`}
                      >
                        {t}
                      </span>
                    );
                  })}
              </div>
            </div>
            <div className="flex justify-around p-4 mt-4 border-t border-gray-400 ">
              <Button text="Cancel" negative style={"text-2xl"} />
              <Button
                text="Update Supplier"
                positive
                style={"text-2xl"}
                action={AddSupplier}
              />
            </div>
          </div>
        </Box>
      </div>
    </Layout>
  );
}
