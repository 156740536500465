import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Segment,
  Header,
  Table,
  Grid,
  Modal,
  Button,
  Form,
  Input,
  TextArea,
  Confirm,
  Select,
} from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { getSystemSettings } from "../../../actions/settingsActions";
import api from "../../../actions/api";

function mapStateToProps(state) {
  return {
    system: state.system,
    user: state.user,
  };
}
const score = [
  { key: 1, text: "1", value: 1 },
  { key: 2, text: "2", value: 2 },
  { key: 3, text: "3", value: 3 },
  { key: 4, text: "4", value: 4 },
];
class CompetenciesPage extends Component {
  state = {
    comps: [],
    confirmDeleteSection: false,
    confirmDeleteCriteria: false,
    compOpen: false,
    sectionOpen: false,
    comp: {
      subject: "",
    },
    data: {
      refNum: 0,
      title: "",
      description: "",
    },
    section: {
      criteria: [],
    },
    criteria: {
      score: 0,
      details: "",
    },
  };
  componentDidMount() {
    this._getData();

    // this.props.i18n.changeLanguage("en");
  }
  componentDidUpdate(prevProps, prevState) {}

  _getData = () => {
    this.props.getSystemSettings();
    api.training.comps.all().then((comps) => {
      this.setState({
        comps,
      });
    });
  };
  onChange = (e) =>
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  onCriteriaChange = (e) =>
    this.setState({
      criteria: { ...this.state.criteria, [e.target.name]: e.target.value },
    });
  handleCriteriaDropdownChange = (event, { name, value }) => {
    this.setState({
      criteria: {
        ...this.state.criteria,
        [name]: value,
      },
    });
  };
  addSection = () => {
    let data = this.state.data;
    data.competency = this.state.comp._id;
    api.training.comps.addSection(data).then(() => {
      this.setState({
        compOpen: false,
        data: {
          refNum: 0,
          title: "",
          description: "",
        },
      });
      this._getData();
    });
  };
  updateSection = (section) => {
    this.setState({
      section,
      sectionOpen: true,
    });
  };
  updateCriteria = (criteria) => {
    this.setState({
      criteria,
    });
  };
  addCriteria = () => {
    let data = this.state.section;

    if (this.state.criteria._id) {
      api.training.comps
        .updateSectionCriteria(
          data._id,
          this.state.criteria._id,
          this.state.criteria
        )
        .then(() => {
          this.setState({
            sectionOpen: false,
            section: {
              criteria: [],
            },
            criteria: {
              score: 0,
              details: "",
            },
          });
          this._getData();
        });
    } else {
      data.criteria.push(this.state.criteria);
      api.training.comps.addSection(data).then(() => {
        this.setState({
          sectionOpen: false,
          section: {
            criteria: [],
          },
          criteria: {
            score: 0,
            details: "",
          },
        });
        this._getData();
      });
    }
  };

  confirmDelete = (data) => {
    this.setState({
      confirmDeleteSection: true,
      deleteSection: data,
    });
  };
  confirmDeleteCriteria = (data) => {
    this.setState({
      confirmDeleteCriteria: true,
      deleteCriteria: data,
    });
  };
  deleteSection = () => {
    api.training.comps.deleteSection(this.state.deleteSection).then(() => {
      this.setState({
        compOpen: false,
        confirmDeleteSection: false,
      });
      this._getData();
    });
  };
  deleteCriteria = () => {
    api.training.comps
      .deleteSectionCriteria(this.state.section._id, this.state.deleteCriteria)
      .then(() => {
        this.setState({
          sectionOpen: false,
          confirmDeleteCriteria: false,
          deleteCriteria: "",
        });
        this._getData();
      });
  };
  compModal = () => {
    return (
      <Modal
        open={this.state.compOpen}
        onClose={() => this.setState({ compOpen: false })}
        centered={false}
      >
        <Modal.Header>Competency {this.state.comp.subject}</Modal.Header>
        <Modal.Content>
          <Segment>
            <Form onSubmit={this.addSection}>
              <Form.Group>
                <Form.Field width={3}>
                  <label>Ref</label>
                  <Input
                    name="refNum"
                    type={"number"}
                    value={this.state.data.refNum}
                    required
                    onChange={this.onChange}
                  />
                </Form.Field>
                <Form.Field width={11}>
                  <label>Title</label>
                  <Input
                    name="title"
                    value={this.state.data.title}
                    required
                    onChange={this.onChange}
                  />
                </Form.Field>
                <Form.Field width={2}>
                  <label>&nbsp;</label>
                  <Button fluid positive type="submit">
                    Save
                  </Button>
                </Form.Field>
              </Form.Group>
              <Form.Field>
                <TextArea
                  name="description"
                  placeholder="Description"
                  onChange={this.onChange}
                  value={this.state.data.description}
                />
              </Form.Field>
            </Form>
          </Segment>
          <Segment className="no-pad">
            <Table striped structured>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Ref</Table.HeaderCell>
                  <Table.HeaderCell>Title</Table.HeaderCell>
                  <Table.HeaderCell>Description</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.state.comp.Sections &&
                  this.state.comp.Sections.map((s, i) => {
                    return (
                      <Table.Row key={i}>
                        <Table.Cell collapsing>
                          {this.state.comp.refNum}.{s.refNum}
                        </Table.Cell>
                        <Table.Cell
                          className="selectable"
                          onClick={() =>
                            this.setState({
                              data: s,
                            })
                          }
                        >
                          {s.title}
                        </Table.Cell>
                        <Table.Cell>{s.description}</Table.Cell>
                        <Table.Cell collapsing>
                          <Button
                            icon="close"
                            negative
                            size={"tiny"}
                            onClick={() => this.confirmDelete(s._id)}
                          />
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
              </Table.Body>
            </Table>
          </Segment>
        </Modal.Content>
        <Modal.Actions>
          <Button
            basic
            inverted
            onClick={() => this.setState({ compOpen: false })}
          >
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };
  getScoreColour = (score) => {
    let settings = this.props.system.settings.compScoreColours;
    let colour = "#222";
    if (score) {
      colour = settings.filter((s) => {
        return s.content === score.toString();
      });
    }
    if (colour.length) {
      return colour[0].color;
    }
    return colour;
  };
  renderComps() {
    const data = this.state.comps.map((d, i) => {
      const sections = d.Sections.length + 1;
      return (
        <React.Fragment key={i}>
          <Table.Row className="sectionRow">
            <Table.Cell rowSpan={sections} textAlign="center" collapsing>
              <Header as={"h2"} className="flourish">
                {d.refNum}
              </Header>
            </Table.Cell>
            <Table.Cell
              collapsing
              rowSpan={sections}
              textAlign="center"
              className="selectable"
            >
              <Button
                fluid
                inverted
                onClick={() => this.setState({ compOpen: true, comp: d })}
              >
                {d.subject}
              </Button>
            </Table.Cell>
          </Table.Row>
          {d.Sections.map((s) => {
            return (
              <Table.Row key={s._id} className="sectionRow">
                <Table.Cell collapsing>
                  <Header as={"h3"} className="flourish">
                    {d.refNum}.{s.refNum}
                  </Header>
                </Table.Cell>
                <Table.Cell onClick={() => this.updateSection(s)}>
                  <Button inverted>{s.title}</Button>
                </Table.Cell>
                <Table.Cell className="no-pad">
                  <Table striped>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell textAlign="center">
                          Score
                        </Table.HeaderCell>
                        <Table.HeaderCell>Details</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {s.criteria.map((c) => {
                        let colour = this.getScoreColour(c.score);
                        return (
                          <Table.Row key={c._id}>
                            <Table.Cell
                              style={{ background: colour }}
                              collapsing
                              textAlign="center"
                            >
                              {c.score}
                            </Table.Cell>
                            <Table.Cell>{c.details}</Table.Cell>
                          </Table.Row>
                        );
                      })}
                    </Table.Body>
                  </Table>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </React.Fragment>
      );
    });
    return (
      <Table striped structured celled>
        {this.compModal()}
        {this.renderSectionsModal()}
        <Confirm
          open={this.state.confirmDeleteSection}
          header="Delete Section"
          onCancel={() => this.setState({ confirmDeleteSection: false })}
          onConfirm={this.deleteSection}
        />
        <Confirm
          open={this.state.confirmDeleteCriteria}
          header="Delete Crteria"
          onCancel={() => this.setState({ confirmDeleteCriteria: false })}
          onConfirm={this.deleteCriteria}
        />
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan={5} textAlign="center">
              <Header as={"h2"}>Competencies</Header>
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row className="sectionRow">
            <Table.HeaderCell textAlign="center">Ref</Table.HeaderCell>
            <Table.HeaderCell>Subject</Table.HeaderCell>
            <Table.HeaderCell colSpan={3}>Sections</Table.HeaderCell>
            {/* <Table.HeaderCell></Table.HeaderCell> */}
          </Table.Row>
        </Table.Header>
        <Table.Body>{data}</Table.Body>
        <Table.Footer fullWidth>
          <Table.Row>
            <Table.Cell></Table.Cell>
          </Table.Row>
        </Table.Footer>
      </Table>
    );
  }
  renderSectionsModal() {
    return (
      <Modal
        open={this.state.sectionOpen}
        onClose={() => this.setState({ sectionOpen: false })}
        centered={false}
      >
        <Modal.Header>Section {this.state.section.title}</Modal.Header>
        <Modal.Content>
          <Segment>
            <Form onSubmit={this.addCriteria}>
              <Form.Group>
                <Form.Field width={4}>
                  <label>Score</label>
                  {/* <Input
                    name="score"
                    value={this.state.criteria.score}
                    required
                    onChange={this.onCriteriaChange}
                  /> */}
                  <Select
                    name="score"
                    value={this.state.criteria.score}
                    onChange={this.handleCriteriaDropdownChange}
                    options={score}
                  />
                </Form.Field>
                <Form.Field width={10}>
                  <label>Criteria</label>
                  <Input
                    name="details"
                    value={this.state.criteria.details}
                    required
                    onChange={this.onCriteriaChange}
                  />
                </Form.Field>
                <Form.Field width={2}>
                  <label>&nbsp;</label>
                  <Button fluid positive type="submit">
                    Save
                  </Button>
                </Form.Field>
              </Form.Group>
            </Form>
          </Segment>
          <Segment className="no-pad">
            <Table striped structured>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Score</Table.HeaderCell>
                  <Table.HeaderCell>Criteria</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.state.section &&
                  this.state.section.criteria.map((s) => {
                    return (
                      <Table.Row key={s._id}>
                        <Table.Cell collapsing>{s.score}</Table.Cell>
                        <Table.Cell
                          className="selectable"
                          onClick={() => this.updateCriteria(s)}
                        >
                          {s.details}
                        </Table.Cell>
                        <Table.Cell collapsing>
                          <Button
                            negative
                            onClick={() => this.confirmDeleteCriteria(s._id)}
                            icon="close"
                          />
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
              </Table.Body>
            </Table>
          </Segment>
        </Modal.Content>
        <Modal.Actions>
          <Button
            basic
            inverted
            onClick={() => this.setState({ sectionOpen: false })}
          >
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
  render() {
    return (
      <Segment loading={this.state.loading} basic className="no-marg no-pad">
        <Grid celled>
          <Grid.Row>
            <Grid.Column width={16}>{this.renderComps()}</Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}></Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}
const TheCompetenciesPage = withTranslation()(CompetenciesPage);
export default connect(mapStateToProps, { getSystemSettings })(
  TheCompetenciesPage
);
