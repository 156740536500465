import React, { Component } from "react";
import { connect } from "react-redux";
import { Segment, Header, Grid, Button } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { getSystemSettings } from "../../../actions/settingsActions";
import api from "../../../actions/api";
import CompScoreList from "./compScoreList";

function mapStateToProps(state) {
  return {
    system: state.system,
    user: state.user,
  };
}

class FocusReport extends Component {
  state = {
    chosen: {
      id: "",
      subject: "",
      refNum: "",
    },
    comps: [],
  };

  componentDidMount() {
    this._getData();
    this.props.getSystemSettings();
    // this.props.i18n.changeLanguage("en");
  }

  componentDidUpdate(prevProps, prevState) {}

  _getData = () => {
    Promise.all([
      api.training.comps.all(),
      // api.management.compScoresList("60912f24075e50122df35919"),
    ])
      .then((res) => {
        this.setState({
          comps: res[0],
          scores: res[1],
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 404) {
          console.log("not found");
        }
        console.log(err);
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  renderCompButtons() {
    return (
      <Segment>
        {this.state.comps.map((c) => {
          return (
            <Button
              style={{ margin: "0.2rem" }}
              toggle
              active={this.state.chosen.id === c._id}
              key={c._id}
              onClick={() =>
                this.setState({
                  chosen: { id: c._id, subject: c.subject, refNum: c.refNum },
                })
              }
            >
              {c.subject}
            </Button>
          );
        })}
      </Segment>
    );
  }

  renderComps() {
    return (
      <CompScoreList
        comp={this.state.chosen}
        settings={this.props.system.settings}
      />
    );
  }

  render() {
    return (
      <Segment loading={this.state.loading} basic>
        <Header textAlign="center" as={"h1"}>
          Focus Report
        </Header>
        <Grid>
          <Grid.Row>
            <Grid.Column width={16}>
              {this.renderCompButtons()}
              <Header as={"h2"} textAlign="center">
                {this.state.chosen.subject}
              </Header>
              {this.renderComps()}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}

const FocusReportPage = withTranslation()(FocusReport);

export default connect(mapStateToProps, { getSystemSettings })(FocusReportPage);
