import React, { Component } from "react";
import {
  Segment,
  Header,
  Grid,
  Table,
  Button,
  Message,
} from "semantic-ui-react";
import api from "../../../actions/api";
import dayjs from "dayjs";

export default class IncidentPage extends Component {
  state = {
    incident: {
      periods: [],
    },
    members: [],
  };
  componentDidMount() {
    this.getData();
  }
  getData = () => {
    api.management.incidents
      .show(this.props.match.params.id)
      .then((incident) => {
        this.setState({
          incident,
        });
        api.management.incidents.incident
          .getMembers(incident._id)
          .then((members) => {
            this.setState({
              members,
            });
          });
      });
  };
  assignMember = (m, p) => {
    api.management.incidents.incident
      .assignMember(this.state.incident._id, m.memberId, p._id)
      .then(() => {
        this.getData();
      });
  };
  unassignMember = (m, p) => {
    api.management.incidents.incident
      .unassignMember(this.state.incident._id, m.memberId, p._id)
      .then(() => {
        this.getData();
      });
  };
  isMemberAssigned = (m, p) => {
    const exists = this.state.members.filter((item) => {
      return item.memberId === m.memberId && item.periods.indexOf(p._id) !== -1;
    });
    return exists[0] !== undefined;
  };
  renderPeriods() {
    return this.state.incident.periods.map((p) => {
      return (
        <Grid.Column key={p._id}>
          <Segment className="no-pad">
            <Header as={"h3"} textAlign="center" style={{ paddingTop: 10 }}>
              Op Period: {p.refNum} - {dayjs(p.start).format("DD-MM-YY HH:mm")}
            </Header>
            {p.members.length === 0 ? (
              <Message info>No resources within this Operation Period</Message>
            ) : (
              <Table>
                <Table.Body>
                  {p.members.map((m) => {
                    return (
                      <Table.Row key={m._id}>
                        <Table.Cell>{m.name}</Table.Cell>
                        <Table.Cell>
                          {m.memberId !== null ? (
                            <React.Fragment>
                              {this.isMemberAssigned(m, p) ? (
                                <Button
                                  negative
                                  onClick={() => this.unassignMember(m, p)}
                                >
                                  Unassign
                                </Button>
                              ) : (
                                <Button
                                  positive
                                  onClick={() => this.assignMember(m, p)}
                                >
                                  Assign
                                </Button>
                              )}
                            </React.Fragment>
                          ) : (
                            <Message info>
                              Member not in TeamSite at time of import
                            </Message>
                          )}
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
            )}
          </Segment>
        </Grid.Column>
      );
    });
  }
  render() {
    const { incident } = this.state;
    return (
      <Segment basic>
        <Header as={"h1"} textAlign="center">
          Incident
        </Header>
        <Segment className="no-pad">
          <Grid className="no-marg">
            <Grid.Row columns={1}>
              <Grid.Column>
                <Header as={"h2"} textAlign="center">
                  {incident.name}
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={3}>
              <Grid.Column>
                <Header as={"h3"}>
                  Date Incident Started: {"  "}
                  <span>{dayjs(incident.opened).format("DD-MM-YY HH:mm")}</span>
                </Header>
              </Grid.Column>
              <Grid.Column>
                <Header as={"h3"}>
                  Incident Type: {"  "}
                  <span>{incident.type}</span>
                </Header>
              </Grid.Column>
              <Grid.Column>
                <Header as={"h3"}>
                  Incident Outcome: {"  "}
                  <span>{incident.outcome}</span>
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={3}>{this.renderPeriods()}</Grid.Row>
          </Grid>
        </Segment>
      </Segment>
    );
  }
}
