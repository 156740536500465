import React, { useState, useEffect, Suspense, lazy } from "react";
import api from "../../../actions/api";
import Box from "../ui-wrappers/Box";
import Layout from "../ui-wrappers/Layout";
import dayjs from "dayjs";
import { Tab, Transition, Dialog } from "@headlessui/react";
import {
  AuthComponents,
  Authentication,
} from "@liquidcomputing/sarstuff__reactjs-singlesignon";

import Tooltip from "../ui-wrappers/Tooltip";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import AssetContents from "./assetContents";
import { PencilIcon, PrinterIcon } from "@heroicons/react/outline";
import AssetServices from "./assetServices";
const AssignAsset = lazy(() => import("./assignAsset"));

const returnTabButtonStyle = (selected) => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  return classNames(
    "w-full py-2.5 text-xl  font-medium  ]",
    selected
      ? "text-gray-100 bg-green-900 bg-opacity-50"
      : "text-blue-100 hover:bg-gray-900 hover:text-white bg-gray-800"
  );
};

export default function AssetPage({ match, history }) {
  const [asset, setAsset] = useState({});
  const [scheduled, setScheduled] = useState(null);
  const [check, setCheck] = useState(false);
  const [viewCheck, setViewCheck] = useState(false);
  const [checkID, setCheckID] = useState("");
  const [theCheck, setTheCheck] = useState({
    date_checked: "",
    date_scheduled: "",
    status: "",
    note: "",
  });
  const [assetChecks, setAssetChecks] = useState([]);
  const canUpdate = Authentication.can("assets:update");
  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    api.assets.show(match.params.id).then((res) => {
      setAsset(res);
    });
    api.assets.check.all(match.params.id).then((res) => {
      setAssetChecks(res);
    });
  };
  const onTheCheckChange = (e) => {
    setTheCheck({ ...theCheck, [e.target.name]: e.target.value });
  };
  const handleAddCheck = () => {
    api.assets.check.add(asset._id, theCheck).then(() => {
      setCheck(false);
      getData();
      setTheCheck({
        date_checked: "",
        date_scheduled: "",
        status: "",
        note: "",
      });
    });
  };
  const handleUpdateCheck = () => {
    api.assets.check.update(asset._id, checkID, theCheck).then(() => {
      setViewCheck(false);
      getData();
      setTheCheck({
        date_checked: "",
        date_scheduled: "",
        status: "",
        note: "",
      });
    });
  };
  const AddCheckDialog = () => {
    return (
      <Transition
        show={check}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Dialog
          open={check}
          onClose={() => setCheck(false)}
          className="fixed z-10 inset-0 overflow-y-auto "
        >
          <div className="text-center min-h-screen">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-80" />
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="z-40 border-4 border-gray-700 inline-block w-full max-w-2xl p-4 my-2   transition-all transform bg-gray-800 shadow-xl rounded-2xl">
              <div className="border-b border-gray-900">
                <Dialog.Title className="text-2xl">
                  Add Asset Check for{" "}
                  <span className="italic">{asset.name}</span>
                </Dialog.Title>
              </div>

              <div className="mt-4 w-full text-2xl text-center">
                Is this a future scheduled check?
              </div>
              <div className="flex justify-around mt-4 border-t border-gray-700 pt-4">
                <button
                  className={`text-xl px-4 py-4 bg-blue-600 rounded-lg ${
                    scheduled === false && "bg-opacity-20"
                  } ${
                    scheduled === true &&
                    "ring ring-yellow-700 ring-offset-1 ring-offset-bak"
                  }`}
                  onClick={() => setScheduled(true)}
                >
                  Yes
                </button>
                <button
                  className={`text-xl px-4 py-4 bg-blue-600 rounded-lg disabled:bg-red-500 ${
                    scheduled === true && "bg-opacity-20"
                  } ${
                    scheduled === false &&
                    "ring ring-yellow-700 ring-offset-1 ring-offset-bak"
                  }`}
                  onClick={() => setScheduled(false)}
                >
                  No
                </button>
              </div>
              <div className="flex justify-around mt-4 border-t border-gray-700 pt-4">
                {scheduled === false && (
                  <div className="py-2 w-full">
                    <label
                      className={`pb-1 block text-lg font-medium text-yellow-700 `}
                    >
                      Date Checked
                    </label>

                    <DatePicker
                      name="date_checked"
                      onChange={(date) =>
                        setTheCheck({
                          ...theCheck,
                          date_checked: date,
                        })
                      }
                      maxDate={new Date()}
                      className="z-50"
                      value={theCheck.date_checked}
                    />

                    <label
                      className={`py-2 block text-md font-medium font-mono text-yellow-700 `}
                    >
                      Note
                    </label>
                    <textarea
                      rows={3}
                      name="note"
                      value={theCheck.note}
                      onChange={onTheCheckChange}
                      className={`p-2 bg-gray-900 mt-1 focus:ring-gray-500 focus:border-gray-500 placeholder-gray-600 block w-full shadow-sm text-xl border-gray-600 rounded-md`}
                    />
                    <div className="flex justify-around mt-4 border-t border-gray-700 pt-4">
                      <button
                        className={`text-xl px-4 py-4 bg-gray-900 rounded-lg disabled:bg-red-500 ${
                          theCheck.status === "Ok" && "bg-opacity-20 border"
                        } ${
                          theCheck.status === "Faulty" &&
                          "bg-red-600 ring ring-yellow-700 ring-offset-1 ring-offset-bak border-0"
                        }`}
                        onClick={() =>
                          setTheCheck({
                            ...theCheck,
                            status: "Faulty",
                          })
                        }
                      >
                        Faulty
                      </button>
                      <button
                        className={`text-xl px-4 py-4 bg-gray-900 rounded-lg disabled:bg-red-500 ${
                          theCheck.status === "Faulty" && "bg-opacity-20 border"
                        } ${
                          theCheck.status === "OK" &&
                          "bg-green-600 ring ring-yellow-700 ring-offset-1 ring-offset-bak border-0"
                        }`}
                        onClick={() =>
                          setTheCheck({
                            ...theCheck,
                            status: "OK",
                          })
                        }
                      >
                        OK
                      </button>
                    </div>
                  </div>
                )}
                {scheduled && (
                  <div className="py-2 w-full mb-6">
                    <label
                      className={`pb-1 block text-lg font-medium text-yellow-700 `}
                    >
                      Date Scheduled
                    </label>
                    <DatePicker
                      name="date_scheduled"
                      onChange={(date) =>
                        setTheCheck({
                          ...theCheck,
                          date_scheduled: date,
                        })
                      }
                      minDate={new Date()}
                      className="z-50"
                      value={theCheck.date_scheduled}
                    />

                    {/* <DatePicker
                      onChange={(date) =>
                        setTheCheck({
                          ...theCheck,
                          date_scheduled: date,
                        })
                      }
                      inline
                      todayButton="Today"
                      showPopperArrow={false}
                      // calendarContainer={MyContainer}
                      selected={theCheck.date_scheduled}
                      shouldCloseOnSelect={true}
                      dateFormat="dd-MM-yyyy"
                      minDate={new Date()}
                      nextMonthButtonLabel=">"
                      previousMonthButtonLabel="<"
                      className="block w-full text-base p-1 md:text-lg bg-gray-900 border-2 border-gray-700 rounded shadow-sm"
                    /> */}
                  </div>
                )}
              </div>
              <div className="flex justify-between mt-4 border-t border-gray-700 pt-4">
                <button
                  className="px-4 py-2 text-lg bg-blue-600 rounded-lg"
                  onClick={() => setCheck(false)}
                >
                  Cancel
                </button>
                <button
                  className="px-4 py-2 text-lg bg-red-600 rounded-lg disabled:bg-red-500"
                  onClick={handleAddCheck}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  };
  const ViewCheckDialog = () => {
    return (
      <Transition
        show={viewCheck}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Dialog
          open={viewCheck}
          onClose={() => setViewCheck(false)}
          className="fixed z-10 inset-0 overflow-y-auto "
        >
          <div className="text-center min-h-screen">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-80" />
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="border-4 border-gray-700 inline-block w-full max-w-2xl p-4 my-2 text-center align-middle transition-all transform bg-gray-800 shadow-xl rounded-2xl">
              <div className="border-b border-gray-900">
                <Dialog.Title>
                  View Asset Check for{" "}
                  <span className="italic">
                    {asset.name} {asset.assetNum}
                  </span>
                </Dialog.Title>
              </div>
              <div className="flex justify-around mt-4 border-t border-gray-700 pt-4">
                <div className="py-2 w-full">
                  <label
                    className={`py-2 block text-md font-medium font-mono text-yellow-700 `}
                  >
                    Note
                  </label>
                  <textarea
                    rows={3}
                    name="note"
                    value={theCheck.note}
                    onChange={onTheCheckChange}
                    className={`p-2 bg-gray-900 mt-1 focus:ring-gray-500 focus:border-gray-500 placeholder-gray-600 block w-full shadow-sm text-xl border-gray-600 rounded-md`}
                  />
                  <label
                    className={`pb-1 block text-lg font-medium text-yellow-700 `}
                  >
                    Date Checked
                  </label>
                  <DatePicker
                    name="date_checked"
                    onChange={(date) =>
                      setTheCheck({
                        ...theCheck,
                        date_checked: date,
                      })
                    }
                    maxDate={new Date()}
                    className="z-50 p-4 rounded-lg"
                    value={theCheck.date_checked}
                  />
                  {/* <DatePicker
                    onChange={(date) =>
                      setTheCheck({
                        ...theCheck,
                        date_checked: date,
                      })
                    }
                    selected={theCheck.date_checked}
                    shouldCloseOnSelect={true}
                    dateFormat="dd-MM-yyyy"
                    maxDate={new Date()}
                    nextMonthButtonLabel=">"
                    previousMonthButtonLabel="<"
                    className="block w-full text-base p-1 md:text-lg bg-gray-900 border-2 border-gray-700 rounded shadow-sm"
                  /> */}

                  <div className="flex justify-around mt-4 border-t border-gray-700 pt-4">
                    <button
                      className={`text-xl px-4 py-4 bg-gray-900 rounded-lg disabled:bg-red-500 ${
                        theCheck.status === "OK" && "bg-opacity-20 border"
                      } ${
                        theCheck.status === "Faulty" &&
                        "bg-red-600 ring ring-yellow-700 ring-offset-1 ring-offset-bak border-0"
                      }`}
                      onClick={() =>
                        setTheCheck({
                          ...theCheck,
                          status: "Faulty",
                        })
                      }
                    >
                      Faulty
                    </button>
                    <button
                      className={`text-xl px-4 py-4 bg-gray-900 rounded-lg disabled:bg-red-500 ${
                        theCheck.status === "Faulty" && "bg-opacity-20 border"
                      } ${
                        theCheck.status === "OK" &&
                        "bg-green-600 ring ring-yellow-700 ring-offset-1 ring-offset-bak border-0"
                      }`}
                      onClick={() =>
                        setTheCheck({
                          ...theCheck,
                          status: "OK",
                        })
                      }
                    >
                      OK
                    </button>
                  </div>
                </div>
              </div>
              <div className="flex justify-between mt-4 border-t border-gray-700 pt-4">
                <button
                  className="px-4 py-2 bg-red-600 rounded-lg"
                  onClick={() => setViewCheck(false)}
                >
                  Cancel
                </button>
                <button
                  disabled={theCheck.status === "" ? true : false}
                  className="px-4 py-2 bg-blue-600 rounded-lg disabled:bg-red-500"
                  onClick={handleUpdateCheck}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  };
  const viewCheckData = (data) => {
    if (!data.date_checked) {
      setViewCheck(true);
      setCheckID(data._id);
    }
  };
  const goto = (data) => {
    switch (data.type) {
      case "member":
        history.push(`/team/${data.id}`);
        break;
      case "location":
        history.push(`/training/locations/${data.id}`);
        break;
      case "vehicle":
        history.push(`/team/vehicles/${data.id}`);
        break;

      default:
        break;
    }
  };
  const gotoSupplier = (id) => {
    history.push(`/assets/suppliers/${id}`);
  };
  // console.log(asset);
  return (
    <Layout full>
      {AddCheckDialog()}
      {ViewCheckDialog()}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
        <div className="col-span-1 ">
          <Box title="Asset Details">
            <div className="w-full ">
              <div className="flex justify-around gap-1">
                <AuthComponents.Can scope={"assets:update"}>
                  <button
                    onClick={() => history.push(`/assets/edit/${asset._id}`)}
                    className="w-full bg-gray-700 p-2 text-xl flex justify-center items-center hover:bg-blue-900"
                  >
                    <PencilIcon className="h-6 pr-2 text-blue-400" />
                    Edit
                  </button>
                </AuthComponents.Can>
                {/*<button className="w-full bg-gray-700 p-2 text-xl flex justify-center items-center hover:bg-blue-900">*/}
                {/*  <PrinterIcon className="h-6 pr-2 text-blue-400" />*/}
                {/*  Print*/}
                {/*</button>*/}
              </div>
              <div className="p-2 flex justify-between text-xl border-b border-gray-700 bg-gray-800 text-sar">
                Name:{" "}
                {asset.archived && (
                  <span className="text-red-600 italic">** Archived **</span>
                )}{" "}
                <span className="px-2 text-white">{asset.name}</span>
              </div>
              {asset.archived && (
                <div className="p-2 flex justify-between text-xl border-b border-gray-700 bg-gray-800 text-sar">
                  Archived Date:{" "}
                  <span className="px-2 text-red-600">
                    {dayjs(asset.date_archived).format("DD-MM-YYYY")}
                  </span>
                </div>
              )}
              <div className="p-2 flex justify-between text-xl border-b border-gray-700 bg-gray-800 text-sar">
                Asset Num:{" "}
                <span className="px-2 text-white">
                  {asset.assetNum ? asset.assetNum : "--"}
                </span>
              </div>
              <div className="p-2 flex justify-between text-xl border-b border-gray-700 bg-gray-800 text-sar">
                Serial Num:{" "}
                <span className="px-2 text-white">
                  {asset.serialNum ? asset.serialNum : "--"}
                </span>
              </div>
              <div className="p-2 flex justify-between text-xl border-b border-gray-700 bg-gray-800 text-sar">
                Purchase Date:{" "}
                <span className="px-2 text-white">
                  {asset.date_purchased
                    ? dayjs(asset.date_purchased).format("DD-MM-YYYY")
                    : "--"}
                </span>
              </div>
              <div className="p-2 flex justify-between text-xl border-b border-gray-700 bg-gray-800 text-sar">
                Expiry Date:{" "}
                <span className="px-2 text-white">
                  {asset.date_expires
                    ? dayjs(asset.date_expires).format("DD-MM-YYYY")
                    : "--"}
                </span>
              </div>
              <div className="p-2 flex justify-between text-xl border-b border-gray-700 bg-gray-800 text-sar">
                Tags:
                <span className="px-2 text-white flex">
                  {asset.tags &&
                    asset.tags.map((t, i) => {
                      return (
                        <div
                          key={i}
                          className={`py-1 px-2 m-1 bg-gray-700 rounded-lg text-white cursor-pointer hover:bg-gray-600 `}
                        >
                          {t}
                        </div>
                      );
                    })}
                </span>
              </div>
              <div className="has-tooltip p-2 flex justify-between text-xl border-b border-gray-700 bg-gray-800 text-sar">
                <span className="flex items-center">
                  Taskable:
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 pl-1"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="orange"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <Tooltip text={"Assign asset in SarSys"} />
                </span>
                {asset.taskable && (
                  <span className="px-2 text-green-600">Yes</span>
                )}
                {!asset.taskable && (
                  <span className="px-2 text-red-600">No</span>
                )}
              </div>
              {asset.taskable && (
                <div className="p-2 flex justify-between text-xl border-b border-gray-700 bg-gray-800 text-gray-400">
                  <div className="text-sar">Taskable Type :</div>{" "}
                  <span className="px-2 text-white">{asset.taskableType}</span>
                </div>
              )}
              <div className="p-2 flex justify-between text-xl border-b border-gray-700 bg-gray-800 text-gray-400">
                <div className="text-sar">
                  Service Interval{" "}
                  <span className="italic text-yellow-700">(months)</span>:
                </div>{" "}
                <span className="px-2 text-white">{asset.serviceInterval}</span>
              </div>
              <div className="p-2 flex justify-between text-xl border-b border-gray-700 bg-gray-800 text-gray-400">
                <div className="text-sar">Last Service :</div>{" "}
                <span className="px-2 text-white">
                  {asset.LastService
                    ? dayjs(asset.LastService).format("DD-MM-YYYY")
                    : "--"}
                </span>
              </div>
              <div className="p-2 flex justify-between text-xl border-b border-gray-700 bg-gray-800 text-sar">
                Status: <span className="px-2 text-white">{asset.status}</span>
              </div>
              <div className="p-2 flex justify-between text-xl border-b border-gray-700 bg-gray-800 text-sar">
                Assigned:{" "}
                <span className="px-2 text-white">
                  {asset.AssignedTo && asset.AssignedTo.name ? (
                    <button
                      onClick={() => goto(asset.AssignedTo)}
                      className="text-right bg-gray-800 py-2 px-4 border border-gray-700 rounded-xl hover:bg-gray-900"
                    >
                      {asset.AssignedTo.name}
                    </button>
                  ) : (
                    "--"
                  )}
                </span>
              </div>
              <div className="p-2 flex justify-between text-xl border-b border-gray-700 bg-gray-800 text-sar">
                Supplier:{" "}
                <span className="px-2 text-white">
                  {asset.supplier && asset.supplier.name ? (
                    <button
                      onClick={() => gotoSupplier(asset.supplier._id)}
                      className="text-right bg-gray-800 py-2 px-4 border border-gray-700 rounded-xl hover:bg-gray-900"
                    >
                      {asset.supplier.name}
                    </button>
                  ) : (
                    "--"
                  )}
                </span>
              </div>
              <div className="p-2 flex justify-between text-xl border-b border-gray-700 bg-gray-800 text-sar">
                Details:{" "}
                <span className="px-2 text-white">{asset?.details?.split("\n").map(a => (<>{a}<br/></>))}</span>
              </div>
            </div>
          </Box>
        </div>
        <div className="col-span-2 	">
          <Box>
            <Tab.Group>
              <Tab.List className="flex  space-x-1 bg-blue-900/20 rounded-xl">
                <Tab
                  className={({ selected }) => returnTabButtonStyle(selected)}
                >
                  Checks
                </Tab>

                <Tab
                  className={({ selected }) => returnTabButtonStyle(selected)}
                >
                  Services
                </Tab>
                <AuthComponents.Can scope={"assets:update"}>
                  <Tab
                    className={({ selected }) => returnTabButtonStyle(selected)}
                  >
                    Assign Asset
                  </Tab>
                </AuthComponents.Can>
                {asset.hasContents && (
                  <Tab
                    className={({ selected }) => returnTabButtonStyle(selected)}
                  >
                    Contents
                  </Tab>
                )}
              </Tab.List>
              <Tab.Panels>
                <Tab.Panel>
                  <div className="md:flex gap-4">
                    <div
                      className="md:flex-1 w-full flex-grow-0 overflow-y-auto"
                      style={{ height: "70vh" }}
                    >
                      <Box
                        basic
                        title="Checks"
                        button={
                          !asset.archived && {
                            text: "Add Check",
                            colour: "bg-green-600",
                            action: () => canUpdate && setCheck(true),
                          }
                        }
                      >
                        <table className="  min-w-full divide-y divide-gray-200 border-separate table-auto ">
                          <thead>
                            <tr className="bg-gray-900 text-md">
                              <th className="w-2/12 px-4 py-3 text-left  font-medium text-gray-400 uppercase ">
                                Date
                              </th>
                              <th className="px-4 py-3 text-left font-medium text-gray-400 uppercase ">
                                Details
                              </th>
                              <th className="px-4 py-3 text-center  font-medium text-gray-400 uppercase ">
                                Status
                              </th>
                              <th className="px-4 py-3 text-left  font-medium text-gray-400 uppercase ">
                                Checked By
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {assetChecks.map((c) => {
                              return (
                                <tr
                                  key={c._id}
                                  className={`text-lg  bg-gray-800  ${
                                    c.alert && " bg-red-900 bg-opacity-30"
                                  } hover:bg-gray-700 ${
                                    c.date_checked && "cursor-not-allowed"
                                  }
                                  ${canUpdate ? "cursor-pointer" : ""}`}
                                  onClick={() => canUpdate && viewCheckData(c)}
                                >
                                  <td className="p-2">
                                    {c.theDate
                                      ? dayjs(c.theDate).format("DD-MM-YYYY ")
                                      : "--"}
                                  </td>
                                  <td className="p-2">{c.note}</td>
                                  <td className="p-2 w-2/12 text-center">
                                    {c.status}
                                  </td>
                                  <td className="p-2 w-3/12 ">
                                    {c.checked_By}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </Box>
                    </div>
                  </div>
                </Tab.Panel>

                <Tab.Panel>
                  <div className="md:flex gap-4">
                    <div className="md:flex-1 w-full flex-grow-0">
                      <Suspense fallback={<div>Loading...</div>}>
                        <AssetServices
                          canUpdate={canUpdate}
                          asset={asset}
                          action={getData}
                          history={history}
                        />
                      </Suspense>
                    </div>
                  </div>
                </Tab.Panel>
                <AuthComponents.Can scope={"assets:update"}>
                  <Tab.Panel>
                    <div className="md:flex gap-4">
                      <div className="md:flex-1 w-full flex-grow-0">
                        <Suspense fallback={<div>Loading...</div>}>
                          <AssignAsset asset={asset} action={getData} />
                        </Suspense>
                      </div>
                    </div>
                  </Tab.Panel>
                </AuthComponents.Can>
                {asset.hasContents && (
                  <Tab.Panel>
                    <div className="md:flex gap-4">
                      <div className="md:flex-1 w-full flex-grow-0">
                        <Suspense fallback={<div>Loading...</div>}>
                          <AssetContents
                            asset={asset}
                            action={getData}
                            canUpdate={canUpdate}
                          />
                        </Suspense>
                      </div>
                    </div>
                  </Tab.Panel>
                )}
              </Tab.Panels>
            </Tab.Group>
          </Box>
        </div>
      </div>
    </Layout>
  );
}
