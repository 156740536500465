import React                                              from "react";
import {Segment, Icon, Image, Message, Button, Container} from "semantic-ui-react";
import Logo                                               from "../assets/images/logo.png";

export default class ErrorCatchPage extends React.Component {
    state = {
        isError: false
    };
    componentDidCatch(error, info){
        // Display fallback UI
        // console.log("## ERROR ##", JSON.stringify({error, info}));
        this.setState({isError: true});
    }
    render(){
        if (this.state.isError){
            return (
                <Container>
                    <Segment basic textAlign={"center"}>
                        <Image src={Logo} style={{width: 250, margin: "auto"}}/>
                        <Message negative>
                            <Message.Header as={"h1"} style={{fontSize: "1.75em"}}><Icon name={"bug"}/> There seems to be a problem <Icon name={"bug"}/></Message.Header><br/>
                            Oh no, it looks like you have found a bug!<br/>
                            Please help us improve Team Site and report this bug to <a style={{color: "blue"}} href={"mailto:support@sarstuff.co.uk"}>support@sarstuff.co.uk</a> detailing the exact steps you have taken prior to seeing this screen.
                        </Message>
                        <Button positive onClick={() => window.location="/"}><Icon name={"refresh"}/> Reload Team Site</Button>
                    </Segment>
                </Container>
            );
        }
        return this.props.children;
    }
}