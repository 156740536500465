import React, { Component } from "react";
import ReactMapboxGl, {
  ZoomControl,
  ScaleControl,
  GeoJSONLayer,
} from "react-mapbox-gl";
import { Segment } from "semantic-ui-react";
import * as turf from "@turf/turf";
import Default_Styles from "../styles/mainStyles";
const { token, styles } = require("../config.json");
const Map = ReactMapboxGl({
  accessToken: token,
  preserveDrawingBuffer: true,
});
const selectedStyles = ["outdoor", "sat"];
const switchStyles = Object.keys(styles).filter((k) =>
  selectedStyles.includes(k)
);
export default class ListMap extends Component {
  state = {
    styleKey: "outdoor",

    marker: {},
    loading: true,
  };
  componentDidMount() {
    if (this.props.locations && this.props.locations.length) {
      if (this.map) {
        setTimeout(() => {
          this.mapBounds();
        }, 500);
      }
    }
    if (this.props.vehicles && this.props.vehicles.length) {
      if (this.map) {
        setTimeout(() => {
          this.mapBounds();
        }, 500);
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.locations !== prevProps.locations) {
      this.mapBounds();
    }
  }
  onStyleLoad = (map) => {
    const { onStyleLoad } = this.props;
    this.setState({
      loading: false,
    });
    return onStyleLoad && onStyleLoad(map);
  };
  nextStyle = () => {
    const { styleKey } = this.state;
    const currentIndex = switchStyles.indexOf(styleKey);
    const nextIndex =
      currentIndex === switchStyles.length - 1 ? 0 : currentIndex + 1;
    this.setState({
      styleKey: switchStyles[nextIndex],
    });
  };
  resetMap = () => {
    setTimeout(() => {
      this.mapBounds();
    }, 500);
    return this.map.state.map.flyTo({
      center: this.props.center,
      zoom: 14,
      bearing: 0,
      pitch: 0,
    });
  };
  mapBounds = () => {
    let data = [];
    if (this.map) {
      if (this.props.locations && this.props.locations.length) {
        this.props.locations.map((loc, i) => {
          if (loc.geoJSON) {
            if (
              loc.geoJSON.geometry &&
              loc.geoJSON.geometry.coordinates.length
            ) {
              data.push(loc.geoJSON.geometry.coordinates);
            }
          }
          return null;
        });
        if (data.length) {
          let list = turf.multiPoint(data);
          let bbox = turf.bbox(list);
          this.map.state.map.fitBounds(bbox, { padding: 180, animate: false });
        }
      }
      if (this.props.vehicles && this.props.vehicles.length) {
        this.props.vehicles.map((loc, i) => {
          if (loc.member && loc.member.geoJSON) {
            if (
              loc.member.geoJSON.geometry &&
              loc.member.geoJSON.geometry.coordinates.length
            ) {
              data.push(loc.member.geoJSON.geometry.coordinates);
            }
          }
          return null;
        });
        if (data.length) {
          let list = turf.multiPoint(data);
          let bbox = turf.bbox(list);
          this.map.state.map.fitBounds(bbox, { padding: 180, animate: false });
        }
      }
      //
    }
  };
  renderLocations() {
    if (this.props.locations)
      if (this.props.locations.length) {
        return this.props.locations.map((loc, i) => {
          if (loc.geoJSON) {
            if (
              loc.geoJSON.geometry &&
              loc.geoJSON.geometry.coordinates.length
            ) {
              return (
                <GeoJSONLayer
                  data={loc.geoJSON}
                  linePaint={Default_Styles.linePaint}
                  circlePaint={Default_Styles.circlePaint}
                  symbolLayout={Default_Styles.symbolLayout}
                  symbolPaint={
                    this.state.styleKey === "outdoor"
                      ? Default_Styles.lightSymbolPaint
                      : Default_Styles.symbolPaint
                  }
                  key={i}
                />
              );
            }
          }
          return null;
        });
      }
  }
  renderVehicles() {
    if (this.props.vehicles)
      if (this.props.vehicles.length) {
        return this.props.vehicles.map((loc, i) => {
          if (loc.member && loc.member.geoJSON) {
            if (
              loc.member.geoJSON.geometry &&
              loc.member.geoJSON.geometry.coordinates.length
            ) {
              let data = loc.member.geoJSON;
              data.properties.member = loc.member.name;
              data.properties.vehicle = loc.make + " " + loc.model;
              return (
                <GeoJSONLayer
                  data={data}
                  linePaint={Default_Styles.linePaint}
                  circlePaint={Default_Styles.circlePaint}
                  symbolLayout={Default_Styles.vehicleSymbolLayout}
                  symbolPaint={
                    this.state.styleKey === "outdoor"
                      ? Default_Styles.lightSymbolPaint
                      : Default_Styles.symbolPaint
                  }
                  key={i}
                />
              );
            }
          }
          return null;
        });
      }
  }
  render() {
    let mapStyle = {
      height: 100 + "%",
      width: 100 + "%",
    };
    if (this.props.mapStyle) {
      mapStyle = this.props.mapStyle;
    } else {
      mapStyle = {
        minHeight: 70 + "vh",
        width: 100 + "%",
      };
    }

    const { styleKey } = this.state;
    return (
      <Segment className="mapHolder no-pad" basic>
        <Map
          ref={(e) => {
            this.map = e;
          }}
          // zoom={[14]}
          center={this.props.center}
          style={styles[styleKey]}
          onStyleLoad={this.onStyleLoad}
          className="basicMap"
          //   onClick={this.onClickMap}
          containerStyle={mapStyle}
        >
          <ZoomControl />
          <ScaleControl position={"bottom-left"} />
          {this.props.locations && this.renderLocations()}
          {this.props.vehicles && this.renderVehicles()}
        </Map>
      </Segment>
    );
  }
}
