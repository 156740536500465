import React, { Component } from "react";
import { Table } from "semantic-ui-react";
import moment from "moment";
export default class logList extends Component {
  renderLogs() {
    if (this.props.data && this.props.data.length) {
      const logs = this.props.data.map((item) => {
        return (
          <Table.Row
            key={item._id}
            warning={item.type === "alert" ? true : false}
          >
            <Table.Cell width="3">
              {moment(item.createdAt).format("DD-MM-YYYY HH:mm")}
            </Table.Cell>
            <Table.Cell width={1}>{item.type}</Table.Cell>
            <Table.Cell>{item.message}</Table.Cell>
            <Table.Cell width="3">{item.user}</Table.Cell>
            <Table.Cell width="2">{item.table}</Table.Cell>
          </Table.Row>
        );
      });
      return logs;
    }
  }
  render() {
    return (
      <Table stackable celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Log</Table.HeaderCell>
            <Table.HeaderCell>User</Table.HeaderCell>
            <Table.HeaderCell>Table</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{this.renderLogs()}</Table.Body>
      </Table>
    );
  }
}
