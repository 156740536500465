import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Menu, Header, Dropdown } from "semantic-ui-react";

function mapStateToProps(state) {
  return {
    user: state.user,
    system: state.system,
  };
}

class TrainingNav extends React.Component {
  state = { activeItem: "home" };
  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name });
  };
  componentDidMount() {}
  render() {
    const { activeItem } = this.state;
    return (
      <Menu stackable className={"sub-menu"}>
        <Menu.Item>
          <Header as={"h3"} className={"subtle"}>
            Training Menu
          </Header>
        </Menu.Item>

        <Menu.Item
          name={"dash"}
          as={NavLink}
          to={"/management/training"}
          active={activeItem === "dash"}
          exact
          onClick={this.handleItemClick}
        >
          Dash
        </Menu.Item>
        <Dropdown text="Competency" className="link item">
          <Dropdown.Menu>
            <Dropdown.Item
              name={"competency"}
              as={NavLink}
              to={"/management/training/competency"}
              active={activeItem === "competency"}
              exact
              onClick={this.handleItemClick}
            >
              Competency List
            </Dropdown.Item>
            <Dropdown.Item
              name={"comps"}
              as={NavLink}
              to={"/management/training/comps"}
              active={activeItem === "comps"}
              onClick={this.handleItemClick}
            >
              Competency Sessions
            </Dropdown.Item>

            <Dropdown.Item
              name={"add"}
              as={NavLink}
              exact
              to={"/management/training/comps/add"}
              active={activeItem === "add"}
              onClick={this.handleItemClick}
            >
              Add A Competency Session
            </Dropdown.Item>

            {/* <Dropdown.Item>Search</Dropdown.Item> */}
          </Dropdown.Menu>
        </Dropdown>
        <Menu.Item
          name={"focus"}
          as={NavLink}
          to={"/management/training/focus"}
          active={activeItem === "focus"}
          exact
          onClick={this.handleItemClick}
        >
          Focus Report
        </Menu.Item>
        <Menu.Item
          name={"attendance"}
          as={NavLink}
          to={"/management/training/attendance"}
          active={activeItem === "attendance"}
          exact
          onClick={this.handleItemClick}
        >
          Attendance
        </Menu.Item>
        <Menu.Item
          name={"courses"}
          as={NavLink}
          to={"/management/training/coursereport"}
          active={activeItem === "courses"}
          exact
          onClick={this.handleItemClick}
        >
          Course Report
        </Menu.Item>
      </Menu>
    );
  }
}

export default connect(mapStateToProps, {})(TrainingNav);
