import React from "react";
import moment from "moment";
import { Header, Segment, Divider, Grid } from "semantic-ui-react";
import api from "../../../actions/api";
import { withRouter } from "react-router";
import TheLoader from "../TheLoader";
class MemberCompList extends React.Component {
  state = {
    courses: [],
    compData: [],
    loadingVetting: true,
  };

  componentDidMount() {
    if (this.props.centralID) {
      this._getData();
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.centralID !== prevProps.centralID) {
      this._getData();
    }
  }
  _getData = () => {
    if (this.props.centralID) {
      api.member.comps(this.props.centralID).then((courses) => {
        this.setState({
          courses,
          loadingVetting: false,
        });
      });
    }
  };
  renderDate = (date) => {
    if (date) {
      return moment(date).format("DD/MM/YY");
    } else {
      return "--";
    }
  };
  checkDate = (date) => {
    let now = new moment();
    let theDate = moment(date);
    let expiresIn = theDate.diff(now, "months");
    return expiresIn;
  };
  getComps = () => {
    let comps = [];
    if (this.props.settings.courses) {
      comps = this.props.settings.courses
        .filter((i) => {
          return i.isRequired === true;
        })
        .map((req) => {
          return {
            name: req.name,
            expires: "",
            validFor: req.valid,
          };
        });
    }
    if (this.state.courses !== undefined) {
      for (let index = 0; index < this.state.courses.length; index++) {
        const theRequire = this.state.courses[index];
        let reqExists = comps.filter((i) => {
          return i.name === theRequire.title;
        });
        if (reqExists[0] === undefined) {
          comps.push({
            name: theRequire.title,
          });
        }
      }
      comps = comps.map((i) => {
        let reqExists = this.state.courses.filter((r) => {
          return r.title === i.name;
        });
        if (reqExists[0] === undefined) {
          return i;
        }
        return {
          ...i,
          ...reqExists[0],
        };
      });
    }
    return comps;
  };
  renderComps() {
    let warning = 3;
    let competency = this.getComps();
    let status = "";
    let disabled = false;
    if (competency && competency.length > 0) {
      return competency.map((item, i) => {
        status = "";
        if (item.expires === "" && item.appliedFor === "") {
          disabled = true;
        }
        let month = this.checkDate(item.expires);
        if (item.expires) {
          switch (true) {
            case month < 0:
              status = "negative1";
              break;
            case month < warning:
              status = "warning";
              break;
            default:
              status = "positive1";
              break;
          }
        }
        return (
          <Grid.Column key={i}>
            <TheLoader
              active={this.state.loadingVetting}
              content="Loading competency"
            />
            <Segment className={`reqBox ${status}`} raised disabled={disabled}>
              <Header as="h2">
                {item.name} {item.isRequired && "*"}
              </Header>
              <Divider />
              <Header className="no-marg">
                Valid For: <strong>{item.validFor} year/s</strong>
              </Header>
              <Header>
                Expires: <strong>{this.renderDate(item.expires)}</strong>
              </Header>
            </Segment>
          </Grid.Column>
        );
      });
    }
    return (
      <Grid.Column textAlign="center" width={16}>
        <Header textAlign="center">no competency or courses required</Header>
      </Grid.Column>
    );
  }
  render() {
    return (
      <Grid stackable>
        <Grid.Row className={"no-pad"}>
          <Grid.Column width={16}>
            <p className={"boxHeader padded"}>Required Courses</p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={4} style={{ padding: 10 }} stretched>
          {this.renderComps()}
        </Grid.Row>
      </Grid>
    );
  }
}
export default withRouter(MemberCompList);
