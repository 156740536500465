import React, { Component } from "react";
import { connect } from "react-redux";
import {Segment, Modal, Header, Menu, MenuItem, Message} from "semantic-ui-react";
import api from "../../../actions/api";
import CourseList from "../../common/lists/courseList";
import { getSystemSettings } from "../../../actions/settingsActions";
import PaginateWrapper from "../../common/PaginateWrapper";
import AddCourse from "./addCourse";
import CommonFunctions from "../../../lib/CommonFunctions";

function mapStateToProps(state) {
  return {
    system: state.system,
    user: state.user,
  };
}

class CourseDash extends Component {
  state = {
    addCourse: false,
    activeItem: "current",
    permissions: {},
  };
  componentDidMount() {
    let permissions = CommonFunctions.eventPermisions();
    this.setState({
      permissions,
    });
  }

  getCourses_Current = (pageNum, pageSize) => {
    return api.training.courses.all({
      pageNum,
      pageSize,
      current: true,
    });
  };
  getCourses_Past = (pageNum, pageSize) => {
    return api.training.courses.all({
      pageNum,
      pageSize,
      past: true,
    });
  };
  handleMenuClick = (e, { name }) => this.setState({ activeItem: name });
  goto = (id) => {
    this.props.history.push(`/training/courses/${id}`);
  };
  addCourseModal = () => {
    return (
      <Modal
        open={this.state.addCourse}
        onClose={() => this.setState({ addCourse: false })}
        centered={false}
        closeOnDimmerClick={false}
      >
        <Header icon="image" content="Update Photo" />
        <Modal.Content>
          <AddCourse />
        </Modal.Content>
      </Modal>
    );
  };
  renderMenu() {
    const { activeItem } = this.state;

    return (
      <Menu>
        <MenuItem>
          <Header as={"h2"}>Courses Menu</Header>
        </MenuItem>
        <MenuItem
          name="current"
          active={activeItem === "current"}
          onClick={this.handleMenuClick}
        >
          Current Course
        </MenuItem>
        {this.state.permissions.canUpdate && (
          <MenuItem
            name="past"
            active={activeItem === "past"}
            onClick={this.handleMenuClick}
          >
            Past Courses
          </MenuItem>
        )}
        {this.state.permissions.canUpdate && (
          <MenuItem
            name="add"
            active={activeItem === "add"}
            onClick={this.handleMenuClick}
          >
            Add Course
          </MenuItem>
        )}
      </Menu>
    );
  }
  renderAddCourse() {
    return (
      <AddCourse cancel={() => this.setState({ activeItem: "current" })} />
    );
  }
    renderDeletedCourseMessage(){
        if(this.props.history.location.state !== undefined && this.props.history.location.state !== null){
            if(this.props.history.location.state.courseRemoved !== undefined){
                return (
                    <Message positive>Successfully deleted the selected course from the database.</Message>
                );
            }
        }
        return null;
    }
  renderCurrentCourses() {
    return (
      <Segment basic>
        <p className={"boxHeader"}>Current Courses</p>
        {this.renderDeletedCourseMessage()}
        <PaginateWrapper
          dataQuery={this.getCourses_Current}
          render={(items) => <CourseList data={items} action={this.goto} />}
        />
      </Segment>
    );
  }
  renderPastCourses() {
    return (
      <Segment basic>
        <p className={"boxHeader"}>Past Courses</p>
        <PaginateWrapper
          dataQuery={this.getCourses_Past}
          render={(items) => <CourseList data={items} action={this.goto} />}
        />
      </Segment>
    );
  }
  render() {
    const { activeItem } = this.state;

    return (
      <Segment className="no-pad" basic>
        {this.addCourseModal()}
        {this.renderMenu()}

        {activeItem === "current" && this.renderCurrentCourses()}
        {activeItem === "past" && this.renderPastCourses()}
        {activeItem === "add" && this.renderAddCourse()}
      </Segment>
    );
  }
}
export default connect(mapStateToProps, { getSystemSettings })(CourseDash);
