import React from "react";

import {
  Segment,
  Button,
  Input,
  Form,
  Header,
  Divider,
  Checkbox,
  Icon,
  Table,
} from "semantic-ui-react";

export default class SettingListRequired extends React.Component {
  state = {
    textvalue: "",
    isRequired: false,
    formData: [],
  };
  componentDidMount() {}
  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.setState({
        formData: this.props.data,
      });
    }
  }
  // handleChange = e => {
  //   this.setState({
  //     textvalue: e.target.value
  //   });
  // };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleChangeComplete = (data) => {
    this.setState({ isRequired: data });
  };
  handleSubmit = () => {
    let fd = this.state.formData;
    fd.push({
      content: this.state.textvalue,
      isRequired: this.state.isRequired,
    });
    this.setState({
      formData: fd,
      textvalue: "",
      isRequired: false,
    });
    let field = this.props.field;
    const data = {};
    data[field] = this.state.formData;
    this.props.submit(data);
  };
  handleDelete = (obj) => {
    let newList = this.state.formData.filter((item, i) => {
      return obj !== item;
    });
    this.setState({
      formData: newList,
    });
    let field = this.props.field;
    const data = {};
    data[field] = newList;
    this.props.delete(data);
  };
  render() {
    const form = (
      <Segment basic>
        <Form onSubmit={this.handleSubmit}>
          <Input
            name="textvalue"
            onChange={this.handleChange}
            value={this.state.textvalue}
            placeholder={this.props.placeholder}
            action={{ icon: "plus", color: "green" }}
            fluid
          />
          <Checkbox
            style={{ padding: 0.5 + "rem" }}
            label="required"
            checked={this.state.isRequired}
            onChange={(e) =>
              this.setState({ isRequired: !this.state.isRequired })
            }
          />
        </Form>
      </Segment>
    );
    let list;
    if (this.props.data) {
      list = this.props.data.map((item, i) => {
        return (
          <Table.Row key={i}>
            <Table.Cell>{item.content}</Table.Cell>
            <Table.Cell textAlign="center">
              {item.isRequired ? (
                <Icon name="check" size="large" />
              ) : (
                <Icon name="close" size="large" />
              )}
            </Table.Cell>
            <Table.Cell>
              <Button
                circular
                icon="close"
                floated="right"
                size="tiny"
                color="red"
                onClick={() => this.handleDelete(item)}
              />
            </Table.Cell>
          </Table.Row>
        );
      });
    }

    return (
      <Segment basic>
        <Header>{this.props.title}</Header>
        <Divider />
        {form}

        <Table stackable className={"settingTable"}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{this.props.label}</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                is Required?
              </Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{list}</Table.Body>
        </Table>
      </Segment>
    );
  }
}
