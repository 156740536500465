import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Segment,
  Header,
  Grid,
  Button,
  Table,
  Message,
  Loader,
  Checkbox,
} from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { getSystemSettings } from "../../../actions/settingsActions";
import api from "../../../actions/api";
import CommonFunctions from "../../../lib/CommonFunctions";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

function mapStateToProps(state) {
  return {
    system: state.system,
    user: state.user,
  };
}

function CoursesReport(props) {
  const [chosen, setChosen] = useState({ id: "", subject: "" });
  const [courses, setCourses] = useState([]);
  const [displayAll, setDisplayAll] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [noData, setNoData] = useState(false);

  const [mountLoading, setMountLoading] = useState(true);

  useEffect(() => {
    if (mountLoading) {
      props.getSystemSettings();
      //     // this.props.i18n.changeLanguage("en");
      setMountLoading(false);
    }
  }, [mountLoading, props]);

  useEffect(() => {
    if (chosen.subject !== "") {
      setError(false);
      setNoData(false);
      setLoading(true);

      api.management
        .courseReport(chosen.subject)
        .then((res) => {
          if (res.length < 1) {
            setNoData(true);
          }

          setCourses(res);
          setLoading(false);
        })
        .catch((e) => {
          setError(true);
          setLoading(false);
        });
    } else {
      setNoData(false);
      setCourses([]);
    }
  }, [chosen.subject]);

  const renderCompButtons = () => {
    return (
      <Segment>
        {props.system &&
        props.system.settings &&
        props.system.settings.courses &&
        props.system.settings.courses.length > 0 ? (
          props.system.settings.courses
            .filter((c) => {
              return displayAll === false ? c.isRequired : true;
            })
            .map((course) => {
              return (
                <Button
                  style={{ margin: "0.2rem" }}
                  toggle
                  active={chosen.id === course._id}
                  key={course._id}
                  onClick={() => {
                    if (chosen.id === course._id) {
                      setChosen({ id: "", subject: "" });
                    } else {
                      setChosen({
                        id: course._id,
                        subject: course.name,
                      });
                    }
                  }}
                >
                  {course.name}
                </Button>
              );
            })
        ) : (
          <React.Fragment />
        )}
      </Segment>
    );
  };

  return (
    <Segment basic>
      <Header textAlign="center" as={"h1"}>
        Course Report
      </Header>
      <Grid>
        <Grid.Row>
          <Grid.Column textAlign="right">
            <Checkbox
              label="Display All Courses"
              checked={displayAll}
              onClick={() => {
                setDisplayAll(!displayAll);
                setChosen({ id: "", subject: "" });
              }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>{renderCompButtons()}</Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            {loading === false && error === false ? (
              <React.Fragment>
                <Header as={"h2"} textAlign="center">
                  {chosen.subject}
                </Header>
                {courses.length > 0 || noData === true ? (
                  <React.Fragment>
                    {courses.length > 0 && (
                      <Segment basic className="no-hpad" textAlign="right">
                        <Button
                          content="Export To .CSV"
                          onClick={() =>
                            CommonFunctions.generateCSV(
                              "Courses Report",
                              [
                                {
                                  csv_field: "Member Name",
                                  database_field: "name",
                                },
                                {
                                  csv_field: "Course Start Date",
                                  database_field: "course_start",
                                },
                                {
                                  csv_field: "Course End Date",
                                  database_field: "course_end",
                                },
                                {
                                  csv_field: "Valid Until",
                                  database_field: "valid_until",
                                },
                                {
                                  csv_field: "Organiser",
                                  database_field: "organiser",
                                },
                              ],
                              courses
                            )
                          }
                        />
                      </Segment>
                    )}
                    <Table>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Member Name</Table.HeaderCell>
                          <Table.HeaderCell>Course Start Date</Table.HeaderCell>
                          <Table.HeaderCell>Course End Date</Table.HeaderCell>
                          <Table.HeaderCell>Valid Until</Table.HeaderCell>
                          <Table.HeaderCell>Organiser</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {courses.length > 0 ? (
                          courses.map((item, index) => {
                            return (
                              <Table.Row
                                key={index}
                                className={
                                  item.course_start &&
                                  item.course_end &&
                                  item.valid_until
                                    ? dayjs().isSame(
                                        item.valid_until,
                                        "DD/MM/YYYY",
                                        "day"
                                      ) ||
                                      dayjs().isAfter(
                                        dayjs(item.valid_until, "DD/MM/YYYY")
                                      )
                                      ? "negative1"
                                      : dayjs().isSame(
                                          dayjs(
                                            item.valid_until,
                                            "DD/MM/YYYY"
                                          ).subtract(3, "months"),
                                          "day"
                                        ) ||
                                        dayjs().isAfter(
                                          dayjs(
                                            item.valid_until,
                                            "DD/MM/YYYY"
                                          ).subtract(3, "months")
                                        )
                                      ? "warning1"
                                      : "positive1"
                                    : "warning1"
                                }
                              >
                                <Table.Cell>{item.name}</Table.Cell>
                                <Table.Cell>{item.course_start}</Table.Cell>
                                <Table.Cell>{item.course_end}</Table.Cell>
                                <Table.Cell>{item.valid_until}</Table.Cell>
                                <Table.Cell>{item.organiser}</Table.Cell>
                              </Table.Row>
                            );
                          })
                        ) : (
                          <Table.Row textAlign="center">
                            <Table.Cell colSpan="6">No Results</Table.Cell>
                          </Table.Row>
                        )}
                      </Table.Body>
                    </Table>
                  </React.Fragment>
                ) : (
                  <Message
                    info
                    icon="info circle"
                    header="No Parameters Set"
                    content="Please select an item from the list above to generate the report."
                  />
                )}
              </React.Fragment>
            ) : error === true && loading === false ? (
              <Message
                error
                icon="warning sign"
                header="An Error Has Been Encountered"
                content="An unexpected error has caused the reports generation to fail."
              />
            ) : (
              <Loader active />
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
}

const CoursesReportPage = withTranslation()(CoursesReport);

export default connect(mapStateToProps, { getSystemSettings })(
  CoursesReportPage
);
