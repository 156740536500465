import React, { useEffect, useState } from "react";
import { toast } from "react-semantic-toasts";
import { Button, Confirm, Segment } from "semantic-ui-react";
import API from "../actions/api";
import BuildNumber from "../_buildNumber";

const FrontendChecker = (props) => {
  const [showReloadWarning, setReloadWarning] = useState(false);

  const checkInterval = 5;

  const updateNotificationDescription = (
    <span>
      <p>
        There is a new version of the <strong>{props.appName}</strong>{" "}
        application. When you have a free moment please click the "Reload
        Application" button below to update to the latest version.
      </p>
      <Button
        positive
        size={"small"}
        floated={"right"}
        onClick={() => setReloadWarning(true)}
      >
        Update Application
      </Button>
    </span>
  );

  const checkForUpdates = () => {
    API._getBuildNumber()
      .then((res) => {
        if (res.toString() !== BuildNumber.toString()) {
          toast({
            type: "info",
            icon: "cloud download",
            title: "Update Available",
            description: updateNotificationDescription,
            time: checkInterval * 60 * 1000 - 5000,
          });
        }
      })
      .catch((err) => {
        // Do nothing, likely a network issue
      });
  };

  useEffect(() => {
    checkForUpdates();
    const interval = setInterval(() => {
      checkForUpdates();
    }, checkInterval * 60 * 1000);
    return () => {
      clearInterval(interval);
    };
  });

  if (showReloadWarning) {
    return (
      <Confirm
        open
        cancelButton={"Cancel Update"}
        confirmButton={"Update Application"}
        header={"application Update"}
        content={
          <Segment basic textAlign={"center"}>
            <h3 style={{ color: "red" }}>WARNING</h3>
            Please ensure your latest entry has been saved prior to continuing
            with this update
            <br />
            Any unsaved changes will be lost as this application will be
            restarted during the update process.
          </Segment>
        }
        onCancel={() => {
          setReloadWarning(false);
        }}
        onConfirm={() => {
          // eslint-disable-next-line
          window.location = "/";
        }}
      />
    );
  }
  return null;
};
export default FrontendChecker;
