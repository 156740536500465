import React, { Component } from "react";
import {
  Segment,
  Menu,
  Button,
  Grid,
  Header,
  Checkbox,
  Form,
} from "semantic-ui-react";
import { connect } from "react-redux";
import {
  AuthComponents,
  Authentication,
} from "@liquidcomputing/sarstuff__reactjs-singlesignon";
import { getSystemSettings } from "../../actions/settingsActions";
import VehicleList from "../common/lists/vehicleList";
import api from "../../actions/api";
import TheLoader from "../common/TheLoader";
import ListMap from "../common/mapping/listMap";
import CommonFunctions from "../../lib/CommonFunctions";

function mapStateToProps(state) {
  return {
    user: state.user,
    system: state.system,
  };
}

const vehicleSeatFilter = [
  { text: "1", value: 1 },
  { text: "2", value: 2 },
  { text: "3", value: 3 },
  { text: "4", value: 4 },
  { text: "5", value: 5 },
  { text: "6", value: 6 },
  { text: "7+", value: 7 },
];
class VehicleListPage extends Component {
  state = {
    vehicleList: [],
    filtered: [],
    filters: { unitOnly: true, approvedOnly: true },
    permissions: {},
    activeItem: "list",
    loading: true,
  };

  componentDidMount() {
    this._getData();
    this.props.getSystemSettings();
    let permissions = CommonFunctions.vehiclePermissions();
    this.setState({
      permissions,
    });
  }

  handleMenuClick = (e, { name }) => this.setState({ activeItem: name });

  _getData = () => {
    api.vehicles.all(false, false).then((res) => {
      this.setState(
        {
          vehicleList: res,
          filtered: res,
          loading: false,
        },
        () => this.handleFilter()
      );
    });
  };

  goto = (vid) => {
    if (this.state.permissions.canUpdateOthers) {
      this.props.history.push(`/team/vehicles/${vid}`);
    }
  };

  getRolesCount = (item) => {
    let total = 0;
    this.state.vehicleList.forEach((vehicle) => {
      if (vehicle.kit.indexOf(item) !== -1) {
        total++;
      }
    });
    return total;
  };

  handleFilter() {
    this.setState({
      filtered: this.state.vehicleList.filter((item) => {
        let status = true;

        // team vehicles only
        if (
          this.state.filters.unitOnly === true &&
          item.unitVehicle === false
        ) {
          status = false;
        }

        // approved vehicles only
        if (
          this.state.filters.approvedOnly === true &&
          item.approved === false
        ) {
          status = false;
        }

        if (
          this.state.filters.kit &&
          item.kit.find((kit) => kit === this.state.filters.kit) === undefined
        ) {
          return false;
        }

        if (
          this.state.filters.seats &&
          (this.state.filters.seats === "7+"
            ? item.seats >= 7
            : parseFloat(item.seats) === this.state.filters.seats) === false
        ) {
          return false;
        }

        return status;
      }),
    });
  }

  renderTeamList() {
    let privacyBypass = false;
    if (Authentication.can("members:privacy_bypass")) {
      privacyBypass = true;
    }

    return (
      <Grid className={"no-pad no-marg"}>
        <Grid.Row>
          <Grid.Column className=" no-pad">
            <Segment className="no-pad">
              <VehicleList
                data={this.state.filtered}
                action={this.goto}
                privacyBypass={privacyBypass}
                selectable={
                  this.state.permissions.canUpdateOthers ? true : false
                }
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  renderFilter() {
    return (
      <Grid className={"no-pad no-marg"}>
        <Grid.Row>
          <Grid.Column className=" no-pad">
            <Segment className="subtle">
              <p className="boxHeader">Filter</p>
              <AuthComponents.Can scope={"vehicles:get:all:management"}>
                <Segment basic>
                  <Header as={"h5"} textAlign="center">
                    Admin Filters
                  </Header>
                  <Form>
                    <Form.Field>
                      <Checkbox
                        toggle
                        name="unitOnly"
                        label="Team Vehicles Only"
                        checked={this.state.filters.unitOnly ? true : false}
                        onChange={() =>
                          this.setState(
                            {
                              filters: {
                                ...this.state.filters,
                                unitOnly: this.state.filters.unitOnly
                                  ? false
                                  : true,
                              },
                            },
                            () => this.handleFilter()
                          )
                        }
                      />
                    </Form.Field>
                    <Form.Field>
                      <Checkbox
                        toggle
                        name="approvedOnly"
                        label="Approved Vehicles Only"
                        checked={this.state.filters.approvedOnly ? true : false}
                        onChange={() =>
                          this.setState(
                            {
                              filters: {
                                ...this.state.filters,
                                approvedOnly: this.state.filters.approvedOnly
                                  ? false
                                  : true,
                              },
                            },
                            () => this.handleFilter()
                          )
                        }
                      />
                    </Form.Field>
                  </Form>
                </Segment>
              </AuthComponents.Can>
              {/* <Divider /> */}
              <Segment basic className="rolesList">
                <Header as={"h5"} textAlign="center">
                  Seat Filters
                </Header>
                <Grid columns={4}>
                  <Grid.Row>
                    {vehicleSeatFilter.map((data, index) => {
                      return (
                        <Grid.Column>
                          <Button
                            key={index}
                            inverted
                            toggle
                            active={this.state.filters.seats === data.value}
                            onClick={() =>
                              this.setState(
                                {
                                  filters: {
                                    ...this.state.filters,
                                    seats:
                                      this.state.filters.seats === data.value
                                        ? undefined
                                        : data.value,
                                  },
                                },
                                () => this.handleFilter()
                              )
                            }
                          >
                            <span>{data.text}</span>
                          </Button>
                        </Grid.Column>
                      );
                    })}
                  </Grid.Row>
                </Grid>
              </Segment>
              <Segment basic className="rolesList">
                {this.props.system.settings.vehicleKit !== undefined &&
                this.props.system.settings.vehicleKit.length > 0 ? (
                  <Header as={"h5"} textAlign="center">
                    Kit Filters
                  </Header>
                ) : (
                  <></>
                )}
                {this.props.system.settings.vehicleKit !== undefined
                  ? this.props.system.settings.vehicleKit.sort().map((data) => {
                      return (
                        <Button
                          key={data}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            flex: 1,
                            textAlign: "left",
                          }}
                          fluid
                          inverted
                          toggle
                          active={this.state.filters.kit === data}
                          onClick={() =>
                            this.setState(
                              {
                                filters: {
                                  ...this.state.filters,
                                  kit:
                                    this.state.filters.kit === data
                                      ? undefined
                                      : data,
                                },
                              },
                              () => this.handleFilter()
                            )
                          }
                        >
                          <span>{data}</span>
                          <span>{this.getRolesCount(data)}</span>
                        </Button>
                      );
                    })
                  : "no roles"}
              </Segment>
              <Segment basic className="rolesList">
                {this.state.filters &&
                  Object.keys(this.state.filters).length > 0 && (
                    <Button
                      negative
                      fluid
                      inverted
                      onClick={() =>
                        this.setState({ filters: {} }, this._getData())
                      }
                    >
                      clear
                    </Button>
                  )}
              </Segment>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  renderMap() {
    const userProfile = Authentication.getUserData();
    return (
      <Grid className={"no-pad no-marg"}>
        <Grid.Row>
          <Grid.Column className=" no-pad">
            <Segment className="no-pad">
              <ListMap
                center={userProfile.organisation.defaultMapCenter}
                vehicles={this.state.filtered}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  renderMenu() {
    const { activeItem } = this.state;
    return (
      <Menu>
        <Menu.Item>
          <Header as={"h3"}>Vehicles</Header>
        </Menu.Item>
        <Menu.Item
          name="list"
          active={activeItem === "list"}
          onClick={this.handleMenuClick}
        >
          List
        </Menu.Item>
        <Menu.Item
          name="map"
          active={activeItem === "map"}
          onClick={this.handleMenuClick}
        >
          Map
        </Menu.Item>
      </Menu>
    );
  }

  render() {
    const { activeItem } = this.state;
    return (
      <Grid stackable className="no-marg">
        <Grid.Row className="no-pad">
          <Grid.Column width={16} className="no-pad">
            {this.renderMenu()}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={3}>{this.renderFilter()}</Grid.Column>
          <Grid.Column width={13}>
            <TheLoader
              active={this.state.loading}
              content={"Vehicles loading"}
            />

            {activeItem === "list" && this.renderTeamList()}
            {activeItem === "map" && this.renderMap()}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default connect(mapStateToProps, {
  getSystemSettings,
})(VehicleListPage);
