import React, { Component } from "react";
import { connect } from "react-redux";
import { Segment, Grid, Statistic, Header, Icon, Button, Modal, Loader, Message, Menu, Tab, Form, Select, TextArea, Checkbox, Divider } from "semantic-ui-react";
import moment from "moment";
import fileDownloader from "js-file-download";
import DatePicker from "react-datepicker";
import { AuthComponents, Authentication } from "@liquidcomputing/sarstuff__reactjs-singlesignon";
import api from "../actions/api";
import ResponseList from "./common/lists/respList";
import { getSystemSettings } from "../actions/settingsActions";
import CopyEventForm from "./calendar/forms/copyEvent";
import CommonFunctions from "../lib/CommonFunctions";
import EventRemoveConfirm from "./eventRemoveConfirm";

moment.locale("en-GB");

function mapStateToProps(state) {
  return {
    system: state.system,
    user: state.user,
  };
}

class EventPage extends Component {
  state = {
    event: {},
    locations: [],
    organisers: [],
    eventTypes: [],
    data: {
      title: "",
      start: "",
      end: "",
      details: "",
      note: "",
      type: "",
      organiserID: "",
      location: {},
    },
    message: "",
    responses: {},
    teamList: [],
    loading: true,
    printing: false,
    showCopyEvent: false,
    eventEditModal: false,
    copyEventData: {},
    copyDataLoading: false,
    copyDataErrorMessages: [],
    showRemoveConfirm: false,
  };

  componentDidMount() {
    this._getData();
    this.props.getSystemSettings().then(() => {
      const data = this.props.system.settings.eventTypes;
      let dataArray = [];
      for (var key in data) {
        dataArray.push({
          key: key,
          text: data[key].content,
          value: data[key].content,
        });
      }
      this.updateEventTypes(dataArray);
    });
  }

  _getData = () => {
    Promise.all([api.event.show(this.props.match.params.id), api.member.all()])
      .then((data) => {
        let teamArray = [];
        const org = data[1];
        for (var key in org) {
          teamArray.push({
            key: key,
            text: org[key].name,
            value: org[key].centralId,
          });
        }
        this.setState({
          event: data[0],
          data: data[0],
          teamList: data[1],
          organisers: teamArray,
        });
      })
      .catch((err) => {
        // let errorMessage =
        //   "There was an unknown network error while retrieving data from the server";
        // if (err.response !== undefined) {
        //   if (err.response.data.message !== undefined) {
        //     errorMessage = err.response.data.message;
        //   }
        // }
        //todo display error message to user
      })
      .finally(() => {
        this.returnResponses();
        this.setState({
          loading: false,
        });
      });
    api.location.all().then((res) => {
      let locArray = [];
      const data = res;
      for (var key in data) {
        locArray.push({
          key: key,
          text: data[key].geoJSON.properties.name,
          value: data[key]._id,
        });
      }
      this.setState({
        locations: locArray,
      });
    });
  };

  updateEventTypes = (data) => {
    this.setState({ eventTypes: data });
  };

  goto = (loc) => {
    if (loc) {
      this.setState({ eventShowModal: false });
      this.props.history.push(loc);
    }
  };

  printEvent = () => {
    this.setState({
      printing: true,
      printingError: null,
    });
    api.event
      .print(this.props.match.params.id)
      .then((theData) => {
        this.setState({
          printing: false,
        });
        fileDownloader(theData, "event.pdf");
      })
      .catch((err) => {
        console.error(err);
      });
  };

  setResponse = (resp, centralId) => {
    let event = this.state.event._id;
    if (event) {
      let data = {
        memberID: centralId,
        eventID: event,
        response: resp,
      };
      return api.event.responses
        .add(event, data)
        .then((res) => {
          this._getData().then((res) => {
            this.setState({
              event: res,
              respError: "",
            });
          });
        })
        .catch((err) => {
          this.setState({
            respError: err,
          });
        });
    }
  };

  setUserResponse = (resp) => {
    let event = this.state.event;
    let data = {};
    if (event) {
      if (event.class === "course") {
        data = {
          memberID: this.props.user.details.sub,
          courseID: event._id,
          response: resp,
          message: this.state.message,
        };
        api.event.responses
          .add(event._id, data)
          .then((res) => {
            this._getData();
          })
          .catch((err) => {
            this.setState({
              respError: err,
            });
          });
      } else {
        data = {
          memberID: this.props.user.details.sub,
          eventID: event._id,
          response: resp,
          message: this.state.message,
        };
        api.event.responses
          .add(event._id, data)
          .then((res) => {
            this._getData();
          })
          .catch((err) => {
            this.setState({
              respError: err,
            });
          });
      }
    }
  };

  setRecResponse = (resp, id) => {
    let event = this.state.event._id;
    if (event) {
      let data = {
        recruit: id,
        eventID: event,
        response: resp,
      };
      return api.event.responses
        .addRec(event, data)
        .then((res) => {
          this._getData().then((res) => {
            this.setState({
              event: res,
              respError: "",
            });
          });
        })
        .catch((err) => {
          this.setState({
            respError: err,
          });
        });
    }
  };

  returnResponses = () => {
    let responses = {
      going: 0,
      notGoing: 0,
      notResponded: 0,
    };

    if (this.state.event.responses) {
      responses.going = this.state.event.responses.filter((resp) => {
        return resp.response === "going";
      });
      responses.notGoing = this.state.event.responses.filter((resp) => {
        return resp.response === "not going";
      });
      let goingIDs = responses.going.map((i) => {
        return i.memberID;
      });
      let notGoingIDs = responses.notGoing.map((i) => {
        return i.memberID;
      });
      responses.notResponded = this.state.teamList.filter((member) => {
        if (goingIDs.indexOf(member.centralId) === -1 && notGoingIDs.indexOf(member.centralId) === -1) {
          return true;
        }
        return false;
      });
    }
    this.setState({
      responses,
    });
  };

  handleChange = (event, { name, value }) => {
    this.setState({
      data: {
        ...this.state.data,
        [name]: value,
      },
    });
  };

  onChange = (e) =>
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });

  handleCopyEvent = () => {
    // Validate the data
    let errorMessages = [];
    if (this.state.copyEventData.newDates === undefined) {
      //eslint-disable-next-line
      this.state.copyEventData.newDates = [];
    }
    if (this.state.copyEventData.newDates.length <= 0) {
      errorMessages.push("You must enter at least one date");
    }
    for (let i in this.state.copyEventData.newDates) {
      let item = this.state.copyEventData.newDates[i];
      if (item.start === "") {
        errorMessages.push("Date [" + (i + 1) + "]: You must select a valid start date and time");
      }
    }
    if (errorMessages.length !== 0) {
      this.setState({
        copyDataErrorMessages: errorMessages,
      });
    } else {
      this.setState(
        {
          copyDataErrorMessages: [],
          copyDataLoading: true,
        },
        () => {
          api.event
            .copy(this.props.match.params.id, this.state.copyEventData)
            .then(() => {
              this.props.history.push("/calendar");

              // this.setState({
              //     showCopyEvent: false,
              //     copyEventData: [],
              //     copyDataErrorMessages: []
              // });
            })
            .catch((err) => {
              let errorMessage = "There was an unknown network error while retrieving data from the server";
              if (err.response !== undefined) {
                if (err.response.data.message !== undefined) {
                  errorMessage = err.response.data.message;
                }
              }
              this.setState({
                copyDataErrorMessages: [errorMessage],
                copyDataLoading: false,
              });
            });
        }
      );
    }
  };

  handleCopyEventData = (data) => {
    this.setState({
      copyEventData: data,
    });
  };

  handleMessageChange = (event, { name, value }) => {
    this.setState({
      message: value,
    });
  };

  handleDropdownChange = (props, e) => {
    this.setState({
      data: { ...this.state.data, [e.name]: e.value },
    });
  };

  handleEditEvent = () => {
    let data = this.state.data;
    data = {
      ...data,
      start: moment(data.start),
      end: moment(data.end),
      responses: undefined,
      yourResponse: undefined,
    };
    api.event.update(data._id, data).then(() => {
      this._getData();
      this.setState({
        eventEditModal: false,
      });
    });
  };

  copyEvent = () => {
    this.setState({
      showCopyEvent: true,
    });
  };

  renderCopyEventModal() {
    return (
      <Modal open={this.state.showCopyEvent} onClose={() => this.setState({ eventModal: false })} centered={false} closeOnDimmerClick={false} size="small">
        <Header>Copy Event</Header>
        <Modal.Content>
          {this.state.copyDataErrorMessages.length >= 1 ? (
            <Message negative>
              <Message.Header>ERROR</Message.Header>
              {this.state.copyDataErrorMessages.map((i) => (
                <p key={i}>{i}</p>
              ))}
            </Message>
          ) : (
            ""
          )}
          <CopyEventForm event={this.state.event} submit={this.handleCopyEventData} loading={this.state.copyDataLoading} />
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="red"
            floated={"left"}
            onClick={() =>
              this.setState({
                showCopyEvent: false,
                copyDataLoading: false,
                copyEventData: [],
                copyDataErrorMessages: [],
              })
            }
          >
            <Icon name="close" /> Cancel
          </Button>
          <Button color="green" onClick={() => this.handleCopyEvent()}>
            <Icon name="plus" /> Copy Event
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
  renderPrintingModal() {
    return (
      <Modal open={this.state.printing}>
        <Modal.Header>Creating PDF</Modal.Header>
        <Modal.Content>
          {this.state.printingError ? (
            <Modal.Description>
              <Message>
                <Message.Header>PDF creation error</Message.Header>
                {this.state.printingError}
              </Message>
              <Button color={"red"} basic onClick={() => this.setState({ printing: false })}>
                Close
              </Button>
            </Modal.Description>
          ) : (
            <Modal.Description>
              <p>Please wait while we create the PDF...</p>
              <Loader />
            </Modal.Description>
          )}
        </Modal.Content>
      </Modal>
    );
  }

  renderMenu() {
    return (
      <Menu stackable className={"sub-menu"}>
        <Menu.Item>Event Menu</Menu.Item>
        <Menu.Item onClick={() => this.setState({ eventEditModal: true })}>
          <Icon name="pencil" />
          Edit
        </Menu.Item>
        {/*<Menu.Item disabled onClick={() => console.log("clicked")}>*/}
        {/*  <Icon name="mail" />*/}
        {/*  Send Email*/}
        {/*</Menu.Item>*/}
        <Menu.Item onClick={this.printEvent}>
          <Icon name="external alternate" />
          Export
        </Menu.Item>
        <Menu.Item onClick={this.copyEvent}>
          <Icon name="copy" />
          Copy
        </Menu.Item>
        <AuthComponents.Can scope={"events:remove"}>
          <Menu.Item onClick={() => this.setState({ showRemoveConfirm: true })}>
            <Icon name="trash" />
            Delete
          </Menu.Item>
        </AuthComponents.Can>
      </Menu>
    );
  }

  renderEditEventModal() {
    return (
      <Modal open={this.state.eventEditModal} onClose={() => this.setState({ eventEditModal: false })} centered={false} closeOnDimmerClick={false} size="small">
        <Header>Edit Event</Header>
        <Modal.Content>
          <Form loading={this.state.addRole_Loading}>
            <Form.Group widths={2}>
              <Form.Field>
                <DatePicker
                  onChange={(date) => {
                    this.setState({
                      data: {
                        ...this.state.data,
                        start: date,
                      },
                    });
                  }}
                  selected={this.state.data.start ? new Date(this.state.data.start) : ""}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  placeholderText="Start Date & Time"
                  timeInputLabel="Time:"
                  dateFormat="dd-MM-yy HH:mm"
                  nextMonthButtonLabel=">"
                  previousMonthButtonLabel="<"
                  // className={errors.start ? "warning" : ""}
                  data-disable-touch-keyboard
                />
              </Form.Field>
              <Form.Field>
                <DatePicker
                  onChange={(date) => {
                    this.setState({
                      data: {
                        ...this.state.data,
                        end: date,
                      },
                    });
                  }}
                  selected={this.state.data.end ? new Date(this.state.data.end) : ""}
                  minDate={this.state.data.start}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  placeholderText="End Date & Time"
                  timeInputLabel="Time:"
                  dateFormat="dd-MM-yy HH:mm"
                  nextMonthButtonLabel=">"
                  previousMonthButtonLabel="<"
                  // className={errors.end ? "warning" : ""}
                  data-disable-touch-keyboard
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths={2}>
              <Form.Field>
                <label htmlFor="title">Title</label>
                <input
                  id="title"
                  name="title"
                  placeholder="title"
                  value={this.state.data.title}
                  onChange={this.onChange}
                  // className={warning.firstName ? "fieldWarning" : "good"}
                />
              </Form.Field>
              <Form.Field>
                <label>Location</label>
                <Select
                  search
                  placeholder={"pick one"}
                  name={"location"}
                  options={this.state.locations}
                  onChange={this.handleDropdownChange}
                  value={this.state.data.location && this.state.data.location._id ? this.state.data.location._id : this.state.data.location}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths={2}>
              <Form.Field>
                <label>Type</label>
                <Select placeholder={"pick one"} name={"type"} options={this.state.eventTypes} onChange={this.handleDropdownChange} value={this.state.data.type} />
              </Form.Field>
              <Form.Field>
                <label>Organiser</label>
                <Select
                  search
                  placeholder={"pick one"}
                  name={"organiserID"}
                  options={this.state.organisers}
                  value={this.state.data.organiserID}
                  onChange={this.handleDropdownChange}
                />
              </Form.Field>
            </Form.Group>
            <Form.Field>
              <Form.TextArea label="Details" name={"details"} placeholder="Tell us more" onChange={this.handleChange} value={this.state.data.details} />
            </Form.Field>
            {(Authentication.can("events:update") || Authentication.can("events:create")) && (
              <Form.Field>
                <Form.TextArea label="Notes" name={"note"} placeholder="Additional notes relating to this event." onChange={this.handleChange} value={this.state.data.note} />
              </Form.Field>
            )}
            <Form.Field>
              <label>Allow sign up</label>
              <Checkbox
                style={{
                  textAlign: "center",
                  padding: 0.5 + "rem " + 2 + "rem",
                }}
                label="Allow Sign Up"
                checked={this.state.data.canSignUp}
                onChange={(e) =>
                  this.setState({
                    data: {
                      ...this.state.data,
                      canSignUp: !this.state.data.canSignUp,
                    },
                  })
                }
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button color="red" floated={"left"} onClick={() => this.setState({ eventEditModal: false })}>
            <Icon name="close" /> Cancel
          </Button>
          <Button color="green" onClick={() => this.handleEditEvent()}>
            <Icon name="save" /> Update
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }

  renderDetails() {
    const { event } = this.state;
    return (
      <Segment className="subtle">
        <Grid stackable>
          <Grid.Row>
            <Grid.Column width={16}>
              <p className="boxHeader">{event.title}</p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Header>
                Title:
                <span>{event.title}</span>
              </Header>
            </Grid.Column>
            <Grid.Column>
              <Header>
                Type:
                <span>{event.type}</span>
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Header>
                Start:
                <span>{moment(event.start).format("DD-MM-YYYY HH:mm")}</span>
              </Header>
            </Grid.Column>
            <Grid.Column>
              <Header>
                End:
                <span>{moment(event.end).format("DD-MM-YYYY HH:mm")}</span>
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Header>
                Details:
                <div>
                  {event.details &&
                    event.details.split("\n").map((item, key) => {
                      return (
                        <span className="w-full" key={key}>
                          {item}
                          <br />
                        </span>
                      );
                    })}
                </div>
                {/* {event.details} */}
              </Header>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns={2}>
            <Grid.Column>
              <Header>
                Location:
                <span>
                  {event.location && event.location.geoJSON.properties.name ? (
                    <Button basic inverted onClick={() => this.goto(`/training/locations/${event.location._id}`)}>
                      <Icon name="globe" />
                      {event.location.geoJSON.properties.name}
                    </Button>
                  ) : (
                    "--"
                  )}
                </span>
              </Header>
            </Grid.Column>
            <Grid.Column>
              <Grid.Column>
                <Header>
                  Organiser:
                  <span>{event.organiser ? event.organiser.name : "--"}</span>
                </Header>
              </Grid.Column>
            </Grid.Column>
          </Grid.Row>
          {(Authentication.can("events:update") || Authentication.can("events:create")) && (
            <Grid.Row columns={1}>
              <Grid.Column>
                <Divider />
                <Header>
                  Private Notes:
                  <span>{event.note ? event.note : "None"}</span>
                </Header>
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </Segment>
    );
  }

  renderYourResponse() {
    const { event } = this.state;
    let disabled = false;
    if (event.start) {
      if (moment(event.start) < moment()) {
        disabled = true;
      }
    }

    return (
      <Segment className="subtle">
        <Grid stackable>
          <Grid.Row>
            <Grid.Column width={16}>
              <p className="boxHeader">Your Response</p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2}>
            <Grid.Column textAlign="center">
              {event.yourResponse ? (
                event.yourResponse.response === "going" ? (
                  <div>
                    <Header as={"h3"} color="green">
                      Going
                    </Header>
                  </div>
                ) : (
                  <div>
                    <Header as={"h3"} color="orange">
                      Not Going
                    </Header>
                  </div>
                )
              ) : (
                <div>
                  <Header as={"h3"} color="orange">
                    you haven't responded
                  </Header>
                </div>
              )}
              {event.yourResponse && <i>as of {moment(event.yourResponse.updatedAt).format("DD-MM-YYYY HH:mm")}</i>}
            </Grid.Column>
            <Grid.Column mobile={16} computer={8} textAlign="center">
              {event.yourResponse ? (
                event.yourResponse.response === "going" ? (
                  <Button onClick={() => this.setUserResponse("not going")} negative basic disabled={disabled}>
                    Change to Not Going
                  </Button>
                ) : (
                  <Button onClick={() => this.setUserResponse("going")} positive basic disabled={disabled}>
                    Change to Going
                  </Button>
                )
              ) : (
                <div>
                  <Button onClick={() => this.setUserResponse("not going")} negative basic disabled={disabled}>
                    Not Going
                  </Button>
                  <Button onClick={() => this.setUserResponse("going")} positive disabled={disabled}>
                    Going
                  </Button>
                </div>
              )}
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column mobile={16} computer={8}>
              <p className="boxHeader">Message</p>
              <Header as={"h3"}>{event.yourResponse && event.yourResponse.message}</Header>
            </Grid.Column>
            <Grid.Column mobile={16} computer={8}>
              <label>Add message to your response if needed</label>
              <TextArea name="message" onChange={this.handleMessageChange} placeholder="message if required" disabled={disabled} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }

  renderModals() {
    return (
      <React.Fragment>
        {this.renderPrintingModal()}
        {this.renderCopyEventModal()}
        {this.renderEditEventModal()}
      </React.Fragment>
    );
  }

  render() {
    let permissions = CommonFunctions.eventPermisions(this.props.user);
    const limitedPanes = [
      {
        menuItem: `Attending `,
        render: () => (
          <Tab.Pane attached={false} className="no-pad no-marg" basic>
            <ResponseList hidePhoto={true} combined={true} response={true} permissions={permissions} data={this.state.responses.going} setResponse={this.setResponse} />
          </Tab.Pane>
        ),
      },
      {
        menuItem: `Not Attending `,
        render: () => (
          <Tab.Pane attached={false} className={"no-pad no-marg"} basic>
            <ResponseList hidePhoto={true} combined={true} response={true} permissions={permissions} setResponse={this.setResponse} data={this.state.responses.notGoing} />
          </Tab.Pane>
        ),
      },
    ];
    const panes = [
      {
        menuItem: `Attending `,
        render: () => (
          <Tab.Pane attached={false} className="no-pad no-marg" basic>
            <ResponseList hidePhoto={true} combined={true} response={true} permissions={permissions} data={this.state.responses.going} setResponse={this.setResponse} />
          </Tab.Pane>
        ),
      },
      {
        menuItem: `Not Attending `,
        render: () => (
          <Tab.Pane attached={false} className={"no-pad no-marg"} basic>
            <ResponseList hidePhoto={true} combined={true} response={true} permissions={permissions} setResponse={this.setResponse} data={this.state.responses.notGoing} />
          </Tab.Pane>
        ),
      },
      {
        menuItem: `Not Responded `,
        render: () => (
          <Tab.Pane attached={false} className="no-pad no-marg" basic>
            <ResponseList hidePhoto={true} permissions={permissions} data={this.state.responses.notResponded} setResponse={this.setResponse} />
          </Tab.Pane>
        ),
      },
    ];
    return (
      <Grid stackable className="no-marg">
        {this.state.showRemoveConfirm && (
          <EventRemoveConfirm
            event={this.state.event}
            onSuccess={() => {
              this.props.history.push("/calendar", { eventRemoved: true });
            }}
            onCancel={() => this.setState({ showRemoveConfirm: false })}
          />
        )}
        <Grid.Row className="no-pad">
          <Grid.Column width={16} className="no-pad">
            {permissions.canUpdate ? (
              this.renderMenu()
            ) : (
              <Header as={"h1"} textAlign="center" style={{ paddingTop: "1rem" }}>
                Event
              </Header>
            )}
            {this.renderModals()}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            {this.renderDetails()}
            {this.state.event.canSignUp && this.renderYourResponse()}
          </Grid.Column>
          <Grid.Column width={8}>
            <Segment className="subtle">
              <Statistic.Group widths={3}>
                <Statistic>
                  <Statistic.Value>{this.state.responses.going ? this.state.responses.going.length : 0}</Statistic.Value>
                  <Statistic.Label>Going</Statistic.Label>
                </Statistic>
                <Statistic>
                  <Statistic.Value>{this.state.responses.notGoing ? this.state.responses.notGoing.length : 0}</Statistic.Value>
                  <Statistic.Label>Not Going</Statistic.Label>
                </Statistic>
                <Statistic>
                  <Statistic.Value>{this.state.responses.notResponded ? this.state.responses.notResponded.length : 0}</Statistic.Value>
                  <Statistic.Label>Not Responded</Statistic.Label>
                </Statistic>
              </Statistic.Group>
            </Segment>
            <Segment className=" subtle">
              <p className="boxHeader">Responses</p>
              <Tab menu={{ secondary: true, pointing: false, widths: 3 }} panes={permissions.canUpdate ? panes : limitedPanes} />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default connect(mapStateToProps, { getSystemSettings })(EventPage);
