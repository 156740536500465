import React, { Component } from "react";
import { Menu, Popup } from "semantic-ui-react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { FaUsers, FaCalendarAlt, FaHome } from "react-icons/fa";
import { MdChildFriendly } from "react-icons/md";
import { RiSurgicalMaskLine } from "react-icons/ri";
import { GiTeacher } from "react-icons/gi";
import { getSystemSettings } from "../../actions/settingsActions";
import CommonFunctions from "../../lib/CommonFunctions";
import { AuthComponents } from "@liquidcomputing/sarstuff__reactjs-singlesignon";
// import { Authentication } from "@liquidcomputing/sarstuff__reactjs-singlesignon";
function mapStateToProps(state) {
  return {
    system: state.system,
    user: state.user,
  };
}

class Sidebar extends Component {
  state = {
    user: {},
    drawerPos: 1,
    activeItem: "home",
  };
  componentDidMount() {
    // let user = Authentication.getUserData();
    // if (user) {
    //   this.setState({
    //     user: thi,
    //   });
    // }
  }
  goto = (data) => {
    this.props.history.push(data);
  };
  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name });
  };
  render() {
    let permissions = CommonFunctions.recruitPermisions(this.props.user);
    // let drawerClass = [];
    // let mainClass = [];
    // if (this.props.drawerPos === 1) {
    //   drawerClass.push("drawerMin");
    //   mainClass.push("mainMin");
    // } else if (this.props.drawerPos === 2) {
    //   drawerClass.push("drawerOpen");
    //   mainClass.push("mainOpen");
    // } else {
    //   drawerClass = [];
    //   mainClass = [];
    // }
    const { activeItem } = this.state;
    return (
      <aside className={"drawerMin"}>
        <Menu vertical>
          <Menu.Item
            as={Link}
            to={`${this.props.match.url}`}
            name={"home"}
            active={activeItem === "home"}
            onClick={this.handleItemClick}
          >
            <FaHome size="2rem" />
          </Menu.Item>
          <Popup
            position="right center"
            trigger={
              <Menu.Item
                as={Link}
                to="/team"
                name={"team"}
                active={activeItem === "team"}
                onClick={this.handleItemClick}
              >
                <FaUsers size="2rem" />
              </Menu.Item>
            }
            content="Team"
          />

          <Menu.Item
            as={Link}
            to={`/calendar`}
            name={"calendar"}
            active={activeItem === "calendar"}
            onClick={this.handleItemClick}
          >
            <FaCalendarAlt size="2rem" />
          </Menu.Item>
          <Popup
            position="right center"
            trigger={
              <Menu.Item
                title={"Training"}
                as={Link}
                to={`/training`}
                name={"training"}
                active={activeItem === "training"}
                onClick={this.handleItemClick}
              >
                <GiTeacher size="2rem" />
              </Menu.Item>
            }
            content="Training"
          />
          <AuthComponents.Can scope={"recruitment:create"}>
            <Popup
              position="right center"
              trigger={
                <Menu.Item
                  title={"Recruitment"}
                  as={Link}
                  to={`/recruitment`}
                  name={"recruitment"}
                  active={activeItem === "recruitment"}
                  onClick={this.handleItemClick}
                >
                  <MdChildFriendly size="2rem" />
                </Menu.Item>
              }
              content="Recruitment"
            />
          </AuthComponents.Can>
          <AuthComponents.Can scope={"assets:update"}>
            <Popup
              position="right center"
              trigger={
                <Menu.Item
                  title={"Asset Management"}
                  as={Link}
                  to={`/assets`}
                  name={"assets"}
                  active={activeItem === "assets"}
                  onClick={this.handleItemClick}
                >
                  <RiSurgicalMaskLine size="2rem" />
                </Menu.Item>
              }
              content="Asset Management"
            />
          </AuthComponents.Can>
        </Menu>
      </aside>
    );
  }
}
let sideBar = withRouter(Sidebar);
export default connect(mapStateToProps, { getSystemSettings })(sideBar);
