import React, { useState, useEffect } from "react";
import Box from "./ui-wrappers/Box";
import Layout from "./ui-wrappers/Layout";
import SettingsList from "../common/lists/settingList";
import SettingsArrayList from "./ui-wrappers/SettingsArrayList";
import api from "../../actions/api";
export default function AssetSettings() {
  const [settings, setSettings] = useState([]);

  useEffect(() => {
    getSettings();
  }, []);
  const getSettings = () => {
    api.assets.settings.all().then((res) => {
      setSettings(res);
    });
  };
  const handleUpdate = (data) => {
    api.assets.settings.update(data).then(() => {
      getSettings();
    });
  };

  return (
    <Layout>
      <div className=" grid grid-cols-1 md:grid-cols-3 gap-4 ">
        <div className="col-span-1 md:col-span-1	">
          <SettingsArrayList
            title="Asset Tags"
            field="tags"
            data={settings.tags}
            onSubmit={handleUpdate}
            onDelete={handleUpdate}
          />
        </div>
        <div className="col-span-1 md:col-span-1	"></div>
        <div className="col-span-1 md:col-span-1	"></div>
      </div>
    </Layout>
  );
}
