import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Menu, Header } from "semantic-ui-react";

function mapStateToProps(state) {
  return {
    user: state.user,
    system: state.system,
  };
}

class TeamNav extends React.Component {
  state = { activeItem: "dash" };
  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name });
  };
  componentDidMount() {}
  render() {
    const { activeItem } = this.state;

    return (
      <Menu stackable className={"sub-menu"}>
        <Menu.Item>
          <Header as={"h3"} className={"subtle"}>
            Team Menu
          </Header>
        </Menu.Item>

        <Menu.Item name={"dash"} as={NavLink} exact to={"/team"} active={activeItem === "dash"} onClick={this.handleItemClick}>
          Members
        </Menu.Item>
        <Menu.Item name={"vehicles"} as={NavLink} exact to={"/team/vehicles"} active={activeItem === "vehicles"} onClick={this.handleItemClick}>
          Vehicles
        </Menu.Item>
      </Menu>
    );
  }
}

export default connect(mapStateToProps, {})(TeamNav);
