import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import IncidentPage from "./incident";
import IncidentList from "./list";

export default class IncidentPages extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route exact path={`/management/incidents/:id`} component={IncidentPage} />
          <Route path="/" component={IncidentList} />
        </Switch>
      </React.Fragment>
    );
  }
}
