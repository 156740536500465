import React from "react";
import moment from "moment";
import { Table, Label } from "semantic-ui-react";
import api from "../../../actions/api";
import { withRouter } from "react-router";
class MemberCourseList extends React.Component {
  state = {
    courses: [],
  };

  componentDidMount() {
    if (this.props.centralID !== null) {
      api.member.courses(this.props.centralID).then((courses) => {
        this.setState({
          courses,
        });
      });
    }
  }

  renderDate = (date) => {
    if (date) {
      return moment(date).format("DD/MM/YY HH:mm");
    } else {
      return "--";
    }
  };
  renderCourses() {
    if (this.state.courses && this.state.courses.length) {
      const courses = this.state.courses
        .sort((a, b) => {
          if (a.Course.start < b.Course.start) {
            return 1;
          } else if (a.Course.start > b.Course.start) {
            return -1;
          } else {
            return 0;
          }
        })
        .map((item) => {
          let expires = moment(item.Course.end)
            .add(item.Course.validityLength, "years")
            .toDate();
          let neg = false;
          if (item.Result === false) {
            neg = true;
          }
          return (
            <Table.Row
              key={item._id}
              onClick={() =>
                this.props.history.push(`/training/courses/${item.Course._id}`)
              }
              negative={neg}
              positive={item.Result && item.Result === true}
            >
              <Table.Cell>{item.Course && item.Course.title}</Table.Cell>
              <Table.Cell>
                {item.Course && this.renderDate(item.Course.start)}
              </Table.Cell>
              <Table.Cell>{item.Course && item.Course.type}</Table.Cell>
              <Table.Cell>
                {item.Course &&
                  item.Course.roles.map((role, i) => {
                    return (
                      <Label style={{ margin: 4 }} key={i}>
                        {role}
                      </Label>
                    );
                  })}
              </Table.Cell>
              <Table.Cell textAlign="center">
                {item.Course && item.Course.validityLength}
              </Table.Cell>
              <Table.Cell>{this.renderDate(expires)}</Table.Cell>
              <Table.Cell textAlign="center">
                {item.Result ? "Yes" : "Not Yet"}
              </Table.Cell>
            </Table.Row>
          );
        });
      return courses;
    }
  }
  render() {
    return (
      <Table stackable selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Title</Table.HeaderCell>
            <Table.HeaderCell>Dates</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Roles</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
              Valid For (Years)
            </Table.HeaderCell>
            <Table.HeaderCell>Expires</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Competent?</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{this.renderCourses()}</Table.Body>
      </Table>
    );
  }
}
export default withRouter(MemberCourseList);
