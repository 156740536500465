import React, { useState, useEffect } from "react";
import Layout from "../../common/Layout";
import Box from "../../common/Box";
import API from "../../../actions/api";
// import dayjs from "dayjs";
import VettingList from "./VettingList";
export default function Vetting() {
  const [vetting, setVetting] = useState([]);
  const [period, setPeriod] = useState(6);

  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    getData(period);
    return () => {
      setVetting([]);
    };
  }, [period]);
  const getData = (months = { period }) => {
    API.management.vettingList(months).then((res) => {
      setVetting(res);
    });
  };
  // console.log(vetting);
  return (
    <Layout full>
      <Box title="Vetting Filter">
        <div className="p-2 flex justify-around">
          <div
            className={`p-4  rounded-xl border border-gray-700 ${
              period === 9 ? "bg-green-900" : "bg-gray-800"
            } cursor-pointer`}
            onClick={() => setPeriod(9)}
          >
            9 Months
          </div>
          <div
            className={`p-4 ${
              period === 6 ? "bg-green-900" : "bg-gray-800"
            } cursor-pointer rounded-xl border border-gray-700`}
            onClick={() => setPeriod(6)}
          >
            6 Months
          </div>
          <div
            className={`p-4 ${
              period === 3 ? "bg-green-900" : "bg-gray-800"
            } cursor-pointer rounded-xl border border-gray-700`}
            onClick={() => setPeriod(3)}
          >
            3 Months
          </div>
        </div>
      </Box>
      <div className="mt-2 grid grid-cols-1 md:grid-cols-2  gap-4">
        {vetting.map((level, i) => {
          return (
            <div className="col-span-1 	" key={i}>
              <Box title={level.name}>
                <VettingList members={level.members} />
              </Box>
            </div>
          );
        })}
      </div>
    </Layout>
  );
}
