import React, { Component } from "react";
import { connect } from "react-redux";
import { Segment, Grid } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { getSystemSettings } from "../../actions/settingsActions";
import api from "../../actions/api";
import CourseList from "../common/lists/courseList";

function mapStateToProps(state) {
  return {
    system: state.system,
    user: state.user,
  };
}
class TrainingDashPage extends Component {
  state = {
    events: [],
  };
  componentDidMount() {
    this._getData();
    this.props.getSystemSettings();
    // this.props.i18n.changeLanguage("en");
  }
  componentDidUpdate(prevProps, prevState) {}

  _getData = () => {
    api.event.future("", 8).then((events) => {
      this.setState({
        events,
      });
    });
  };
  doAction = (id, data) => {
    if(data.__modelType === "event"){
      this.props.history.push(`calendar/${id}`);
    } else if(data.__modelType === "course"){
      this.props.history.push(`training/courses/${id}`);
    }
  };
  render() {
    return (
      <Segment loading={this.state.loading} basic>
        <Grid celled stackable>
          <Grid.Row>
            <Grid.Column width={16}>
              <Segment className="subtle">
                <p className="boxHeader">Next 10 training events</p>
                <CourseList data={this.state.events} action={this.doAction} />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}
const TrainingDash = withTranslation()(TrainingDashPage);
export default connect(mapStateToProps, { getSystemSettings })(TrainingDash);
