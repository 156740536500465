import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Menu, Header } from "semantic-ui-react";

function mapStateToProps(state) {
  return {
    user: state.user,
    system: state.system,
  };
}

class CompNav extends React.Component {
  state = { activeItem: "home" };
  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name });
  };
  componentDidMount() {}
  render() {
    const { activeItem } = this.state;
    return (
      <Menu stackable className={"sub-menu"}>
        <Menu.Item>
          <Header as={"h3"} className={"subtle"}>
            Competency Menu
          </Header>
        </Menu.Item>

        <Menu.Item
          name={"dash"}
          as={NavLink}
          exact
          to={"/training/comps"}
          active={activeItem === "dash"}
          onClick={this.handleItemClick}
        >
          Dash
        </Menu.Item>
        <Menu.Item
          name={"add"}
          as={NavLink}
          exact
          to={"/training/comps/add"}
          active={activeItem === "add"}
          onClick={this.handleItemClick}
        >
          Add Comp Session
        </Menu.Item>
        <Menu.Item
          name={"scores"}
          as={NavLink}
          exact
          to={"/training/comps/scores"}
          active={activeItem === "scores"}
          onClick={this.handleItemClick}
        >
          Comp Scores
        </Menu.Item>
      </Menu>
    );
  }
}

export default connect(mapStateToProps, {})(CompNav);
