import React, { PureComponent } from "react";
import { Segment, Table, Button, Modal, Header, Icon } from "semantic-ui-react";
import PropTypes from "prop-types";
import AddNoteForm from "./forms/addNote";
import Moment from "react-moment";
export default class NotesPage extends PureComponent {
  state = {
    add: false,
    notes: [],
  };
  componentDidMount() {
    if (this.props.notes) {
      this.setState({
        notes: this.props.notes,
      });
    }
  }

  onSubmit = (message) => {
    let data = message;
    data.loggedBy = this.props.whoami.details.name;
    data.date = new Date().toString();
    let notes = this.state.notes;
    notes.push(data);

    return this.props.addNote(notes, () => {
      this.setState({
        add: false,
      });
    });
  };
  renderAddModal() {
    return (
      <Modal open={this.state.add} onClose={() => this.setState({ add: false })} centered={false} size="small">
        <Header content="Add Note" />
        <Modal.Content>
          <AddNoteForm submit={this.onSubmit} cancel={() => this.setState({ add: false })} />
        </Modal.Content>
      </Modal>
    );
  }
  render() {
    let list;
    if (this.props.notes && this.props.notes.length > 0) {
      list = this.props.notes
        .sort((a, b) => {
          if (a.date < b.date) {
            return 1;
          } else if (a.date > b.date) {
            return -1;
          } else {
            return 0;
          }
        })
        .map((note, i) => {
          return (
            <Table.Row key={i}>
              <Table.Cell width={4}>
                <Moment format={"DD-MM-YYYY HH:mm"}>{note.date}</Moment>
              </Table.Cell>
              <Table.Cell>{note.message}</Table.Cell>
              <Table.Cell width={4}>{note.loggedBy}</Table.Cell>
            </Table.Row>
          );
        });
    } else {
      list = (
        <Table.Row key={9999}>
          <Table.Cell colSpan={3}>No Notes</Table.Cell>
        </Table.Row>
      );
    }
    return (
      <Segment basic>
        {this.renderAddModal()}
        <p className="boxHeader">
          Notes{" "}
          <Button basic className="subtleButton" size="tiny" icon onClick={() => this.setState({ add: true })}>
            <Icon name="plus" color="green" />
          </Button>
        </p>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Date</Table.HeaderCell>
              <Table.HeaderCell>Details</Table.HeaderCell>
              <Table.HeaderCell>By</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{list}</Table.Body>
        </Table>
      </Segment>
    );
  }
}
NotesPage.propTypes = {
  notes: PropTypes.array,
};
