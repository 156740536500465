import React, { Fragment, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import {
  ChevronDownIcon,
  XIcon,
  InboxInIcon,
  InformationCircleIcon,
  ChatIcon,
} from "@heroicons/react/outline";
import { NavLink } from "react-router-dom";

const AssetNav = ({ match }) => {
  return (
    <Disclosure as="nav" className="bg-bak">
      {({ open }) => (
        <>
          <div className=" mx-auto  px-2">
            <div className="flex items-center justify-between h-16">
              <div className="flex items-center">
                <div className="hidden md:block">
                  <div className=" pt-2 flex items-center justify-center text-center space-x-4">
                    <div className="font-mono antialiased text-md flex flex-col px-2 py-2 justify-center items-center text-gray-400 tracking-wider">
                      Assets
                    </div>
                    <NavLink
                      exact
                      to={`${match.url}`}
                      activeClassName="bg-gray-800 "
                      className="text-white px-2 py-2 rounded-md text-lg font-medium font-mono hover:bg-gray-800"
                    >
                      Dash
                    </NavLink>
                    <NavLink
                      to={`${match.url}/list`}
                      activeClassName="bg-gray-800 "
                      className="text-white px-2 py-2 rounded-md text-lg font-medium font-mono hover:bg-gray-800"
                    >
                      Assets
                    </NavLink>
                    <NavLink
                      to={`${match.url}/suppliers`}
                      activeClassName="bg-gray-800 "
                      className="text-white px-2 py-2 rounded-md text-lg font-medium font-mono hover:bg-gray-800"
                    >
                      Suppliers
                    </NavLink>
                    <NavLink
                      to={`${match.url}/contents`}
                      activeClassName="bg-gray-800 "
                      className="text-white px-2 py-2 rounded-md text-lg font-medium font-mono hover:bg-gray-800"
                    >
                      Contents Report
                    </NavLink>
                    <NavLink
                      to={`${match.url}/settings`}
                      activeClassName="bg-gray-800 "
                      className="text-white px-2 py-2 rounded-md text-lg font-medium font-mono hover:bg-gray-800"
                    >
                      Settings
                    </NavLink>
                  </div>
                </div>
              </div>

              <div className="-mr-2 flex md:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="bg-warmGray-900 inline-flex items-center justify-between p-4 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                  <div className="bg-gray-900 ">Asset Menu</div>
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <ChevronDownIcon
                      className="block h-6 w-6"
                      aria-hidden="true"
                    />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="md:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              <NavLink
                exact
                to={`${match.url}`}
                activeClassName="bg-gray-800 "
                className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
              >
                Dash
              </NavLink>
              <NavLink
                to={`${match.url}/list`}
                activeClassName="bg-gray-800 "
                className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
              >
                Assets
              </NavLink>
              <NavLink
                to={`${match.url}/contents`}
                activeClassName="bg-gray-800 "
                className="text-white px-2 py-2 rounded-md text-lg font-medium font-mono hover:bg-gray-800"
              >
                Contents Report
              </NavLink>
              <NavLink
                to={`${match.url}/settings`}
                activeClassName="bg-gray-800 "
                className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
              >
                Settings
              </NavLink>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};
export default AssetNav;
