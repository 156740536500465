import React, { PureComponent } from "react";
import { Form, Button, TextArea, Divider, Segment, Select, Radio } from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";
import moment from "moment";
import api from "../../../actions/api";
moment.locale("en-GB");
export default class AddServiceForm extends PureComponent {
  state = {
    data: {
      date: "",
      type: "",
      supplier: null,
      notes: "",
      mileage: 0,
      hours: 0,
      pass: false,
      cost: 0,
      vehicle: "",
      asset: "",
    },
    suppliers: [],
  };
  componentDidMount() {
    if (this.props.vehicle) {
      this.setState({
        data: {
          ...this.state.data,
          vehicle: this.props.vehicle._id,
        },
      });
    }
    if (this.props.asset) {
      this.setState({
        data: {
          ...this.state.data,
          asset: this.props.asset._id,
        },
      });
    }
    this._getdata();
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.vehicle !== prevProps.vehicle) {
      this.setState({
        data: {
          ...this.state.data,
          vehicle: this.props.vehicle._id,
        },
      });
    }
    if (this.props.asset !== prevProps.asset) {
      this.setState({
        data: {
          ...this.state.data,
          asset: this.props.asset._id,
        },
      });
    }
  }
  _getdata = () => {
    api.management.suppliers.all().then((data) => {
      if (data) {
        let dataArray = [];
        for (var key in data) {
          dataArray.push({
            key: key,
            text: data[key].name,
            value: data[key]._id,
          });
        }
        this.setState({
          suppliers: dataArray,
        });
      }
    });
  };
  resetState = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.setState({
      data: {
        date: "",
        type: "",
        supplier: "",
        notes: "",
        mileage: 0,
        hours: 0,
        pass: false,
        cost: 0,
        vehicle: "",
        asset: "",
      },
    });
  };
  onSubmit = () => {
    this.props.submit(this.state.data).then(() => {
      if (this.props.notModal) {
        this.resetState();
      }
    });
  };
  onChange = (e) =>
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  onDropdownChange = (e, data) => {
    const vname = data.name;
    this.setState({
      data: { ...this.state.data, [vname]: data.value },
    });
  };
  render() {
    const { data } = this.state;
    return (
      <Segment>
        <Form onSubmit={this.onSubmit}>
          <Form.Group widths={2}>
            <Form.Field>
              <label>Date of Service</label>
              <DateInput
                autoComplete={"off"}
                name="date"
                startMode={"day"}
                animation="none"
                dateFormat={"YYYY-MM-DD"}
                closable={true}
                placeholder="Date of service"
                value={data.date ? moment(data.date).format("YYYY-MM-DD") : ""}
                iconPosition="left"
                onChange={this.onDropdownChange}
                localization="en_GB"
              />
            </Form.Field>
            <Form.Field>
              <label>Supplier</label>
              <Select name="supplier" options={this.state.suppliers} value={data.supplier} onChange={this.onDropdownChange} />
            </Form.Field>
          </Form.Group>
          <Form.Group widths={3}>
            <Form.Field>
              <label>Type</label>
              <input name="type" onChange={this.onChange} value={data.type} />
            </Form.Field>
            {data.vehicle && (
              <Form.Field>
                <label>Mileage</label>
                <input name="mileage" onChange={this.onChange} value={data.mileage} type="number" />
              </Form.Field>
            )}
            <Form.Field>
              <label>Passed Service:</label>

              <Radio
                toggle
                className={"goodToggle"}
                checked={data.pass}
                onClick={() =>
                  this.setState({
                    data: {
                      ...this.state.data,
                      pass: !data.pass,
                    },
                  })
                }
              />
            </Form.Field>
          </Form.Group>

          <Form.Field>
            <TextArea
              name="notes"
              id="notes"
              placeholder={this.props.placeholder ? this.props.placeholder : "add your note here"}
              style={{ minHeight: 50 }}
              value={this.state.data.notes}
              onChange={this.onChange}
            />
          </Form.Field>
          <Divider />
          <Form.Group widths={"equal"} className={"form-actions"}>
            <Form.Field>
              <Button negative floated={"left"} onClick={this.props.cancel}>
                Cancel
              </Button>
            </Form.Field>
            <Form.Field>
              <Button positive floated={"right"} disabled={this.state.disabled}>
                Add
              </Button>
            </Form.Field>
          </Form.Group>
        </Form>
      </Segment>
    );
  }
}
