import React, { useState, useEffect } from "react";
import api from "../../../actions/api";
import Box from "../ui-wrappers/Box";
import Layout from "../ui-wrappers/Layout";
import {Authentication} from "@liquidcomputing/sarstuff__reactjs-singlesignon";

export default function SupplierDash({ history }) {
  const [suppliers, setSuppliers] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [filter, setFilter] = useState("");

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    api.management.suppliers.all().then((res) => {
      setSuppliers(res);
      setFiltered(res);
    });
  };
  return (
    <Layout full>
      <Box
        title="Suppliers"
        button={Authentication.can("suppliers:create") && {
          text: "Add Supplier",
          colour: "bg-green-600",
          action: () => history.push(`/assets/suppliers/add`),
        }}
      >
        <div className="w-full">
          <div className="p-2">Filter bar</div>
          <table className="min-w-full divide-y divide-gray-200 border-separate table-fixed">
            <thead>
              <tr className="bg-gray-800">
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-400 uppercase ">
                  Name
                </th>

                <th className="px-4 py-3 text-center text-xs font-medium text-gray-400 uppercase ">
                  Primary Contact
                </th>
                <th>Type</th>
              </tr>
            </thead>
            <tbody className="bg-gray-900 divide-y divide-gray-200 text-lg">
              {filtered.map((a) => {
                let contact = [];
                if (a.contacts) {
                  contact = a.contacts.filter((contact) => {
                    return (contact = contact.primary === true);
                  });
                }
                return (
                  <tr
                    key={a._id}
                    className={`hover:bg-gray-800 cursor-pointer`}
                    onClick={() => history.push(`/assets/suppliers/${a._id}`)}
                  >
                    <td className="p-2">{a.name}</td>

                    <td className="p-2">
                      {contact[0] ? (
                        <span>
                          {contact[0].name ? contact[0].name : "--"}
                          <span className="cellTitle"> Phone: </span>
                          {contact[0].phone ? contact[0].phone : "--"}
                          <br />
                          <span className="cellTitle"> Email: </span>
                          {contact[0].email ? contact[0].email : "--"}
                        </span>
                      ) : (
                        "--"
                      )}
                    </td>
                    <td className="text-right p-2">
                      {a.type.map((t, i) => {
                        return (
                          <span
                            key={i}
                            className="text-lg p-2 rounded-lg bg-gray-800"
                          >
                            {t}
                          </span>
                        );
                      })}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Box>
    </Layout>
  );
}
