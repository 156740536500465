import React, { Component } from "react";
import { Button, Icon } from "semantic-ui-react";
export default class TheButton extends Component {
  render() {
    let disabled = false;
    if (this.props.disabled === "closed" || this.props.disabled === "suspended" || this.props.disabled === true) {
      disabled = true;
    }
    return (
      <Button
        className={"theButton"}
        // {...this.props}
        onClick={this.props.onClick}
        color={this.props.color ? this.props.color : "grey"}
        size={this.props.size}
        circular={this.props.circular}
        icon={this.props.icon}
        floated={this.props.floated}
        basic={this.props.basic}
        disabled={disabled}
      >
        {this.props.icon && <Icon name={this.props.iconName} />}
        {this.props.label}
      </Button>
    );
  }
}
