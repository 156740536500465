import React, { Component } from "react";
import {
  Statistic,
  Header,
  Segment,
  Table,
  Message,
  Button,
  Grid,
  Menu,
  Divider,
  GridColumn,
  Checkbox,
} from "semantic-ui-react";
import dayjs from "dayjs";
import API from "../../../actions/api";

export default class TeamDash extends Component {
  state = {
    months: 6,
    loading: true,
    hide: false,
    users: [],
    fees: [],
    vetting: [],
    settings: [],
    dash: {},
    activeItem: "dash",
    fee: "Membership",
  };
  componentDidMount() {
    this.getData();
    this.getVetting();
    this.getFees(this.state.fee);
  }
  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name });
  };
  getData() {
    this.setState({
        loading: true,
     }, () => {
        API.management
          .listNotInTeamSite()
          .then((res) => {
            this.setState({
              users: res,
            });
          })
          .finally(() => {
            this.setState({
              loading: false,
            });
          });
        API.management
          .dash()
          .then((res) => {
            this.setState({
              dash: res,
            });
          })
          .finally(() => {
            this.setState({
              loading: false,
            });
          });
        API.settings.all().then((res) => {
          this.setState({
            settings: res.fees,
          });
        });
      }
    );
  }
  getVetting = (months = 6) => {
    if (months) {
      this.setState({
        months,
      });
    }
    API.management.vettingList(months).then((res) => {
      this.setState({
        vetting: res,
      });
    });
  };
  getFees = (fee) => {
    API.management.feesList(fee).then((res) => {
      let list = res;
      if (this.state.hide) {
        this.setState({
          fees: list.filter((f) => {
            return f._fee._id !== undefined;
          }),
        });
      } else {
        this.setState({
          fees: list,
        });
      }
    });
  };
  hideBlank = () => {
    this.setState(
      (prevState) => ({ hide: !prevState.hide }),
      () => {
        this.getFees(this.state.fee);
      }
    );
  };
  filterFee = (data) => {
    this.setState({ fee: data }, () => {
      this.getFees(data);
    });
  };
  importUser(user) {
    this.setState({
      loading: true,
    }, () => {
      API.management.importFromCentral(user._id).then(() => {
        alert("Member has been imported successfully.");
        this.getData();
      }).catch((err) => {
        alert("There was an error: " + err.response.data.message);
        this.setState({
          loading: false,
        });
      });
    });

    // API.member
    //   .add({
    //     teamId: user.teamId,
    //     roles: user.roles,
    //     geoJSON: user.location,
    //     name: user.firstName + " " + user.surname,
    //     firstName: user.firstName,
    //     lastName: user.surname,
    //     phone: user.phone,
    //     phoneSecondary: user.phoneSecondary,
    //     email: user.email,
    //     operational: user.operational,
    //     onCall: user.onCall,
    //     address: user.location,
    //     centralId: user._id,
    //   })
    //   .then((res) => {
    //     this.getData();
    //   })
    //   .catch((err) => {
    //     alert("There was an unexpected error while trying to import the user");
    //     this.setState({
    //       loading: false,
    //     });
    //   });
  }

  calcDiff = (date) => {
    if (date) {
      let date1 = dayjs(date);
      return date1.diff(dayjs(), "days");
    } else {
      return "--";
    }
  };

  renderDash() {
    let op = 0;
    let nonOp = 0;
    let total = 0;
    if (this.state.dash && this.state.dash.operational) {
      this.state.dash.operational.forEach((item) => {
        if (item._id === true) {
          op = item.count;
        } else if (item._id === false) {
          nonOp = item.count;
        }
      });
      total = op + nonOp;
    }
    return (
      <Grid stackable className="no-marg no-pad">
        <Grid.Row columns={2}>
          <Grid.Column>
            <Segment className="subtle" basic>
              <Statistic.Group widths={4}>
                <Statistic>
                  <Statistic.Value>{op ? op : 0}</Statistic.Value>
                  <Statistic.Label>Operational</Statistic.Label>
                </Statistic>
                <Statistic>
                  <Statistic.Value>{nonOp ? nonOp : 0}</Statistic.Value>
                  <Statistic.Label>Non Operational</Statistic.Label>
                </Statistic>
                <Statistic
                  onClick={() =>
                    this.props.history.push(`/management/team/archived`)
                  }
                  className={"selectable"}
                >
                  <Statistic.Value>{this.state.dash.archived}</Statistic.Value>
                  <Statistic.Label>Archived</Statistic.Label>
                </Statistic>
                <Statistic>
                  <Statistic.Value>{total}</Statistic.Value>
                  <Statistic.Label>Total</Statistic.Label>
                </Statistic>
              </Statistic.Group>
            </Segment>
            {/*<Segment>*/}
            {/*  <p className="boxHeader">Archived Members</p>*/}
            {/*  <Statistic.Group widths={2}>*/}
            {/*    <Statistic*/}
            {/*      onClick={() =>*/}
            {/*        this.props.history.push(`/management/team/archived`)*/}
            {/*      }*/}
            {/*      className={"selectable"}*/}
            {/*    >*/}
            {/*      <Statistic.Value>{this.state.dash.archived}</Statistic.Value>*/}
            {/*      <Statistic.Label>Archived</Statistic.Label>*/}
            {/*    </Statistic>*/}
            {/*    /!*<Statistic>*!/*/}
            {/*    /!*  <Statistic.Value>{nonOp ? nonOp : 0}</Statistic.Value>*!/*/}
            {/*    /!*  <Statistic.Label>Deleting in next 60 days</Statistic.Label>*!/*/}
            {/*    /!*</Statistic>*!/*/}
            {/*  </Statistic.Group>*/}
            {/*</Segment>*/}
          </Grid.Column>
          <Grid.Column>
            <Segment
              loading={this.state.loading}
              basic
              className="subtle "
              style={{ marginBottom: 30 }}
            >
              <p className="boxHeader">Central Users not in TeamSite</p>

              {this.state.users.length ? (
                <Table stackable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Name</Table.HeaderCell>
                      <Table.HeaderCell>Email</Table.HeaderCell>
                      <Table.HeaderCell>{""}</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {this.state.users.map((item) => {
                      return (
                        <Table.Row
                          key={item._id}
                          negative={item.isArchived ? true : false}
                        >
                          <Table.Cell>
                            {item.firstName} {item.surname}
                          </Table.Cell>
                          <Table.Cell>{item.email}</Table.Cell>
                          <Table.Cell>
                            <Button
                              onClick={() => this.importUser(item)}
                              color={"green"}
                              size={"mini"}
                            >
                              Import
                            </Button>
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              ) : (
                <Message color={"blue"}>
                  <Message.Header>No Accounts</Message.Header>There are no
                  accounts to import
                </Message>
              )}
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  render() {
    const { activeItem } = this.state;
    return (
      <Segment basic className="no-pad">
        {this.renderDash()}
      </Segment>
    );
  }
}
