import React, { Component } from "react";
import {
  Segment,
  Header,
  Label,
  Divider,
  Icon,
  Modal,
  Button,
  Form,
  Select,
  Confirm,
  Message,
} from "semantic-ui-react";
import {
  AuthComponents,
  Authentication,
} from "@liquidcomputing/sarstuff__reactjs-singlesignon";

import PropTypes from "prop-types";

export default class ArrayCard extends Component {
  state = {
    data: {},
    loading: false,
    confirmOpen: false,
    addCat: false,
    removeData: null,
  };
  confirmClose = () => {
    this.setState({
      confirmOpen: false,
    });
  };
  handleDropdownChange = (e, data) => {
    this.setState({
      data: { ...this.state.data, [data.name]: data.value },
    });
  };
  handleAddData = () => {
    return this.props.add(this.state.data).then(() => {
      this.setState({
        addCat: false,
      });
    });
  };
  handleRemoveData = () => {
    let field = "field";
    if (this.props.fieldName) {
      field = this.props.fieldName;
    }
    return this.props
      .update({
        [field]: this.props.data.filter(
          (item) => item !== this.state.removeData
        ),
      })
      .then(() => {
        this.setState({
          confirmOpen: false,
          removeData: null,
        });
      });
  };

  renderModal() {
    return (
      <Modal
        open={this.state.addCat}
        onClose={() => this.setState({ addCat: false })}
        closeOnDimmerClick={false}
        size="small"
      >
        <Header>Add {this.props.title}</Header>
        <Modal.Content>
          <Form loading={this.state.loading}>
            <Form.Group widths={2}>
              <Form.Field>
                <Select
                  multiple
                  options={
                    (this.props.list !== undefined &&
                      this.props.list
                        .filter((item) => {
                          if (this.props.data !== undefined) {
                            return this.props.data.indexOf(item) === -1;
                          }
                          return true;
                        })
                        .map((item, index) => {
                          return { key: index, text: item, value: item };
                        })) ||
                    []
                  }
                  onChange={this.handleDropdownChange}
                  name={this.props.fieldName}
                  //   value={this.state.data.drivingCat}
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            disabled={this.state.loading}
            color="red"
            floated={"left"}
            onClick={() => this.setState({ addCat: false })}
          >
            <Icon name="close" /> Cancel
          </Button>
          <Button
            disabled={this.state.loading}
            color="green"
            onClick={() => this.handleAddData()}
          >
            <Icon name="plus" /> Add
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
  renderConfirmBox = () => {
    return (
      <Confirm
        header={`Are you sure you would like to remove '${this.state.removeData}'?`}
        open={this.state.confirmOpen}
        onCancel={this.confirmClose}
        onConfirm={this.handleRemoveData}
      />
    );
  };
  render() {
    let data =
      this.props.data !== undefined
        ? this.props.data.map((data) => {
            return (
              <Label key={data} size={"large"} color={"black"}>
                <React.Fragment>
                  {data}{" "}
                  {!this.props.disabled && (
                    <AuthComponents.Can scope={"members:update:anyone"}>
                      <Icon
                        name="delete"
                        disabled={this.props.disabled}
                        onClick={() =>
                          this.setState({
                            confirmOpen: true,
                            removeData: data,
                          })
                        }
                      />
                    </AuthComponents.Can>
                  )}
                </React.Fragment>
              </Label>
            );
          })
        : [];
    return (
      <Segment className="no-marg arrayCard">
        {this.renderModal()}
        {this.renderConfirmBox()}
        <Divider horizontal className="no-marg">
          <React.Fragment>
            {this.props.title ? this.props.title : "--"}{" "}
            {/* {this.props.permissions === undefined && (
              <Button
                className="subtleButton"
                onClick={() => this.setState({ addCat: !this.state.addCat })}
                icon
                basic
                size="tiny"
              >
                <Icon name="plus" color="green" />
              </Button>
            )} */}
              {!this.props.disabled && ( <AuthComponents.Can scope={"members:update:anyone"}>
              <Button
                className="subtleButton"
                onClick={() => this.setState({ addCat: !this.state.addCat })}
                icon
                basic
                disabled={this.props.disabled}
                size="tiny"
              >
                <Icon name="plus" color="green" />
              </Button>
            </AuthComponents.Can> )}
          </React.Fragment>
        </Divider>
        <Segment basic className="userRoles no-pad">
          {data.length === 0 ? (
            <Message color="black">
              <Message.Header>No Data</Message.Header>There is no data to
              display here
            </Message>
          ) : (
            data
          )}
        </Segment>
      </Segment>
    );
  }
}
ArrayCard.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  list: PropTypes.array,
  fieldName: PropTypes.string.isRequired,
  add: PropTypes.func,
  update: PropTypes.func,
};
