import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Segment, Header } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import api from "../actions/api";
import UserCard from "./common/cards/userCard";
import { getSystemSettings } from "../actions/settingsActions";
import CommonFunctions from "../lib/CommonFunctions";
import TheLoader from "./common/TheLoader";

function mapStateToProps(state) {
  return {
    system: state.system,
    user: state.user,
  };
}

class Home extends PureComponent {
  state = {
    user: {},
    addNew: false,
    eventDash: [],
    callouts: [],
    loading: true,
  };
  componentDidMount() {
    this._getData();
    this.props.getSystemSettings();
    // this.props.i18n.changeLanguage("en");
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.user !== prevState.user) {
      if (this.state.user._id) {
        api.vehicles.aMembersVehicles(this.state.user._id).then((vehicles) => {
          this.setState({
            vehicles: vehicles,
          });
        });
      }
    }
  }

  _getData = () => {
    Promise.all([
      api.member.show("[me]"),
      api.member.events(this.props.user.details.sub),
      api.member.callouts("[me]"),
    ])
      .then((res) => {
        this.setState({
          user: res[0],
          events: res[1],
          callouts: res[2],
          loading: false,
        });
        this.getEventDash();
      })
      .catch((err) => {
        if (err.response && err.response.status === 404) {
          this.setState({
            addNew: true,
          });
        }
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };
  getEventDash = () => {
    if (this.state.user._id) {
      api.member.eventsDash(this.props.user.details.sub).then((eventDash) => {
        this.setState({
          eventDash,
        });
      });
      // api.member.events(this.state.user.centralId).then((events) => {
      //   this.setState({
      //     events,
      //   });
      // });
    }
  };
  updateUser = (data) => {
    this.setState({ loading: true });
    api.member.update("[me]", data).then((res) => {
      this._getData();
      this.setState({ loading: false });
    });
  };
  updateStatus = (data) => {
    // this.setState({ loading: true });
    return api.member.updateStatus("[me]", data).then(() => {
      this._getData(this.props.match.params.id, this.props.match.params.uid);
      // this.setState({ loading: false });
    });
  };
  addUser = () => {
    let central = this.props.user.details;
    let data = {
      email: central.email,
      phone: central.phone,
      centralId: central.sub,
      firstName: central.firstName,
      lastName: central.surname,
      name: central.firstName + " " + central.surname,
      phoneSecondary: central.phoneSecondary,
      // organisation: {
      //   _id: central.organisation._id,
      //   name: central.organisation.name
      // }
    };
    api.member
      .add(data)
      .then(() => {
        this._getData();
      })
      .catch((err) => {
        //todo display error message to user
      });
  };
  render() {
    let permissions = CommonFunctions.memberPermissions(
      this.state.user,
      this.props.user
    );
    return (
      <Segment basic className="page-holder">
        <TheLoader active={this.state.loading} />
        {this.state.user._id ? (
          <UserCard
            settings={this.props.system.settings}
            permissions={permissions}
            addUser={this.addUser}
            addNew={this.state.addNew}
            user={this.state.user}
            update={this.updateUser}
            status={this.updateStatus}
            logs={this.state.logs}
            org={this.state.org}
            reloadUser={() => this._getData()}
            country={this.props.system.country}
            events={this.state.events}
            vehicles={this.state.vehicles}
            eventsDash={this.state.eventDash}
            showScores={false}
            callouts={this.state.callouts}
          />
        ) : (
          <Segment basic textAlign="center">
            <Header>No User Details</Header>
          </Segment>
        )}
        {/* <Segment basic></Segment> */}
      </Segment>
    );
  }
}
const homePage = withTranslation()(Home);
export default connect(mapStateToProps, { getSystemSettings })(homePage);
