import React, { useState, useEffect } from "react";
import api from "../../../actions/api";
import Box from "../ui-wrappers/Box";
import Layout from "../ui-wrappers/Layout";
import LocationMap from "../../common/mapping/locationMap";

export default function Supplier({ match, history }) {
  const [supplier, setSupplier] = useState({});
  const [showMap, setShowMap] = useState(false);
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    if (supplier.address && supplier.address.geoJSON.geometry) {
      setShowMap(true);
    }
  }, [supplier]);
  const getData = () => {
    api.management.suppliers.show(match.params.id).then((res) => {
      setSupplier(res);
    });
  };
  console.log(supplier);
  return (
    <div>
      <div className="p-4 flex gap-4">
        <Box
          title={supplier.name}
          button={{
            text: "Edit ",
            colour: "bg-blue-600",
            action: () =>
              history.push(`/assets/suppliers/${supplier._id}/edit`),
          }}
        >
          <div className="text-xl flex justify-between p-2 text-yellow-700">
            Address:{" "}
            <span className="text-white">
              {supplier.address &&
                supplier.address.geoJSON &&
                supplier.address.geoJSON.properties &&
                supplier.address.geoJSON.properties.address}
            </span>
          </div>
          <div className="text-xl flex justify-between p-2 text-yellow-700">
            Phone: <span className="text-white">{supplier.phone}</span>
          </div>

          <div className="text-xl flex justify-between p-2 text-yellow-700">
            Type:{" "}
            <span className="text-white">
              {supplier.type &&
                supplier.type.map((t, i) => {
                  return (
                    <span
                      key={i}
                      className="text-lg p-2 m-2 rounded-lg bg-gray-700"
                    >
                      {t}
                    </span>
                  );
                })}
            </span>
          </div>
          <div className="text-xl flex justify-between p-2 text-yellow-700">
            Details: <span className="text-white">{supplier.details}</span>
          </div>
          {showMap && (
            <div>
              <LocationMap
                marker={supplier.address}
                mapStyle={{ minHeight: 45 + "vh" }}
              />
            </div>
          )}
        </Box>
        <Box></Box>
      </div>
    </div>
  );
}
