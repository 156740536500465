import React from "react";
import {Button, Header, Loader, Message, Modal, Segment} from "semantic-ui-react";
import API from "../../../actions/api";

export default class CourseRemoveConfirm extends React.Component {
    state = {
        isDeleting: false,
        error: null
    };

    handleDelete(){
        this.setState({
            isDeleting: true
        }, () => {
            API.training.courses.remove(this.props.course._id).then(() => {
                this.props.onSuccess();
            }).catch(err => {
                let errorMessage = "There was an unexpected error while trying to delete the course, please try again in a few moments.";

                // TODO better error handing

                this.setState({
                    isDeleting: false,
                    error: errorMessage
                })
            });
        });
    }
    render(){
        if(this.state.error !== null){
            return (
                <Modal open={true} closeOnDimmerClick={false} closeOnEscape={false} closeOnDocumentClick={false}>
                    <Modal.Content>
                        <Message negative>{this.state.error}</Message>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button positive onClick={() => this.props.onCancel()}>Close</Button>
                    </Modal.Actions>
                </Modal>
            );
        }
        if(this.state.isDeleting){
            return (
                <Modal open={true} closeOnDimmerClick={false} closeOnEscape={false} closeOnDocumentClick={false}>
                    <Modal.Header>
                        <Header>Deleting course "{this.props.course.title}"</Header>
                    </Modal.Header>
                    <Modal.Content>
                        <Segment basic textAlign={"center"}>
                            <Loader active inline content={"Deleting course in progress..."} />
                        </Segment>
                    </Modal.Content>
                </Modal>
            );
        }
        return (
            <Modal open={true} closeOnDimmerClick={false} closeOnEscape={false} closeOnDocumentClick={false}>
                <Modal.Header>
                    <Header>Confirm deletion of course "{this.props.course.title}"</Header>
                </Modal.Header>
                <Modal.Content>
                    <Message negative>
                        <h2>Are you sure?</h2>
                        You are about to delete this course, all its responses and results, this action is not reversible. Are you sure you like to go ahead and delete this course permanently?
                    </Message>
                </Modal.Content>
                <Modal.Actions>
                    <Button positive floated={"left"} onClick={() => this.props.onCancel()}>Cancel</Button>
                    <Button negative onClick={() => this.handleDelete()}>Delete</Button>
                </Modal.Actions>
            </Modal>
        );
    }
}