import React from "react";

export default function Button({
  text,
  action,
  positive,
  negative,
  floatRight,
  style,
}) {
  return (
    <button
      onClick={action}
      className={`p-2 bg-gray-800 text-sm text-white border border-gray-400 rounded-lg hover:bg-opacity-60 
        ${positive ? "bg-green-800" : ""}
        ${negative ? "bg-red-800" : ""}
        ${floatRight ? "float-right" : ""}
        ${style ? style : ""}
      
      `}
    >
      {text}
    </button>
  );
}
