import React, { Component } from "react";
import {
  Segment,
  Header,
  Table,
  Button,
  Modal,
  Grid,
  Divider,
  Message
} from "semantic-ui-react";
import dayjs from "dayjs";
import api from "../../../actions/api";
import TheLoader from "../../common/TheLoader";

export default class IncidentList extends Component {
  state = {
    noSarSYS: false,
    incidents: [],
    local_incidents: [],
    view: false,
    loading: true,
    incident: {},
  };
  componentDidMount() {
    this.getData();
  }
  getData = () => {
    Promise.all([
      api.management.incidents.sarsys.all(),
      api.management.incidents.all(),
    ]).then((res) => {
      this.setState({
        incidents: res[0],
        local_incidents: res[1],
      });
    }).catch(() => {
      this.setState({
        noSarSYS: true
      })
    }).finally(() => {
      this.setState({
        loading: false,
      });
    });
  };
  importIncident = (id) => {
    api.management.incidents.sarsys.import(id).then((data) => {
      this.setState({
        view: false,
        loading: true,
      });
      this.getData();
    });
  };
  getIncident = (id) => {
    this.setState({
      view: true,
      loading: true,
    });
    api.management.incidents.sarsys.view(id).then((incident) => {
      this.setState({
        incident,
        loading: false,
      });
    });
  };
  renderIncidentView() {
    return (
      <Modal
        centered={false}
        open={this.state.view}
        onClose={() => this.setState({ view: false })}
      >
        <Modal.Header>View SarSYS Incident</Modal.Header>
        <Modal.Content>
          <Segment loading={this.state.loading}>
            <Header as={"h3"} textAlign="center">
              {this.state.incident.name}
            </Header>
            <Grid textAlign="center" celled>
              {this.state.incident.periods &&
                this.state.incident.periods.map((p) => {
                  return (
                    <Grid.Row key={p.refNum} columns={3}>
                      <Grid.Column>
                        <Header as={"h3"}>Start Date</Header>
                        {dayjs(p.start).format("DD/MM/YY HH:mm")}
                      </Grid.Column>
                      <Grid.Column>
                        <Header as={"h3"}>Resources</Header>
                        {p.members.length}
                      </Grid.Column>
                      <Grid.Column>
                        <Header as={"h3"}>End Date</Header>
                        {dayjs(p.end).format("DD/MM/YY HH:mm")}
                      </Grid.Column>
                    </Grid.Row>
                  );
                })}
              <Grid.Row>
                <Grid.Column width={16}>
                  <Header as={"h3"}>Outcome</Header>
                  {this.state.incident.outcome}
                  <Divider />
                  <Button
                    positive
                    onClick={() =>
                      this.importIncident(this.state.incident.sarsysId)
                    }
                  >
                    Import
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Modal.Content>
      </Modal>
    );
  }
  renderList() {
    const list = this.state.incidents.map((i) => {
      const existingIncident = this.state.local_incidents.filter((f) => {
        return f.sarsysId === i._id;
      });
      return (
        <Table.Row key={i._id}>
          <Table.Cell singleLine>
            {dayjs(i.created).format("DD/MM/YY HH:mm")}
          </Table.Cell>
          <Table.Cell>{i.name}</Table.Cell>
          <Table.Cell>{i.type}</Table.Cell>
          <Table.Cell>{i._totalOpPeriods}</Table.Cell>
          <Table.Cell>{i._totalResources}</Table.Cell>
          <Table.Cell>
            {i.outcome}
          </Table.Cell>
          <Table.Cell textAlign="right">
            {existingIncident.length > 0 ? (
              <Button
                basic
                primary
                onClick={() =>
                  this.props.history.push(`/management/incidents/${i._id}`)
                }
              >
                Goto
              </Button>
            ) : (
              <Button basic positive onClick={() => this.getIncident(i._id)}>
                View for Import
              </Button>
            )}
          </Table.Cell>
        </Table.Row>
      );
    });
    return (
      <Table striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell collapsing>Date</Table.HeaderCell>
            <Table.HeaderCell singleLine>Name</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell collapsing>Total Op Periods</Table.HeaderCell>
            <Table.HeaderCell collapsing>Total Resources</Table.HeaderCell>
            <Table.HeaderCell>Outcome</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{list}</Table.Body>
      </Table>
    );
  }
  render() {
    if(this.state.noSarSYS){
      return (
        <Segment basic>
          <Message negative>
            <Message.Header>SarSYS Communication Error</Message.Header>
            It appears you do not have SarSYS or have SarSYS [CAD] which is not yet supported in TeamSite.
          </Message>
        </Segment>
      );
    }
    return (
      <Segment basic className="no-pad no-marg">
        {this.renderIncidentView()}

        <Segment basic style={{ minHeight: "10vh" }}>
          <Header as={"h1"} textAlign="center">
            Incidents
          </Header>
          <Divider />

          <TheLoader active={this.state.loading} />
          {this.renderList()}
        </Segment>
      </Segment>
    );
  }
}
