import React from "react";
import { Icon, Button } from "semantic-ui-react";
import clsx from "clsx";

export default class CustomToolbar extends React.Component {
  view = view => {
    this.props.onView(view);
  };

  viewNamesGroup(messages) {
    let viewNames = this.props.views;
    const view = this.props.view;
    if (viewNames.length > 1) {
      return viewNames.map(name => (
        <Button type="button" key={name} className={("ui button ", clsx({ "ui button rbc-active": view === name }))} onClick={this.view.bind(null, name)}>
          {/* {messages[name]} */}
          {name}
        </Button>
      ));
    }
  }
  render() {
    const { messages } = this.props;
    return (
      <div className="rbc-toolbar">
        <div className="rbc-btn-group">
          <Button className="toolbar-navigation-button" type="button" onClick={() => this.props.onNavigate("PREV")}>
            <Icon className="navigate-icon" name={this.props.isRTL ? "angle right" : "angle left"} />
          </Button>
          <Button className="toolbar-navigation-button" type="button" onClick={() => this.props.onNavigate("TODAY")}>
            <span className="today-label" onClick={() => this.props.onNavigate("TODAY")}>
              Today
            </span>
          </Button>
          {this.props.canAdd && (
            <Button className="toolbar-navigation-button" type="button" positive onClick={() => this.props.ADD()}>
              <span className="navigate-icon">Add</span>
            </Button>
          )}
          <Button className="toolbar-navigation-button" type="button" onClick={() => this.props.onNavigate("NEXT")}>
            <Icon className="navigate-icon" name={this.props.isRTL ? "angle left" : "angle right"} />
          </Button>
        </div>
        <span className="rbc-toolbar-label">
          <h3>{this.props.label}</h3>
        </span>
        {/* <span className="rbc-toolbar-label">
          <i>
            <Icon name="star" /> You are the event organiser.
          </i>
        </span> */}
        <span className="rbc-btn-group">{this.viewNamesGroup(messages)}</span>
      </div>
    );
  }
}
