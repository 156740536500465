import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Grid,
  Header,
  Segment,
  Divider,
  Menu,
  Form,
  Button,
  Icon,
  Input,
  Table,
} from "semantic-ui-react";
import { getSystemSettings } from "../../../actions/settingsActions";
import LocationMap from "../../common/mapping/locationMap";
import TheButton from "../../common/TheButton";
import api from "../../../actions/api";
import Geosuggest from "react-geosuggest";
import CommonFunctions from "../../../assets/utils/commonFunctions";
import { Authentication } from "@liquidcomputing/sarstuff__reactjs-singlesignon";
function mapStateToProps(state) {
  return {
    system: state.system,
    user: state.user,
  };
}

class LocationDash extends Component {
  state = {
    loading: false,
    bounds: false,
    marker: null,

    w3w: {
      first: "",
      second: "",
      third: "",
    },
    w3wError: "",
    type: "grid",
    errors: {},
    grid: "",
    val: {},
    suggestion: false,
    locations: [],
    flyTo: {},
  };
  componentDidMount = () => {
    this.props
      .getSystemSettings()
      .then(() => {})
      .then(() => {
        this.setState({
          country: this.props.system.country,
        });
      })
      .catch((err) => {
        console.log(err);
      });
    this.getData();
    // this.getLatLngEN(581464, 166106);
  };
  getData = () => {
    api.location.all().then((res) => {
      this.setState({
        locations: res,
        loading: false,
        bounds: true,
      });
    });
  };

  validateGrid = (data) => {
    const errors = {};
    //TODO Tim can you check the first two characters are text and the reaming digits are even in number
    if (!data) errors.grid = true;
    return errors;
  };
  validateEN = (e, n) => {
    const errors = {};
    //TODO Tim can you check the first two characters are text and the reaming digits are even in number
    if (!e) errors.e = true;
    if (!n) errors.n = true;
    return errors;
  };
  handleGridChange = (e) => {
    this.setState({
      grid: e.target.value.toUpperCase(),
    });
  };
  handleChange = (e) => {
    this.setState({
      marker: {
        ...this.state.marker,
        geoJSON: {
          ...this.state.marker.geoJSON,
          properties: {
            ...this.state.marker.geoJSON.properties,
            [e.target.name]: e.target.value,
          },
        },
      },
    });
  };
  handleDropdownChange = (props, e) => {
    this.setState({
      marker: {
        ...this.state.marker,
        geoJSON: {
          ...this.state.marker.geoJSON,
          properties: {
            ...this.state.marker.geoJSON.properties,
            [e.name]: e.value,
          },
        },
      },
    });
  };

  handleW3WChange = (e) => {
    this.setState({
      w3w: {
        ...this.state.w3w,
        [e.target.name]: e.target.value,
      },
      w3wError: "",
    });
  };
  findW3w = () => {
    if (this.state.w3w.first && this.state.w3w.second && this.state.w3w.third) {
      CommonFunctions.findW3w(
        this.state.w3w.first,
        this.state.w3w.second,
        this.state.w3w.third
      )
        .then((w3w) => {
          let marker = {
            geoJSON: {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [w3w.coordinates.lng, w3w.coordinates.lat],
              },
              properties: {
                grid: this.getGrid(w3w.coordinates.lat, w3w.coordinates.lng),
                name: w3w.words,
              },
            },
          };
          this.setState({
            marker: marker,
            w3wError: "",
          });
        })
        .catch((error) => {
          // catch errors here
          if (error.code) {
            this.setState({
              w3wError: error.code,
            });
          }
        });
    }
  };

  getGrid = (lat, lng) => {
    return CommonFunctions.getGrid(
      lat,
      lng,
      this.props.system.country.gridType
    );
  };
  getLatLng = (grid) => {
    return CommonFunctions.getLatLng(grid, this.props.system.country.gridType);
  };
  getLatLngEN = (e, n) => {
    return CommonFunctions.getLatLngEN(e, n);
  };
  getLatLngUTM = (data) => {
    return CommonFunctions.getLatLngUTM(data);
  };
  findGrid = () => {
    let bob = this.getLatLng(this.state.grid);
    if (bob) {
      let geoJSON = {
        geoJSON: {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: bob,
          },
          properties: {
            name: this.state.grid,
            grid: this.state.grid,
          },
        },
      };
      this.setState({
        marker: geoJSON,
        grid: "",
      });
    }
  };
  findUTM = () => {
    const errors = this.validateGrid(this.state.grid);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      let data = this.getLatLngUTM(this.state.grid);
      let geoJSON = {
        geoJSON: {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [data.longitude, data.latitude],
          },
          properties: {
            name: data.grid,
            grid: data.grid,
          },
        },
      };
      this.setState({
        marker: geoJSON,
        grid: "",
      });
    }
  };
  findEN = () => {
    const errors = this.validateEN(
      this.state.mast.easting,
      this.state.mast.northing
    );
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      let data = this.getLatLngEN(
        this.state.mast.easting,
        this.state.mast.northing
      );
      let geoJSON = {
        geoJSON: {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [data.longitude, data.latitude],
          },
          properties: {
            name: data.grid,
            grid: this.getGrid(data.latitude, data.longitude),
          },
        },
      };
      this.setState({
        marker: geoJSON,
        grid: "",
      });
    }
  };
  clearMarker = () => {
    this.setState({
      marker: null,
      val: {},
      suggestion: false,
      phone: null,
      type: "grid",
    });
  };
  validate = (data) => {
    const errors = {};
    //TODO Tim can you check the first two characters are text and the reaming digits are even in number
    // if (!data.geoJSON.properties.type) errors.type = "Need to pick a type.";
    return errors;
  };
  addPoint = () => {
    const errors = this.validate(this.state.marker);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      let data = this.state.marker;

      return api.location.add(data).then(() => {
        this.getData();
        this.setState({
          marker: null,
          grid: "",
          bounds: false,
        });
      });
    }
  };
  onSuggestSelect = (suggest) => {
    if (suggest) {
      let coords = [suggest.location.lng, suggest.location.lat];
      let components = suggest.gmaps.address_components;
      let admin_area = "";
      let postal_town = "";
      let postal_code = "";
      // eslint-disable-next-line
      let name = "";
      // eslint-disable-next-line
      let street = "";
      let county = "";
      let town = "";
      for (let component = 0; component < components.length; component++) {
        // if (components[component].types[0] === "premise") {
        //   name = components[component].long_name;
        // }
        // if (components[component].types[0] === "route") {
        //   street = components[component].long_name;
        // }
        if (components[component].types[0] === "locality") {
          admin_area = components[component].long_name;
        }
        if (components[component].types[0] === "postal_town") {
          postal_town = components[component].long_name;
        }
        if (components[component].types[0] === "administrative_area_level_2") {
          county = components[component].long_name;
        }
        if (components[component].types[0] === "postal_code") {
          postal_code = components[component].long_name;
        }
        if (admin_area) {
          town = admin_area;
        } else {
          town = postal_town;
        }
      }
      this.setState({
        suggestion: true,
        marker: {
          geoJSON: {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: coords,
            },
            properties: {
              name: suggest.label,
              town: town,
              county: county,
              postcode: postal_code,
              address: suggest.gmaps.formatted_address,
              grid: this.getGrid(suggest.location.lat, suggest.location.lng),
            },
          },
        },

        // val: suggest,
        // zoom: 10
      });
    }
  };
  handleMapData = (data) => {
    let marker = {
      geoJSON: {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: data,
        },
        properties: {
          grid: this.getGrid(data[1], data[0]),
          name: this.getGrid(data[1], data[0]),
        },
      },
    };
    this.setState(
      {
        marker: marker,
      },
      () => {
        if (this.state.type === "mast") {
          this.createSector();
        }
      }
    );
  };

  renderLocationBar() {
    let w3disabled = true;
    if (
      this.state.w3w.first !== "" &&
      this.state.w3w.second !== "" &&
      this.state.w3w.third !== ""
    ) {
      w3disabled = false;
    }
    return (
      <Segment basic className="no-pad">
        <Menu widths={3}>
          <Menu.Item
            active={this.state.type === "grid"}
            onClick={() => this.setState({ type: "grid" })}
          >
            <Icon name="map" size="small" />
            Grid
          </Menu.Item>
          <Menu.Item
            active={this.state.type === "address"}
            onClick={() => this.setState({ type: "address", marker: null })}
          >
            <Icon name="map signs" size="small" />
            Address
          </Menu.Item>
          <Menu.Item
            active={this.state.type === "w3w"}
            onClick={() => this.setState({ type: "w3w", marker: null })}
          >
            {/* <Image
              src={require("../../../assets/images/w3w.png")}
              alt="what3words"
              style={{ width: 20, marginRight: 5 }}
            /> */}
            W3W
          </Menu.Item>
        </Menu>
        {/* <Divider /> */}
        {this.props.system.country.gridType === "OSGB" &&
          this.state.type === "grid" && (
            <Segment basic width={16}>
              <Form onSubmit={() => this.findGrid()}>
                <Form.Field error={!!this.state.errors.grid}>
                  <Input
                    name="grid"
                    id={"grid-search"}
                    action={{ icon: "search" }}
                    placeholder="TQ1234..."
                    value={this.state.grid}
                    onChange={this.handleGridChange}
                  />
                </Form.Field>
              </Form>
            </Segment>
          )}
        {this.props.system.country.gridType === "OSIE" &&
          this.state.type === "grid" && (
            <Segment basic width={16}>
              <Form onSubmit={() => this.findGrid()}>
                <Form.Field error={!!this.state.errors.grid}>
                  <Input
                    name="grid"
                    id={"grid-search"}
                    action={{ icon: "search" }}
                    placeholder="T01234..."
                    value={this.state.grid}
                    onChange={this.handleGridChange}
                  />
                </Form.Field>
              </Form>
            </Segment>
          )}
        {this.props.system.country.gridType === "UTM" &&
          this.state.type === "grid" && (
            <Segment basic width={16}>
              <Form onSubmit={() => this.findUTM()}>
                <Form.Field error={!!this.state.errors.grid}>
                  <Input
                    name="grid"
                    id={"grid-search"}
                    action={{ icon: "search" }}
                    placeholder="31 N358 567..."
                    value={this.state.grid}
                    onChange={this.handleGridChange}
                  />
                </Form.Field>
              </Form>
            </Segment>
          )}

        {this.state.type === "w3w" && (
          <Segment basic width={16}>
            <Form>
              <Form.Group widths={"equal"}>
                <Form.Field>
                  <input
                    name="first"
                    value={this.state.w3w.first}
                    placeholder="first word"
                    onChange={this.handleW3WChange}
                  />
                </Form.Field>
                <Form.Field>
                  <input
                    name="second"
                    value={this.state.w3w.second}
                    placeholder="second word"
                    onChange={this.handleW3WChange}
                  />
                </Form.Field>
                <Form.Field>
                  <input
                    name="third"
                    value={this.state.w3w.third}
                    placeholder="third word"
                    onChange={this.handleW3WChange}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Field>
                <Button
                  onClick={() => this.findW3w()}
                  color="blue"
                  fluid
                  disabled={w3disabled}
                >
                  Find
                </Button>
              </Form.Field>
              {this.state.w3wError && (
                <Header color="red" textAlign="center">
                  {this.state.w3wError}
                </Header>
              )}
            </Form>
          </Segment>
        )}
        {this.state.type === "address" && (
          <Segment basic width={16}>
            <Geosuggest
              ref={(el) => (this._geoSuggest = el)}
              placeholder="Tesco, Ashford, Kent"
              inputClassName="geosuggest__input"
              country={this.props.system.country.name}
              onSuggestSelect={this.onSuggestSelect}
              suggestsClassName="geosuggest__suggests"
              suggestsHiddenClassName="geosuggest__suggests--hidden"
              suggestItemClassName="geosuggest__item"
            />
          </Segment>
        )}
        {this.state.marker !== null &&
          this.state.type !== "mast" &&
          this.renderBasicLocationForm()}
      </Segment>
    );
  }
  renderBasicLocationForm() {
    return (
      <Segment basic>
        <Header textAlign={"center"}>
          {this.state.marker !== null
            ? "Grid: " + this.state.marker.geoJSON.properties.grid
            : "Grid: --"}
        </Header>
        <Form>
          <Form.Field>
            <label>Location Name</label>
            <input
              name="name"
              onChange={this.handleChange}
              value={this.state.marker.geoJSON.properties.name}
              placeholder={"name...."}
            />
          </Form.Field>
          {/* <Form.Field>
            <label>Location Type</label>
            <Form.Select
              placeholder={"pick one"}
              name={"type"}
              options={this.state.locationTypes}
              value={this.state.marker.geoJSON.properties.type}
              onChange={this.handleDropdownChange}
            />
            {this.state.errors.type && <p style={{ color: "red" }}>{this.state.errors.type}</p>}
          </Form.Field> */}
          <Divider />
          <Form.Group widths={"equal"} className={"form-actions"}>
            <Form.Field>
              <Button
                negative
                floated={"left"}
                onClick={() => this.clearMarker()}
                size={"tiny"}
                icon
              >
                <Icon name="close" />
                Clear
              </Button>
            </Form.Field>
            <Form.Field>
              <TheButton
                floated="right"
                color="green"
                icon={true}
                iconName={"checkmark"}
                label={" Add"}
                onClick={this.addPoint}
                disabled={this.props.readOnly}
                size={"tiny"}
              />
            </Form.Field>
          </Form.Group>
        </Form>
      </Segment>
    );
  }
  renderMap() {
    let locations = this.state.locations.filter((loc) => {
      return loc.hide !== true && loc.geoJSON.geometry.type === "Point";
    });
    return (
      // <React.Fragment>
      <LocationMap
        data={this.handleMapData}
        marker={this.state.marker}
        locations={locations}
        flyTo={this.state.flyTo}
        country={this.props.system.country}
        bounds={this.state.bounds}
        mapStyle={{
          minHeight: 80 + "vh",
          width: 100 + "%",
        }}
      />
      // </React.Fragment>
    );
  }
  renderLocations() {
    const locations = this.state.locations.map((loc) => {
      let row = "";
      if (loc.hide) {
        row = "hidden";
      }
      return (
        <Table.Row key={loc._id} className={row}>
          <Table.Cell
            onClick={() =>
              this.props.history.push(`/training/locations/${loc._id}`)
            }
          >
            {loc.geoJSON && loc.geoJSON.properties
              ? loc.geoJSON.properties.name
              : "--"}
          </Table.Cell>
          {/* <Table.Cell onClick={() => this.props.history.push(`/incident/locations/${loc._id}`)}>{loc.geoJSON.properties.type}</Table.Cell> */}
          <Table.Cell
            onClick={() =>
              this.props.history.push(`/training/locations/${loc._id}`)
            }
          >
            {loc.geoJSON.properties.grid}
          </Table.Cell>
          <Table.Cell
            textAlign="right"
            onClick={() => this.setState({ flyTo: loc })}
          >
            <Button
              onClick={() => this.setState({ flyTo: loc })}
              icon
              // circular
              size="small"
              inverted
              color="blue"
            >
              <Icon name="pin" color="blue" />
            </Button>
          </Table.Cell>
        </Table.Row>
      );
    });
    return (
      <Table stackable selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            {/* <Table.HeaderCell>Type</Table.HeaderCell> */}
            <Table.HeaderCell>Grid</Table.HeaderCell>

            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>{locations}</Table.Body>
      </Table>
    );
  }
  render() {
    return (
      <Segment basic loading={this.state.loading}>
        <Grid stackable>
          <Grid.Row>
            <Grid.Column computer={6} mobile={16}>
              <Segment className="no-pad">
                {Authentication.can("locations:update") &&
                  this.renderLocationBar()}
              </Segment>
              <Segment>
                <Header as={"h1"} textAlign={"center"}>
                  Locations
                </Header>
                {this.renderLocations()}
              </Segment>
            </Grid.Column>
            <Grid.Column computer={10} mobile={16}>
              {this.renderMap()}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}
export default connect(mapStateToProps, { getSystemSettings })(LocationDash);
