import React, { Component } from "react";
import {
  Statistic,
  Header,
  Segment,
  Select,
  Button,
  Grid,
  Menu,
  Icon,
  Form,
  Modal,
  Table,
} from "semantic-ui-react";
import API from "../../actions/api";
import VehicleList from "../common/lists/vehicleList";
import moment from "moment";
import CommonFunctions from "../../lib/CommonFunctions";
const tyres = [
  { key: 1, text: "Road", value: "Road" },
  { key: 2, text: "Mud", value: "Mud" },
  { key: 3, text: "Mud/Snow", value: "Mud/Snow" },
  { key: 4, text: "All Terrain", value: "All Terrain" },
];
export default class VehicleDash extends Component {
  state = {
    activeItem: "dash",
    loading: true,
    vehicles: [],
    memberVehicles: [],
    vehicleModal: false,
    vehicle: {},
    permissions: {},
  };
  componentDidMount() {
    let permissions = CommonFunctions.vehiclePermissions();
    this.setState({
      permissions,
    });
    this.getData();
  }
  getData() {
    API.vehicles.getTeamVehicles().then((data) => {
      this.setState({
        vehicles: data,
        loading: false,
      });
    });
    API.vehicles.getMemberVehicles().then((data) => {
      this.setState({
        memberVehicles: data,
        loading: false,
      });
    });
  }
  handleMenuClick = (e, { name }) => this.setState({ activeItem: name });

  onVehicleChange = (e) => {
    this.setState({
      vehicle: { ...this.state.vehicle, [e.target.name]: e.target.value },
    });
  };
  handleVehicleDropdownChange = (e, data) => {
    const vname = data.name;
    this.setState({
      vehicle: { ...this.state.vehicle, [vname]: data.value },
    });
  };
  goto = (vid) => {
    this.props.history.push(`/team/vehicles/${vid}`);
  };
  addVehicle = () => {
    let data = this.state.vehicle;
    API.vehicles.add(data).then(() => {
      this.setState({
        vehicleModal: false,
      });
      this.getData();
    });
  };
  addVehicleModal() {
    return (
      <Modal
        open={this.state.vehicleModal}
        onClose={() => this.setState({ vehicleModal: false })}
        centered={false}
        size="large"
        closeOnDimmerClick={false}
      >
        <Header icon="car" content="Add Vehicle" />
        <Modal.Content>
          <Form>
            <Form.Group widths={3}>
              <Form.Field>
                <label>Make</label>
                <input name="make" onChange={this.onVehicleChange} />
              </Form.Field>
              <Form.Field>
                <label>Model</label>
                <input name="model" onChange={this.onVehicleChange} />
              </Form.Field>
              <Form.Field>
                <label>Reg Number</label>
                <input name="regNumber" onChange={this.onVehicleChange} />
              </Form.Field>
            </Form.Group>
            <Form.Group widths={4}>
              <Form.Field>
                <label>Seats</label>
                <input name="seats" onChange={this.onVehicleChange} />
              </Form.Field>
              <Form.Field>
                <label>Tyres</label>
                <Select
                  name="tyres"
                  options={tyres}
                  onChange={this.handleVehicleDropdownChange}
                />
              </Form.Field>
              <Form.Field>
                <label>Doors</label>
                <input name="doors" onChange={this.onVehicleChange} />
              </Form.Field>
              <Form.Field>
                <label>Tow Weight</label>
                <input name="towWeight" onChange={this.onVehicleChange} />
              </Form.Field>
            </Form.Group>
            <Form.Group widths={4}>
              <Form.Field>
                <label>Colour</label>
                <input name="colour" onChange={this.onVehicleChange} />
              </Form.Field>
              <Form.Field>
                <label>Unit Vehicle</label>
                <Form.Checkbox
                  className={"goodToggle"}
                  style={{ padding: 10 }}
                  checked={this.state.vehicle.keepAccount}
                  onClick={() =>
                    this.setState({
                      vehicle: {
                        ...this.state.vehicle,
                        unitVehicle: !this.state.vehicle.unitVehicle,
                      },
                    })
                  }
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            negative
            floated={"left"}
            onClick={() => this.setState({ vehicleModal: false })}
          >
            Cancel
          </Button>
          <Button color="green" onClick={this.addVehicle} inverted>
            <Icon name="save" />
            Add
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
  calcDiff = (date) => {
    if (date) {
      let date1 = moment(date);
      let date2 = date1.add(1, "year");
      return date2.diff(moment(), "days");
    } else {
      return "--";
    }
  };
  calcApp = (date) => {
    if (date) {
      let date1 = moment(date);
      return date1.diff(moment(), "days");
    } else {
      return "--";
    }
  };
  styleDiff = (diff, type) => {
    if (diff !== "--") {
      if (diff < 90 && diff > 0 && type === "w") {
        return true;
      } else if (diff < 0 && type === "n") {
        return true;
      }
      return false;
    }
    return false;
  };
  renderMenu() {
    const { activeItem } = this.state;
    return (
      <Menu>
        <Menu.Item>
          <Header>Vehicles</Header>
        </Menu.Item>

        <Menu.Item
          name="dash"
          active={activeItem === "dash"}
          onClick={this.handleMenuClick}
        >
          <Icon name="home" />
          Dash
        </Menu.Item>
        <Menu.Item
          name="lists"
          active={activeItem === "lists"}
          onClick={this.handleMenuClick}
        >
          <Icon name="list" />
          Lists
        </Menu.Item>
        <Menu.Item onClick={() => this.setState({ vehicleModal: true })}>
          <Icon name="plus" color="green" />
          Add
        </Menu.Item>
      </Menu>
    );
  }

  renderDash() {
    let approved = 0;
    let mApproved = 0;
    let uApproved = 0;
    this.state.memberVehicles.filter((v) => {
      if (v.approved) {
        mApproved++;
      }
      return null;
    });
    this.state.vehicles.filter((v) => {
      if (v.approved) {
        uApproved++;
      }
      return null;
    });
    approved = mApproved + uApproved;
    return (
      <Segment basic>
        {this.addVehicleModal()}
        <Grid stackable celled className="no-pad no-marg">
          <Grid.Row columns={2}>
            <Grid.Column className="no-pad">
              <Segment basic className="no-pad">
                <Statistic.Group widths={3}>
                  <Statistic>
                    <Statistic.Value>
                      {this.state.memberVehicles.length}
                    </Statistic.Value>
                    <Statistic.Label>Member Vehicles</Statistic.Label>
                  </Statistic>
                  <Statistic>
                    <Statistic.Value>{approved}</Statistic.Value>
                    <Statistic.Label>Approved Vehicles</Statistic.Label>
                  </Statistic>
                  <Statistic>
                    <Statistic.Value>
                      {this.state.vehicles.length}
                    </Statistic.Value>
                    <Statistic.Label>Unit vehicles</Statistic.Label>
                  </Statistic>
                </Statistic.Group>
              </Segment>
            </Grid.Column>
            <Grid.Column className="no-pad">
              <p className="boxHeader">Unit Vehicles</p>
              <VehicleList
                selectable={
                  this.state.permissions.canUpdateOthers ? true : false
                }
                teamVehicle="true"
                data={this.state.vehicles}
                action={this.goto}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }

  renderLists() {
    const list = this.state.vehicles
      .sort((a, b) => {
        if (a.mot > b.mot) {
          return 1;
        } else if (a.mot < b.mot) {
          return -1;
        } else {
          return 0;
        }
      })
      .map((item) => {
        let mot = this.calcDiff(item.mot);
        let insurance = this.calcDiff(item.insurance);
        return (
          <Table.Row key={item._id} onClick={() => this.goto(item._id)}>
            <Table.Cell>{item.callsign}</Table.Cell>
            <Table.Cell>{item.regNumber}</Table.Cell>
            <Table.Cell
              warning={this.styleDiff(mot, "w")}
              negative={this.styleDiff(mot, "n")}
            >
              in {mot} days
            </Table.Cell>
            <Table.Cell
              warning={this.styleDiff(insurance, "w")}
              negative={this.styleDiff(insurance, "n")}
            >
              in {insurance} days
            </Table.Cell>
          </Table.Row>
        );
      });
    const list2 = this.state.memberVehicles
      .sort((a, b) => {
        let date_a =
          a.appraisal !== undefined ? a.appraisal : "1970-01-01T00:00:00.000Z";
        let date_b =
          b.appraisal !== undefined ? b.appraisal : "1970-01-01T00:00:00.000";
        if (date_a < date_b) {
          return -1;
        } else if (date_a > date_b) {
          return 1;
        } else {
          return 0;
        }
      })
      .map((item) => {
        let appraisal = this.calcApp(item.appraisal);

        return (
          <Table.Row
            key={item._id}
            onClick={() => this.goto(item._id)}
            positive={item.approved}
            negative={!item.approved}
          >
            <Table.Cell>{item.member ? item.member.name : "--"}</Table.Cell>
            <Table.Cell>{item.regNumber}</Table.Cell>
            <Table.Cell>in {this.calcDiff(item.mot)} days</Table.Cell>
            <Table.Cell>in {this.calcDiff(item.insurance)} days</Table.Cell>
            <Table.Cell
              warning={this.styleDiff(appraisal, "w")}
              negative={this.styleDiff(appraisal, "n")}
            >
              in {appraisal} days
            </Table.Cell>
          </Table.Row>
        );
      });
    return (
      <Grid stackable className="no-marg">
        <Grid.Row columns="2">
          <Grid.Column>
            <Segment basic className="subtle">
              <p className="boxHeader">Unit Vehicle Dates</p>
              <Table stackable selectable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>Reg</Table.HeaderCell>
                    <Table.HeaderCell>MoT Due</Table.HeaderCell>
                    <Table.HeaderCell>Ins. Due</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>{list}</Table.Body>
              </Table>
              {/* <VehicleList teamVehicle="true" tickets={true} data={this.state.vehicles} action={this.goto} small={true} /> */}
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment basic className="subtle">
              <p className="boxHeader">Member Vehicle Dates</p>
              <Table stackable selectable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Callsign</Table.HeaderCell>
                    <Table.HeaderCell>Reg</Table.HeaderCell>
                    <Table.HeaderCell>MoT Due</Table.HeaderCell>
                    <Table.HeaderCell>Ins. Due</Table.HeaderCell>
                    <Table.HeaderCell>Appraisal Due</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>{list2}</Table.Body>
              </Table>
              {/* <VehicleList teamVehicle="true" tickets={true} data={this.state.vehicles} action={this.goto} small={true} /> */}
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
  render() {
    const { activeItem } = this.state;
    return (
      <Segment basic className="no-pad no-marg">
        {this.addVehicleModal()}
        {this.renderMenu()}
        {activeItem === "dash" && this.renderDash()}
        {activeItem === "lists" && this.renderLists()}
      </Segment>
    );
  }
}
