import React, { Component } from "react";
import {
  Segment,
  Input,
  Button,
  Grid,
  Header,
  Menu,
  Icon,
} from "semantic-ui-react";
import { connect } from "react-redux";
import { Authentication } from "@liquidcomputing/sarstuff__reactjs-singlesignon";
import { getSystemSettings } from "../../actions/settingsActions";
import TeamList from "../common/lists/teamList";
import api from "../../actions/api";
import TheLoader from "../common/TheLoader";
import ListMap from "../common/mapping/listMap";
import ReallyCoolButton from "../common/ReallyCoolButton";
import CommonFunctions from "../../lib/CommonFunctions";

function mapStateToProps(state) {
  return {
    user: state.user,
    system: state.system,
  };
}

class TeamListPage extends Component {
  state = {
    activeItem: "list",
    loading: true,
    filter: "",
    filtered: [],
    teamList: [],
  };

  componentDidMount() {
    this._getData();
    this.props.getSystemSettings();
  }

  handleMenuClick = (e, { name }) => this.setState({ activeItem: name });

  _getData = () => {
    api.member.all().then((res) => {
      this.setState({
        teamList: res,
        filtered: res,
        loading: false,
      });
    });
  };

  goto = (id) => {
    this.props.history.push(`/team/${id}`);
  };

  filterBy = (data) => {
    if (data) {
      if (data === this.state.filter) {
        data = "";
      }
      this.setState(
        {
          filter: data,
        },
        () => {
          if (this.state.filter !== "") {
            let list = this.state.teamList;
            list = list.filter((item) => {
              let hasRoles = item.roles.filter((role) => {
                return role === this.state.filter;
              });
              return hasRoles.length >= 1;
            });
            this.setState({
              filtered: list,
            });
          } else {
            this.setState({
              filtered: this.state.teamList,
            });
          }
        }
      );
    }
  };

  filterList = (e) => {
    let updatedList = this.state.teamList;
    updatedList = updatedList.filter((item) => {
      let nameMatch = item.name
        ? item.name.toLowerCase().search(e.target.value.toLowerCase()) !== -1
        : false;
      let callsignMatch = item.teamId
        ? item.teamId.toLowerCase().search(e.target.value.toLowerCase()) !== -1
        : false;
      return nameMatch || callsignMatch;
    });
    this.setState({ filtered: updatedList });
  };

  getRolesCount = (item) => {
    let total = 0;
    this.state.teamList.forEach((team) => {
      if (team.roles !== undefined && team.roles.indexOf(item) !== -1) {
        total++;
      }
    });
    return total;
  };

  renderTeamList() {
    let privacyBypass = false;
    if (Authentication.can("members:privacy_bypass")) {
      privacyBypass = true;
    }
    return (
      <Grid className={"no-pad no-marg"}>
        <Grid.Row>
          <Grid.Column className=" no-pad">
            {/* <a href={`mailto:${emailList}`}>Emails</a> */}
            <Segment className="no-pad">
              <TeamList
                data={this.state.filtered}
                action={this.goto}
                privacyBypass={privacyBypass}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  renderFilter() {
    return (
      <Grid className={"no-pad no-marg"}>
        <Grid.Row>
          <Grid.Column className=" no-pad">
            <Segment className="subtle">
              {Authentication.can("members:export_emails") && (
                <div className="flex gap-2">
                  <button
                    type="button"
                    className="w-full my-2 px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600 hover:bg-gray-700"
                    onClick={() => {
                      CommonFunctions.generateCSV(
                        "Member List Export",
                        [
                          { csv_field: "Unit ID", database_field: "teamId" },
                          { csv_field: "Name", database_field: "name" },
                          { csv_field: "Telephone", database_field: "phone" },
                          { csv_field: "Email", database_field: "email" },
                        ],
                        this.state.filtered
                      );
                    }}
                  >
                    <Icon name="download" /> To .csv
                  </button>

                  <ReallyCoolButton
                    content={"Emails To Clipboard"}
                    fieldToCopy={"email"}
                    toClipboard={this.state.filtered}
                    margin={2}
                    icon={<Icon name="copy" />}
                  />
                </div>
              )}
              <p className="boxHeader"> Filter</p>
              <Input
                className="inputStyle"
                autoComplete="off"
                name="name"
                fluid
                placeholder="Please enter a name or callsign"
                icon="search"
                onChange={this.filterList}
              />
              <Segment basic className="rolesList no-pad">
                {this.props.system.settings.orgRoles !== undefined
                  ? this.props.system.settings.orgRoles.sort().map((data) => {
                      return (
                        <Button
                          key={data}
                          // size="tiny"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            flex: 1,
                            textAlign: "left",
                          }}
                          fluid
                          inverted
                          toggle
                          active={this.state.filter === data}
                          onClick={() => this.filterBy(data)}
                        >
                          <span>{data}</span>
                          <span>{this.getRolesCount(data)}</span>
                        </Button>
                      );
                    })
                  : "no roles"}
                {this.state.filter !== "" && (
                  <Button
                    negative
                    fluid
                    inverted
                    onClick={() =>
                      this.setState({ filter: "" }, this._getData())
                    }
                  >
                    clear
                  </Button>
                )}
              </Segment>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  renderTeamMap() {
    return (
      <Grid className={"no-pad no-marg"}>
        <Grid.Row>
          <Grid.Column className=" no-pad">
            <Segment className="no-pad">
              <ListMap locations={this.state.filtered} />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  renderMenu() {
    const { activeItem } = this.state;
    return (
      <Menu>
        <Menu.Item>
          <Header as={"h3"}>Members</Header>
        </Menu.Item>
        <Menu.Item
          name="list"
          active={activeItem === "list"}
          onClick={this.handleMenuClick}
        >
          List
        </Menu.Item>
        <Menu.Item
          name="map"
          active={activeItem === "map"}
          onClick={this.handleMenuClick}
        >
          Map
        </Menu.Item>
      </Menu>
    );
  }

  render() {
    const { activeItem } = this.state;
    return (
      <Grid stackable className="no-marg">
        <Grid.Row className="no-pad">
          <Grid.Column width={16} className="no-pad">
            {this.renderMenu()}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={3}>{this.renderFilter()}</Grid.Column>
          <Grid.Column width={13}>
            <TheLoader
              active={this.state.loading}
              content={"Team members loading"}
            />

            {activeItem === "list" && this.renderTeamList()}
            {activeItem === "map" && this.renderTeamMap()}
          </Grid.Column>
        </Grid.Row>
        {/* <Grid.Row>
          <Grid.Column width={16}>
            <Segment basic></Segment>
          </Grid.Column>
        </Grid.Row> */}
      </Grid>
    );
  }

  render1() {
    let disabled = false;
    if (this.state.filter !== "") {
      disabled = true;
    }
    let privacyBypass = false;
    if (Authentication.can("members:privacy_bypass")) {
      privacyBypass = true;
    }
    return (
      <Segment basic>
        <Segment>
          <Grid columns={2} stackable textAlign="center">
            <Grid.Row>
              <Grid.Column className="roleButtons">
                {this.props.system.settings.orgRoles !== undefined
                  ? this.props.system.settings.orgRoles.sort().map((data) => {
                      return (
                        <Button
                          key={data}
                          size="tiny"
                          toggle
                          active={this.state.filter === data}
                          onClick={() => this.filterBy(data)}
                        >
                          {data}
                        </Button>
                      );
                    })
                  : "no roles"}
                {this.state.filter !== "" && (
                  <Button
                    negative
                    size="tiny"
                    onClick={() =>
                      this.setState({ filter: "" }, this._getData())
                    }
                  >
                    clear
                  </Button>
                )}
              </Grid.Column>
              {/* <Divider vertical>Or</Divider> */}
              <Grid.Column>
                <Input
                  disabled={disabled}
                  placeholder="Search by name..."
                  onChange={this.filterList}
                  size="large"
                  style={{ width: 100 + "%" }}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <Segment basic className="no-pad">
          <TheLoader
            active={this.state.loading}
            content={"Team members loading"}
          />
          <TeamList
            data={this.state.filtered}
            action={this.goto}
            privacyBypass={privacyBypass}
          />
        </Segment>
      </Segment>
    );
  }
}

export default connect(mapStateToProps, {
  getSystemSettings,
})(TeamListPage);
