import React, { useState, useEffect, Fragment } from "react";
import Input from "../ui-wrappers/Input";
import Layout from "../ui-wrappers/Layout";
import { Switch, Listbox, Transition } from "@headlessui/react";
import Box from "../ui-wrappers/Box";
import DatePicker from "react-datepicker";
import Button from "../ui-wrappers/Button";
import Select from "../ui-wrappers/Select";
import api from "../../../actions/api";

const status = [
  { key: 1, text: "Operational", value: "Operational" },
  { key: 2, text: "Non-Operational", value: "Non-Operational" },
  { key: 3, text: "Faulty", value: "Faulty" },
  { key: 4, text: "Out for Repair", value: "Out for Repair" },
];
const taskableTypes = [
  { key: 1, text: "Other", value: "Other" },
  { key: 2, text: "Drone", value: "Drone" },
  { key: 3, text: "Sonar", value: "Sonar" },
  { key: 4, text: "Boat", value: "Boat" },
];

export default function EditAsset({ match, history }) {
  const [tags, setTags] = useState([]);
  const [theTags, setTheTags] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [selected, setSelected] = useState(null);

  const [asset, setAsset] = useState({
    name: "",
    assetNum: "",
    serialNum: "",
    multi: false,
    qty: "1",
    date_purchased: "",
    purchase_cost: "",
    tags: [],
    serviceInterval: "",
  });

  useEffect(() => {
    api.assets.show(match.params.id).then((res) => {
      setAsset({
        ...asset,
        ...res,
      });
      if (res.supplier) {
        setSelected(res.supplier._id);
      }

      setTheTags(res.tags);
    });
    api.management.suppliers.all().then((res) => {
      setSuppliers(
        res.length
          ? res.map((s) => {
              return {
                key: s._id,
                text: s.name,
                value: s._id,
              };
            })
          : []
      );
    });
    api.assets.settings.all().then((res) => {
      setTags(
        res.tags.map((t, i) => {
          return {
            key: i,
            text: t,
            value: t,
          };
        })
      );
    });
  }, []);
  useEffect(() => {
    asset.tags = theTags;
  }, [theTags]);
  const onAssetChange = (e) => {
    setAsset({ ...asset, [e.target.name]: e.target.value });
  };
  const onSubmit = () => {
    let data = asset;
    data.tags = theTags;
    data.supplier = selected;
    api.assets.update(asset._id, data).then((res) => {
      history.push(`/assets/${asset._id}`);
    });
  };
  const manageTags = (data) => {
    let bob = theTags.includes(data);
    if (bob) {
      let frank = theTags.filter((t) => {
        return t !== data;
      });
      setTheTags(frank);
    } else {
      setTheTags(theTags.concat([data]));
    }
  };

  const renderTags = () => {
    return tags.map((t) => {
      let active = theTags.includes(t.value);
      return (
        <span
          onClick={() => manageTags(t.value)}
          key={t.key}
          className={`p-2 m-1 bg-gray-700 rounded-lg text-white cursor-pointer hover:bg-gray-600 ${
            active && " bg-green-500 hover:bg-green-900"
          }`}
        >
          {t.text}
        </span>
      );
    });
  };
  return (
    <Layout>
      <div className=" grid grid-cols-1 md:grid-cols-2 gap-4 ">
        <div className="col-span-1 md:col-span-1">
          <Box title="Asset Details">
            <div className="p-2">
              <div className="flex flex-row gap-4">
                <Input
                  label={"Name"}
                  field="name"
                  type={"text"}
                  value={asset.name}
                  onChange={onAssetChange}
                  placeholder="Dry suit.."
                />

                <Input
                  label={"Asset Number"}
                  field="assetNum"
                  type={"text"}
                  value={asset.assetNum.toUpperCase()}
                  onChange={onAssetChange}
                  placeholder="ABC123"
                />
              </div>
              <div className="flex flex-row gap-4">
                <Input
                  label={"Serial Number"}
                  field="serialNum"
                  type={"text"}
                  value={asset.serialNum}
                  onChange={onAssetChange}
                  placeholder="AX456777788889000000"
                />

                <Select
                  label="Status"
                  placeholder={"pick one"}
                  field={"status"}
                  value={asset.status}
                  options={status}
                  onChange={onAssetChange}
                  // className={errors.title ? "warning" : ""}
                />
              </div>
              <div className="grid grid-cols-4 justify-center gap-2 p-2">
                <div className="col-span-1 justify-center ">
                  <label
                    className={`py-2 block text-md font-medium font-mono text-yellow-700 `}
                  >
                    Multi
                  </label>
                  <Switch
                    checked={asset.multi}
                    name="multi"
                    onChange={(e) =>
                      setAsset({
                        ...asset,
                        multi: e,
                      })
                    }
                    className={`${
                      asset.multi ? "bg-green-900" : "bg-gray-600"
                    } relative inline-flex flex-shrink-0  items-center h-8  w-16  border-2 border-transparent rounded-full cursor-pointer `}
                  >
                    <span className="sr-only">Set as multi</span>
                    <span
                      className={`${
                        asset.multi ? "translate-x-10" : "translate-x-0"
                      } bg-gray-100 border border-yellow-900 inline-block w-8 h-8  rounded-full shadow-lg transform ring-0 transition ease-in-out duration-200`}
                    />
                  </Switch>
                  {asset.multi && (
                    <Input
                      label={"Qty"}
                      field="qty"
                      type={"string"}
                      value={asset.qty}
                      onChange={onAssetChange}
                      placeholder="23"
                    />
                  )}
                </div>

                <div className="col-span-1 justify-center ">
                  <label
                    className={`py-2 block text-md font-medium font-mono text-yellow-700 `}
                  >
                    Has Contents
                  </label>
                  <Switch
                    checked={asset.hasContents}
                    name="multi"
                    onChange={(e) =>
                      setAsset({
                        ...asset,
                        hasContents: e,
                      })
                    }
                    className={`${
                      asset.hasContents ? "bg-green-900" : "bg-gray-600"
                    } relative inline-flex flex-shrink-0  items-center h-8  w-16  border-2 border-transparent rounded-full cursor-pointer `}
                  >
                    <span className="sr-only">Set as hasContents</span>
                    <span
                      className={`${
                        asset.hasContents ? "translate-x-10" : "translate-x-0"
                      } bg-gray-100 border border-yellow-900 inline-block w-8 h-8  rounded-full shadow-lg transform ring-0 transition ease-in-out duration-200`}
                    />
                  </Switch>
                </div>
                <div className="col-span-1 justify-center ">
                  <label
                    className={`py-2 block text-md font-medium font-mono text-yellow-700 `}
                  >
                    Archive
                  </label>
                  <Switch
                    checked={asset.archived}
                    name="multi"
                    onChange={(e) =>
                      setAsset({
                        ...asset,
                        archived: e,
                      })
                    }
                    className={`${
                      asset.archived ? "bg-red-900" : "bg-gray-600"
                    } relative inline-flex flex-shrink-0  items-center h-8  w-16  border-2 border-transparent rounded-full cursor-pointer `}
                  >
                    <span className="sr-only">Set as archived</span>
                    <span
                      className={`${
                        asset.archived ? "translate-x-10" : "translate-x-0"
                      } bg-gray-100 border border-yellow-900 inline-block w-8 h-8  rounded-full shadow-lg transform ring-0 transition ease-in-out duration-200`}
                    />
                  </Switch>
                </div>
                <div className="col-span-1 justify-center ">
                  <label
                    className={`py-2 block text-md font-medium font-mono text-yellow-700 `}
                  >
                    Taskable
                  </label>
                  <Switch
                    checked={asset.taskable}
                    name="multi"
                    onChange={(e) =>
                      setAsset({
                        ...asset,
                        taskable: e,
                      })
                    }
                    className={`${
                      asset.taskable ? "bg-green-900" : "bg-gray-600"
                    } relative inline-flex flex-shrink-0  items-center h-8  w-16  border-2 border-transparent rounded-full cursor-pointer `}
                  >
                    <span className="sr-only">Set as taskable</span>
                    <span
                      className={`${
                        asset.taskable ? "translate-x-10" : "translate-x-0"
                      } bg-gray-100 border border-yellow-900 inline-block w-8 h-8  rounded-full shadow-lg transform ring-0 transition ease-in-out duration-200`}
                    />
                  </Switch>
                  {asset.taskable && (
                    <div className="">
                      <label
                        className={`pt-4 block text-md font-medium font-mono text-yellow-700 `}
                      >
                        Taskable Type
                      </label>
                      <Select
                        placeholder={"pick one"}
                        name={"taskableType"}
                        value={asset.taskableType}
                        options={taskableTypes}
                        onChange={(e) =>
                          setAsset({
                            ...asset,
                            taskableType: e.target.value,
                          })
                        }
                        // className={errors.title ? "warning" : ""}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className="flex flex-col justify-center ">
                <label
                  className={`pt-4 block text-md font-medium font-mono text-yellow-700 `}
                >
                  Supplier
                </label>
                <Select
                  placeholder={"pick one"}
                  name={"type"}
                  value={selected ? selected : ""}
                  options={suppliers ? suppliers : []}
                  onChange={(e) => setSelected(e.target.value)}
                  // className={errors.title ? "warning" : ""}
                />
                {/* <Listbox value={selected} onChange={(e) => setSelected(e)}>
                  <div className="relative mt-1 p-2">
                    <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-gray-900 rounded-lg shadow-md cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm">
                      <span className="block truncate p-2 text-lg">
                        {selected.name}
                      </span>
                      <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <SelectorIcon
                          className="w-5 h-5 text-white"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>
                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute w-full py-2 mt-1 overflow-auto text-base bg-gray-800 rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {suppliers.map((person, personIdx) => (
                          <Listbox.Option
                            key={personIdx}
                            className={({ active }) =>
                              `${
                                active
                                  ? "text-amber-900 bg-amber-100"
                                  : "text-white"
                              }
                          cursor-default border-b border-gray-700 hover:bg-gray-700 select-none relative py-2 pl-10 pr-4 text-lg`
                            }
                            value={person}
                          >
                            {({ selected, active }) => (
                              <>
                                <span
                                  className={`${
                                    selected ? "font-medium" : "font-normal"
                                  } block truncate `}
                                >
                                  {person.name}
                                </span>
                                {selected ? (
                                  <span
                                    className={`${
                                      active
                                        ? "text-amber-600"
                                        : "text-amber-600"
                                    }
                                absolute inset-y-0 left-0 flex items-center pl-3`}
                                  >
                                    <CheckIcon
                                      className="w-5 h-5 text-yellow-600"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </Listbox> */}
              </div>
              <div className="py-2">
                <label
                  className={`text-center p-4 block text-lg font-medium text-yellow-700 `}
                >
                  Tags
                </label>
                {renderTags()}
              </div>
              <div className="py-4">
                <div className="flex flex-row gap-4">
                  <div className="py-2 w-full">
                    <label
                      className={`pb-1 block text-lg font-medium text-yellow-700 `}
                    >
                      Date Purchased
                    </label>
                    <DatePicker
                      onChange={(date) =>
                        setAsset({
                          ...asset,
                          date_purchased: date,
                        })
                      }
                      selected={
                        asset.date_purchased
                          ? new Date(asset.date_purchased)
                          : ""
                      }
                      shouldCloseOnSelect={true}
                      dateFormat="dd-MM-yyyy"
                      maxDate={new Date()}
                      nextMonthButtonLabel=">"
                      previousMonthButtonLabel="<"
                      className="block w-full text-base p-1 md:text-lg bg-gray-900 border-2 border-gray-700 rounded shadow-sm"
                    />
                  </div>
                  <div className="py-2 w-full">
                    <label
                      className={`pb-1 block text-lg font-medium text-yellow-700 `}
                    >
                      Date Expires
                    </label>
                    <DatePicker
                      onChange={(date) =>
                        setAsset({
                          ...asset,
                          date_expires: date,
                        })
                      }
                      selected={
                        asset.date_expires ? new Date(asset.date_expires) : ""
                      }
                      shouldCloseOnSelect={true}
                      dateFormat="dd-MM-yyyy"
                      // minDate={new Date()}
                      nextMonthButtonLabel=">"
                      previousMonthButtonLabel="<"
                      className="block w-full text-base p-1 md:text-lg bg-gray-900 border-2 border-gray-700 rounded shadow-sm"
                    />
                  </div>
                  <div className=" w-full">
                    <Input
                      label={"Purchase price"}
                      field="purchase_cost"
                      type={"text"}
                      value={asset.purchase_cost}
                      onChange={onAssetChange}
                      placeholder="£123"
                    />
                  </div>
                  <div className=" w-full">
                    <Input
                      label={"Service Interval "}
                      field="serviceInterval"
                      type={"number"}
                      value={asset.serviceInterval ? asset.serviceInterval : 12}
                      onChange={onAssetChange}
                      placeholder="6"
                    />
                    <span className="italic text-sar">in months</span>
                  </div>
                </div>
              </div>
              <div className="pb-2">
                <div className="flex flex-row gap-4">
                  <div className=" w-full">
                    <label
                      className={`py-2 block text-md font-medium font-mono text-yellow-700 `}
                    >
                      Details
                    </label>
                    <textarea
                      rows={3}
                      name="details"
                      value={asset.details}
                      onChange={onAssetChange}
                      className={`p-2 bg-gray-900 mt-1 focus:ring-gray-500 focus:border-gray-500 placeholder-gray-600 block w-full shadow-sm text-xl border-gray-600 rounded-md`}
                    />
                  </div>
                </div>
              </div>
              <div className="py-4 flex justify-between ">
                <Button
                  style=" text-xl "
                  text="Cancel"
                  negative
                  action={() => history.push(`/assets/${asset._id}`)}
                />
                <Button
                  style=" text-xl "
                  text={` Update ${asset.name}`}
                  positive
                  action={onSubmit}
                />
              </div>
            </div>
          </Box>
        </div>
        <div className="col-span-1 md:col-span-1">
          <Box title="Asset Photo"></Box>
        </div>
      </div>
    </Layout>
  );
}
