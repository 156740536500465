import React, { Component } from "react";
import {
  Divider,
  Segment,
  Statistic,
  Header,
  Icon,
  Popup,
} from "semantic-ui-react";

export default class CalloutBar extends Component {
  render() {
    let totalCallouts = 0;
    if (this.props.data && this.props.data.byType) {
      totalCallouts = this.props.data.callouts.length;
      const stats = this.props.data.byType;
      const typeList = Object.keys(stats);
      if (typeList.length) {
        const tasks = typeList.map((f) => {
          const callouts = stats[f];
          const rows = callouts.length;
          return (
            <Statistic key={f}>
              <Statistic.Value>{rows}</Statistic.Value>
              <Statistic.Label>{f}</Statistic.Label>
            </Statistic>
          );
        });
        let len = tasks.length;

        if (this.props.oldCallouts > 0) {
          len = len + 1;
          totalCallouts = totalCallouts + this.props.oldCallouts;
        }

        return (
          <Segment basic textAlign="center">
            <Header as={"h2"} textAlign="center">
              Deployments
            </Header>
            <Divider />
            <Statistic.Group widths={len}>
              {this.props.oldCallouts > 0 && (
                <Popup
                  position={"top center"}
                  basic
                  trigger={
                    <Statistic>
                      <Statistic.Value>{totalCallouts}</Statistic.Value>
                      <Statistic.Label>
                        Total Deployments <Icon name="info circle" />
                      </Statistic.Label>
                    </Statistic>
                  }
                  content={`Includes ${this.props.oldCallouts} old deployments`}
                />
              )}
              {tasks}
            </Statistic.Group>
          </Segment>
        );
      } else {
        return (
          <Segment textAlign="center">
            <Header as={"h2"} textAlign="center">
              Deployments
            </Header>
            <Divider />
            {this.props.oldCallouts > 0 ? (
              <Statistic>
                <Statistic.Value>{this.props.oldCallouts}</Statistic.Value>
                <Statistic.Label>Old Deployments</Statistic.Label>
              </Statistic>
            ) : (
              <Header textAlign="center">No Deployments</Header>
            )}
          </Segment>
        );
      }
    } else {
      return (
        <Segment>
          <Header as={"h2"} textAlign="center">
            Deployments
          </Header>
          <Divider />
          <Header textAlign="center">No Deployments</Header>
        </Segment>
      );
    }
  }
}
