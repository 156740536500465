import React from "react";
import { NavLink } from "react-router-dom";
import { Menu, Header } from "semantic-ui-react";

export default class SettingsNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItem: "",
    };
  }

  handleItemClick = () => {};

  render() {
    const { activeItem } = this.state;
    return (
      <Menu attached="top" stackable className={"settings-menu"}>
        <Menu.Item>
          <Header as={"h2"} className={"primary"}>
            Settings
          </Header>
        </Menu.Item>
        <Menu.Item
          name={"home"}
          as={NavLink}
          to={this.props.match.url}
          active={activeItem === "home"}
          exact
          onClick={this.handleItemClick}
        >
          {" "}
          Dashboard
        </Menu.Item>
        <Menu.Item
          name={"org"}
          as={NavLink}
          to={this.props.match.url + "/org"}
          active={activeItem === "org"}
          exact
          onClick={this.handleItemClick}
        >
          Org
        </Menu.Item>
        <Menu.Item
          name={"recruitment"}
          as={NavLink}
          to={this.props.match.url + "/recruitment"}
          active={activeItem === "recruitment"}
          exact
          onClick={this.handleItemClick}
        >
          Recruitment
        </Menu.Item>
        <Menu.Item
          name={"training"}
          as={NavLink}
          to={this.props.match.url + "/training"}
          active={activeItem === "training"}
          exact
          onClick={this.handleItemClick}
        >
          Training
        </Menu.Item>
        <Menu.Item
          name={"team"}
          as={NavLink}
          to={this.props.match.url + "/team"}
          active={activeItem === "team"}
          exact
          onClick={this.handleItemClick}
        >
          Team
        </Menu.Item>
        <Menu.Item
          name={"app"}
          as={NavLink}
          to={this.props.match.url + "/app"}
          active={activeItem === "app"}
          exact
          onClick={this.handleItemClick}
        >
          App
        </Menu.Item>
        <Menu.Item
          name={"changelog"}
          as={NavLink}
          to={this.props.match.url + "/changelog"}
          active={activeItem === "changelog"}
          exact
          onClick={this.handleItemClick}
        >
          Change Log
        </Menu.Item>
      </Menu>
    );
  }
}
