import React, { Component } from "react";
import { Segment, Button, Grid } from "semantic-ui-react";
import { connect } from "react-redux";
import { getSystemSettings } from "../../actions/settingsActions";
import api from "../../actions/api";
import RecruitList from "../common/lists/recruitList";
import TheLoader from "../common/TheLoader";
import ReallyCoolButton from "../common/ReallyCoolButton";
import { Authentication } from "@liquidcomputing/sarstuff__reactjs-singlesignon";
import CommonFunctions from "../../lib/CommonFunctions";

function mapStateToProps(state) {
  return {
    user: state.user,
    system: state.system,
  };
}
class RecruitListPage extends Component {
  state = {
    loading: true,
    filter: "",
    filtered: [],
    recruits: [],
    archivedRecruits: [],
    showArchive: false,
  };
  componentDidMount() {
    this._getData();
    this.props.getSystemSettings();
  }

  _getData = () => {
    Promise.all([api.recruitment.all(), api.recruitment.archived()])
      .then((data) => {
        this.setState({
          recruits: data[0],
          filtered: data[0],
          archivedRecruits: data[1],
        });
      })
      .catch((err) => {
        let errorMessage =
          "There was an unknown network error while retrieving data from the server";
        if (err.response !== undefined) {
          if (err.response.data.message !== undefined) {
            errorMessage = err.response.data.message;
          }
        }
        console.log(errorMessage);
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };
  toggleArchived = () => {
    if (!this.state.showArchive) {
      this.setState({
        filtered: this.state.archivedRecruits,
        showArchive: true,
      });
    } else {
      this.setState({
        filtered: this.state.recruits,
        showArchive: false,
      });
    }
  };
  goto = (id) => {
    this.props.history.push(`/recruitment/${id}`);
  };

  render() {
    return (
      <Segment basic>
        <Grid centered stackable className="no-marg">
          <Grid.Row columns={3}>
            <Grid.Column>
              <span style={{ fontSize: 20, paddingRight: 20 }}>
                Filter Recruits:
              </span>
              <Button.Group>
                <Button
                  toggle
                  active={this.state.showArchive}
                  onClick={this.toggleArchived}
                >
                  Archived
                </Button>
                <Button.Or />
                <Button
                  toggle
                  active={!this.state.showArchive}
                  onClick={this.toggleArchived}
                >
                  Active
                </Button>
              </Button.Group>
            </Grid.Column>
            <Grid.Column textAlign="center">
              <p className="boxHeader">Recruits List</p>
            </Grid.Column>

            <Grid.Column textAlign="center">
              <button
                type="button"
                className="mr-2 mb-2 px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600 hover:bg-gray-700"
                onClick={() => {
                  CommonFunctions.generateCSV(
                    "Recruit List Export",
                    [
                      { csv_field: "First Name", database_field: "firstName" },
                      { csv_field: "Last Name", database_field: "lastName" },
                      { csv_field: "Nickname", database_field: "nickName" },
                      { csv_field: "Email", database_field: "email" },
                      { csv_field: "Phone", database_field: "phone" },
                    ],
                    this.state.filtered
                  );
                }}
              >
                Export To .csv
              </button>
              {Authentication.can("members:export_emails") && (
                <ReallyCoolButton
                  content={"Copy Emails To Clipboard"}
                  fieldToCopy={"email"}
                  toClipboard={this.state.filtered}
                  width={"auto"}
                />
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Segment className="no-pad">
          <TheLoader active={this.state.loading} content={"Recruits loading"} />
          <RecruitList
            data={this.state.filtered}
            action={this.goto}
            size
            expanded
          />
        </Segment>
      </Segment>
    );
  }
}

export default connect(mapStateToProps, {
  getSystemSettings,
})(RecruitListPage);
