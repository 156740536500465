import React, { useState, useEffect } from "react";
import { Grid, Segment, Header, Divider, Form } from "semantic-ui-react";
import api from "../../../actions/api";

// THIS COMPONENT REQUIRES "eventTypes", "data" and "submit" TO BE PASSED TO IT TO FUNCTION

export default function ActivityReportDefaults(props) {
  const [activityReportDeploymentDefault, setActivityReportDeploymentDefault] =
    useState("");
  const [activityReportTrainingDefault, setActivityReportTrainingDefault] =
    useState("");

  const [incidentTypeList, setIncidentTypeList] = useState([]);
  const [eventTypes, setEventTypes] = useState([]);
  const [mountLoading, setMountLoading] = useState(true);

  useEffect(() => {
    if (mountLoading) {
      api.settings.getIncidentTypesList().then((res) => {
        setActivityReportDeploymentDefault(
          props.data.activityReportDeploymentDefault
        );
        setActivityReportTrainingDefault(
          props.data.activityReportTrainingDefault
        );
        setIncidentTypeList(res);
        setMountLoading(false);
      });
    }
  }, [mountLoading, props.data]);

  useEffect(() => {
    if (
      activityReportDeploymentDefault === "" &&
      activityReportTrainingDefault === "" &&
      props.data &&
      (props.data.activityReportDeploymentDefault ||
        props.data.activityReportTrainingDefault)
    ) {
      setActivityReportDeploymentDefault(
        props.data.activityReportDeploymentDefault
      );
      setActivityReportTrainingDefault(
        props.data.activityReportTrainingDefault
      );
    }
  }, [
    props.data,
    activityReportDeploymentDefault,
    activityReportTrainingDefault,
  ]);

  useEffect(() => {
    if (eventTypes.length === 0 && props.eventTypes) {
      setEventTypes(props.eventTypes);
    }
  }, [props.eventTypes, eventTypes]);

  return (
    <Grid.Column className="mb-10 pb-4">
      <Segment>
        <Segment basic>
          <Header>Activity Report Defaults</Header>
          <Divider />
          <Form className="mb-4 pb-4">
            <Form.Dropdown
              name="activityReportDeploymentDefault"
              selection
              label="Deployment Type"
              placeholder="Please Select"
              value={activityReportDeploymentDefault}
              options={incidentTypeList}
              onChange={(e, data) => {
                setActivityReportDeploymentDefault(data.value);
                props.submit({ [data.name]: data.value });
              }}
            />
            <Form.Dropdown
              name="activityReportTrainingDefault"
              selection
              label="Training Type"
              placeholder="Please Select"
              value={activityReportTrainingDefault}
              options={
                eventTypes
                  ? eventTypes.map((item) => {
                      return {
                        text: item.content,
                        value: item.content,
                      };
                    })
                  : []
              }
              onChange={(e, data) => {
                setActivityReportTrainingDefault(data.value);
                props.submit({ [data.name]: data.value });
              }}
            />
          </Form>
        </Segment>
      </Segment>
    </Grid.Column>
  );
}
