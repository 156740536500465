import React from "react";
import {
  Table,
  Segment,
  Button,
  Input,
  Form,
  Header,
  Divider,
} from "semantic-ui-react";

export default class SettingList extends React.Component {
  state = {
    textvalue: "",
    formData: [],
  };

  componentDidMount() {
    this.setState({
      formData: this.props.data,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.setState({
        formData: this.props.data,
      });
    }
  }

  handleChange = (e) => {
    this.setState({
      textvalue: e.target.value,
    });
  };

  handleSubmit = () => {
    let fd = this.state.formData;
    if (fd !== undefined) {
      fd.push(this.state.textvalue);
    }

    this.setState({
      formData: fd,
      textvalue: "",
    });
    let field = this.props.field;
    const data = {};
    data[field] = this.state.formData;
    this.props.submit(data);
  };

  handleDelete = (obj) => {
    let newList = this.state.formData.filter((item, i) => {
      return obj !== item;
    });
    this.setState({
      formData: newList,
    });
    let field = this.props.field;
    const data = {};
    data[field] = newList;
    this.props.delete(data);
  };

  render() {
    const form = (
      <Segment basic>
        <Form onSubmit={this.handleSubmit}>
          <Input
            action={{ icon: "plus", color: "green" }}
            onChange={this.handleChange}
            value={this.state.textvalue}
            placeholder={this.props.placeholder}
            fluid
          />
        </Form>
      </Segment>
    );

    let list;

    if (this.props.data) {
      list = this.props.data.map((item, i) => {
        return (
          <Table.Row key={i}>
            <Table.Cell>{item}</Table.Cell>

            <Table.Cell>
              <Button
                circular
                icon="close"
                floated="right"
                size="tiny"
                color="red"
                onClick={() => this.handleDelete(item)}
              />
            </Table.Cell>
          </Table.Row>
        );
      });
    }

    return (
      <Segment basic>
        <Header>{this.props.title}</Header>
        <Divider />
        {form}
        <Table stackable className={"settingTable"}>
          <Table.Body>{list}</Table.Body>
        </Table>
      </Segment>
    );
  }
}
