import React from "react";
import { Link } from "react-router-dom";
import { Menu, Icon, Dropdown, Header, Popup } from "semantic-ui-react";
import { connect } from "react-redux";
import {
  Authentication,
  AuthComponents,
} from "@liquidcomputing/sarstuff__reactjs-singlesignon";
import { GiSergeant } from "react-icons/gi";
import MediaQuery from "react-responsive";
import { getSystemSettings } from "../../actions/settingsActions";
import BuildNumber from "../../_buildNumber";

function mapStateToProps(state) {
  return {
    system: state.system,
    user: state.user,
  };
}

class FooterNav extends React.Component {
  state = {
    activeItem: "",
  };
  componentDidMount() {
    let user = Authentication.getUserData();
    if (user) {
      this.props.getUserData(user);
      this.props.updateCountry({
        name: user.organisation.country,
        county: user.organisation.county,
        gridType: user.organisation.gridType,
      });
    }
  }
  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name });
  };
  handleLogout = () => {
    Authentication.logout();
  };
  render() {
    const { activeItem } = this.state;
    return (
      <React.Fragment>
        <MediaQuery maxDeviceWidth={769}>
            <Menu fixed={"bottom"} className={"footer-menu"} stackable>
                <Dropdown
                    text={Authentication.getUserData().name}
                    pointing
                    className="link item"
                    upward
                >
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => this.handleLogout()}>
                            <Icon name="key" /> Sign Out
                        </Dropdown.Item>
                        <AuthComponents.Can scope={"settings:update"}>
                            <Dropdown.Item name={"settings"} as={Link} to="/settings">
                                <Icon name="setting" /> Settings
                            </Dropdown.Item>
                        </AuthComponents.Can>
                      <AuthComponents.Can scope={"security_role:management"}>
                        <Dropdown.Item name={"management"} as={Link} to="/management">
                          <GiSergeant size="1.3rem" style={{ marginRight: "0.5rem" }} /> Management
                        </Dropdown.Item>
                      </AuthComponents.Can>
                        <AuthComponents.Can scope={"logs:get:all"}>
                            <Dropdown.Item name={"logs"} as={Link} to="/management/logs">
                                <Icon name="list" /> System Logs
                            </Dropdown.Item>
                        </AuthComponents.Can>
                        <Dropdown.Item>
                            System Build # {BuildNumber}
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Menu>
        </MediaQuery>
        <MediaQuery minDeviceWidth={770}>
          <Menu fixed={"bottom"} className={"footer-menu"} stackable>
            <Dropdown
              text={Authentication.getUserData().name}
              pointing
              className="link item"
              upward
            >
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => this.handleLogout()}>
                    <Icon name="key" /> Sign Out
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <AuthComponents.Can scope={"settings:update"}>
              <Popup
                trigger={
                  <Menu.Item
                    name={"settings"}
                    as={Link}
                    to="/settings"
                    color="yellow"
                    active={activeItem === "settings"}
                    onClick={this.handleItemClick}
                  >
                    <Icon name="setting" /> Settings
                  </Menu.Item>
                }
                content="System Settings"
              />
            </AuthComponents.Can>
            <AuthComponents.Can scope={"security_role:management"}>
              <Menu.Item
                name={"management"}
                as={Link}
                to="/management"
                active={activeItem === "management"}
                onClick={this.handleItemClick}
              >
                <GiSergeant
                  size="2rem"
                  color={"white"}
                  style={{ marginRight: "1rem" }}
                />
                Management
              </Menu.Item>
            </AuthComponents.Can>
            <AuthComponents.Can scope={"logs:get:all"}>
              <Menu.Item
                name={"logs"}
                as={Link}
                to="/management/logs"
                active={activeItem === "logs"}
                onClick={this.handleItemClick}
              >
                <Icon name="list" />
                System Logs
              </Menu.Item>
            </AuthComponents.Can>

            <Menu.Menu position="right">
              <Menu.Item>
                <Header as={"h3"} className={"subtle"}>
                  {this.props.user.details.organisation
                    ? this.props.user.details.organisation.name
                    : "--"}
                </Header>
              </Menu.Item>
              <Menu.Item>
                <Header as={"h5"} className={"subtle"}>
                  # {BuildNumber}
                </Header>
              </Menu.Item>
            </Menu.Menu>
          </Menu>
        </MediaQuery>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, (dispatch) => {
  return {
    getSystemSettings,
    getUserData: (data) => {
      dispatch({
        type: "USER_GOT",
        payload: data,
      });
    },
    updateCountry: (data) => {
      dispatch({
        type: "UPDATE_COUNTRY",
        payload: data,
      });
    },
  };
})(FooterNav);
