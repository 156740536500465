import React from "react";

import dayjs from "dayjs";
export default function vettingList({ members = [] }) {
  const calcDiff = (date) => {
    if (date) {
      let date1 = dayjs(date);
      return date1.diff(dayjs(), "days");
    } else {
      return "--";
    }
  };
  return (
    <table
      className={`min-w-full divide-y divide-gray-200 border-separate table-fixed `}
    >
      <thead>
        <tr className="bg-gray-800">
          <th className="px-4 py-3 text-left text-xs font-medium text-gray-400 uppercase ">
            Name
          </th>
          <th className="px-4 py-3 text-left text-xs font-medium text-gray-400 uppercase ">
            Applied For
          </th>
          <th className="px-4 py-3 text-left text-xs font-medium text-gray-400 uppercase ">
            Expires
          </th>
          <th className="px-4 py-3 text-left text-xs font-medium text-gray-400 uppercase ">
            Expires in Days
          </th>
        </tr>
      </thead>
      <tbody>
        {members.map((m) => {
          return (
            <tr
              key={m._id}
              onClick={() => this.props.history.push(`/team/${m._id}`)}
            >
              <td className="p-2 border-b border-gray-900">{m.name}</td>
              <td className="p-2 border-b border-gray-900">
                {m.vetting.appliedFor
                  ? dayjs(m.vetting.appliedFor).format("DD-MM-YYYY")
                  : "--"}
              </td>
              <td className="p-2 border-b border-gray-900">
                {m.vetting.expires &&
                  dayjs(m.vetting.expires).format("DD-MM-YYYY")}
              </td>
              <td className="p-2 border-b border-gray-900">
                {m.vetting.expires && (
                  <span>{calcDiff(m.vetting.expires)}</span>
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
