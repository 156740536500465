import React from "react";
import {
  Icon,
  Segment,
  Button,
  Form,
  Table,
  Header,
  Divider,
  Checkbox,
} from "semantic-ui-react";

export default class SettingListCourses extends React.Component {
  state = {
    name: "",
    valid: 1,
    isRequired: false,
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleChangeComplete = (data) => {
    this.setState({ isRequired: data });
  };

  handleSubmit = () => {
    let fd = this.props.data;
    const data = {};

    if (this.state.editing) {
      fd[this.state.editIndex] = {
        name: this.state.name,
        valid: this.state.valid,
        isRequired: this.state.isRequired,
      };
    } else {
      fd.push({
        name: this.state.name,
        valid: this.state.valid,
        isRequired: this.state.isRequired,
      });
    }

    let field = this.props.field;

    data[field] = fd;

    this.setState({
      name: "",
      valid: 1,
      isRequired: false,
      editing: false,
      editIndex: null,
    });

    this.props.submit(data);
  };

  handleDelete = (obj) => {
    let newList = this.props.data.filter((item, i) => {
      return obj !== item;
    });

    let field = this.props.field;
    const data = {};

    data[field] = newList;

    this.setState({
      name: "",
      valid: 1,
      isRequired: false,
      editing: false,
      editIndex: null,
    });

    this.props.delete(data);
  };

  render() {
    const form = (
      <Segment basic className="no-pad">
        <Form>
          <Form.Group>
            <Form.Field width={10}>
              <label>Course</label>
              <input
                id="name"
                name="name"
                placeholder="course name"
                value={this.state.name}
                onChange={this.handleChange}
              />
            </Form.Field>
            <Form.Field width={3}>
              <label>Valid (years)</label>
              <input
                id="valid"
                name="valid"
                placeholder="course valid for in years"
                value={this.state.valid}
                onChange={this.handleChange}
              />
            </Form.Field>
            <Form.Field width={3}>
              <label>&nbsp;</label>
              <Checkbox
                style={{ padding: 0.5 + "rem" }}
                label="required"
                checked={this.state.isRequired}
                onChange={(e) =>
                  this.setState({ isRequired: !this.state.isRequired })
                }
              />
            </Form.Field>
          </Form.Group>
          <div className="grid grid-cols-2">
            {this.state.editing ? (
              <Form.Button
                negative
                onClick={() =>
                  this.setState({
                    name: "",
                    valid: 1,
                    isRequired: false,
                    editing: false,
                    editIndex: null,
                  })
                }
              >
                Cancel
              </Form.Button>
            ) : (
              <></>
            )}
            <div
              className={`ml-auto ${this.state.editing ? "" : "col-span-2"}`}
            >
              <Form.Button onClick={this.handleSubmit} positive>
                {this.state.editing ? "Edit" : "Add"}
              </Form.Button>
            </div>
          </div>
        </Form>
      </Segment>
    );

    let list;

    if (this.props.data) {
      list = this.props.data.map((item, i) => {
        return (
          <Table.Row key={i}>
            <Table.Cell>{item.name}</Table.Cell>
            <Table.Cell textAlign="center">{item.valid}</Table.Cell>
            <Table.Cell textAlign="center">
              {item.isRequired ? (
                <Icon name="check" size="large" />
              ) : (
                <Icon name="close" size="large" />
              )}
            </Table.Cell>
            <Table.Cell>
              <Button
                circular
                icon="close"
                floated="right"
                size="tiny"
                color="red"
                onClick={() => this.handleDelete(item)}
              />
              <Button
                circular
                icon="pencil"
                floated="right"
                size="tiny"
                color="blue"
                onClick={() =>
                  this.setState({
                    ...this.state,
                    ...item,
                    editing: true,
                    editIndex: i,
                  })
                }
              />
            </Table.Cell>
          </Table.Row>
        );
      });
    }

    return (
      <Segment basic>
        <Header>{this.props.title}</Header>
        <Divider />
        {form}
        <Table stackable className={"settingTable"}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Course</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                Vaild For (years)
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                is Required?
              </Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{list}</Table.Body>
        </Table>
      </Segment>
    );
  }
}
