import React, { Component } from "react";
import { Segment } from "semantic-ui-react";
import api from "../../../actions/api";

import LogList from "./logList";
import PaginateWrapper from "../../common/PaginateWrapper";
export default class Logs extends Component {
  state = {
    logs: [],
  };
  componentDidMount() {}
  getLogs = (pageNum, pageSize) => {
    return api.management.logs.all({
      pageNum,
      pageSize,
      current: true,
    });
  };

  render() {
    return (
      <Segment basic>
        <Segment basic className=" subtle">
          <p className={"boxHeader"}>System Logs</p>
          <PaginateWrapper
            dataQuery={this.getLogs}
            render={(items) => <LogList data={items} />}
          />
        </Segment>
      </Segment>
    );
  }
}
