import React, { Component } from "react";
import { Segment, Grid, Header, Label, Button } from "semantic-ui-react";
import PropTypes from "prop-types";
import moment from "moment";
import LocationCard from "./locationCard";
class CourseCard extends Component {
  state = {
    data: {},
  };
  componentDidMount() {
    this.getData();
  }
  getData = () => {
    if (this.props.course) {
      this.setState({
        data: this.props.course,
      });
    }
  };
  onChange = (e) =>
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  onCancel = () => {
    this.getData();
    // this._geoSuggest.clear();
    this.setState({
      val: {},
      update: false,
    });
  };
  setUserResponse = (resp) => {
    let course = this.props.course;
    // let data = {};
    if (course) {
      // data = {
      //   memberID: this.props.user.details.sub,
      //   courseID: course._id,
      //   response: resp,
      // };
      this.props.response(resp, this.props.user.details.sub);
    }
  };
  renderDate = (date) => {
    if (date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    } else {
      return "--";
    }
  };
  renderYourResponse() {
    const { course } = this.props;
    let disabled = false;
    if (course.start) {
      if (moment(course.start) < moment()) {
        disabled = true;
      }
    }

    return (
      <Grid.Column>
        <Grid.Row>
          <Grid.Column width={16}>
            <p className="boxHeader">Your Response</p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column textAlign="center">
            <Segment basic textAlign="center">
              {course.yourResponse ? (
                course.yourResponse.response === "going" ? (
                  <div>
                    <Header as={"h3"} color="green">
                      Going
                    </Header>
                  </div>
                ) : (
                  <div>
                    <Header as={"h3"} color="orange">
                      Not Going
                    </Header>
                  </div>
                )
              ) : (
                <Segment basic textAlign="center">
                  <Header as={"h3"} color="orange">
                    you havent responded
                  </Header>
                </Segment>
              )}
              {course.yourResponse && (
                <i>
                  as of{" "}
                  {moment(course.yourResponse.updatedAt).format(
                    "DD-MM-YYYY HH:mm"
                  )}
                </i>
              )}
            </Segment>
          </Grid.Column>
          <Grid.Column mobile={16} computer={8} textAlign="center">
            <Segment basic textAlign="center">
              {course.yourResponse ? (
                course.yourResponse.response === "going" ? (
                  <Button
                    onClick={() => this.setUserResponse("not going")}
                    negative
                    basic
                    disabled={disabled}
                  >
                    Change to Not Going
                  </Button>
                ) : (
                  <Button
                    onClick={() => this.setUserResponse("going")}
                    positive
                    basic
                    disabled={disabled}
                  >
                    Change to Going
                  </Button>
                )
              ) : (
                <Segment basic textAlign="center">
                  <Button
                    onClick={() => this.setUserResponse("not going")}
                    negative
                    disabled={disabled}
                  >
                    Not Going
                  </Button>
                  <Button
                    onClick={() => this.setUserResponse("going")}
                    positive
                    disabled={disabled}
                  >
                    Going
                  </Button>
                </Segment>
              )}
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid.Column>
    );
  }
  renderDetails() {
    const { course } = this.props;
    return (
      <Grid stackable className="no-marg ">
        <Grid.Row columns={1}>
          <Grid.Column className=" no-pad">
            <p className={"boxHeader"}>{course.title}</p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column>
            <Header as={"h3"}>
              Start Date:
              <span>{this.renderDate(course.start)}</span>
            </Header>
          </Grid.Column>
          <Grid.Column>
            <Header as={"h3"}>
              End Date:
              <span>{this.renderDate(course.end)}</span>
            </Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column>
            <Header as={"h3"}>
              Organiser:
              <span>{course.organiserName}</span>
            </Header>
          </Grid.Column>
          <Grid.Column>
            <Header as={"h3"}>
              Type:
              <span>{course.type}</span>
            </Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column>
            <Header as={"h3"}>
              Valid For:
              <span>{course.validityLength} years</span>
            </Header>
          </Grid.Column>
          <Grid.Column>
            <Header as={"h3"}>
              Role/s Gained:
              <span>
                {course.roles &&
                  course.roles.map((role) => {
                    return (
                      <Label style={{ margin: 4 }} key={role}>
                        {role}
                      </Label>
                    );
                  })}
              </span>
            </Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column>
            <Header as={"h3"}>
              Required:
              <span>{course.isRequired ? "Yes" : "No"}</span>
            </Header>
          </Grid.Column>
          <Grid.Column>
            <Header as={"h3"}>
              Can Sign Up:
              <span>{course.canSignUp ? "Yes" : "No"}</span>
            </Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1}>
          <Grid.Column>
            <Header as={"h3"}>Details:</Header>
            <Header as={"h3"} style={{ color: "white" }}>
              {course.details}
            </Header>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
  renderLocation() {
    const { course } = this.props;
    let location = null;
    if (course.locationID && course.locationID._id) {
      location = course.locationID;
    } else if (course.location && course.location.geoJSON) {
      location = course.location;
    }

    return (
      <Segment basic style={{ marginBottom: "2rem " }}>
        {location !== null ? (
          <LocationCard location={location} />
        ) : (
          <Header>No Location</Header>
        )}
      </Segment>
    );
  }
  render() {
    return (
      <Segment className="no-pad" basic>
        <Segment className=" subtle">{this.renderDetails()}</Segment>
        {this.props.course.canSignUp && (
          <Segment className=" subtle">{this.renderYourResponse()}</Segment>
        )}
        <Segment className="no-pad subtle">{this.renderLocation()}</Segment>
        <Segment basic style={{ height: 80 }}></Segment>
      </Segment>
    );
  }
}
CourseCard.propTypes = {
  course: PropTypes.object.isRequired,
};
export default CourseCard;
