import React, { useState, useEffect } from "react";
import Layout from "../../common/Layout";
import Box from "../../common/Box";
import API from "../../../actions/api";
import dayjs from "dayjs";
import VettingList from "./VettingList";
export default function Fees() {
  const [fees, setFees] = useState([]);
  const [fee, setFee] = useState("Membership");
  const [hide, setHide] = useState(false);
  const [settings, setSettings] = useState([]);

  useEffect(() => {
    // getData();
  }, []);
  useEffect(() => {
    getData(fee);
    return () => {
      setFees([]);
    };
  }, [fee, hide]);
  const getData = (data = { fee }) => {
    API.management.feesList(data).then((res) => {
      if (hide) {
        setFees(
          res.filter((f) => {
            return f._fee._id !== undefined;
          })
        );
      } else {
        setFees(res);
      }
    });
    API.settings.all().then((res) => {
      setSettings(res.fees);
    });
  };
  // console.log(settings);
  return (
    <Layout full>
      <Box title="Fees Filter">
        <div className="flex justify-around gap-2 p-4">
          {settings.map((s) => {
            return (
              <div
                key={s._id}
                onClick={() => setFee(s.name)}
                className={` ${
                  fee === s.name ? "bg-green-900" : "bg-gray-900"
                } py-2 px-4 hover:bg-gray-800 rounded-xl border border-gray-700  cursor-pointer text-lg`}
              >
                {s.name}
              </div>
            );
          })}
          <button
            className={` ${
              hide
                ? "bg-blue-900 hover:bg-blue-800"
                : "bg-gray-900 hover:bg-gray-800"
            } py-2 px-4 rounded-xl border border-gray-700  cursor-pointer`}
            onClick={() => setHide(!hide)}
          >
            Hide empty records
          </button>
        </div>
      </Box>
      <div className="p-2"></div>
      <Box title={fee}>
        <table
          className={`min-w-full divide-y divide-gray-200 border-separate table-fixed `}
        >
          <thead>
            <tr className="bg-gray-800">
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-400 uppercase ">
                Name
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-400 uppercase ">
                Paid
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-400 uppercase ">
                Expires
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-400 uppercase ">
                Method
              </th>
            </tr>
          </thead>
          <tbody>
            {fees.map((f) => {
              return (
                <tr
                  key={f._id}
                  onClick={() => this.props.history.push(`/team/${f._id}`)}
                >
                  <td className="p-2 border-b border-gray-900">{f.name}</td>
                  <td className="p-2 border-b border-gray-900">
                    {f._fee.paid
                      ? dayjs(f._fee.paid).format("DD-MM-YYYY")
                      : "--"}
                  </td>
                  <td className="p-2 border-b border-gray-900">
                    {f._fee.expires
                      ? dayjs(f._fee.expires).format("DD-MM-YYYY")
                      : "--"}
                  </td>
                  <td className="p-2 border-b border-gray-900">
                    {f._fee.method ? f._fee.method : "--"}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Box>
    </Layout>
  );
}
