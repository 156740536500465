import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Segment,
  Tab,
  Grid,
  Modal,
  Icon,
  Menu,
  Header,
} from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { AuthComponents } from "@liquidcomputing/sarstuff__reactjs-singlesignon";
import api from "../../../actions/api";
import { getSystemSettings } from "../../../actions/settingsActions";
import CourseCard from "../../common/cards/courseCard";
import ResponseList from "../../common/lists/respList";
import ResponseResultList from "../../common/lists/responseResultList";
import CommonFunctions from "../../../lib/CommonFunctions";
import CourseRemoveConfirm from "./courseRemoveConfirm";

function mapStateToProps(state) {
  return {
    system: state.system,
    user: state.user,
  };
}
class CoursePage extends Component {
  state = {
    responses: {
      notGoing: 0,
      going: 0,
      notResponded: 0,
    },
    course: {},
    courseLoading: true,
    showMembers: false,
    showRemoveConfirm: false
  };
  componentDidMount() {
    this._getData();
  }
  _getData = () => {
    Promise.all([
      api.training.courses.show(this.props.match.params.id),
      api.member.all(),
    ])
      .then((data) => {
        this.setState({
          course: data[0],
          teamList: data[1],
        });
      })
      .catch((err) => {
        let errorMessage =
          "There was an unknown network error while retrieving data from the server";
        if (err.response !== undefined) {
          if (err.response.data.message !== undefined) {
            errorMessage = err.response.data.message;
          }
        }
        console.log(errorMessage);
      })
      .finally(() => {
        this.returnResponses();
        this.setState({
          courseLoading: false,
        });
      });
  };
  returnResponses = () => {
    let responses = {
      going: 0,
      notGoing: 0,
      notResponded: 0,
    };

    if (this.state.course.responses) {
      responses.going = this.state.course.responses.filter((resp) => {
        return resp.response === "going";
      });
      responses.notGoing = this.state.course.responses.filter((resp) => {
        return resp.response === "not going";
      });
      let goingIDs = responses.going.map((i) => {
        return i.memberID;
      });
      let notGoingIDs = responses.notGoing.map((i) => {
        return i.memberID;
      });
      responses.notResponded = this.state.teamList.filter((member) => {
        if (
          goingIDs.indexOf(member.centralId) === -1 &&
          notGoingIDs.indexOf(member.centralId) === -1
        ) {
          return true;
        }
        return false;
      });
    }
    this.setState({
      responses,
    });
  };
  setResponse = (resp, centralId) => {
    let course = this.state.course._id;
    if (course) {
      let data = {
        memberID: centralId,
        courseID: course,
        response: resp,
      };
      return api.event.responses
        .add(course, data)
        .then((res) => {
          this._getData().then((res) => {
            this.setState({
              course: res,
              respError: "",
            });
          });
        })
        .catch((err) => {
          this.setState({
            respError: err,
          });
        });
    }
  };
  setResult = (resp, centralId, memberID, comment = "") => {
    let course = this.state.course._id;
    if (course) {
      let data = {
        memberCentralID: centralId,
        memberID: memberID,
        courseID: course,
        // result: resp,
        isCompetent: resp,
        comment: comment,
      };
      return api.training.courses.result
        .add(course, data)
        .then((res) => {
          this._getData().then((res) => {
            this.setState({
              course: res,
              respError: "",
            });
          });
        })
        .catch((err) => {
          this.setState({
            respError: err,
          });
        });
    }
  };
  showMembersModal() {
    return (
      <Modal
        open={this.state.showMembers}
        onClose={() => this.setState({ showMembers: false })}
        centered={false}
        closeOnDimmerClick={true}
      >
        <Modal.Header>Members List</Modal.Header>
        <Modal.Content>
          <ResponseList
            data={this.state.responses.notResponded}
            setResponse={this.setResponse}
          />
        </Modal.Content>
      </Modal>
    );
  }
  renderMenu() {
    return (
      <Menu>
        <Menu.Item>
          <Header as={"h3"} className={"subtle"}>
            Course Menu
          </Header>
        </Menu.Item>
        <Menu.Item onClick={() => this.props.history.push(`/training/courses/${this.state.course._id}/update`)}>
          <Icon name="pencil" />
          Edit
        </Menu.Item>
        {/* <Menu.Item onClick={() => this.setState({ showMembers: true })}>
          Show Members
        </Menu.Item> */}
        <Menu.Item>
          Mark All Competent <small>(soon)</small>
        </Menu.Item>
        <AuthComponents.Can scope={"training:courses:remove"}>
            <Menu.Item onClick={() => this.setState({ showRemoveConfirm: true })}>
                <Icon name="trash" />
                Delete
            </Menu.Item>
        </AuthComponents.Can>
      </Menu>
    );
  }
  render() {
    let permissions = CommonFunctions.eventPermisions();
    const panes = [
      {
        menuItem: `Attending (${
          this.state.responses.going && this.state.responses.going.length
        })`,
        render: () => (
          <Tab.Pane attached={false} className="no-pad no-marg">
            <ResponseResultList
              hidePhoto={true}
              response={true}
              permissions={permissions}
              setResponse={this.setResponse}
              setResult={this.setResult}
              data={this.state.responses.going}
              results={this.state.course.results}
            />
          </Tab.Pane>
        ),
      },
      {
        menuItem: `Not Attending (${
          this.state.responses.notGoing && this.state.responses.notGoing.length
        })`,
        render: () => (
          <Tab.Pane attached={false} basic className={"no-pad no-marg"}>
            <ResponseResultList
              hidePhoto={true}
              response={true}
              permissions={permissions}
              setResponse={this.setResponse}
              setResult={this.setResult}
              data={this.state.responses.notGoing}
              results={this.state.course.results}
            />
          </Tab.Pane>
        ),
      },
      {
        menuItem: `Not Responded (${
          this.state.responses.notResponded &&
          this.state.responses.notResponded.length
        })`,
        render: () => (
          <Tab.Pane attached={false} basic className={"no-pad no-marg"}>
            <ResponseList
              hidePhoto={true}
              response={false}
              permissions={permissions}
              setResponse={this.setResponse}
              setResult={this.setResult}
              data={this.state.responses.notResponded}
              results={this.state.course.results}
            />
          </Tab.Pane>
        ),
      },
    ];
    const limitedPanes = [
      {
        menuItem: `Attending (${
          this.state.responses.going && this.state.responses.going.length
        })`,
        render: () => (
          <Tab.Pane attached={false} className="no-pad no-marg">
            <ResponseResultList
              hidePhoto={true}
              response={true}
              permissions={permissions}
              setResponse={this.setResponse}
              setResult={this.setResult}
              data={this.state.responses.going}
              results={this.state.course.results}
            />
          </Tab.Pane>
        ),
      },
      {
        menuItem: `Not Attending (${
          this.state.responses.notGoing && this.state.responses.notGoing.length
        })`,
        render: () => (
          <Tab.Pane attached={false} basic className={"no-pad no-marg"}>
            <ResponseResultList
              hidePhoto={true}
              response={true}
              permissions={permissions}
              setResponse={this.setResponse}
              setResult={this.setResult}
              data={this.state.responses.notGoing}
              results={this.state.course.results}
            />
          </Tab.Pane>
        ),
      },
    ];
    return (
      <Grid stackable className="no-marg no-pad">
        {this.showMembersModal()}
          {this.state.showRemoveConfirm && <CourseRemoveConfirm course={this.state.course} onSuccess={() => {
              this.props.history.push("/training/courses", { courseRemoved: true });
          }} onCancel={() => this.setState({ showRemoveConfirm: false })} />}
        {permissions.canUpdate && (
          <Grid.Row columns={1} className="no-pad">
            <Grid.Column className="no-pad"> {this.renderMenu()}</Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row columns={2}>
          <Grid.Column>
            <CourseCard
              course={this.state.course}
              permissions={permissions}
              user={this.props.user}
              response={this.setResponse}
            />
          </Grid.Column>
          <Grid.Column>
            <Segment className="no-marg no-pad">
              <Tab
                menu={{ secondary: true, pointing: false }}
                panes={permissions.canUpdate ? panes : limitedPanes}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
const coursePage = withTranslation()(CoursePage);
export default connect(mapStateToProps, { getSystemSettings })(coursePage);
