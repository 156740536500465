import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Menu, Header } from "semantic-ui-react";

function mapStateToProps(state) {
  return {
    user: state.user,
    system: state.system,
  };
}

class LocationNav extends React.Component {
  state = { activeItem: "home" };
  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name });
  };
  componentDidMount() {}
  render() {
    const { activeItem } = this.state;
    return (
      <Menu stackable className={"sub-menu"}>
        <Menu.Item>
          <Header as={"h3"} className={"subtle"}>
            Training Menu
          </Header>
        </Menu.Item>

        <Menu.Item
          name={"dash"}
          as={NavLink}
          exact
          to={"/training"}
          active={activeItem === "dash"}
          onClick={this.handleItemClick}
        >
          Dash
        </Menu.Item>
        <Menu.Item
          name={"locations"}
          as={NavLink}
          exact
          to={"/training/locations"}
          active={activeItem === "locations"}
          onClick={this.handleItemClick}
        >
          Locations
        </Menu.Item>
        <Menu.Item
          name={"courses"}
          as={NavLink}
          exact
          to={"/training/courses"}
          active={activeItem === "courses"}
          onClick={this.handleItemClick}
        >
          Courses
        </Menu.Item>
      </Menu>
    );
  }
}

export default connect(mapStateToProps, {})(LocationNav);
