import React, { Component } from "react";
import { Grid, Header } from "semantic-ui-react";
import LocationMap from "../mapping/locationMap";

export default class LocationCard extends Component {
  render() {
    const location = this.props.location;
    if (!location.geoJSON) {
      return <Header>No Location</Header>;
    }
    return (
      <Grid stackable className="no-pad">
        <Grid.Row columns={1}>
          <Grid.Column>
            <p className={"boxHeader"}>Location</p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column textAlign="left">
            <Header as={"h3"}>
              Name:{" "}
              <span>
                {location.geoJSON.properties &&
                  location.geoJSON.properties.name}
              </span>
            </Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column textAlign="left">
            <Header as={"h3"}>
              Town:{" "}
              <span>
                {location.geoJSON.properties &&
                  location.geoJSON.properties.town}
              </span>
            </Header>
          </Grid.Column>
          <Grid.Column textAlign="left">
            <Header as={"h3"}>
              Postcode:
              <span>
                {location.geoJSON.properties &&
                  location.geoJSON.properties.postcode}
              </span>
            </Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="no-pad">
          <Grid.Column className="no-pad">
            {location.geoJSON.geometry && (
              <LocationMap
                marker={location}
                mapStyle={{
                  minHeight: 40 + "vh",
                }}
              />
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
