import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { Container } from "semantic-ui-react";
import TeamDash from "./dash";
import Fees from "./fees";
import MemberActivity from "./memberActivity";
import TeamNav from "./teamNav";
import Vetting from "./vetting";
// import archivedTeamList from "./archivedTeamList";
// import VettingList from "./vettingList";

export default class TeamPages extends Component {
  render() {
    return (
      <Container fluid>
        <TeamNav match={this.props.match} />
        <Switch>
          {/* <Route exact path="/management/team/archived" component={archivedTeamList} /> */}
          <Route
            exact
            path="/management/team/activity"
            component={MemberActivity}
          />
          <Route exact path="/management/team/fees" component={Fees} />
          <Route exact path="/management/team/vetting" component={Vetting} />
          <Route exact path="" component={TeamDash} />
        </Switch>
      </Container>
    );
  }
}
