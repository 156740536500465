import React, { Component } from "react";
import ReactMapboxGl, {
  ZoomControl,
  ScaleControl,
  GeoJSONLayer,
} from "react-mapbox-gl";
import { Segment, Button, Popup as ToolTip, Icon } from "semantic-ui-react";
import * as turf from "@turf/turf";
import Default_Styles from "../styles/mainStyles";
const { token, styles } = require("../config.json");
const Map = ReactMapboxGl({
  accessToken: token,
  preserveDrawingBuffer: true,
});
const selectedStyles = ["outdoor", "sat"];
const switchStyles = Object.keys(styles).filter((k) =>
  selectedStyles.includes(k)
);

export default class LocationMap extends Component {
  state = {
    styleKey: "outdoor",
    loading: true,
    marker: {},
  };
  componentDidMount() {
    if (this.props.marker) {
      this.setState({
        marker: this.props.marker,
        loading: false,
        center:
          this.props.marker.geometry && this.props.marker.geometry.coordinates,
      });
    }
    this.mapBounds();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.marker !== prevProps.marker) {
      this.setState({
        marker: this.props.marker,
        loading: false,
      });
      if (this.props.marker !== null && this.props.marker.geoJSON && this.map) {
        this.map.state.map.panTo({
          lng: this.props.marker.geoJSON.geometry.coordinates[0],
          lat: this.props.marker.geoJSON.geometry.coordinates[1],
        });
      }
    }
    if (this.props.locations !== prevProps.locations) {
      this.setState({
        loading: false,
      });
    }
    if (prevProps.flyTo !== this.props.flyTo) {
      if (this.props.flyTo) {
        let point = this.props.flyTo.geoJSON.geometry.coordinates;
        if (this.props.flyTo.geoJSON.geometry.type === "Polygon") {
          point = this.props.flyTo.geoJSON.geometry.coordinates[0][0];
        }
        this.map.state.map.flyTo({
          center: point,
          zoom: 16,
          bearing: 0,
          pitch: 0,
        });
      }
    }
    if (this.props.bounds !== prevProps.bounds) {
      this.mapBounds();
    }
  }

  onStyleLoad = (map) => {
    const { onStyleLoad } = this.props;
    this.setState({
      loading: false,
    });
    this.mapBounds();
    return onStyleLoad && onStyleLoad(map);
  };
  nextStyle = () => {
    const { styleKey } = this.state;
    const currentIndex = switchStyles.indexOf(styleKey);
    const nextIndex =
      currentIndex === switchStyles.length - 1 ? 0 : currentIndex + 1;
    this.setState({
      styleKey: switchStyles[nextIndex],
    });
  };
  resetMap = () => {
    setTimeout(() => {
      this.mapBounds();
    }, 500);
    return this.map.state.map.flyTo({
      center: this.props.center,
      zoom: 14,
      bearing: 0,
      pitch: 0,
    });
  };
  onClickMap = (map, evt) => {
    if (evt.lngLat) {
      let coords = [
        parseFloat(evt.lngLat.lng.toFixed(5)),
        parseFloat(evt.lngLat.lat.toFixed(5)),
      ];
      if (coords.length && this.props.data) {
        this.props.data(coords);
      }
    }
  };
  mapHack = () => {
    this.setState(
      {
        reload: false,
      },
      () =>
        this.setState(
          {
            os: !this.state.os,
          },
          () => {
            this.setState({
              reload: true,
            });
          }
        )
    );
  };
  mapBounds = () => {
    let data = [];
    if (this.map) {
      if (this.props.locations && this.props.locations.length) {
        this.props.locations.map((loc, i) => {
          if (loc.geoJSON) {
            if (loc.geoJSON.geometry) {
              data.push(loc.geoJSON.geometry.coordinates);
            }
          }
          return null;
        });
        let list = turf.multiPoint(data);
        let bbox = turf.bbox(list);
        this.map.state.map.fitBounds(bbox, { padding: 160, animate: false });
      }
    }
  };

  renderMarker() {
    if (this.map && this.props.marker !== null && this.props.marker.geoJSON) {
      if (this.state.marker.geoJSON.geometry) {
        this.map.state.map.panTo({
          lng: this.props.marker.geoJSON.geometry.coordinates[0],
          lat: this.props.marker.geoJSON.geometry.coordinates[1],
        });
      }

      return (
        <GeoJSONLayer
          id="marker"
          data={this.state.marker.geoJSON}
          circleLayout={Default_Styles.circleLayout}
          circlePaint={Default_Styles.markerPaint}
          symbolLayout={Default_Styles.symbolLayout}
          symbolPaint={Default_Styles.symbolPaint}
        />
      );
    }
  }
  renderData = (data) => {
    let features = data
      .filter((loc) => {
        if (loc.geoJSON.geometry.coordinates.length) {
          return true;
        }
        return false;
      })
      .map((loc) => {
        return loc.geoJSON;
      });
    return {
      type: "FeatureCollection",
      features: features,
    };
  };
  renderLocations() {
    let locs = this.renderData(this.props.locations);
    return (
      <GeoJSONLayer
        id="locations"
        data={locs}
        circleLayout={Default_Styles.circleLayout}
        circlePaint={Default_Styles.markerPaint}
        symbolLayout={Default_Styles.symbolLayout}
        symbolPaint={Default_Styles.symbolPaint}
      />
    );
  }
  renderMapBar() {
    return (
      <div className="mapBar">
        <ToolTip
          trigger={
            <Button icon onClick={(e) => this.resetMap(e)} circular>
              <Icon color="orange" name="compass" />
            </Button>
          }
          content="reset bearing and pitch"
          basic
        />
        <ToolTip
          trigger={
            <Button icon onClick={(e) => this.nextStyle(e)} circular>
              <Icon name="world" />
            </Button>
          }
          content="Change Map Type"
          basic
        />
        {/* <ToolTip
          trigger={
            <Button icon onClick={() => this.mapHack()} circular toggle active={this.state.os}>
              OS
            </Button>
          }
          content="Toggle OS Map"
          basic
        /> */}
      </div>
    );
  }
  render() {
    let mapStyle = {
      height: 100 + "%",
      width: 100 + "%",
    };
    if (this.props.mapStyle) {
      mapStyle = this.props.mapStyle;
    } else {
      mapStyle = {
        minHeight: 50 + "vh",
        width: 100 + "%",
      };
    }
    const { styleKey } = this.state;
    return (
      <Segment className="mapHolder no-pad no-marg">
        {!this.state.loading && styleKey && (
          <Map
            ref={(e) => {
              this.map = e;
            }}
            // center={this.state.center}
            style={styles[styleKey]}
            onStyleLoad={this.onStyleLoad}
            className="basicMap"
            onClick={this.onClickMap}
            containerStyle={mapStyle}
          >
            {this.renderMapBar()}
            <ZoomControl />
            <ScaleControl position={"bottom-left"} />

            {this.state.marker !== null &&
              this.state.marker.geoJSON &&
              this.state.marker.geoJSON.geometry &&
              this.renderMarker()}
            {/* {this.props.marker &&
              this.props.marker.geoJSON &&
              this.renderMarker()} */}
            {this.props.locations && this.renderLocations()}
          </Map>
        )}
        {/* <span>Click on Map or search to add a new Location</span> */}
      </Segment>
    );
  }
}
