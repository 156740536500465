import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import RecruitmentDash from "./dash";
import RecruitmentNav from "./recruitmentNav";
import RecruitPage from "./recruitPage";
import RecruitmentEventPage from "./recEvent";
import recruitList from "./recruitList";
import intakes from "./intakes";

export default class RecruitmentPages extends Component {
  render() {
    return (
      <React.Fragment>
        <RecruitmentNav match={this.props.match} />

        <Switch>
          <Route exact path="/recruitment" component={RecruitmentDash} />
          <Route exact path="/recruitment/list" component={recruitList} />
          <Route exact path="/recruitment/intakes" component={intakes} />
          <Route
            exact
            path="/recruitment/event/:id"
            component={RecruitmentEventPage}
          />
          <Route path="/recruitment/:id" component={RecruitPage} />
          {/* <Route exact path="/contacts/:id/edit" component={UpdateContact} /> */}
        </Switch>
      </React.Fragment>
    );
  }
}
