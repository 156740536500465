import { GT_WGS84, GT_OSGB, GT_Irish } from "./geotools2";
import Utm, { LatLon } from "./geodesy/utm.js";
const w3w = require("@what3words/api");
w3w.setOptions({ key: "H4C958GH" });
class CommonFunctions {
  getGrid = (lat, lng, gridType) => {
    switch (gridType) {
      case "OSGB":
        let wgs84 = new GT_WGS84();
        wgs84.setDegrees(lat, lng);
        let osgb = wgs84.getOSGB();
        if (osgb.status === "OK" && osgb.eastings > 0 && osgb.northings > 0) {
          try {
            let gridref = osgb.getGridRef(4).replace(/\s/g, "");
            return gridref;
          } catch (err) {
            console.log(err);
            return null;
          }
        }
        break;
      case "OSIE":
        let irish = new GT_WGS84();
        irish.setDegrees(lat, lng);
        let irishGrid = irish.getIrish();
        if (
          irishGrid.status === "OK" &&
          irishGrid.eastings > 0 &&
          irishGrid.northings > 0
        ) {
          try {
            let gridref = irishGrid.getGridRef(4).replace(/\s/g, "");
            return gridref;
          } catch (err) {
            console.log(err);
            return null;
          }
        } else {
          let osgbI = irish.getOSGB();
          if (
            osgbI.status === "OK" &&
            osgbI.eastings > 0 &&
            osgbI.northings > 0
          ) {
            try {
              let gridrefI = osgbI.getGridRef(4).replace(/\s/g, "");
              return gridrefI;
            } catch (err) {
              console.log(err);
              return null;
            }
          }
        }
        break;
      case "UTM":
        if (lat && lng) {
          try {
            let LL = new LatLon(lat, lng);
            const utmCoord = LL.toUtm();
            return utmCoord.toString();
          } catch (err) {
            console.log(err);
            return null;
          }
        }

        break;

      default:
        let wgs84D = new GT_WGS84();
        wgs84D.setDegrees(lat, lng);
        let osgbD = wgs84D.getOSGB();
        if (
          osgbD.status === "OK" &&
          osgbD.eastings > 0 &&
          osgbD.northings > 0
        ) {
          try {
            let gridrefD = osgbD.getGridRef(4).replace(/\s/g, "");
            return gridrefD;
          } catch (err) {
            console.log(err);
            return null;
          }
        }
        break;
    }
  };
  getW3w = async (lat, lng) => {
    if (lat && lng) {
      try {
        let data = await w3w.convertTo3wa({ lat: lat, lng: lng });
        if (data) {
          return data.words;
        }
        return null;
      } catch (err) {
        console.log(err);
        return null;
      }
    }
    return null;
  };
  findW3w = async (first, second, third) => {
    if (first && second && third) {
      let data = await w3w.convertToCoordinates(
        first + "." + second + "." + third
      );
      if (data) {
        return data;
      }
    }
    return null;
  };
  getLatLng = (grid, gridType) => {
    switch (gridType) {
      case "OSGB":
        let osgb = new GT_OSGB();
        if (osgb.parseGridRef(grid.toUpperCase())) {
          let wgs84 = osgb.getWGS84();
          let lat = wgs84.latitude;
          let lng = wgs84.longitude;
          return [lng, lat];
        } else {
          let osgbI = new GT_Irish();
          if (osgbI.parseGridRef(grid.toUpperCase())) {
            let wgs84 = osgbI.getWGS84();
            let lat = wgs84.latitude;
            let lng = wgs84.longitude;
            return [lng, lat];
          }
        }
        break;
      case "OSIE":
        let osIrish = new GT_Irish();
        if (osIrish.parseGridRef(grid.toUpperCase())) {
          let wgs84 = osIrish.getWGS84();
          let lat = wgs84.latitude;
          let lng = wgs84.longitude;
          return [lng, lat];
        } else {
          let osgbI = new GT_OSGB();
          if (osgbI.parseGridRef(grid.toUpperCase())) {
            let wgs84I = osgbI.getWGS84();
            let lat = wgs84I.latitude;
            let lng = wgs84I.longitude;
            return [lng, lat];
          }
        }
        break;
      case "UTM":
        if (grid) {
          try {
            let UTM = Utm.parse(grid);
            const ll = UTM.toLatLon();
            return [ll.lng, ll.lat];
          } catch (err) {
            console.log(err);
            return null;
          }
        }
        break;
      default:
        return;
    }
  };
  gridCheck = (grid, gridType) => {
    switch (gridType) {
      case "OSGB":
        let osgb = new GT_OSGB();
        if (osgb.parseGridRef(grid.toUpperCase())) {
          return true;
        } else {
          let osIrish = new GT_Irish();
          if (osIrish.parseGridRef(grid.toUpperCase())) {
            return true;
          }
          return false;
        }
      case "OSIE":
        let osIrish = new GT_Irish();
        if (osIrish.parseGridRef(grid.toUpperCase())) {
          return true;
        } else {
          let osgbI = new GT_OSGB();
          if (osgbI.parseGridRef(grid.toUpperCase())) {
            return true;
          }
          return false;
        }
      case "UTM":
        return true;
      default:
        return false;
    }
  };
  getLatLngEN = (e, n) => {
    let osgb = new GT_OSGB();
    osgb.setGridCoordinates(e, n);
    let coords = osgb.getWGS84();
    return coords;
  };
  getLatLngUTM = data => {
    const utm = Utm.parse(data);
    let latlon = utm.toLatLon();
    latlon.grid = data;
    return latlon;
  };
  returnAddress = (suggest, gridType) => {
    if (suggest) {
      let coords = [suggest.location.lng, suggest.location.lat];
      let components = suggest.gmaps.address_components;
      let admin_area = "";
      let postal_town = "";
      let postal_code = "";
      // eslint-disable-next-line
      let name = "";
      // eslint-disable-next-line
      let street = "";
      let county = "";
      let town = "";
      for (let component = 0; component < components.length; component++) {
        if (components[component].types[0] === "premise") {
          name = components[component].long_name;
        }
        if (components[component].types[0] === "route") {
          street = components[component].long_name;
        }
        if (components[component].types[0] === "locality") {
          admin_area = components[component].long_name;
        }
        if (components[component].types[0] === "postal_town") {
          postal_town = components[component].long_name;
        }
        if (components[component].types[0] === "administrative_area_level_2") {
          county = components[component].long_name;
        }
        if (components[component].types[0] === "postal_code") {
          postal_code = components[component].long_name;
        }
        if (admin_area) {
          town = admin_area;
        } else {
          town = postal_town;
        }
      }

      let address = {
        geoJSON: {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: coords
          },
          properties: {
            name: suggest.label,
            town: town,
            county: county,
            postcode: postal_code,
            address: suggest.gmaps.formatted_address,
            grid: this.getGrid(
              suggest.location.lat,
              suggest.location.lng,
              gridType
            )
          }
        }
      };
      return address;
    }
  };
}
export default new CommonFunctions();
