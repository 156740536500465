import React from "react";
import moment from "moment";
import { Table, Message } from "semantic-ui-react";

export default class CourseList extends React.PureComponent {
  state = {};

  renderDate = (date) => {
    if (date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    } else {
      return "--";
    }
  };
  renderCourses() {
    if (this.props.data && this.props.data.length) {
      return this.props.data.map((item) => {
        return (
          <Table.Row key={item._id} onClick={() => this.props.action(item._id, item)}>
            <Table.Cell>{item.title}</Table.Cell>
            <Table.Cell>
              {this.renderDate(item.start)} - {this.renderDate(item.end)}
            </Table.Cell>
            <Table.Cell>{item.type}</Table.Cell>
            <Table.Cell>
              {item.organiserName
                ? item.organiserName
                : item.organiser
                ? item.organiser.name
                : "--"}
            </Table.Cell>
          </Table.Row>
        );
      });
    }
  }
  render() {
    if (this.props.data.length === 0) {
      return (
        <Message color="black">
          <Message.Header>No Data</Message.Header>There is nothing to display
        </Message>
      );
    }
    return (
      <Table stackable selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Title</Table.HeaderCell>
            <Table.HeaderCell>Dates</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Organiser</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{this.renderCourses()}</Table.Body>
      </Table>
    );
  }
}
