import React, { Component } from "react";
import { connect } from "react-redux";
import { getSystemSettings } from "../../../../actions/settingsActions";

import dayjs from "dayjs";
import { Tab, Table, Label } from "semantic-ui-react";
import api from "../../../../actions/api";

function mapStateToProps(state) {
  return {
    system: state.system,
    user: state.user,
  };
}
class CompsDash extends Component {
  state = {
    sessions: [],
  };
  componentDidMount() {
    this.getData();
  }

  getData = () => {
    api.training.comps.getAllSessions().then((sessions) => {
      this.setState({
        sessions,
      });
    });
  };
  renderCurrent() {
    let sessions = this.state.sessions.filter((s) => {
      return s.sessionDate > dayjs().format("YYYY-MM-DD");
    });
    return (
      <Table striped selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Sections</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {sessions.map((s) => {
            return (
              <Table.Row
                key={s._id}
                onClick={() =>
                  this.props.history.push(`/management/training/comps/${s._id}`)
                }
              >
                <Table.Cell>
                  {dayjs(s.sessionDate).format("DD-MM-YYYY")}
                </Table.Cell>
                <Table.Cell>{s.type}</Table.Cell>
                <Table.Cell>
                  {s.sections.map((sec) => {
                    return <Label key={sec._id}>{sec.title}</Label>;
                  })}
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    );
  }
  renderPast() {
    let sessions = this.state.sessions
      .filter((s) => {
        return s.sessionDate < dayjs().format("YYYY-MM-DD");
      })
      .sort((a, b) => {
        if (a.sessionDate < b.sessionDate) {
          return 1;
        } else if (a.sessionDate > b.sessionDate) {
          return -1;
        } else {
          return 0;
        }
      });
    return (
      <Table striped selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Sections</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {sessions.map((s) => {
            return (
              <Table.Row
                key={s._id}
                onClick={() =>
                  this.props.history.push(`/training/comps/${s._id}`)
                }
              >
                <Table.Cell>
                  {dayjs(s.sessionDate).format("DD-MM-YYYY")}
                </Table.Cell>
                <Table.Cell>{s.type}</Table.Cell>
                <Table.Cell>
                  {s.sections.map((sec) => {
                    return <Label key={sec._id}>{sec.title}</Label>;
                  })}
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    );
  }
  render() {
    const panes = [
      {
        menuItem: "Current",
        render: () => <Tab.Pane basic>{this.renderCurrent()}</Tab.Pane>,
      },
      {
        menuItem: "Past",
        render: () => <Tab.Pane basic>{this.renderPast()}</Tab.Pane>,
      },
    ];
    return <Tab panes={panes} menu={{ secondary: true, pointing: true }} />;
  }
}
export default connect(mapStateToProps, { getSystemSettings })(CompsDash);
