import React from "react";
import { Segment, Button, Form } from "semantic-ui-react";
import { DateTimeInput } from "semantic-ui-calendar-react";

export default class CopyEventFrom extends React.Component {
  state = {
    data: {
      newDates: []
    }
  };
  handleAddDate = e => {
    e.preventDefault();
    let newDates = this.state.data.newDates;
    newDates.push({
      start: ""
    });
    this.setState(
      {
        data: {
          ...this.state.data,
          newDates
        }
      },
      () => this.props.submit(this.state.data)
    );
  };
  handleDateChange = index => (event, { name, value }) => {
    let newDates = this.state.data.newDates.map((item, item_index) => {
      if (item_index === index) {
        return {
          ...item,
          [name]: value
        };
      }
      return item;
    });
    this.setState(
      {
        data: {
          ...this.state.data,
          newDates
        }
      },
      () => this.props.submit(this.state.data)
    );
  };
  handleDateDelete = index => e => {
    e.preventDefault();
    this.setState(
      {
        data: {
          ...this.state.data,
          newDates: this.state.data.newDates.filter(
            (i, i_index) => i_index !== index
          )
        }
      },
      () => this.props.submit(this.state.data)
    );
  };
  render() {
    return (
      <Form loading={this.props.loading}>
        <Form.Field>
          <Button onClick={this.handleAddDate}>Add Date</Button>
        </Form.Field>
        {this.state.data.newDates.map((item, index) => {
          return (
            <Segment key={index}>
              <Form.Field>
                <DateTimeInput
                  // inline
                  hideMobileKeyboard
                  name="start"
                  animation="none"
                  dateTimeFormat="YYYY/MM/DD HH:mm"
                  closable={true}
                  disableMinute={true}
                  placeholder={"Start Date & Time [" + (index + 1) + "]"}
                  value={item.start}
                  iconPosition="left"
                  onChange={this.handleDateChange(index)}
                  autoComplete="off"
                />
              </Form.Field>
              <Form.Field>
                <Button negative onClick={this.handleDateDelete(index)}>
                  Remove
                </Button>
              </Form.Field>
            </Segment>
          );
        })}
      </Form>
    );
  }
}
