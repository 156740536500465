import React from "react";
import {
  Button,
  Divider,
  Form,
  Header,
  Icon,
  Message,
  Modal,
} from "semantic-ui-react";
import moment from "moment";
import { DateInput } from "semantic-ui-calendar-react";
import API from "../../../actions/api";
import InlineError from "./errorMessage";

export default class UpdateMemberDetailsModal extends React.Component {
  state = {
    isSuccess: false,
    isSubmitting: false,
    errors: {},
    errorMessage: null,
    data: {
      firstName: "",
      lastName: "",
      nickName: "",
      phone: "",
      phoneSecondary: "",
      dob: "",
      email_secondary: "",
      nokName: "",
      nokPhone: "",
      nokRel: "",

      // Admin fields
      teamId: "",
      forceId: "",
      dateJoined: "",
    },
  };
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      data: {
        firstName: props.user.firstName,
        lastName: props.user.lastName,
        nickName: props.user.nickName,
        phone: props.user.phone,
        phoneSecondary: props.user.phoneSecondary,
        dob: props.user.dob,
        email_secondary: props.user.email_secondary,
        nokName: props.user.nok !== undefined ? props.user.nok.name : "",
        nokPhone: props.user.nok !== undefined ? props.user.nok.phone : "",
        nokRel: props.user.nok !== undefined ? props.user.nok.relationship : "",

        // Admin fields
        teamId: props.user.teamId,
        forceId: props.user.forceId,
        dateJoined: props.user.dateJoined,
      },
    };
  }

  validate = (data) => {
    const errors = {};
    if (!data.firstName) errors.firstName = "Can't be empty";
    if (!data.lastName) errors.lastName = "Can't be empty";
    if (!data.phone) errors.phone = "Can't be empty";
    // if (!data.dob) errors.dob = "Can't be empty";
    // if (!data.nokName) errors.nokName = "Can't be empty";
    // if (!data.nokPhone) errors.nokPhone = "Can't be empty";
    // if (!data.nokRel) errors.nokRel = "Can't be empty";
    return errors;
  };
  onSubmit = () => {
    const errors = this.validate(this.state.data);
    this.setState({
      errors,
      errorMessage:
        Object.keys(errors).length !== 0
          ? "There are some validation issues that require correction before continuing."
          : null,
    });
    if (Object.keys(errors).length === 0) {
      let data = this.state.data;
      this.setState(
        {
          isSubmitting: true,
        },
        () => {
          API.member
            .update(this.props.user._id, {
              firstName: data.firstName,
              lastName: data.lastName,
              nickName: data.nickName,
              phone: data.phone,
              phoneSecondary: data.phoneSecondary,
              dob: data.dob,
              email_secondary: data.email_secondary,
              nok: {
                name: data.nokName,
                phone: data.nokPhone,
                relationship: data.nokRel,
              },

              // Admin fields
              teamId: data.teamId,
              forceId: data.forceId,
              dateJoined: data.dateJoined,
            })
            .then(() => {
              this.setState({
                isSuccess: true,
              });
            })
            .catch((err) => {
              //TODO better error handing here

              this.setState({
                errorMessage:
                  "There was an unexpected issue while submitting your data to the server, please try again in a few moments.",
              });
            })
            .finally(() => {
              this.setState({
                isSubmitting: false,
              });
            });
        }
      );
    }
  };
  onChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  };
  handleChange = (event, { name, value }) => {
    this.setState({
      data: {
        ...this.state.data,
        [name]: value,
      },
    });
  };

  render() {
    const { errors, data } = this.state;
    return (
      <Modal
        open
        size="large"
        onClose={this.props.onClose}
        centered={false}
        closeOnDimmerClick={false}
      >
        <Header icon="user" content="Update User Details" />
        <Modal.Content>
          {this.state.isSuccess ? (
            <React.Fragment>
              <Message positive>Successfully updated details</Message>
            </React.Fragment>
          ) : (
            <Form loading={this.state.isSubmitting}>
              {this.state.errorMessage && (
                <Message negative>{this.state.errorMessage}</Message>
              )}
              <Form.Group widths="equal">
                <Form.Field error={!!errors.firstName}>
                  <label htmlFor="firstName">First Name</label>
                  <input
                    name="firstName"
                    id="firstName"
                    value={data.firstName}
                    onChange={this.onChange}
                    className={errors.firstName ? "fieldWarning" : "good"}
                  />
                  {errors.firstName && <InlineError text={errors.firstName} />}
                </Form.Field>
                <Form.Field error={!!errors.lastName}>
                  <label htmlFor="lastName">Last Name</label>
                  <input
                    name="lastName"
                    id="lastName"
                    value={data.lastName}
                    onChange={this.onChange}
                    className={errors.lastName ? "fieldWarning" : "good"}
                  />
                  {errors.lastName && <InlineError text={errors.lastName} />}
                </Form.Field>
                <Form.Field error={!!errors.nickName}>
                  <label htmlFor="nickName">Nickname</label>
                  <input
                    name="nickName"
                    id="nickName"
                    value={data.nickName}
                    onChange={this.onChange}
                    className={errors.nickName ? "fieldWarning" : "good"}
                  />
                  {errors.nickName && <InlineError text={errors.nickName} />}
                </Form.Field>
              </Form.Group>
              <Form.Group widths={3}>
                <Form.Field error={!!errors.phone}>
                  <label htmlFor="phone">Mobile Phone</label>
                  <input
                    name="phone"
                    id="phone"
                    value={data.phone}
                    onChange={this.onChange}
                    className={errors.phone ? "fieldWarning" : "good"}
                  />
                  {errors.phone && <InlineError text={errors.phone} />}
                </Form.Field>
                <Form.Field error={!!errors.phone}>
                  <label htmlFor="phoneSecondary">Secondary Phone</label>
                  <input
                    name="phoneSecondary"
                    id="phoneSecondary"
                    value={data.phoneSecondary}
                    onChange={this.onChange}
                    className={errors.phoneSecondary ? "fieldWarning" : "good"}
                  />
                  {errors.phoneSecondary && (
                    <InlineError text={errors.phoneSecondary} />
                  )}
                </Form.Field>
                <Form.Field>
                  <label htmlFor="dob">Date of Birth</label>
                  <DateInput
                    name="dob"
                    startMode={"year"}
                    animation="none"
                    dateFormat={"YYYY-MM-DD"}
                    closable={true}
                    value={
                      data.dob ? moment(data.dob).format("YYYY-MM-DD") : ""
                    }
                    iconPosition="left"
                    onChange={this.handleChange}
                    localization="en_GB"
                  />
                  {errors.dob && <InlineError text={errors.dob} />}
                </Form.Field>
              </Form.Group>
              <Form.Group widths={2}>
                <Form.Field>
                  <label htmlFor="email">Primary Email</label>
                  <input
                    name="email"
                    value={this.props.user.email}
                    readOnly
                    disabled
                  />
                  <span style={{ color: "red" }}>
                    *Primary Email Address can only be updated within SarStuff
                    Central.
                  </span>
                </Form.Field>
                <Form.Field>
                  <label htmlFor="email_secondary">Secondary Email</label>
                  <input
                    name="email_secondary"
                    id="email_secondary"
                    value={data.email_secondary}
                    onChange={this.onChange}
                    autoComplete={"off"}
                    type="email"
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths={3}>
                <Form.Field>
                  <label htmlFor="nokName">NOK Name</label>
                  <input
                    name="nokName"
                    id="nokName"
                    value={data.nokName}
                    onChange={this.onChange}
                    className={errors.nokName ? "fieldWarning" : "good"}
                  />
                  {errors.nokName && <InlineError text={errors.nokName} />}
                </Form.Field>
                <Form.Field>
                  <label htmlFor="nokPhone">NOK Phone</label>
                  <input
                    name="nokPhone"
                    id="nokPhone"
                    value={data.nokPhone}
                    onChange={this.onChange}
                    className={errors.nokPhone ? "fieldWarning" : "good"}
                  />
                  {errors.nokPhone && <InlineError text={errors.nokPhone} />}
                </Form.Field>
                <Form.Field>
                  <label htmlFor="nokRel">NOK Relationship</label>
                  <input
                    name="nokRel"
                    id="nokRel"
                    value={data.nokRel}
                    onChange={this.onChange}
                    className={errors.nokRel ? "fieldWarning" : "good"}
                  />
                  {errors.nokRel && <InlineError text={errors.nokRel} />}
                </Form.Field>
              </Form.Group>
              {this.props.permissions.canUpdateOthers && (
                <React.Fragment>
                  <Divider horizontal>Admin</Divider>
                  <Form.Group widths={3}>
                    <Form.Field>
                      <label htmlFor="teamId">Unit ID</label>
                      <input
                        disabled={!this.props.permissions.canUpdateOthers}
                        name="teamId"
                        id="teamId"
                        placeholder="teamId"
                        value={data.teamId}
                        onChange={this.onChange}
                      />
                    </Form.Field>
                    <Form.Field>
                      <label htmlFor="forceId">Force ID</label>
                      <input
                        disabled={!this.props.permissions.canUpdateOthers}
                        name="forceId"
                        id="forceId"
                        placeholder="police id"
                        value={data.forceId}
                        onChange={this.onChange}
                      />
                    </Form.Field>
                    <Form.Field>
                      <label htmlFor="dateJoined">Date Joined</label>
                      <DateInput
                        name="dateJoined"
                        // startMode={"year"}
                        animation="none"
                        dateFormat={"YYYY-MM-DD"}
                        closable={true}
                        value={
                          data.dateJoined
                            ? moment(data.dateJoined).format("YYYY-MM-DD")
                            : ""
                        }
                        iconPosition="left"
                        onChange={this.handleChange}
                        localization="en_GB"
                      />
                    </Form.Field>
                  </Form.Group>
                </React.Fragment>
              )}
            </Form>
          )}
        </Modal.Content>
        {this.state.isSuccess ? (
          <Modal.Actions>
            <Button
              disabled={this.state.isSubmitting}
              color="green"
              onClick={this.props.onClose}
              inverted
            >
              <Icon name="x" />
              Close
            </Button>
          </Modal.Actions>
        ) : (
          <Modal.Actions>
            <Button
              disabled={this.state.isSubmitting}
              negative
              floated={"left"}
              onClick={this.props.onClose}
            >
              Cancel
            </Button>
            <Button
              disabled={this.state.isSubmitting}
              color="green"
              onClick={this.onSubmit}
              inverted
            >
              <Icon name="save" />
              Update
            </Button>
          </Modal.Actions>
        )}
      </Modal>
    );
  }
}
