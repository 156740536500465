import React from "react";
import Blank from "../../../assets/images/blank.png";
import moment from "moment";

import { Table, Image, Button, Confirm } from "semantic-ui-react";
export default class ResponseList extends React.Component {
  state = {
    confirmOpen: false,
    response: "",
    recruit: {}
  };
  confirmClose = () => {
    this.setState({
      confirmOpen: false
    });
  };
  sendResponse = () => {
    this.props.setResponse(this.state.response, this.state.recruit._id).then(() => {
      this.confirmClose();
    });
  };
  renderConfirmBox = () => {
    return (
      <Confirm
        header={`Are you sure you would like to change this response for ${this.state.recruit.name}?`}
        open={this.state.confirmOpen}
        onCancel={this.confirmClose}
        onConfirm={this.sendResponse}
      />
    );
  };
  renderMembers() {
    if (this.props.data && this.props.data.length) {
      const members = this.props.data.map(item => {
        if (item.recruit) {
          return (
            <Table.Row key={item._id} positive={item.response === "going" ? true : false} warning={item.response === "not going" ? true : false}>
              {item.recruit && item.recruit.photo ? (
                <Table.Cell width={1}>
                  <Image src={item.recruit.photo} size="mini" />
                </Table.Cell>
              ) : (
                <Table.Cell width={1}>
                  <Image src={Blank} size="mini" />
                </Table.Cell>
              )}
              {item.recruit ? <Table.Cell>{item.recruit.name}</Table.Cell> : "-"}

              <Table.Cell>{item.message}</Table.Cell>
              <Table.Cell>{moment(item.updatedAt).format("DD-MM-YYYY HH:mm")}</Table.Cell>
              <Table.Cell width={6} textAlign="center">
                {item.response === "going" ? (
                  <Button negative onClick={() => this.setState({ response: "not going", recruit: item.recruit, confirmOpen: true })}>
                    Change to not going
                  </Button>
                ) : (
                  <Button positive onClick={() => this.setState({ response: "going", recruit: item.recruit, confirmOpen: true })}>
                    Change to going
                  </Button>
                )}
              </Table.Cell>
            </Table.Row>
          );
        } else {
          return (
            <Table.Row key={item._id} positive={item.response === "going" ? true : false} warning={item.response === "not going" ? true : false}>
              {item.photo ? (
                <Table.Cell width={1}>
                  <Image src={item.photo} size="mini" />
                </Table.Cell>
              ) : (
                <Table.Cell width={1}>
                  <Image src={Blank} size="mini" />
                </Table.Cell>
              )}

              <Table.Cell>{item.name}</Table.Cell>
              {this.props.response && <Table.Cell>{item.message}</Table.Cell>}
              {this.props.response && <Table.Cell>{moment(item.updatedAt).format("DD-MM-YYYY HH:mm")}</Table.Cell>}
              <Table.Cell textAlign="center">
                {!item.response && (
                  <React.Fragment>
                    <Button positive onClick={() => this.setState({ response: "going", recruit: item, confirmOpen: true })}>
                      Going
                    </Button>{" "}
                    <Button negative onClick={() => this.setState({ response: "not going", recruit: item, confirmOpen: true })}>
                      Not Going
                    </Button>
                  </React.Fragment>
                )}
              </Table.Cell>
            </Table.Row>
          );
        }
      });
      return members;
    }
  }
  render() {
    return (
      <Table stackable>
        {this.renderConfirmBox()}
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Photo</Table.HeaderCell>
            {/* <Table.HeaderCell>Unit ID</Table.HeaderCell> */}
            <Table.HeaderCell>Name</Table.HeaderCell>
            {this.props.response && <Table.HeaderCell>Message</Table.HeaderCell>}
            {this.props.response && <Table.HeaderCell>Responded</Table.HeaderCell>}
            <Table.HeaderCell textAlign="center">Action</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{this.renderMembers()}</Table.Body>
      </Table>
    );
  }
}
