import React from "react";
// import Blank from "../../../assets/images/blank.png";
import { Table } from "semantic-ui-react";

export default class TeamList extends React.Component {
  state = {};

  renderMembers() {
    if (this.props.data && this.props.data.length) {
      return this.props.data.map((item) => {
        let address = "N/A";
        if (item.geoJSON && item.geoJSON.properties) {
          address = item.geoJSON.properties.town;
        }
        return (
          <Table.Row
            key={item._id}
            positive={item.operational && item.onCall === true}
            warning={item.operational && item.onCall === false}
            negative={item.operational === false}
            onClick={() => this.props.action(item._id)}
          >
            {/*<Table.Cell width="1"><Image src={item.photo ? item.photo : Blank} size="mini" /></Table.Cell>*/}
            <Table.Cell width="2">{item.teamId}</Table.Cell>
            <Table.Cell>
              {item.name} {item.nickName && <i>({item.nickName})</i>}
            </Table.Cell>
            <Table.Cell>
              Tel: <strong>{item.phone}</strong>
              <br />
              Email: <strong>{item.email}</strong>
            </Table.Cell>
            <Table.Cell>{address}</Table.Cell>
          </Table.Row>
        );
      });
    }
  }
  render() {
    return (
      <>
        <Table stackable selectable>
          <Table.Header>
            <Table.Row>
              {/*<Table.HeaderCell>Photo</Table.HeaderCell>*/}
              <Table.HeaderCell>Unit ID</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Contact Info</Table.HeaderCell>
              <Table.HeaderCell>Town</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{this.renderMembers()}</Table.Body>
        </Table>
      </>
    );
  }
}
