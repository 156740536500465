import React, { useState, useEffect } from "react";
import Layout from "./ui-wrappers/Layout";
import { Loader } from "semantic-ui-react";
import Box from "./ui-wrappers/Box";
import Stat from "./ui-wrappers/Stat";
import api from "../../actions/api";
import Button from "./ui-wrappers/Button";
import dayjs from "dayjs";
const AssetDash = ({ history }) => {
  const [assets, setAssets] = useState([]);
  const [dash, setDash] = useState([]);
  const [serviceWarnings, setServiceWarnings] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    Promise.all([api.assets.all(), api.assets.dash()])
      .then((res) => {
        setAssets(res[0]);
        setDash(res[1]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Layout full>
      <div className=" grid grid-cols-1 md:grid-cols-3 gap-4 ">
        <div className="col-span-1 md:col-span-1	">
          <Box>
            <div className="text-mono text-center text-sm text-gray-500">
              Asset Managment System Version: 0.9 beta
            </div>
            <div className="flex flex-col md:flex-row">
              <Stat
                label="Archived"
                value={
                  assets.filter((a) => {
                    return a.archived === true;
                  }).length
                }
                action={() => history.push(`/assets/list`)}
              />

              <div className="bg-gray-800 flex items-center align-bottom justify-center bg-opacity-50">
                <Button
                  text="Add New Asset"
                  action={() => history.push(`/assets/add`)}
                  positive
                />
              </div>
              <Stat
                label="Active"
                value={
                  assets.filter((a) => {
                    return a.archived === false;
                  }).length
                }
                action={() => history.push(`/assets/list`)}
              />
            </div>
          </Box>
        </div>
        <div className="col-span-1 md:col-span-2	">
          <Box title="Upcoming Servicing and Checks">
            {!loading ? (
              <table className="min-w-full divide-y divide-gray-200 border-separate table-fixed">
                <thead>
                  <tr className="bg-gray-800">
                    <th className="px-4 py-3 text-left text-xs font-medium text-gray-400 uppercase ">
                      Asset
                    </th>
                    <th className="px-4 py-3 text-left text-xs font-medium text-gray-400 uppercase ">
                      Asset Number
                    </th>
                    <th className="px-4 py-3 text-center text-xs font-medium text-gray-400 uppercase ">
                      Date
                    </th>
                    <th className="px-4 py-3 text-center text-xs font-medium text-gray-400 uppercase ">
                      Type
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-gray-900 divide-y divide-gray-200">
                  {dash.map((u) => {
                    return (
                      <tr
                        key={u._id}
                        className={`${
                          u.onCall && "bg-green-900 bg-opacity-50"
                        } cursor-pointer hover:bg-gray-800`}
                        onClick={() => history.push(`/assets/${u.asset._id}`)}
                      >
                        <td className="p-2">{u.asset.name}</td>
                        <td className="p-2">{u.asset.assetNum}</td>
                        <td className="p-2">
                          {dayjs(u.theDate).format("DD-MM-YYYY")}
                        </td>
                        <td className="p-2 flex justify-center mx-auto">
                          {u.type}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <Loader active>Loading asset contents...</Loader>
            )}
          </Box>
        </div>
      </div>
    </Layout>
  );
};
export default AssetDash;
