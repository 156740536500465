import React from "react";
import dayjs from "dayjs";
import { Table, Segment, Header, Statistic, Divider } from "semantic-ui-react";

export default class CPDList extends React.Component {
  state = {};
  renderDate = (date) => {
    if (date) {
      return dayjs(date).format("DD/MM/YYYY");
    } else {
      return "--";
    }
  };
  renderCallouts() {
    if (this.props.data) {
      const stats = this.props.data.byType;
      const typeList = Object.keys(stats);
      if (typeList.length) {
        const tasks = typeList.map((f) => {
          const callouts = stats[f];
          const rows = callouts.map((d, i) => {
            return (
              <Table.Row key={f + "_" + i}>
                <Table.Cell width={2}>{this.renderDate(d.date)}</Table.Cell>
                <Table.Cell>{d.details}</Table.Cell>
                <Table.Cell width={3}>{d.type}</Table.Cell>

                <Table.Cell>{d.minutes}</Table.Cell>
                {/* <Table.Cell width={2}>soon</Table.Cell> */}
              </Table.Row>
            );
          });
          return (
            <React.Fragment key={f}>
              <Table.Row>
                <Table.Cell colSpan={4} className="headerCell">
                  <Header as={"h3"}>{f}</Header>
                </Table.Cell>
              </Table.Row>
              {rows}
            </React.Fragment>
          );
        });
        return tasks;
      }
    }
  }
  renderStats() {
    if (this.props.data) {
      const type = this.props.data.byType;
      const typeList = Object.keys(type);
      console.log(typeList);
      if (typeList.length) {
        const tasks = typeList.map((f) => {
          const callouts = type[f];
          let mins = 0;
          callouts.forEach((c) => {
            mins = mins + c.minutes;
          });

          return (
            <Statistic key={f}>
              <Statistic.Value>{mins}</Statistic.Value>
              <Statistic.Label>{f}</Statistic.Label>
            </Statistic>
          );
        });
        return (
          <Segment style={{ marginBottom: "1rem" }}>
            <Statistic.Group widths={tasks.length}>{tasks}</Statistic.Group>
          </Segment>
        );
      }
    }
  }

  render() {
    console.log("data", this.props.data);
    return (
      <Segment basic className="no-pad">
        {this.renderStats()}
        {/* <Divider /> */}
        <Segment className="no-pad">
          <Table stackable selectable className="calloutList  ">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Date</Table.HeaderCell>
                <Table.HeaderCell>Details</Table.HeaderCell>
                <Table.HeaderCell>Type</Table.HeaderCell>
                <Table.HeaderCell>Minutes</Table.HeaderCell>
                {/* <Table.HeaderCell>Hrs</Table.HeaderCell> */}
              </Table.Row>
            </Table.Header>
            <Table.Body>{this.renderCallouts()}</Table.Body>
          </Table>
        </Segment>
      </Segment>
    );
  }
}
