import React, { Component, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import { AuthComponents } from "@liquidcomputing/sarstuff__reactjs-singlesignon";
import { SemanticToastContainer } from "react-semantic-toasts";
import AppConfig from "./_appConfig";
import TopNav from "./components/common/topNav";
import Sidebar from "./components/common/sidebar";
import FooterNav from "./components/common/footerNav";
import Home from "./components/home";
import TeamPages from "./components/team";
import SettingsPage from "./components/settings";
import AssetPages from "./components/asset_management";
import Calendar from "./components/calendar";
import TrainingPages from "./components/training";
import RecruitmentPages from "./components/recruitment";
import EventPage from "./components/event";
import ManagementPage from "./components/management";
import LogRocketHandler from "./lib/LogRocket";
import FrontendUpdateChecker from "./lib/FrontendUpdateChecker";
import { Toaster } from "react-hot-toast";

import "semantic-ui-css/semantic.min.css";
import "react-semantic-toasts/styles/react-semantic-alert.css";
import ErrorCatchPage from "./lib/ErrorCatchPage";

export default class App extends Component {
  state = {
    error: null,
    drawerPos: 1,
  };
  updateWidth = (data) => {
    this.setState({
      drawerPos: data,
    });
  };
  render() {
    // let drawerClass = [];
    // let mainClass = [];
    // if (this.state.drawerPos === 1) {
    //   drawerClass.push("drawerMin");
    //   mainClass.push("mainMin");
    // } else if (this.state.drawerPos === 2) {
    //   drawerClass.push("drawerOpen");
    //   mainClass.push("mainOpen");
    // } else {
    //   drawerClass = [];
    //   mainClass = [];
    // }
    return (
      <AuthComponents.Authentication
        config={{
          clientId: AppConfig.auth.clientId,
          loginServer: AppConfig.auth.loginServer,
          localPrefix: AppConfig.app_storage_prefix,
          applicationServer: AppConfig.api_server,
        }}
      >
        <div className="wrapper">
          <SemanticToastContainer position="top-right" />
          <Toaster position="topright" />
          {/*<LogRocketHandler />*/}
          <FrontendUpdateChecker appName={"Team Site"} />
          <Suspense fallback={<div>Loading</div>}>
            <ErrorCatchPage>
              <TopNav match={this.props.match} history={this.props.history} />
              <div className={"drawerMin"}>
                <Sidebar
                  drawerPos={this.state.drawerPos}
                  match={this.props.match}
                  history={this.props.history}
                  addNote={false}
                />
              </div>
              <div className={"mainMin"}>
                <Switch>
                  <Route exact path="/" component={Home} />
                  <Route path="/team" component={TeamPages} />
                  <Route path="/assets" component={AssetPages} />
                  <Route exact path="/calendar" component={Calendar} />
                  <Route path="/calendar/:id" component={EventPage} />
                  <Route path="/training" component={TrainingPages} />
                  <Route path="/recruitment" component={RecruitmentPages} />
                  <Route path="/settings" component={SettingsPage} />
                  <Route path="/management" component={ManagementPage} />
                </Switch>
              </div>
              <FooterNav
                match={this.props.match}
                history={this.props.history}
              />
            </ErrorCatchPage>
          </Suspense>
        </div>
      </AuthComponents.Authentication>
    );
  }
}
