import React from "react";
import moment from "moment";
import { Table, Label } from "semantic-ui-react";
export default class SupplierList extends React.Component {
  state = {};
  renderDate = (date) => {
    if (date) {
      return moment(date).format("DD/MM/YYYY");
    } else {
      return "--";
    }
  };
  renderSuppliers() {
    let contact = [];
    if (this.props.data.length) {
      this.props.data.forEach((supplier) => {
        if (supplier.contacts) {
          contact = supplier.contacts.filter((contact) => {
            return (contact = contact.primary === true);
          });
        }
      });
    }
    if (this.props.data && this.props.data.length) {
      const suppliers = this.props.data.map((item) => {
        return (
          <Table.Row key={item._id} onClick={() => this.props.action(item._id)}>
            <Table.Cell>{item.name}</Table.Cell>

            <Table.Cell>
              {item.type
                ? item.type.map((type) => {
                    return <Label key={type}>{type}</Label>;
                  })
                : "--"}
            </Table.Cell>
            <Table.Cell>
              {contact[0] ? (
                <span>
                  {contact[0].name ? contact[0].name : "--"}
                  <span className="cellTitle">Phone:</span>
                  {contact[0].phone ? contact[0].phone : "--"}
                  <br />
                  <span className="cellTitle">Email:</span>
                  {contact[0].email ? contact[0].email : "--"}
                </span>
              ) : (
                "--"
              )}
            </Table.Cell>
          </Table.Row>
        );
      });
      return suppliers;
    }
  }
  render() {
    return (
      <Table stackable selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Primary Contact</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{this.renderSuppliers()}</Table.Body>
      </Table>
    );
  }
}
