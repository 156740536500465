import React from "react";

export default function Tooltip(props) {
  return (
    <span
      className={`tooltip rounded shadow-lg p-2 bg-gray-600 text-gray-200 -mt-16 ${props.style}`}
    >
      {props.text}
    </span>
  );
}
