import React, { useState, useEffect } from "react";
import api from "../../../actions/api";
import Box from "../ui-wrappers/Box";
import Layout from "../ui-wrappers/Layout";
import ManageSupplierContacts from "./manageSupplierContacts";

export default function ContactsPage({ match, history }) {
  const [supplier, setSupplier] = useState({});

  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    api.management.suppliers.show(match.params.id).then((res) => {
      setSupplier(res);
    });
  };

  const handleSupplierContactsUpdate = (contacts) => {
    return api.management.suppliers
      .update(match.params.id, {
        ...supplier,
        contacts,
      })
      .then(() => {
        getData();
      });
  };

  return (
    <Layout full>
      <div className="font-mono">
        <ManageSupplierContacts
          contacts={supplier.contacts}
          onCancel={() => getData()}
          onSubmit={(data) => handleSupplierContactsUpdate(data)}
        />
      </div>
    </Layout>
  );
}
