import React, { Component } from "react";
import { connect } from "react-redux";
import { getSystemSettings } from "../../actions/settingsActions";
import { withTranslation } from "react-i18next";
import api from "../../actions/api";
import UserCard from "../common/cards/userCard";
import CommonFunctions from "../../lib/CommonFunctions";
import { Loader } from "semantic-ui-react";

function mapStateToProps(state) {
  return {
    system: state.system,
    user: state.user,
  };
}
class MemberPage extends Component {
  state = {
    user: {},
    vehicles: {},
    addNew: false,
    callouts: [],
    events: [],
  };
  componentDidMount() {
    this._getData();
    this.props.getSystemSettings();
    // this.props.i18n.changeLanguage("en");
  }
  _getData = () => {
    api.member
      .show(this.props.match.params.id)
      .then((res) => {
        this.setState(
          {
            user: res,
          },
          () => {
            api.member.events(this.state.user.centralId).then((events) => {
              this.setState({
                events: events,
              });
              this.getEventDash();
            });
            api.member.events(this.state.user._id).then((events) => {
              this.setState({
                bob: events,
              });
              this.getEventDash();
            });
          }
        );
      })
      .catch((err) => {
        console.log(err);
        // if (err.response.status === 404) {
        //   this.setState({
        //     addNew: true,
        //   });
        // }
      });
    api.vehicles
      .aMembersVehicles(this.props.match.params.id)
      .then((vehicles) => {
        this.setState({
          vehicles: vehicles,
        });
      });
    api.member.callouts(this.props.match.params.id).then((callouts) => {
      this.setState({
        callouts,
      });
    });
  };
  getEventDash = () => {
    if (this.state.user._id) {
      api.member.eventsDash(this.state.user.centralId).then((eventDash) => {
        this.setState({
          eventDash,
        });
      });
    }
  };
  updateUser = (data) => {
    // console.log("update", data);
    return api.member.update(this.state.user._id, data).then(() => {
      this._getData();
    });
  };
  archiveUser = (data) => {
    return api.member.archive(this.state.user._id, data).then(() => {
      this._getData();
    });
  };
  updateStatus = (data) => {
    this.setState({ loading: true });
    return api.member.updateStatus(this.state.user._id, data).then(() => {
      this._getData();
      this.setState({ loading: false });
    });
  };
  updateUserVetting = (data) => {
    return api.member.updateVetting(this.state.user._id, data).then(() => {
      this._getData();
    });
  };
  updateUserFees = (data) => {
    return api.member.updateFees(this.state.user._id, data).then(() => {
      this._getData();
    });
  };
  render() {
    let permission = CommonFunctions.memberPermissions(
      this.state.user,
      this.props.user
    );

    if (this.state.user._id === undefined) {
      return <Loader active />;
    }

    return (
      <UserCard
        permissions={permission}
        settings={this.props.system.settings}
        whoami={this.props.user}
        user={this.state.user}
        update={this.updateUser}
        archive={this.archiveUser}
        status={this.updateStatus}
        vetting={this.updateUserVetting}
        fees={this.updateUserFees}
        logs={this.state.logs}
        reloadUser={() =>
          this._getData(this.props.match.params.id, this.props.match.params.uid)
        }
        country={this.props.system.country}
        events={this.state.events}
        vehicles={this.state.vehicles}
        eventsDash={this.state.eventDash}
        showScores={true}
        callouts={this.state.callouts}
      />
    );
  }
}
const Member = withTranslation()(MemberPage);
export default connect(mapStateToProps, { getSystemSettings })(Member);
