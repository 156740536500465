import React from "react";
import Blank from "../../../assets/images/blank.png";
import { Table, Image, Header } from "semantic-ui-react";
export default class RecruitList extends React.Component {
  state = {};

  renderMembers() {
    if (this.props.data && this.props.data.length) {
      const members = this.props.data.map((item) => {
        return (
          <Table.Row key={item._id} warning={item.isArchived === true} onClick={() => this.props.action(item._id)}>
            {/* <Table.Cell width="1" collapsing>
              {item.photo ? <Image src={item.photo} size={this.props.size ? "tiny" : "mini"} /> : <Image src={Blank} size="mini" />}
            </Table.Cell> */}

            <Table.Cell collapsing>
              {this.props.size ? (
                <Header as="h4">
                  {item.firstName} {item.lastName} {item.nickName && <i>({item.nickName})</i>}
                </Header>
              ) : (
                <p>
                  {item.firstName} {item.lastName} {item.nickName && <i>({item.nickName})</i>}
                </p>
              )}
            </Table.Cell>
            {this.props.expanded && (
              <React.Fragment>
                <Table.Cell collapsing>{item.phone}</Table.Cell>
                <Table.Cell collapsing>{item.email}</Table.Cell>
              </React.Fragment>
            )}

            <Table.Cell collapsing>{item.intake ? item.intake.ref : "No Intake!"}</Table.Cell>
          </Table.Row>
        );
      });
      return members;
    }
  }
  render() {
    return (
      <Table stackable selectable>
        <Table.Header>
          <Table.Row>
            {/* <Table.HeaderCell collapsing>Photo</Table.HeaderCell> */}
            <Table.HeaderCell collapsing>Name</Table.HeaderCell>
            {this.props.expanded && (
              <React.Fragment>
                <Table.HeaderCell collapsing>Phone</Table.HeaderCell>
                <Table.HeaderCell collapsing>email</Table.HeaderCell>
              </React.Fragment>
            )}
            <Table.HeaderCell collapsing>Intake</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{this.renderMembers()}</Table.Body>
      </Table>
    );
  }
}
