import React from "react";
import { connect } from "react-redux";
import {
  Grid,
  Segment,
  Form,
  Divider,
  Message,
  Header,
} from "semantic-ui-react";
import {
  getSystemSettings,
  updateSetting,
} from "../../actions/settingsActions";

import SettingsList from "../common/lists/settingList";
import SettingsListColor from "../common/lists/settingListColor";
import SettingListItem from "../common/lists/settingListItem";

function mapStateToProps(state) {
  return {
    system: state.system,
  };
}
class OrgSettingsPage extends React.Component {
  state = {
    disabled: true,
    data: {
      teamName: "",
      systemName: "",
    },
  };
  updateFormTimeout = 0;
  componentDidMount() {
    this.getData();
  }
  getData = () => {
    return this.props.getSystemSettings();
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.system !== prevProps.system) {
      this.setState({
        data: this.props.system.settings,
      });
    }
    if (this.props.system !== prevProps.system) {
      if (
        this.props.system.settings &&
        this.props.system.settings.incidentTypes
      ) {
        this.setOptions("type", this.props.system.settings.incidentTypes);
      }
    }
  };
  toggleDisabled = () => {
    this.setState({
      disabled: !this.state.disabled,
    });
  };
  onChange = (e) =>
    this.setState(
      {
        data: { ...this.state.data, [e.target.name]: e.target.value },
      },
      () => {
        this.props.updateSetting(this.state.data, () => {});
      }
    );
  updateModel = (e) => {
    this.setState({
      updating: true,
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
    if (this.updateFormTimeout) {
      clearTimeout(this.updateFormTimeout);
    }
    this.updateFormTimeout = setTimeout(() => {
      return this.props
        .updateSetting(this.state.data)
        .then((res) => {
          this.setState({
            updating: false,
          });
          //TODO add a fancy screen notification that the title has successfully updated
        })
        .catch((err) => {
          console.error("Unable to save", err);
        });
    }, 500);
  };
  handleSubmit = (data) => {
    return this.props.updateSetting(data).then(() => {
      this.getData();
    });
  };
  handleDelete = (data) => {
    this.props.updateSetting(data, () => {});
  };
  setOptions = (key, types) => {
    if (types) {
      const data = types;
      let dataArray = [];
      for (var i in data) {
        dataArray.push({
          key: i,
          text: data[i].name,
          value: data[i].name,
        });
      }
      this.setState({ [key]: dataArray });
    }
  };

  renderSettings() {
    return (
      <Segment basic>
        {/*<Header>To do, sort out saving settings</Header>*/}
        <Grid>
          <Grid.Row>
            <Grid.Column width={16}>
              <Segment>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width="16">
                      <Form>
                        {this.state.data.vettingWarning && (
                          <Form.Field>
                            <label htmlFor="vettingWarning">
                              Vetting Warning (months)
                            </label>
                            <input
                              name="vettingWarning"
                              id={"vettingWarning"}
                              type="number"
                              value={this.state.data.vettingWarning}
                              disabled
                            />
                          </Form.Field>
                        )}
                      </Form>
                      <Divider />
                    </Grid.Column>

                    <Message
                      icon
                      hidden={!this.state.updating}
                      positive
                      size="mini"
                      floating
                    >
                      {/* <Icon name="circle notched" loading /> */}
                      <Message.Content>
                        <Message.Header>Just one second</Message.Header>
                        Updating form.
                      </Message.Content>
                    </Message>
                  </Grid.Row>
                </Grid>
              </Segment>
              <Segment>
                <SettingsListColor
                  submit={this.handleSubmit}
                  delete={this.handleDelete}
                  data={
                    this.props.system.settings.eventTypes
                      ? this.props.system.settings.eventTypes
                      : []
                  }
                  placeholder="fundraising..."
                  title="Event Types"
                  field="eventTypes"
                />
              </Segment>
              <Segment>
                <SettingsList
                  submit={this.handleSubmit}
                  delete={this.handleDelete}
                  data={
                    this.props.system.settings.supplierTypes
                      ? this.props.system.settings.supplierTypes
                      : []
                  }
                  placeholder="goods..."
                  title="Supplier Types"
                  field="supplierTypes"
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
  renderLists() {
    return (
      <Segment basic>
        {this.props.system.settings.feeSwitch && (
          <Segment>
            <SettingListItem
              submit={this.handleSubmit}
              delete={this.handleDelete}
              data={
                this.props.system.settings.fees
                  ? this.props.system.settings.fees
                  : []
              }
              placeholder="Fees..."
              title="The Fees List"
              label="Fee"
              field="fees"
            />
          </Segment>
        )}
        <Segment>
          <SettingsList
            submit={this.handleSubmit}
            delete={this.handleDelete}
            data={
              this.props.system.settings.orgRoles
                ? this.props.system.settings.orgRoles
                : []
            }
            placeholder="search tech..."
            title="Organisation Roles"
            field="orgRoles"
          />
        </Segment>
      </Segment>
    );
  }
  render() {
    return (
      <Grid stackable style={{ marginBottom: 10 }}>
        <Grid.Row columns={2}>
          <Grid.Column>{this.renderSettings()}</Grid.Column>
          <Grid.Column>{this.renderLists()}</Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default connect(mapStateToProps, {
  getSystemSettings,
  updateSetting,
})(OrgSettingsPage);
