import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Segment,
  Modal,
  Header,
  Form,
  Button,
  Icon,
  Grid,
  Menu,
  Radio,
  Popup,
  Table,
} from "semantic-ui-react";
import { getSystemSettings } from "../../actions/settingsActions";
import { withTranslation } from "react-i18next";
import api from "../../actions/api";
import { DateInput } from "semantic-ui-calendar-react";
import moment from "moment";
import RecruitList from "../common/lists/recruitList";
import TheLoader from "../common/TheLoader";

moment.locale("en-GB");

function mapStateToProps(state) {
  return {
    system: state.system,
    user: state.user,
  };
}
class IntakesPage extends Component {
  state = {
    data: {
      start: "",
      completed: "",
    },
    filter: {},
    archivedIntakes: false,
    addIntake_Loading: false,
    user: {},
    addNew: false,
    intakes: [],
    recruits: [],
    recruitLoading: true,
  };
  componentDidMount() {
    this._getData();
    this.props.getSystemSettings();
    // this.props.i18n.changeLanguage("en");
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.filter !== this.state.filter) {
      if (this.state.filter._id) {
        api.recruitment.intakes
          .recruits(this.state.filter._id)
          .then((recruits) => {
            this.setState({
              recruits,
            });
          });
      } else {
        api.recruitment.all().then((recruits) => {
          this.setState({
            recruits,
            recruitLoading: false,
          });
        });
      }
    }
  }
  getArchived = () => {
    api.recruitment.intakes.archived().then((res) => {
      this.setState({
        intakes: res,
        archivedIntakes: true,
        filter: {},
      });
    });
  };
  _getData = () => {
    api.recruitment.intakes.all().then((res) => {
      this.setState({
        intakes: res,
        archivedIntakes: false,
      });
    });
    api.recruitment.all().then((recruits) => {
      this.setState({
        recruits,
        recruitLoading: false,
      });
    });
  };
  addIntake = () => {
    let data = this.state.data;
    api.recruitment.intakes.add(data).then(() => {
      this.setState({ addIntake: false });
      this._getData();
    });
  };
  updateIntake = () => {
    let data = this.state.data;
    api.recruitment.intakes.update(data._id, data).then(() => {
      this.setState({ editIntake: false });
      this._getData();
    });
  };
  goto = (id) => {
    this.props.history.push(`/recruitment/${id}`);
  };
  handleChange = (event, { name, value }) => {
    this.setState({
      data: {
        ...this.state.data,
        [name]: value,
      },
    });
  };
  renderDate = (date) => {
    if (date) {
      return moment(date).format("DD/MM/YYYY");
    } else {
      return "--";
    }
  };
  onChange = (e) => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  };
  addIntakeModal = () => {
    return (
      <Modal
        open={this.state.addIntake}
        onClose={() => this.setState({ addIntake: false })}
        closeOnDimmerClick={false}
        size="small"
        centered={false}
      >
        <Header>Add Recruitment Intake</Header>
        <Modal.Content>
          <Form>
            <Form.Group widths={2}>
              <Form.Field>
                <label>Ref Number</label>
                <input name="ref" onChange={this.onChange} />
              </Form.Field>
              <Form.Field>
                <label>Type</label>
                <input name="type" onChange={this.onChange} />
              </Form.Field>
            </Form.Group>
            <Form.Group widths={2}>
              <Form.Field>
                <label>Intake Start Date</label>
                <DateInput
                  autoComplete={"off"}
                  name="start"
                  animation="none"
                  dateFormat="YYYY/MM/DD"
                  closable={true}
                  placeholder="Start Date"
                  value={this.state.data.start}
                  iconPosition="left"
                  onChange={this.handleChange}
                />
              </Form.Field>
              <Form.Field>
                {/* <label>Type</label>
                <input name="type" onChange={this.onChange} /> */}
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            disabled={this.state.addIntake_Loading}
            color="red"
            floated={"left"}
            onClick={() => this.setState({ addIntake: false })}
          >
            <Icon name="close" /> Cancel
          </Button>
          <Button
            disabled={this.state.addIntake_Loading}
            color="green"
            onClick={() => this.addIntake()}
          >
            <Icon name="plus" /> Add
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };
  editIntakeModal = () => {
    return (
      <Modal
        open={this.state.editIntake}
        onClose={() => this.setState({ editIntake: false })}
        closeOnDimmerClick={false}
        size="small"
      >
        <Header>Add Recruitment Intake</Header>
        <Modal.Content>
          <Form>
            <Form.Group widths={2}>
              <Form.Field>
                <label>Ref Number</label>
                <input
                  name="ref"
                  onChange={this.onChange}
                  value={this.state.data.ref}
                />
              </Form.Field>
              <Form.Field>
                <label>Type</label>
                <input
                  name="type"
                  onChange={this.onChange}
                  value={this.state.data.type}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths={2}>
              <Form.Field>
                <label>Start Date</label>
                <DateInput
                  name="start"
                  animation="none"
                  dateFormat="YYYY/MM/DD"
                  closable={true}
                  placeholder="Start Date"
                  value={
                    this.state.data.start &&
                    moment(this.state.data.start).format("YYYY/MM/DD")
                  }
                  iconPosition="left"
                  onChange={this.handleChange}
                />
              </Form.Field>
              <Form.Field>
                <label>Completed Date</label>
                <DateInput
                  name="completed"
                  animation="none"
                  dateFormat="YYYY/MM/DD"
                  closable={true}
                  placeholder="Completed Date"
                  value={
                    this.state.data.completed
                      ? moment(this.state.data.completed).format("YYYY/MM/DD")
                      : ""
                  }
                  iconPosition="left"
                  onChange={this.handleChange}
                />
              </Form.Field>
            </Form.Group>
            <Form.Field>
              <label>Archive Intake:</label>

              <Radio
                toggle
                className={"goodToggle"}
                checked={this.state.data.archived}
                onClick={() =>
                  this.setState({
                    data: {
                      ...this.state.data,
                      archived: !this.state.data.archived,
                    },
                  })
                }
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            disabled={this.state.addIntake_Loading}
            color="red"
            floated={"left"}
            onClick={() => this.setState({ editIntake: false })}
          >
            <Icon name="close" /> Cancel
          </Button>
          <Button
            disabled={this.state.addIntake_Loading}
            color="green"
            onClick={() => this.updateIntake()}
          >
            <Icon name="plus" /> Update
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };
  renderIntakes() {
    const intakes = this.state.intakes.map((intake) => {
      let active = 0;
      let archived = 0;
      if (intake.recruits) {
        intake.recruits.forEach((rec) => {
          if (rec.isArchived) {
            archived++;
          } else {
            active++;
          }
        });
      }
      return (
        <Table.Row key={intake._id}>
          <Table.Cell onClick={() => this.setState({ filter: intake })}>
            {intake.ref}
          </Table.Cell>
          <Table.Cell onClick={() => this.setState({ filter: intake })}>
            {intake.type}
          </Table.Cell>
          <Table.Cell onClick={() => this.setState({ filter: intake })}>
            {this.renderDate(intake.start)}
          </Table.Cell>
          <Table.Cell onClick={() => this.setState({ filter: intake })}>
            {this.renderDate(intake.completed)}
          </Table.Cell>
          <Table.Cell onClick={() => this.setState({ filter: intake })}>
            <Popup
              basic
              trigger={
                <Header>
                  {archived} <i style={{ color: "#0099ff" }}> -- </i>
                  {active}
                </Header>
              }
              content={`${archived} Archived and ${active} Active Recruits`}
            />
          </Table.Cell>
          <Table.Cell onClick={() => this.setState({ filter: intake })}>
            <Button
              size="tiny"
              icon
              onClick={() => this.setState({ editIntake: true, data: intake })}
              primary
            >
              <Icon name="pencil" />
            </Button>
          </Table.Cell>
        </Table.Row>
      );
    });
    return (
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Ref</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Start Date</Table.HeaderCell>
            <Table.HeaderCell>Completed</Table.HeaderCell>
            <Table.HeaderCell>#Recruits</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {this.state.intakes.length ? (
            intakes
          ) : (
            <Table.Row columns={1}>
              <Table.Cell colSpan={6}>No Intakes</Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    );
  }
  renderModals() {
    return (
      <React.Fragment>
        {this.addIntakeModal()}
        {this.editIntakeModal()}
      </React.Fragment>
    );
  }
  renderMenu() {
    let { archivedIntakes } = this.state;
    // let activeFilter = false;
    // if (filter._id) {
    //   activeFilter = true;
    // }
    return (
      <Menu stackable>
        <Menu.Item>
          <Header as={"h3"} className={"menuTitle"}>
            Intakes:
          </Header>
        </Menu.Item>
        <Menu.Item
          name={"add"}
          onClick={() => this.setState({ addIntake: true })}
        >
          <Icon name="plus" color="green" /> Add
        </Menu.Item>
        {archivedIntakes ? (
          <Menu.Item name={"archived"} onClick={this._getData}>
            Show Active
          </Menu.Item>
        ) : (
          <Menu.Item name={"archived"} onClick={this.getArchived}>
            Show Archived
          </Menu.Item>
        )}

        {/* <Menu.Item
          name={"archived"}
          active={activeFilter}
          onClick={() => this.setState({ filter: {} })}
        >
          Clear Filter
        </Menu.Item> */}
      </Menu>
    );
  }
  render() {
    let { filter, archivedIntakes } = this.state;
    // let activeFilter = false;
    // if (filter._id) {
    //   activeFilter = true;
    // }

    return (
      <Grid stackable className="no-marg">
        <Grid.Row className="no-pad">
          <Grid.Column className="no-pad" width={16}>
            {this.renderModals()}
            {this.renderMenu()}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={10} className="no-pad">
            <Segment basic>
              <p className="boxHeader">
                {archivedIntakes ? "Archived Intakes" : "Active Intakes"}
              </p>
              {this.renderIntakes()}
            </Segment>
          </Grid.Column>
          <Grid.Column width={6}>
            <Segment basic>
              <p className="boxHeader">
                Recruits {filter.ref && `on intake ${filter.ref}`}
              </p>
              <TheLoader
                active={this.state.recruitLoading}
                content="Loading recruits"
              />
              <RecruitList data={this.state.recruits} action={this.goto} size />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
const Intake = withTranslation()(IntakesPage);
export default connect(mapStateToProps, { getSystemSettings })(Intake);
