import React, { PureComponent } from "react";
import {
  Segment,
  Button,
  Grid,
  Form,
  Header,
  Icon,
  Menu,
  Dropdown,
  Image,
  Divider,
  Modal,
  Select,
  Confirm,
  Popup,
  Message,
  Radio,
  Table,
} from "semantic-ui-react";
import PropTypes from "prop-types";
import api from "../../../actions/api";
import InlineError from "../forms/errorMessage.js";
import { DateInput } from "semantic-ui-calendar-react";
import DatePicker from "react-datepicker";
import moment from "moment";
import { withRouter } from "react-router";
import Blank from "../../../assets/images/vblank.png";
import ArrayCard from "./arrayCard";
import AddServiceForm from "../forms/addService";
import toast from "react-hot-toast";
import NotesPage from "../NotesPage";
import Box from "../Box";
import DisplayAndUpdatePhoto from "./_components/DisplayAndUpdatePhoto";
moment.locale("en-GB");

const tyres = [
  { key: 1, text: "Road", value: "Road" },
  { key: 2, text: "Mud", value: "Mud" },
  { key: 3, text: "Mud/Snow", value: "Mud/Snow" },
  { key: 4, text: "All Terrain", value: "All Terrain" },
];

class VehicleCard extends PureComponent {
  state = {
    vehicle: {
      colour: "",
      make: "",
      model: "",
      tyres: "",
      doors: "",
      callsign: "",
      regNumber: "",
      seats: "",
      towWeight: "",
      insurance: null,
      mot: null,
      mileage: 0,
    },
    user: {},
    errors: {},
    addKit: false,
    vehicleModal: false,
    addKit_Data: {
      kit: "",
    },
    addKit_Error: null,
    addKit_Errors: {},
    addKit_Loading: false,
    data: {
      update: false,
    },
    activeItem: "dash",
    addAppraisal: false,
    addServices: false,
    archive: false,
    locations: [],
    dvlaError: [],
  };
  componentDidMount() {
    this.getData();
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.vehicle !== prevProps.vehicle) {
      this.setState({
        vehicle: {
          ...this.state.vehicle,
          ...this.props.vehicle,
        },
      });
    }
    if (this.props.locations !== prevProps.locations) {
      const data = this.props.locations;
      let dataArray = [];
      for (var key in data) {
        dataArray.push({
          key: key,
          text: data[key].geoJSON.properties.name,
          value: data[key]._id,
        });
      }
      this.setState({
        locations: dataArray,
      });
    }
  }

  getData = () => {
    if (this.props.vehicle) {
      this.setState({
        vehicle: {
          ...this.state.vehicle,
          ...this.props.vehicle,
        },
      });
    }
    if (this.props.user) {
      this.setState({
        user: this.props.user,
      });
    }
  };
  getDVLAData = () => {
    if (this.props.vehicle && this.props.vehicle.regNumber) {
      api.vehicles.dvla(this.props.vehicle._id).then((res) => {
        console.log("dvla", res);
        if (res.success === false) {
          this.setState({
            dvlaError: res.error.errors,
          });
        } else {
          this.props.reload();
        }
      });
    }
  };
  handleChange = (event, { name, value }) => {
    this.setState({
      vehicle: {
        ...this.state.vehicle,
        [name]: value,
      },
    });
  };
  onChange = (e) =>
    this.setState({
      vehicle: { ...this.state.vehicle, [e.target.name]: e.target.value },
    });
  confirmClose = () => {
    this.setState({
      confirmOpen: false,
    });
  };
  onCancel = () => {};
  addServices = (data) => {
    return api.service.add(data).then(() => {
      this.setState({ addServices: false });
    });
  };
  handleDropdownChange = (e, data) => {
    const vname = data.name;
    this.setState({
      addKit_Data: { ...this.state.addKit_Data, [vname]: data.value },
    });
  };
  handleVehicleDropdownChange = (e, data) => {
    const vname = data.name;
    this.setState({
      vehicle: { ...this.state.vehicle, [vname]: data.value },
    });
  };
  handleAddVehicleKit() {
    let current = this.props.vehicle.kit;
    current.push(this.state.addKit_Data.kit);
    this.props.update({ kit: current }).then(() => {
      this.setState({
        addKit_Data: {
          kit: "",
        },
        addKit: false,
      });
    });
  }
  validate = (data) => {
    const errors = {};
    if (!data.make) errors.make = "Can't be empty";
    if (!data.model) errors.model = "Can't be empty";
    if (!data.regNumber) errors.regNumber = "Can't be empty";
    if (!data.colour) errors.colour = "Can't be empty.";

    return errors;
  };
  handleUpdateVehicle = () => {
    let data = this.state.vehicle;
    const errors = this.validate(data);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.props
        .update({
          ...data,
          photo: undefined,
        })
        .then(() => {
          this.setState({
            vehicleModal: false,
            addAppraisal: false,
          });
        });
    }
  };
  addNote = (data) => {
    return this.props.update({
      notes: data,
    });
  };
  handleAppraisalVehicle = () => {
    let data = this.state.vehicle;
    this.props.appraisal(data).then(() => {
      this.setState({
        vehicleModal: false,
        addAppraisal: false,
      });
    });
  };
  handleArchiveVehicle = () => {
    api.vehicles
      .archiveVehicle(this.state.vehicle._id, { archive: true })
      .then(() => {
        toast.success("Vehicle deleted!");

        this.setState({ archive: false });
        this.props.history.push(`/team/vehicles`);
      });
  };
  handleMenuClick = (e, { name }) => this.setState({ activeItem: name });

  toggleApprove = () => {
    this.props
      .approve({ approved: !this.state.vehicle.approved })
      .then(() => {});
  };
  removeVehicleKit = () => {
    this.props
      .update({
        kit: this.props.vehicle.kit.filter(
          (item) => item !== this.state.removeKit
        ),
      })
      .then(() => {
        this.setState({
          confirmOpen: false,
          removeKit: null,
        });
      });
  };
  checkDateStyle = (date) => {
    let now = new moment();
    let theDate = moment(date).add(1, "years");
    let expiresIn = theDate.diff(now, "days");
    if (expiresIn) {
      switch (true) {
        case expiresIn < 0:
          return "negative1";

        case expiresIn < 60:
          return "warning";

        default:
          return "positive1";
      }
    }
  };
  checkDate = (date) => {
    let now = new moment();
    let theDate = moment(date).add(1, "years");
    let expiresIn = theDate.diff(now, "days");
    return expiresIn + " days";
  };
  handleKit = (data) => {
    if (this.props.vehicle.kit) {
      let current = this.props.vehicle.kit;
      data.kit.forEach((element) => {
        current.push(element);
      });
      return this.props.update({ kit: current });
    } else {
      return this.props.update({ kit: data.kit });
    }
  };
  updateKitData = (data) => {
    return this.props.update({
      kit: data.kit,
    });
  };

  handleGetUploadSpec = () => {
    return api.vehicles.getPhotoUploadConfig(this.props.vehicle._id)
  };
  handleGettingPhoto = () => {
    return api.vehicles.getPhoto(this.props.vehicle._id);
  };
  handlePhotoUpload = formData => api.vehicles.setPhotoImage(this.props.vehicle._id, formData).then(a => {
    this.props.reload();
    return a;
  });


  renderConfirmBox = () => {
    return (
      <Confirm
        header={`Are you sure you would like to remove the kit '${this.state.removeKit}'?`}
        open={this.state.confirmOpen}
        onCancel={this.confirmClose}
        onConfirm={this.removeVehicleKit}
      />
    );
  };
  renderEditVehicleModal() {
    let { vehicle, errors } = this.state;
    return (
      <Modal
        open={this.state.vehicleModal}
        onClose={() => this.setState({ vehicleModal: false })}
        centered={false}
        size="large"
        closeOnDimmerClick={false}
      >
        <Header icon="car" content="Edit Vehicle" />
        <Modal.Content>
          <Form>
            <Form.Group widths={4}>
              <Form.Field>
                <label>Make</label>
                <input
                  name="make"
                  onChange={this.onChange}
                  value={vehicle.make}
                />
                {errors.make && (
                  <p style={{ color: "orange" }}>{errors.make}</p>
                )}
              </Form.Field>
              <Form.Field>
                <label>Model</label>
                <input
                  name="model"
                  onChange={this.onChange}
                  value={vehicle.model}
                />
                {errors.model && (
                  <p style={{ color: "orange" }}>{errors.model}</p>
                )}
              </Form.Field>
              <Form.Field>
                <label>Colour</label>
                <input
                  name="colour"
                  onChange={this.onChange}
                  value={vehicle.colour}
                />
                {errors.colour && (
                  <p style={{ color: "orange" }}>{errors.colour}</p>
                )}
              </Form.Field>
              <Form.Field>
                <label>Reg Number</label>
                <input
                  name="regNumber"
                  onChange={this.onChange}
                  value={vehicle.regNumber}
                />
                {errors.regNumber && (
                  <p style={{ color: "orange" }}>{errors.regNumber}</p>
                )}
              </Form.Field>
            </Form.Group>
            <Form.Group widths={4}>
              <Form.Field>
                <label>Seats</label>
                <input
                  name="seats"
                  onChange={this.onChange}
                  value={vehicle.seats}
                  type="number"
                />
              </Form.Field>
              <Form.Field>
                <label>Tyres</label>
                <Select
                  name="tyres"
                  options={tyres}
                  value={vehicle.tyres}
                  onChange={this.handleVehicleDropdownChange}
                />
              </Form.Field>
              <Form.Field>
                <label>Doors</label>
                <input
                  name="doors"
                  onChange={this.onChange}
                  value={vehicle.doors}
                  type="number"
                />
              </Form.Field>
              <Form.Field>
                <label>Tow Weight (kg)</label>
                <input
                  name="towWeight"
                  onChange={this.onChange}
                  value={vehicle.towWeight}
                  type="number"
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths={4}>
              <Form.Field width="4">
                <label>Low Emission Zone Compliant:</label>

                <Radio
                  toggle
                  className={"goodToggle"}
                  checked={vehicle.lezComp}
                  onClick={() =>
                    this.setState({
                      vehicle: {
                        ...this.state.vehicle,
                        lezComp: !vehicle.lezComp,
                      },
                    })
                  }
                />
              </Form.Field>

              <Form.Field>
                <label>Insurance</label>
                <DatePicker
                  onChange={(date) => {
                    this.setState({
                      vehicle: {
                        ...this.state.vehicle,
                        insurance: date,
                      },
                    });
                  }}
                  selected={
                    vehicle.insurance ? new Date(vehicle.insurance) : ""
                  }
                  dateFormat="yyyy-MM-dd"
                  maxDate={new Date()}
                  nextMonthButtonLabel=">"
                  previousMonthButtonLabel="<"
                  className="datePicker bg-gray-800"
                  data-disable-touch-keyboard
                />
              </Form.Field>
              <Form.Field>
                <label>MoT</label>
                <DatePicker
                  onChange={(date) => {
                    this.setState({
                      vehicle: {
                        ...this.state.vehicle,
                        mot: date,
                      },
                    });
                  }}
                  selected={vehicle.mot ? new Date(vehicle.mot) : ""}
                  dateFormat="yyyy-MM-dd"
                  maxDate={new Date()}
                  nextMonthButtonLabel=">"
                  previousMonthButtonLabel="<"
                  className="datePicker bg-gray-800"
                  data-disable-touch-keyboard
                />
              </Form.Field>
              <Form.Field>
                <label>Mileage</label>
                <input
                  name="mileage"
                  value={vehicle.mileage}
                  type="number"
                  onChange={this.onChange}
                />
              </Form.Field>
            </Form.Group>
            {vehicle.unitVehicle && (
              <React.Fragment>
                <Divider horizontal>Team Vehicle Information</Divider>
                <Form.Group widths={3}>
                  <Form.Field width={4}>
                    <label>Call Sign</label>
                    <input
                      name="callsign"
                      onChange={this.onChange}
                      value={vehicle.callsign}
                    />
                  </Form.Field>
                  <Form.Field width={8}>
                    <label>Location/Base</label>
                    <Select
                      value={vehicle.assigned && vehicle.assigned._id}
                      name="assigned"
                      options={this.state.locations}
                      onChange={this.handleVehicleDropdownChange}
                    />
                  </Form.Field>
                  <Form.Field width={4}>
                    <label>Availablility</label>
                    <Select
                      name="available"
                      value={vehicle.available}
                      options={[
                        { key: 0, text: "Available", value: true },
                        { key: 1, text: "Off the road", value: false },
                      ]}
                      onChange={this.handleVehicleDropdownChange}
                    />
                  </Form.Field>
                </Form.Group>
              </React.Fragment>
            )}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            negative
            floated={"left"}
            onClick={() => this.setState({ vehicleModal: false })}
          >
            Cancel
          </Button>
          <Button color="green" onClick={this.handleUpdateVehicle} inverted>
            <Icon name="save" />
            Update
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
  renderDash() {
    const { vehicle } = this.props;
    let approve = false;

    if (this.props.permissions.canUpdateOthers) {
      approve = true;
    }
    return (
      <Grid stackable className="no-marg">
        <Grid.Row>
          <Grid.Column width={4} className={"borderRight"}>

            {this.props.vehicle._id && <DisplayAndUpdatePhoto
              blankImage={Blank}
              canUpdate={this.props.permissions.canUpdate}
              onGetUploadSpec={this.handleGetUploadSpec}
              onGetPhoto={this.handleGettingPhoto}
              onUpload={this.handlePhotoUpload}
            />}

            <Divider />
            <ArrayCard
              title={"Vehicle Kit"}
              fieldName="kit"
              data={this.props.vehicle.kit}
              list={this.props.settings.vehicleKit}
              add={this.handleKit}
              update={this.updateKitData}
              permissions={this.props.permissions}
            />
          </Grid.Column>
          <Grid.Column width={12}>
            <Grid stackable className="vehicleData">
              <Grid.Row>
                <Grid.Column width={8}>
                  <Segment className="subtle">
                    <p className="boxHeader">Basics</p>
                    <Grid padded>
                      <Grid.Row columns={2}>
                        <Grid.Column>
                          <Header as={"h3"}>
                            Make:
                            <span>{vehicle.make}</span>
                          </Header>
                        </Grid.Column>
                        <Grid.Column>
                          <Header as={"h3"}>
                            Model:
                            <span>{vehicle.model}</span>
                          </Header>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row columns={2}>
                        <Grid.Column>
                          <Header as={"h3"}>
                            Colour:
                            <span>{vehicle.colour}</span>
                          </Header>
                        </Grid.Column>
                        <Grid.Column>
                          <Header as={"h3"}>
                            Registration:
                            <span
                              style={{
                                textTransform: "uppercase",
                                letterSpacing: 2,
                              }}
                            >
                              {vehicle.regNumber}
                            </span>
                          </Header>
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row columns={2}>
                        <Grid.Column>
                          <Header as={"h3"}>
                            Seats:
                            <span>{vehicle.seats}</span>
                          </Header>
                        </Grid.Column>
                        <Grid.Column>
                          <Header as={"h3"}>
                            Doors:
                            <span>{vehicle.doors}</span>
                          </Header>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row columns={2}>
                        <Grid.Column>
                          <Header as={"h3"}>
                            Tyres:
                            <span>{vehicle.tyres}</span>
                          </Header>
                        </Grid.Column>
                        <Grid.Column>
                          <Header as={"h3"}>
                            Tow Weight (kg):
                            <span>{vehicle.towWeight}</span>
                          </Header>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row columns={2}>
                        <Grid.Column>
                          <Header as={"h3"}>
                            Insurance:
                            <Popup
                              trigger={
                                <span>
                                  {vehicle.insurance
                                    ? moment(vehicle.insurance).format(
                                        "DD-MM-YYYY"
                                      )
                                    : "--"}
                                </span>
                              }
                              content={
                                vehicle.insurance &&
                                "expires in " +
                                  this.checkDate(vehicle.insurance)
                              }
                            />
                          </Header>
                        </Grid.Column>
                        <Grid.Column>
                          <Header as={"h3"}>
                            MoT:
                            <Popup
                              trigger={
                                <span>
                                  {vehicle.mot
                                    ? moment(vehicle.mot).format("DD-MM-YYYY")
                                    : "--"}
                                </span>
                              }
                              content={
                                vehicle.mot &&
                                "expires in " + this.checkDate(vehicle.mot)
                              }
                            />
                          </Header>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row columns={2}>
                        <Grid.Column>
                          <Popup
                            trigger={
                              <Header as={"h3"}>
                                LEZ Compliant:
                                <span>{vehicle.lezComp ? "yes" : "no"}</span>
                              </Header>
                            }
                            content={"Low Emission Zone compliant"}
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <Popup
                            trigger={
                              <Header as={"h3"}>
                                Mileage:
                                <span>{vehicle.mileage}</span>
                              </Header>
                            }
                            content={"Low Emission Zone compliant"}
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Segment>
                </Grid.Column>
                <Grid.Column width={8}>
                  <Segment className="subtle">
                    <p className="boxHeader">Info</p>
                    <Grid padded>
                      <Grid.Row columns={2}>
                        <Grid.Column>
                          <Header as={"h3"}>
                            Base:
                            <span>
                              {vehicle.member &&
                                vehicle.member.geoJSON.properties &&
                                vehicle.member.geoJSON.properties.town}
                            </span>
                            <span>
                              {vehicle.assigned &&
                                vehicle.assigned.geoJSON.properties.name}
                            </span>
                          </Header>
                        </Grid.Column>
                        <Grid.Column>
                          <Header as={"h3"}>
                            Available:
                            {!vehicle.unitVehicle ? (
                              <span
                                className={
                                  vehicle.member && vehicle.member.onCall
                                    ? "good1"
                                    : "bad1"
                                }
                              >
                                {vehicle.member && vehicle.member.onCall
                                  ? "On-call"
                                  : "Off-call"}
                              </span>
                            ) : (
                              <span
                                className={vehicle.available ? "good1" : "bad1"}
                              >
                                {vehicle.unitVehicle === true &&
                                vehicle.available
                                  ? "Yes"
                                  : "Off the Road"}
                              </span>
                            )}
                          </Header>
                        </Grid.Column>
                      </Grid.Row>
                      {!vehicle.unitVehicle && (
                        <Grid.Row columns={1}>
                          <Grid.Column>
                            <Header as={"h3"}>
                              Phone:
                              <span>
                                {vehicle.member && vehicle.member.phone}
                              </span>
                            </Header>
                          </Grid.Column>
                        </Grid.Row>
                      )}
                    </Grid>
                  </Segment>
                  <Segment className="subtle">
                    <p className="boxHeader">Admin</p>
                    <Grid padded>
                      <Grid.Row columns={2}>
                        <Grid.Column>
                          <Header as={"h3"} style={{ paddingTop: "0.4rem" }}>
                            Appraisal:
                            {approve ? (
                              <Button
                                basic
                                inverted
                                size="tiny"
                                floated="right"
                                onClick={() =>
                                  this.setState({ addAppraisal: true })
                                }
                              >
                                {vehicle.appraisal
                                  ? moment(vehicle.appraisal).format(
                                      "DD-MM-YYYY"
                                    )
                                  : "--"}
                              </Button>
                            ) : vehicle.appraisal ? (
                              moment(vehicle.appraisal).format("DD-MM-YYYY")
                            ) : (
                              "--"
                            )}
                          </Header>
                        </Grid.Column>

                        <Grid.Column>
                          <Popup
                            // position={"right center"}
                            trigger={
                              <Header
                                as={"h3"}
                                style={{ paddingTop: "0.4rem" }}
                              >
                                Approved:
                                <span
                                  style={{
                                    textTransform: "uppercase",
                                    letterSpacing: 2,
                                  }}
                                  className={
                                    vehicle.approved ? "good1" : "bad1"
                                  }
                                >
                                  {" "}
                                  {vehicle.approved ? "yes" : "no"}
                                </span>
                              </Header>
                            }
                            content={
                              "This vehicle been checked and approved as a Team vehicle. This will show vehicle in list."
                            }
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Segment>
                  <Segment className="subtle">
                    <p className="boxHeader">
                      DVLA <small>(beta)</small>
                    </p>
                    {this.state.dvlaError.length > 0 &&
                      this.state.dvlaError.map((e, i) => {
                        return (
                          <Message
                            error
                            key={i}
                            header={e.title}
                            content={e.detail}
                          />
                        );
                      })}
                    {vehicle.dvla && (
                      <Grid padded>
                        <Grid.Row columns={2}>
                          <Grid.Column>
                            <Header as={"h3"} style={{ paddingTop: "0.4rem" }}>
                              Co<sup>2</sup> Emissions:
                              <span>{vehicle.dvla.co2Emissions}</span>
                            </Header>
                          </Grid.Column>

                          <Grid.Column>
                            <Header as={"h3"} style={{ paddingTop: "0.4rem" }}>
                              Fuel Type:
                              <span>{vehicle.dvla.fuelType}</span>
                            </Header>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={2}>
                          <Grid.Column>
                            <Header as={"h3"} style={{ paddingTop: "0.4rem" }}>
                              Engine:
                              <span>{vehicle.dvla.engineCapacity}</span>
                            </Header>
                          </Grid.Column>

                          <Grid.Column>
                            <Header as={"h3"} style={{ paddingTop: "0.4rem" }}>
                              Year:
                              <span>{vehicle.dvla.yearOfManufacture}</span>
                            </Header>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={2}>
                          <Grid.Column>
                            <Header as={"h3"} style={{ paddingTop: "0.4rem" }}>
                              Make:
                              <span>{vehicle.dvla.make}</span>
                            </Header>
                          </Grid.Column>

                          <Grid.Column>
                            <Header as={"h3"} style={{ paddingTop: "0.4rem" }}>
                              Colour:
                              <span>{vehicle.dvla.colour}</span>
                            </Header>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={2}>
                          <Grid.Column>
                            <Header as={"h3"} style={{ paddingTop: "0.4rem" }}>
                              MoT:
                              <span>{vehicle.dvla.motStatus}</span>
                            </Header>
                          </Grid.Column>

                          <Grid.Column>
                            <Header as={"h3"} style={{ paddingTop: "0.4rem" }}>
                              MoT Expires:
                              <span>
                                {vehicle.dvla.motExpiryDate
                                  ? moment(vehicle.dvla.motExpiryDate).format(
                                      "DD-MM-YYYY"
                                    )
                                  : "--"}
                              </span>
                            </Header>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={2}>
                          <Grid.Column>
                            <Header as={"h3"} style={{ paddingTop: "0.4rem" }}>
                              Tax:
                              <span>{vehicle.dvla.taxStatus}</span>
                            </Header>
                          </Grid.Column>

                          <Grid.Column>
                            <Header as={"h3"} style={{ paddingTop: "0.4rem" }}>
                              Tax Due:
                              <span>
                                {vehicle.dvla.taxDueDate
                                  ? moment(vehicle.dvla.taxDueDate).format(
                                      "DD-MM-YYYY"
                                    )
                                  : "--"}
                              </span>
                            </Header>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={1}>
                          <Grid.Column>
                            <Header as={"h3"} style={{ paddingTop: "0.4rem" }}>
                              Date Checked:
                              <span>
                                {moment(vehicle.dvla.dateChecked).format(
                                  "DD-MM-YYYY"
                                )}
                              </span>
                            </Header>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    )}
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
  renderDocuments() {
    return (
      <Segment basic className="subtle">
        <p className="boxHeader">Documents</p>
        <Header>give me a chance......</Header>
      </Segment>
    );
  }
  renderAppraisalModal() {
    return (
      <Modal
        open={this.state.addAppraisal}
        onClose={() => this.setState({ addAppraisal: false })}
        centered={false}
        closeOnDimmerClick={false}
        size="tiny"
      >
        <Header>Add Next Vehicle Appraisal Date</Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <label>Next Appraisal Date</label>
              <DateInput
                name="appraisal"
                startMode={"day"}
                animation="none"
                dateFormat={"YYYY-MM-DD"}
                closable={true}
                placeholder="Appraisal date"
                value={
                  this.state.vehicle.appraisal
                    ? moment(this.state.vehicle.appraisal).format("YYYY-MM-DD")
                    : ""
                }
                iconPosition="left"
                onChange={this.handleChange}
                localization="en_GB"
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="red"
            floated={"left"}
            onClick={() => this.setState({ addAppraisal: false })}
          >
            <Icon name="close" /> Cancel
          </Button>
          <Button color="green" onClick={() => this.handleAppraisalVehicle()}>
            <Icon name="plus" /> Add
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
  renderAddKit() {
    return (
      <Modal
        open={this.state.addKit}
        onClose={() => this.setState({ addKit: false })}
        closeOnDimmerClick={false}
        size="small"
      >
        <Header>Add Vehicle Kit</Header>
        <Modal.Content>
          <Form loading={this.state.addKit_Loading}>
            <Form.Group widths={2}>
              <Form.Field>
                <Select
                  options={
                    (this.props.settings.vehicleKit !== undefined &&
                      this.props.settings.vehicleKit
                        .filter((item) => {
                          if (this.props.vehicle.kit !== undefined) {
                            return this.props.vehicle.kit.indexOf(item) === -1;
                          }
                          return true;
                        })
                        .map((item, index) => {
                          return { key: index, text: item, value: item };
                        })) ||
                    []
                  }
                  onChange={this.handleDropdownChange}
                  name={"kit"}
                  value={this.state.addKit_Data.kit}
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            disabled={this.state.addKit_Loading}
            color="red"
            floated={"left"}
            onClick={() => this.setState({ addKit: false })}
          >
            <Icon name="close" /> Cancel
          </Button>
          <Button
            disabled={this.state.addKit_Loading}
            color="green"
            onClick={() => this.handleAddVehicleKit()}
          >
            <Icon name="plus" /> Add
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
  renderAddServiceModal() {
    return (
      <Modal
        open={this.state.addServices}
        onClose={() => this.setState({ addServices: false })}
        centered={false}
        closeOnDimmerClick={false}
        size="tiny"
      >
        <Header>Add Service</Header>
        <Modal.Content className="no-pad">
          <AddServiceForm
            vehicle={this.state.vehicle}
            cancel={() => this.setState({ addServices: false })}
            submit={this.addServices}
          />
        </Modal.Content>
      </Modal>
    );
  }
  renderServices() {
    const list = this.state.vehicle.Services.map((s) => {
      return (
        <Table.Row key={s._id}>
          <Table.Cell width={3}>
            {moment(s.date).format("DD/MM/YYYY")}
          </Table.Cell>
          <Table.Cell width={3}>sup</Table.Cell>
          <Table.Cell width={2}>{s.type}</Table.Cell>
          <Table.Cell width={2}>{s.cost}</Table.Cell>
          <Table.Cell width={2}>{s.mileage}</Table.Cell>
          <Table.Cell>{s.notes}</Table.Cell>
          <Table.Cell width={1}>{s.pass ? "Yes" : "No"}</Table.Cell>
        </Table.Row>
      );
    });
    return (
      <Segment basic>
        {this.renderAddServiceModal()}
        <p className="boxHeader">
          Services
          <Button
            onClick={() => this.setState({ addServices: true })}
            icon
            basic
            className="subtleButton"
          >
            <Icon name="plus" color="green" />
          </Button>
        </p>

        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Date</Table.HeaderCell>
              <Table.HeaderCell>Supplier</Table.HeaderCell>
              <Table.HeaderCell>Type</Table.HeaderCell>
              <Table.HeaderCell width={1}>Cost</Table.HeaderCell>
              <Table.HeaderCell width={2}>Mileage</Table.HeaderCell>
              <Table.HeaderCell>Notes</Table.HeaderCell>
              <Table.HeaderCell width={1}>Pass?</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{list}</Table.Body>
        </Table>
      </Segment>
    );
  }
  renderFullMenu() {
    const { activeItem } = this.state;
    const { user } = this.props;
    let name = user.name;
    if (this.state.user.nickName) {
      name = name + " " + `(${this.state.user.nickName})`;
    }
    // if (this.state.data.teamId) {
    //   name = name + " " + this.state.data.teamId;
    // }

    let approve = false;
    if (this.props.permissions.canUpdateOthers) {
      approve = true;
    }
    return (
      <Menu stackable className="subMenu">
        <Menu.Item>
          <Header
            as={"h3"}
            className={"selectable"}
            onClick={() =>
              this.props.history.push(`/team/${this.props.user._id}`)
            }
          >
            {name ? name + "'s " : "Team Vehicle "} {this.props.vehicle.make}{" "}
            {this.props.vehicle.model}{" "}
            {this.props.vehicle.callsign &&
              "(" + this.props.vehicle.callsign + ")"}
          </Header>
        </Menu.Item>
        <Menu.Item
          name="dash"
          active={activeItem === "dash"}
          onClick={this.handleMenuClick}
        >
          Dash
        </Menu.Item>
        {/* <Menu.Item
          name="documents"
          active={activeItem === "documents"}
          onClick={this.handleMenuClick}
        >
          Documents
        </Menu.Item> */}
        <Menu.Item
          name="assets"
          active={activeItem === "assets"}
          onClick={this.handleMenuClick}
        >
          Assets
        </Menu.Item>
        {this.props.vehicle.unitVehicle && (
          <Menu.Item
            name="service"
            active={activeItem === "service"}
            onClick={this.handleMenuClick}
          >
            Services
          </Menu.Item>
        )}

        <Menu.Menu position={"right"}>
          <Dropdown text="Admin" pointing className="link item">
            <Dropdown.Menu>
              <Dropdown.Header icon="car" content="Vehicle Menu" />
              <Dropdown.Divider />
              <Dropdown.Item
                name="diary"
                onClick={() => {
                  this.setState({ vehicleModal: true });
                }}
              >
                <Icon name="pencil" color="blue" />
                Edit
              </Dropdown.Item>
              <Dropdown.Item
                name="notes"
                active={activeItem === "notes"}
                onClick={this.getDVLAData}
              >
                <Icon name="question" />
                DVLA Check
              </Dropdown.Item>
              <Dropdown.Divider />
              {this.props.permissions.canUpdateOthers && (
                <>
                  <Dropdown.Header icon="cogs" content=" Admin Only Access" />
                  {approve === true && this.state.vehicle.approved && (
                    <Popup
                      trigger={
                        <Dropdown.Item onClick={this.toggleApprove}>
                          <Icon name="thumbs down" color="red" />
                          Un-Approve
                        </Dropdown.Item>
                      }
                      content={"un-approve member vehicle for use"}
                    />
                  )}
                </>
              )}
              {this.props.permissions.canUpdateOthers && (
                <>
                  {approve === true && !this.state.vehicle.approved && (
                    <Popup
                      trigger={
                        <Dropdown.Item onClick={this.toggleApprove}>
                          <Icon name="thumbs up" color="green" />
                          Approve
                        </Dropdown.Item>
                      }
                      content={"approve member vehicle for use"}
                    />
                  )}

                  <Dropdown.Item
                    name="notes"
                    active={activeItem === "notes"}
                    onClick={this.handleMenuClick}
                  >
                    <Icon name="sticky note outline" />
                    Notes
                  </Dropdown.Item>
                  <Dropdown.Item
                    name="archive"
                    active={activeItem === "notes"}
                    onClick={() => this.setState({ archive: true })}
                  >
                    <Icon name="car" color="red" />
                    Archive vehicle
                  </Dropdown.Item>

                  {/* <Dropdown.Divider />
                  <Dropdown.Header>To be done</Dropdown.Header>
                  <Dropdown.Item>Transfer Vehicle</Dropdown.Item> */}
                </>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Menu>
      </Menu>
    );
  }
  renderConfirmArchive() {
    return (
      <Confirm
        header={`Are you sure you would like to archive '${
          this.props.vehicle.make
        }
        ${this.props.vehicle.model} 
        ${
          this.props.vehicle.callsign && "(" + this.props.vehicle.callsign + ")"
        }'?`}
        open={this.state.archive}
        onCancel={() => this.setState({ archive: false })}
        onConfirm={this.handleArchiveVehicle}
      />
    );
  }
  renderModals() {
    return (
      <React.Fragment>
        {this.renderConfirmArchive()}
        {this.renderAddKit()}
        {this.renderConfirmBox()}
        {this.renderEditVehicleModal()}
        {this.renderAppraisalModal()}
      </React.Fragment>
    );
  }
  renderNotes() {
    return (
      <NotesPage
        notes={this.props.vehicle.notes}
        addNote={this.addNote}
        whoami={this.props.whoami}
      />
    );
  }
  renderAssets() {
    return (
      <div className="mt-2">
        <Box title="Assigned Assets">
          <table className="min-w-full divide-y divide-gray-200 border-separate table-fixed">
            <thead>
              <tr className="bg-gray-800">
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-400 uppercase ">
                  Asset
                </th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-400 uppercase ">
                  Asset Number
                </th>
                <th className="px-4 py-3 text-center text-xs font-medium text-gray-400 uppercase ">
                  Purchase Date
                </th>
                <th className="px-4 py-3 text-center text-xs font-medium text-gray-400 uppercase ">
                  Expires Date
                </th>
                <th className="px-4 py-3 text-center text-xs font-medium text-gray-400 uppercase ">
                  Tags
                </th>
              </tr>
            </thead>
            <tbody className="bg-gray-900 divide-y divide-gray-200 text-lg">
              {this.props.assets.map((a) => {
                return (
                  <tr
                    key={a._id}
                    className={`cursor-pointer hover:bg-gray-800`}
                    onClick={() => this.props.history.push(`/assets/${a._id}`)}
                  >
                    <td className="p-2">{a.name}</td>
                    <td className="p-2">{a.assetNum}</td>
                    <td className="p-2   ">
                      {moment(a.date_purchased).format("DD-MM-YY")}
                    </td>
                    <td className="p-2 ">
                      {a.date_expires
                        ? moment(a.date_expires).format("DD-MM-YY")
                        : "--"}
                    </td>
                    <td className="p-2 ">
                      {a.tags.map((t, i) => {
                        return (
                          <span
                            key={i}
                            className="p-2 m-1 bg-gray-800 rounded-lg border border-gray-700"
                          >
                            {t}
                          </span>
                        );
                      })}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Box>
      </div>
    );
  }
  render() {
    const { activeItem } = this.state;
    return (
      <Grid stackable className="no-marg ">
        {this.renderModals()}
        <Grid.Row className={" no-pad"}>
          <Grid.Column width={16} className={" no-pad"}>
            {this.renderFullMenu()}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className={" no-pad"}>
          <Grid.Column width={16}>
            <React.Fragment>
              {activeItem === "dash" && this.renderDash()}
              {activeItem === "documents" && this.renderDocuments()}
              {activeItem === "details" && this.renderDetails()}
              {activeItem === "notes" && this.renderNotes()}
              {activeItem === "assets" && this.renderAssets()}
            </React.Fragment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
VehicleCard.propTypes = {
  //   user: PropTypes.object.isRequired,
  vehicle: PropTypes.object.isRequired,
};
export default withRouter(VehicleCard);
