import React from "react";
import {
  Icon,
  Segment,
  Button,
  Form,
  Table,
  Header,
  Divider,
  Checkbox,
} from "semantic-ui-react";

export default class SettingListItem extends React.Component {
  state = {
    name: "",
    valid: 1,
    isRequired: false,
  };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleChangeComplete = (data) => {
    this.setState({ isRequired: data });
  };
  handleSubmit = () => {
    let fd = this.props.data;
    fd.push({
      name: this.state.name,
      valid: this.state.valid,
      isRequired: this.state.isRequired,
    });
    this.setState({
      name: "",
      valid: 1,
      isRequired: false,
    });
    let field = this.props.field;
    const data = {};
    data[field] = fd;
    this.props.submit(data);
  };
  handleDelete = (obj) => {
    let newList = this.props.data.filter((item, i) => {
      return obj !== item;
    });
    let field = this.props.field;
    const data = {};
    data[field] = newList;
    this.props.delete(data);
  };
  render() {
    const form = (
      <Segment basic>
        <Form onSubmit={this.handleSubmit}>
          <Form.Group>
            <Form.Field width={10}>
              <input
                id="name"
                name="name"
                placeholder={this.props.label}
                value={this.state.name}
                onChange={this.handleChange}
              />
            </Form.Field>
            <Form.Field width={4}>
              <input
                id="valid"
                name="valid"
                placeholder="Valid for in years"
                value={this.state.valid}
                onChange={this.handleChange}
              />
            </Form.Field>
            <Form.Field width={2}>
              <Checkbox
                style={{ padding: 0.5 + "rem" }}
                label="required"
                checked={this.state.isRequired}
                onChange={(e) =>
                  this.setState({ isRequired: !this.state.isRequired })
                }
              />
            </Form.Field>
          </Form.Group>
          <Form.Button type="Submit" fluid positive>
            Add
          </Form.Button>
        </Form>
      </Segment>
    );
    let list;
    if (this.props.data) {
      list = this.props.data.map((item, i) => {
        return (
          <Table.Row key={i}>
            <Table.Cell>{item.name}</Table.Cell>
            <Table.Cell textAlign="center">{item.valid}</Table.Cell>
            <Table.Cell textAlign="center">
              {item.isRequired ? (
                <Icon name="check" size="large" />
              ) : (
                <Icon name="close" size="large" />
              )}
            </Table.Cell>
            <Table.Cell>
              <Button
                circular
                icon="close"
                floated="right"
                size="tiny"
                color="red"
                onClick={() => this.handleDelete(item)}
              />
            </Table.Cell>
          </Table.Row>
        );
      });
    }
    return (
      <Segment basic>
        <Header>{this.props.title}</Header>
        <Divider />
        {form}
        <Table stackable className={"settingTable"}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{this.props.label}</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                Vaild For (years)
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                is Required?
              </Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{list}</Table.Body>
        </Table>
      </Segment>
    );
  }
}
