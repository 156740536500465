import React, { Component } from "react";
import {
  Grid,
  Header,
  Segment,
  Popup,
  Button,
  Table,
  Divider,
  Form,
  Input,
  Checkbox,
  Menu,
  Icon,
} from "semantic-ui-react";
import Box from "../../../components/common/Box";
import { getSystemSettings } from "../../../actions/settingsActions";
import LocationMap from "../../common/mapping/locationMap";
import { connect } from "react-redux";
import api from "../../../actions/api";
import moment from "moment";
import CommonFunctions from "../../../assets/utils/commonFunctions";
import { Authentication } from "@liquidcomputing/sarstuff__reactjs-singlesignon";
function mapStateToProps(state) {
  return {
    system: state.system,
    user: state.user,
  };
}

class LocationPage extends Component {
  state = {
    loading: true,
    errors: {},
    data: {
      geoJSON: {
        properties: {
          name: "",
          address: "",
        },
      },
    },

    grid: "",
    coords: null,
    flyTo: {},
    location: {
      // geoJSON: {
      //   name: "",
      //   address: ""
      // }
    },
    map: true,
    tasks: [],
    assets: [],
    edit: false,
  };
  componentDidMount = () => {
    this.props.getSystemSettings().then(() => {});
    this.getData();
  };
  getData = () => {
    api.location.show(this.props.match.params.id).then((res) => {
      this.setState({
        location: {
          // ...this.state.location,
          ...res,
        },
        data: res,
        loading: false,
      });
      // }
    });
    api.assets
      .getbyAssigned("location", this.props.match.params.id)
      .then((assets) => {
        this.setState({
          assets,
        });
      });
  };
  getGrid = (lat, lng) => {
    return CommonFunctions.getGrid(
      lat,
      lng,
      this.props.system.country.gridType
    );
  };
  getLatLng = (grid) => {
    return CommonFunctions.getLatLng(grid, this.props.system.country.gridType);
  };
  getLatLngEN = (e, n) => {
    return CommonFunctions.getLatLngEN(e, n);
  };
  getLatLngUTM = (data) => {
    return CommonFunctions.getLatLngUTM(data);
  };

  findUTM = () => {
    const errors = this.validateGrid(this.state.grid);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      let data = this.getLatLngUTM(this.state.grid);
      let geoJSON = {
        geoJSON: {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [data.longitude, data.latitude],
          },
          properties: {
            name: data.grid,
            grid: data.grid,
          },
        },
      };
      this.setState({
        marker: geoJSON,
        grid: "",
      });
    }
  };
  findGrid = () => {
    const errors = this.validateGrid(this.state.grid);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      let data = this.getLatLng(this.state.grid);
      this.setState({
        coords: data,
        grid: "",
      });
    }
  };
  handleDropdownChange(props, e) {
    this.setState({
      location: {
        ...this.state.location,
        geoJSON: {
          ...this.state.location.geoJSON,
          properties: {
            ...this.state.location.geoJSON.properties,
            [e.name]: e.value,
          },
        },
      },
    });
  }
  onChange = (e) => {
    this.setState({
      location: {
        ...this.state.location,
        geoJSON: {
          ...this.state.location.geoJSON,
          properties: {
            ...this.state.location.geoJSON.properties,
            [e.target.name]: e.target.value,
          },
        },
      },
    });
  };
  validateGrid = (data) => {
    const errors = {};
    //TODO Tim can you check the first two characters are text and the reaming digits are even in number
    if (!data) errors.grid = true;
    return errors;
  };
  handleGridChange = (e) => {
    this.setState({
      grid: e.target.value.toUpperCase(),
    });
  };
  handleCheckboxChange = (props, e) => {
    this.setState({
      location: { ...this.state.location, [e.name]: e.checked },
    });
  };
  goto = (type = "event", id) => {
    if (type === "event") {
      this.props.history.push(`/calendar/${id}`);
    } else if (type === "course") {
      this.props.history.push(`/training/courses/${id}`);
    }
  };
  addPoint = (data) => {
    if (data) {
      return api.locations
        .updateCoords(this.props.match.params.id, data.geoJSON)
        .then(() => {
          this.getData();
        });
    }
  };
  onSubmit = () => {
    let data = this.state.location;
    if (data) {
      api.location.update(this.props.match.params.id, data).then(() => {
        this.setState({
          edit: false,
        });
        this.getData();
      });
    }
  };
  resetForm = () => {
    this.setState({
      data: this.state.location,
      edit: false,
    });
  };

  handleData = (data) => {
    if (this.state.edit) {
      if (this.state.location.geoJSON.geometry.type === "Point") {
        this.setState({
          location: {
            ...this.state.location,
            geoJSON: {
              ...this.state.location.geoJSON,
              geometry: {
                ...this.state.location.geoJSON.geometry,
                coordinates: data,
              },
              properties: {
                ...this.state.location.geoJSON.properties,
                grid: this.getGrid(data[1], data[0]),
                // name: this.getGrid(data[1], data[0])
              },
            },
          },
        });
      } else {
        let newData = this.createSector(data);
        if (newData) {
          this.setState({
            location: {
              ...this.state.location,
              geoJSON: {
                ...this.state.location.geoJSON,
                geometry: newData.geometry,
                properties: {
                  ...this.state.location.geoJSON.properties,
                  grid: this.getGrid(data[1], data[0]),
                  // name: this.getGrid(data[1], data[0])
                },
              },
            },
          });
        }
      }
    }
  };
  renderMap() {
    let center, marker, mast;

    let disabled = false;

    if (this.state.location && this.state.location.geoJSON) {
      if (this.state.location.geoJSON.geometry.type === "Point") {
        marker = this.state.location;
        mast = null;
        center = this.state.location.geoJSON.geometry.coordinates;
      } else {
        mast = this.state.location;
        marker = null;
        center = this.state.location.geoJSON.geometry.coordinates[0][0];
      }
      return (
        <LocationMap
          center={center}
          marker={marker}
          data={this.handleData}
          mast={mast}
          flyTo={this.state.flyTo}
          readOnly={disabled}
          edit={true}
          country={this.props.system.country}
        />
      );
    }
  }
  renderLocation() {
    let loc = this.state.location;
    if (loc && loc.geoJSON) {
      let properties = loc.geoJSON.properties;
      return (
        <Segment basic className="no-pad">
          <Segment className="subtle ">
            <Grid celled textAlign="left" className="no-pad   no-marg">
              <Grid.Row>
                <Grid.Column width={16}>
                  <p className="boxHeader">
                    {properties.name} {"  "}
                    <span style={{ color: "red" }}>
                      {loc.hide && "  ******hidden*****"}
                    </span>
                  </p>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column width={16} className=" no-pad">
                  {!loc.hide && (
                    <Menu widths={3}>
                      <Popup
                        trigger={
                          <Menu.Item onClick={this.createGPX}>
                            <Icon name="map" />
                            GPX
                          </Menu.Item>
                        }
                        content="Download GPX file"
                      />
                      <Popup
                        trigger={
                          <Menu.Item onClick={this.convertPlantoKML}>
                            <Icon name="map marker" />
                            KML
                          </Menu.Item>
                        }
                        content="Create KML for google earth"
                      />
                      {Authentication.can("locations:update") && (
                        <Popup
                          trigger={
                            <Menu.Item
                              onClick={() => this.setState({ edit: true })}
                            >
                              <Icon name="pencil" />
                              Edit
                            </Menu.Item>
                          }
                          content="Create KML for google earth"
                        />
                      )}
                    </Menu>
                  )}
                </Grid.Column>
              </Grid.Row>
              {properties.address && (
                <Grid.Row>
                  <Grid.Column className="lightBak" width={16}>
                    <Header>
                      Address:
                      <span>{properties.address}</span>
                    </Header>
                  </Grid.Column>
                </Grid.Row>
              )}
              <Grid.Row>
                <Grid.Column className="lightBak" width={16}>
                  <Header>
                    Grid:
                    <span>{properties.grid}</span>
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column className="lightBak">
                  <Header>
                    What3Words:
                    <span>{properties.w3w}</span>
                  </Header>
                </Grid.Column>
              </Grid.Row>
              {loc.geoJSON.geometry.type === "Polygon" && (
                <Grid.Row>
                  <Grid.Column className="lightBak " width={6}>
                    <Header>
                      Start:
                      <span>{properties.start}&deg;</span>
                    </Header>
                  </Grid.Column>
                  <Grid.Column className="lightBak" width={6}>
                    <Header>
                      Finish:
                      <span>{properties.finish}&deg;</span>
                    </Header>
                  </Grid.Column>
                  <Grid.Column className="lightBak" width={4}>
                    <Header>
                      Distance:
                      <span>{properties.distance}km</span>
                    </Header>
                  </Grid.Column>
                </Grid.Row>
              )}
            </Grid>
          </Segment>
          <Segment className="subtle">
            <Grid celled textAlign="left" className="   no-marg">
              <Grid.Row>
                <Grid.Column width={16}>
                  <p className="boxHeader">Events</p>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16} className="no-pad">
                  {this.renderEvents()}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Segment>
      );
    }
  }

  renderEditLocation() {
    return (
      <Segment>
        <Form onSubmit={this.onSubmit}>
          <Form.Field>
            <label htmlFor="name">Name</label>
            <Input
              name="name"
              placeholder="name"
              value={this.state.location.geoJSON.properties.name}
              onChange={this.onChange}
            />
          </Form.Field>
          <Form.Field>
            <label htmlFor="address">Address</label>
            <Input
              name="address"
              placeholder="address"
              value={
                this.state.location.geoJSON.properties.address
                  ? this.state.location.geoJSON.properties.address
                  : ""
              }
              onChange={this.onChange}
            />
          </Form.Field>
          <Form.Field>
            <label htmlFor="hide">Location Visability</label>
            <Checkbox
              style={{ padding: 10 }}
              toggle
              id="hide"
              name="hide"
              checked={this.state.location.hide}
              label={
                this.state.location.hide ? "Un-Hide location" : "Hide location."
              }
              onChange={this.handleCheckboxChange}
            />
          </Form.Field>

          <Divider />
          <Form.Group widths={"equal"} className={"form-actions"}>
            <Form.Field>
              <Button negative floated={"left"} onClick={this.resetForm}>
                Cancel
              </Button>
            </Form.Field>
            <Form.Field>
              <Button positive floated={"right"}>
                Update
              </Button>
            </Form.Field>
          </Form.Group>
        </Form>
      </Segment>
    );
  }
  renderEvents() {
    const events = this.state.location.events.map((event) => {
      let row = "";
      if (event.hide) {
        row = "hidden";
      }
      return (
        <Table.Row
          key={event._id}
          className={row}
          onClick={() => this.goto(event.class, event._id)}
        >
          <Table.Cell>
            {moment(event.start).format("DD-MM-YY HH:mm")}
          </Table.Cell>
          <Table.Cell>{moment(event.end).format("DD-MM-YY HH:mm")}</Table.Cell>
          <Table.Cell>
            {event.class === "course" ? event.type : event.details}
          </Table.Cell>
          <Table.Cell>
            {event.class === "course"
              ? event.organiserName
              : event.organiser !== undefined && event.organiser !== null
              ? event.organiser.name
              : "[UNKNOWN]"}
          </Table.Cell>
        </Table.Row>
      );
    });
    return (
      <Table stackable selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Start</Table.HeaderCell>
            <Table.HeaderCell>End</Table.HeaderCell>
            <Table.HeaderCell>Details</Table.HeaderCell>
            <Table.HeaderCell>Organiser</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{events}</Table.Body>
      </Table>
    );
  }
  renderAssets() {
    return (
      <table className="min-w-full divide-y divide-gray-200 border-separate table-fixed">
        <thead>
          <tr className="bg-gray-800">
            <th className="px-4 py-3 text-left text-xs font-medium text-gray-400 uppercase ">
              Asset
            </th>
            <th className="px-4 py-3 text-left text-xs font-medium text-gray-400 uppercase ">
              Asset Number
            </th>
            <th className="px-4 py-3 text-center text-xs font-medium text-gray-400 uppercase ">
              Purchase Date
            </th>
            <th className="px-4 py-3 text-center text-xs font-medium text-gray-400 uppercase ">
              Expires Date
            </th>
            <th className="px-4 py-3 text-center text-xs font-medium text-gray-400 uppercase ">
              Tags
            </th>
          </tr>
        </thead>
        <tbody className="bg-gray-900 divide-y divide-gray-200 text-lg">
          {this.state.assets.map((a) => {
            return (
              <tr
                key={a._id}
                className={`cursor-pointer hover:bg-gray-800`}
                onClick={() => this.props.history.push(`/assets/${a._id}`)}
              >
                <td className="p-2">{a.name}</td>
                <td className="p-2">{a.assetNum}</td>
                <td className="p-2   ">
                  {moment(a.date_purchased).format("DD-MM-YY")}
                </td>
                <td className="p-2 ">
                  {a.date_expires
                    ? moment(a.date_expires).format("DD-MM-YY")
                    : "--"}
                </td>
                <td className="p-2 ">
                  {a.tags.map((t, i) => {
                    return (
                      <span
                        key={i}
                        className="p-2 m-1 bg-gray-800 rounded-lg border border-gray-700"
                      >
                        {t}
                      </span>
                    );
                  })}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
  render() {
    const { assets } = this.state;
    return (
      <Segment basic loading={this.state.loading}>
        <Grid stackable className="no-marg">
          <Grid.Row>
            <Grid.Column width={6}>
              {/*  */}
              {this.state.edit
                ? this.renderEditLocation()
                : this.renderLocation()}
            </Grid.Column>
            <Grid.Column width={10}>
              <Box>
                <Menu widths={2}>
                  <Menu.Item onClick={() => this.setState({ map: true })}>
                    <Icon name="map" />
                    Map
                  </Menu.Item>
                  {assets.length > 0 && (
                    <Menu.Item onClick={() => this.setState({ map: false })}>
                      <Icon name="boxes" />
                      Assets
                    </Menu.Item>
                  )}
                </Menu>
                {this.state.map ? this.renderMap() : this.renderAssets()}
              </Box>
              {/* {this.renderSearchBar()} */}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}
export default connect(mapStateToProps, { getSystemSettings })(LocationPage);
