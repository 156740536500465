const VersionNumber = "1.5";
const ChangeLogList = [
  {
    date: "12/04/2024",
    type: "Bug Fix",
    details: "Team > Activity Report. Last training date incorrectly reporting the wrong date."
  },
  {
    date: "09/11/2023",
    type: "Bug Fix",
    details: "Training dashboard, next 10 training events date order fix."
  },
  {
    date: "30/08/2023",
    type: "Bug Fix",
    details: "Training dashboard, clicking a course takes you to the correct event page."
  },
  {
    date: "06/06/2023",
    type: "Enhancement",
    details: "Show the asset serial number on the asset list."
  },
  {
    date: "14/04/2023",
    type: "Enhancement",
    details: "Added the ability to pull jobs from SarSYs [CAD] as SarSYS incidents within TeamSite."
  },
  {
    date: "28/03/2023",
    type: "Bug Fix",
    details: "Resolved issue with member vehicles photos not being returned.",
  },
  {
    date: "22/08/2022",
    type: "Enhancement",
    details: "Added new security role same management but without access to settings."
  },
  {
    date: "04/08/2022",
    type: "Enhancement",
    details: "Security enhancements.", // Updated the SSO plugin to support session timeout
  },
  {
    date: "14/07/2022",
    type: "Enhancement",
    details: "Vehicle list can now be filtered by the number of seats.",
  },
  {
    date: "15/06/2022",
    type: "Bug Fix",
    details: "Bug with the date picker on courses, this has been replaced.",
  },
  {
    date: "10/06/2022",
    type: "Enhancement",
    details: "Added date joined field for a member",
  },
  {
    date: "10/06/2022",
    type: "Enhancement",
    details: "Viewing CPD logs and adding via APP",
  },
  {
    date: "18/05/2022",
    type: "Enhancement",
    details: "Enable filter by callsign on teamlist",
  },
  {
    date: "18/05/2022",
    type: "Enhancement",
    details: "Enable DVLA check for users on their own vehicle",
  },
  {
    date: "18/05/2022",
    type: "Bug fixes",
    details: "Enable vehicles to be deleted in main TeamSite",
  },
  {
    date: "18/05/2022",
    type: "Enhancement",
    details: "Added Change log",
  },
];

export { VersionNumber, ChangeLogList };
